import React, { useEffect } from "react"
import { StateMachineProvider, StateMachineStepView } from "../../context/stateMachine/StateMachineV2"
import {
  caseRateRenewalSteps,
  caseRateRenewalWorkflows,
  Steps as CaseRateRenewalSteps,
  Workflows as CaseRateRenewalWorkflows
} from "./workflow"
import {
  AdvancedCheckInWarningQueryString,
  CaseRateRenewalsContextProvider,
  useCaseRateRenewals
} from "./useCaseRateRenewals"
import useCarrotPlan from "../hooks/useCarrotPlan"
import { useGetDaysSinceLastUnlock, useSetNonStaleCarrotPlanOnAnniversaryCheck } from "./useQueryCaseRateRenewals"
import { useHistory } from "react-router-dom"
import queryString from "query-string"
import { getHeap } from "../../../utils/heap"
import { AdvancedCheckInWarning } from "./AdvancedCheckInWarning"
import { Box, CloseIcon, Dialog, IconButton, useMediaQuery, useTheme } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"

type CarrotPlansGatedAccountContainerProps = {
  shouldCrrModalRender: boolean
  showAdvancedCheckInFeatures: boolean
  isCrrAdvancedCheckIn: boolean
}

type CarrotPlansGatedAccountModalProps = {
  showAdvancedCheckInFeatures: boolean
  isCrrAdvancedCheckIn: boolean
}

enum Sections {
  CASE_RATE_RENEWAL = "CaseRateRenewal"
}

function CarrotPlansGatedAccountModal({
  showAdvancedCheckInFeatures,
  isCrrAdvancedCheckIn
}: CarrotPlansGatedAccountModalProps): JSX.Element {
  const { openGatedAccountModal, disableModalCloseOnLoading } = useCaseRateRenewals()
  const { mutate: setNonStaleCarrotPlanOnAnniversaryCheck } = useSetNonStaleCarrotPlanOnAnniversaryCheck()
  const { data: daysFromLastUnlock } = useGetDaysSinceLastUnlock()
  const history = useHistory()
  const { step } = queryString.parse(window.location.search)

  if (isCrrAdvancedCheckIn) {
    getHeap().track("CaseRateRenewalsAdvancedCheckIn", { EventName: "Advanced check in opened" })
  }

  useEffect(() => {
    if (daysFromLastUnlock >= 365) {
      setNonStaleCarrotPlanOnAnniversaryCheck(null)
    }
  }, [daysFromLastUnlock, setNonStaleCarrotPlanOnAnniversaryCheck])

  const caseRateRenewalSection = {
    name: Sections.CASE_RATE_RENEWAL,
    initialStep: showAdvancedCheckInFeatures
      ? CaseRateRenewalSteps.CARROT_PLAN_OPTIONS
      : CaseRateRenewalSteps.WELCOME_BACK_INTRO,
    desiredWorkflow: CaseRateRenewalWorkflows.CASE_RATE_RENEWAL,
    steps: caseRateRenewalSteps,
    workflows: caseRateRenewalWorkflows
  }
  const intl = useIntl()

  const handleClose = showAdvancedCheckInFeatures
    ? () => history.push(`/?step=${AdvancedCheckInWarningQueryString}`)
    : null

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <Dialog open={openGatedAccountModal} onClose={handleClose} fullScreen={fullScreen}>
      <StateMachineProvider
        initialSection={Sections.CASE_RATE_RENEWAL}
        stateMachineDescription={[caseRateRenewalSection]}
        onComplete={null}
      >
        <Box display="flex" justifyContent="flex-end">
          {showAdvancedCheckInFeatures && step !== AdvancedCheckInWarningQueryString && !disableModalCloseOnLoading && (
            <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        <Box data-testid="crr-modal">
          {step === AdvancedCheckInWarningQueryString ? <AdvancedCheckInWarning /> : <StateMachineStepView />}
        </Box>
      </StateMachineProvider>
    </Dialog>
  )
}

export function CarrotPlansGatedAccountContainer({
  shouldCrrModalRender,
  showAdvancedCheckInFeatures,
  isCrrAdvancedCheckIn
}: CarrotPlansGatedAccountContainerProps): JSX.Element {
  const { data: carrotPlan, isLoading: carrotPlanIsLoading } = useCarrotPlan()

  return !carrotPlanIsLoading && carrotPlan ? (
    <CaseRateRenewalsContextProvider shouldCrrModalRender={shouldCrrModalRender}>
      <CarrotPlansGatedAccountModal
        showAdvancedCheckInFeatures={showAdvancedCheckInFeatures}
        isCrrAdvancedCheckIn={isCrrAdvancedCheckIn}
      />
    </CaseRateRenewalsContextProvider>
  ) : null
}
