import React from "react"
import { useContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { AdditionalTopicsLink, SubCategoryLink, useReadLearnLinks } from "./shared/ReadLearnLinks"
import { Box, Link, RightIcon, Typography, Tile } from "@carrotfertility/carotene-core"
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"
import { Category } from "./shared/CategoryMapper"

type ReadLearnCategoryProps = {
  category: Category
}

function CategoryLink(props: RouterLinkProps) {
  return (
    <Link {...props} component={RouterLink} color="inherit" endIcon={<RightIcon fontSize="medium" />}>
      {props.children}
    </Link>
  )
}

export default function ReadLearnCategory({ category }: ReadLearnCategoryProps): JSX.Element {
  const { subCategories } = category
  const { categoryLink } = useReadLearnLinks({ category })

  const subCategoryToConditions = React.useMemo(
    () =>
      subCategories.map((subCategory) => ({
        element: (
          <SubCategoryLink
            key={`id-${subCategory?.fields?.navTitle ?? subCategory?.fields?.title}`}
            {...{ category, subCategory }}
          />
        ),
        conditions: subCategory?.fields?.conditions
      })),
    [category, subCategories]
  )
  const filteredSubCategory = useContentfulConditionalGate(subCategoryToConditions)
  const { showAdditionalTopics, additionalTopicCount, linksToShow } = {
    showAdditionalTopics: filteredSubCategory.length > 3,
    additionalTopicCount: filteredSubCategory.length - 3,
    linksToShow: filteredSubCategory.slice(0, 3)
  }
  return (
    <Tile>
      <Box
        display="flex"
        flexDirection="column"
        gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        minHeight="256px"
      >
        <img src={category?.icon?.file?.url} width="36px" height="36px" alt="" />
        <Typography variant="h4" component={CategoryLink} to={categoryLink}>
          {category?.title}
        </Typography>
        <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
          {linksToShow}
        </Box>
        {showAdditionalTopics ? (
          <AdditionalTopicsLink categoryLink={categoryLink} additionalTopicCount={additionalTopicCount} />
        ) : null}
      </Box>
    </Tile>
  )
}
