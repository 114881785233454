import React, { ReactElement, useState } from "react"
// @ts-expect-error TS7016
import { v4 as uuid } from "uuid"
import { Container, FlexContainer, Stack } from "@carrotfertility/carotene"
import { UploadFile } from "components/views/reimbursements/upload/UploadFile"
import ArrowSubmitButton from "features/legacy-components/ArrowSubmitButton"
import { UploadDropzoneWithIllustration } from "components/views/reimbursements/upload/UploadDropzoneWithIllustration"
import CarrotClient from "lib/carrot-api/index"
import AttachmentStates from "../../../utils/AttachmentStates"
import Settings from "../../../utils/CarrotConfig"
import { HR } from "components/views/atoms/Atoms"
import { FormattedMessage } from "react-intl"
import { Box, Button, RightIcon } from "@carrotfertility/carotene-core"
import FileCategories from "../../../utils/FileCategories"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const MAX_FILE_SIZE = Settings.MAX_FILE_SIZE

export interface AttachmentType {
  file: File
  state: AttachmentStates
  guid: string
  fileCategory: string
}

// FileType is needed so that if a user goes back we can display their uploaded file with a name
export type FileTypes = Array<{ name: string; guid: string }>

type UploadFileProps = {
  fileCategory: FileCategories
  header: ReactElement
  body: ReactElement
  onContinueClick: (attachments: FileTypes) => void
  files: FileTypes
  useArrowButton: boolean
}

const uploadAttachment = async (attachment: AttachmentType, guid: string, fileCategory: string): Promise<string> => {
  const successResponseGuid = await client.uploadReimbursementFile(attachment.file, guid, fileCategory)
  return successResponseGuid
}

const findAttachmentByGuid = (attachments: AttachmentType[], guid: string): AttachmentType =>
  attachments.find((attachment) => attachment.guid === guid)

const toUploading = (attachment: AttachmentType): AttachmentType => {
  attachment.state = AttachmentStates.UPLOADING
  return attachment
}
const toComplete = (attachment: AttachmentType): AttachmentType => {
  attachment.state = AttachmentStates.COMPLETED
  return attachment
}
const toFailed = (attachment: AttachmentType): AttachmentType => {
  attachment.state = AttachmentStates.FAILED
  return attachment
}

const toVirusDetected = (attachment: AttachmentType): AttachmentType => {
  attachment.state = AttachmentStates.VIRUSDETECTED
  return attachment
}

const getFileNameAndGuid = (attachment: AttachmentType): Record<string, string> => ({
  guid: attachment.guid,
  name: attachment.file.name
})

export const UploadFileModalStep = ({
  fileCategory,
  header,
  body,
  onContinueClick,
  files,
  useArrowButton
}: UploadFileProps): JSX.Element => {
  const [attachments, setAttachments] = useState<AttachmentType[]>(() => {
    // if the user has come back to this step we initialize the list of attachments
    // from the file name and guid saved in the reimbursement context. we can assume the state is COMPLETED
    if (files.length) {
      return files.map((file: any) => {
        return {
          file: {
            name: file.name
          },
          state: AttachmentStates.COMPLETED,
          guid: file.guid,
          fileCategory: fileCategory
        } as AttachmentType
      })
    }
    return []
  })

  // @ts-expect-error TS7006
  const processAcceptedDrop = (files): void => {
    // @ts-expect-error TS7006
    const newAttachments: AttachmentType[] = files.map((file) => ({
      file,
      state: file.size > MAX_FILE_SIZE ? AttachmentStates.OVERSIZED : AttachmentStates.UPLOADING,
      guid: uuid(),
      fileCategory: fileCategory
    }))

    setAttachments((prevAttachments) => [...prevAttachments, ...newAttachments])

    const attachmentsToUpload = newAttachments.filter((a) => a.file.size <= MAX_FILE_SIZE)

    attachmentsToUpload.forEach(async (attachment) => {
      const guid = attachment.guid
      try {
        await uploadAttachment(attachment, guid, fileCategory)
        toComplete(attachment)
      } catch (error) {
        const errorBody = await error?.response?.text()
        if (errorBody === "Virus detected in file upload") {
          toVirusDetected(attachment)
        } else {
          toFailed(attachment)
        }
      } finally {
        setAttachments((attachments) => {
          const index = attachments.findIndex((a) => a.guid === attachment.guid)
          attachments[index] = attachment
          return [...attachments]
        })
      }
    })
  }

  // @ts-expect-error TS7006
  const processRejectedDrop = (files): void => {
    // @ts-expect-error TS7006
    const newAttachments: AttachmentType[] = files.map((file) => ({
      file: file.file,
      state: AttachmentStates.INVALIDTYPE,
      guid: uuid(),
      fileCategory: fileCategory
    }))

    setAttachments((prevAttachments) => [...prevAttachments, ...newAttachments])
  }

  // removes the attachment from component state
  const onDelete = (guid: string): void => {
    setAttachments((prevAttachments) => prevAttachments.filter((a) => a.guid !== guid))
  }
  const onRetry = async (guid: string): Promise<void> => {
    const attachment = toUploading(findAttachmentByGuid(attachments, guid))
    try {
      await uploadAttachment(attachment, guid, fileCategory)
      toComplete(attachment)
    } catch {
      toFailed(attachment)
    } finally {
      setAttachments((attachments) => {
        const index = attachments.findIndex((a) => a.guid === attachment.guid)
        attachments[index] = attachment
        return [...attachments]
      })
    }
  }

  return (
    <>
      <Container paddingTopBottom="medium">
        {header}
        {body}
      </Container>
      <Container
        sx={{
          paddingBottom: "huge"
        }}
      >
        <UploadDropzoneWithIllustration
          showNew
          small
          onDropAccepted={processAcceptedDrop}
          onDropRejected={processRejectedDrop}
        />
      </Container>
      <Box
        component="ul"
        sx={{
          "> li::before": {
            display: "none"
          }
        }}
      >
        {attachments.map((attachment) => (
          <li key={attachment.guid}>
            <Stack spacing="small" stack="small">
              <HR color="black-10" />
              <UploadFile attachment={attachment} onDelete={onDelete} onRetry={onRetry} />
            </Stack>
          </li>
        ))}
      </Box>
      <FlexContainer paddingTopBottom={useArrowButton ? "huge" : "none"} justifyContent="flex-end">
        {useArrowButton ? (
          <ArrowSubmitButton
            enabled={
              attachments.length > 0 && attachments.filter((a) => a.state !== AttachmentStates.COMPLETED).length === 0
            }
            onClick={() => onContinueClick(attachments.map(getFileNameAndGuid) as FileTypes)}
          >
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        ) : (
          <Button
            disabled={
              attachments.length === 0 || attachments.filter((a) => a.state !== AttachmentStates.COMPLETED).length > 0
            }
            onClick={() => onContinueClick(attachments.map(getFileNameAndGuid) as FileTypes)}
            variant="outlined"
            color="secondary"
            endIcon={<RightIcon />}
          >
            <FormattedMessage defaultMessage="Continue" />
          </Button>
        )}
      </FlexContainer>
    </>
  )
}
