import React, { useContext, useState } from "react"
import CarrotClient from "carrot-api"
import { Alert, Box, Button, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import Settings from "utils/CarrotConfig"
import { sleep } from "utils/Helpers"
import { useDidMount } from "utils/Hooks"
import { FormattedMessage, useIntl } from "react-intl"
import { HttpErrors } from "utils/HttpErrors"
import { getCountryCallingCode } from "libphonenumber-js"
import { TtcPaths } from "../../../utils/Paths"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const DefaultVerifyPhoneNumberState = {
  isResendLinkVisible: false,
  showResendHelperText: false,
  isOtherError: false,
  isIncorrectCode: false
}

const VerifyPhoneNumber = () => {
  const { phoneNumber, countryCode, isUsaCompany } = useContext(RequestCardContext)
  const [state, setState] = useState(DefaultVerifyPhoneNumberState)
  const theme = useTheme()
  const intl = useIntl()

  const { canGoBack, send } = useStateMachine()

  useDidMount(() => {
    enableResend()
  })

  const enableResend = async () => {
    await sleep(4000)
    setState({ ...state, isResendLinkVisible: true, showResendHelperText: false })
  }

  const handleResendClick = async () => {
    resendVerification()
    setState({ ...state, isResendLinkVisible: false, showResendHelperText: true })
    enableResend()
  }

  const resendVerification = async () => {
    const phoneCountryCode = getCountryCallingCode(countryCode)
    const phoneNumberWithCountryCode = `${phoneCountryCode}${phoneNumber}`
    try {
      await client.startPhoneVerification(phoneNumberWithCountryCode, countryCode)
    } catch (error) {
      setState({ ...state, isOtherError: true, isIncorrectCode: false })
    }
    return true
  }

  // @ts-expect-error TS7006
  const onSubmission = async (formValues) => {
    try {
      setState({ ...state, isOtherError: false, isIncorrectCode: false })
      const phoneCountryCode = getCountryCallingCode(countryCode)
      const phoneNumberWithCountryCode = `${phoneCountryCode}${phoneNumber}`
      await client.verifyPhoneNumber(phoneNumberWithCountryCode, formValues.verificationCode, countryCode)
      send("")
    } catch (e) {
      if (e.name === HttpErrors.NOT_FOUND) {
        setState({ ...state, isOtherError: false, isIncorrectCode: true })
      } else {
        setState({ ...state, isOtherError: true, isIncorrectCode: false })
      }
    }
  }

  const countryPhoneCode = getCountryCallingCode(countryCode)
  const formattedNumber = phoneNumber
    ? `+${countryPhoneCode} ${phoneNumber}`
    : intl.formatMessage({ defaultMessage: "your number" })

  const { isResendLinkVisible, isOtherError, isIncorrectCode, showResendHelperText } = state

  return (
    <Form onSubmit={onSubmission}>
      <RequestCardWrapper showBackButton={canGoBack} showContinueButton sectionNum={isUsaCompany ? 2 : 1}>
        <div>
          <Typography variant="h3" component="h2">
            <FormattedMessage defaultMessage="Confirm your phone number" />
          </Typography>
          <Stack spacing={theme.tokens.spacing.xl}>
            <Typography variant="body1">
              <FormattedMessage
                defaultMessage="Enter the 6-digit verification code we sent to {formattedNumber}."
                values={{
                  formattedNumber
                }}
              />
            </Typography>
            <Stack spacing={theme.tokens.spacing.lg}>
              {isIncorrectCode && (
                <Alert severity="error">
                  <FormattedMessage defaultMessage="Incorrect code" />
                </Alert>
              )}
              <FormTextField
                name={"verificationCode"}
                label={intl.formatMessage({ defaultMessage: "Verification Code" })}
                error={state.isIncorrectCode}
                required={true}
                helperText={
                  showResendHelperText
                    ? intl.formatMessage({ defaultMessage: "A new code has been sent to your phone number." })
                    : ""
                }
                registerOptions={{
                  required: intl.formatMessage({ defaultMessage: "Required" })
                }}
              />
              {isResendLinkVisible ? (
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    onClick={handleResendClick}
                    color="secondary"
                    size="medium"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <FormattedMessage defaultMessage="Resend code" />
                  </Button>
                </Box>
              ) : (
                <Typography>
                  <FormattedMessage defaultMessage="Code sent to {phoneNumber}." values={{ phoneNumber }} />
                </Typography>
              )}
            </Stack>
            {isOtherError && (
              <Alert severity="error">
                <FormattedMessage
                  defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
                  values={{
                    link: (linkContent) => (
                      <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Alert>
            )}
          </Stack>
        </div>
      </RequestCardWrapper>
    </Form>
  )
}

export { VerifyPhoneNumber }
