import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "utils/CarrotClient"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { useSelector } from "react-redux"
import { getCompanyId, getCountryCode } from "reducers/companyInfo"

export type PhoneSupportNumber = {
  internationalFormatPhoneNumber: string
  e164FormatPhoneNumber: string
}

const phoneSupportNumberQueryKey = "getPhoneSupportNumber"

export function useGetPhoneSupportNumber(
  companyId: string,
  countryCode: string
): UseQueryResult<PhoneSupportNumber, ResponseError> {
  return useQuery<PhoneSupportNumber, ResponseError>(
    [phoneSupportNumberQueryKey],
    () => carrotClient.getPhoneSupportNumber({ companyId, countryCode }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) =>
        reportErrorMessage(
          `Failed to fetch phone number for company ${companyId} and countryCode ${countryCode}, error: ${error}`
        )
    }
  )
}

export function useGetPhoneSupportNumberForUser(): UseQueryResult<PhoneSupportNumber, ResponseError> {
  const companyId = useSelector(getCompanyId)
  const countryCode = useSelector(getCountryCode)
  return useGetPhoneSupportNumber(companyId, countryCode)
}
