import slugify from "slugify"
import {
  IArticle,
  ICategory,
  IGroupSession,
  IGuide,
  IGuideSection,
  ISubCategory,
  IVideo
} from "../../../contentful-types"
import ContentTypes, { ReadLearnArticleItem } from "../../../utils/ContentTypes"
import { ExpertBio } from "./ExpertBioMapper"

export const findCategoryBySlug = (categories: Array<ICategory>, categorySlug: string): ICategory =>
  categories.find((c) => slugify(c.fields.navTitle) === categorySlug)

export const findArticlesBySlug = (categories: Array<ICategory>, articleSlug: string): ReadLearnArticleItem[] =>
  categories
    .map((c) => c.fields.subCategories.map((s) => s.fields.articles))
    .flat(Infinity)
    .filter((a) => a !== undefined)
    .filter(
      (a: ReadLearnArticleItem) =>
        (a as IArticle | IGuide | IGroupSession).fields.slug === articleSlug || slugify(a.fields.title) === articleSlug
    ) as (IArticle | IGuide | IVideo | IGroupSession)[]

export const findGuidesBySlug = (categories: Array<ICategory>, guideSlug: string): IGuide[] =>
  categories
    .map((c) => c.fields.subCategories.map((s) => s.fields.articles))
    .flat(Infinity)
    .filter((a) => a !== undefined)
    .filter((a: IGuide) => a.sys.contentType.sys.id === ContentTypes.GUIDE)
    .filter((a: IGuide) => (a as IGuide).fields.slug === guideSlug) as IGuide[]

export const findSubcategoryForArticle = (categories: Array<ICategory>, article: ReadLearnArticleItem): ISubCategory =>
  categories
    .map((c) => c.fields.subCategories)
    .flat(Infinity)
    .filter((s) => s !== undefined)
    .find((s: ISubCategory) => {
      return s.fields.articles.includes(article)
    }) as ISubCategory

export const findSectionBySlug = (guide: IGuide, section: string): IGuideSection => {
  if (guide) {
    return guide.fields.sections.find((s) => {
      return s.fields.slug.includes(section)
    }) as IGuideSection | undefined
  }
}

export const findGroupSessionsBySlug = (categories: Array<ICategory>, groupSlug: string): IGroupSession[] =>
  categories
    .map((c) => c.fields.subCategories.map((s) => s.fields.articles))
    .flat(Infinity)
    .filter((a) => a !== undefined)
    .filter((a: IGroupSession) => a.sys.contentType.sys.id === ContentTypes.GROUP_SESSION)
    .filter((a: IGroupSession) => (a as IGroupSession).fields.slug === groupSlug) as IGroupSession[]

export const findModeratorBySlug = (moderators: Array<ExpertBio>, moderatorSlug: string): ExpertBio[] => {
  return moderators.filter((m) => {
    return m.slug === moderatorSlug
  }) as ExpertBio[] | undefined
}
