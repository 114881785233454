import { Steps } from "./steps"

import { RequestCardInformation } from "../steps/RequestCardInformation"
import { RequestCardMailingAddress } from "../steps/RequestCardMailingAddress"
import { ConfirmPhoneNumber } from "../steps/ConfirmPhoneNumber"
import { VerifyPhoneNumber } from "../steps/VerifyPhoneNumber"
import { StripeUserAgreement } from "../steps/StripeUserAgreement"
import { RequestCardSuccess } from "../steps/RequestCardSuccess"

export const benefitEnrollmentSteps = [
  { name: Steps.REQUEST_CARD_INFORMATION, component: RequestCardInformation },
  { name: Steps.MAILING_ADDRESS, component: RequestCardMailingAddress },
  { name: Steps.CONFIRM_PHONE_NUMBER, component: ConfirmPhoneNumber },
  { name: Steps.VERIFY_PHONE_NUMBER, component: VerifyPhoneNumber },
  { name: Steps.STRIPE_USER_AGREEMENT, component: StripeUserAgreement },
  { name: Steps.REQUEST_CARD_SUCCESS, component: RequestCardSuccess }
]
