import { Button } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"

export function SearchThisLocationButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <Button
      onClick={onClick}
      id="provider-finder-search-this-location-button"
      variant="contained"
      size="small"
      color="secondary"
      sx={{
        position: "absolute",
        top: 0,
        left: "50%",
        marginTop: "24px",
        transform: "translate(-50%, 0)"
      }}
    >
      <FormattedMessage defaultMessage="Search this location" />
    </Button>
  )
}
