import React, { useContext, useState } from "react"
import { useHistory } from "react-router"
import CarrotClient from "lib/carrot-api"
import Settings from "utils/CarrotConfig"
import { isHttpError } from "utils/HttpErrors"
import { CarrotErrorCodes, getErrorMessageFromCode } from "utils/CarrotErrors"
import { useIntl } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { useAuthentication } from "../../context/authentication/AuthenticationProvider"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"

interface SamlNoEmailOnboardingProviderProps {
  onSubmit: ({ email }: { email: string }) => Promise<void>
  getTranslatedErrorMessage: (errorCode: string) => string | JSX.Element | (string | JSX.Element)[]
  error: string
  loading: boolean
  heading: string
  subheading: string
}

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const SamlNoEmailOnboardingContext = React.createContext<SamlNoEmailOnboardingProviderProps>(null)

export const useSamlNoEmailOnboardingContext = () => useContext(SamlNoEmailOnboardingContext)

// @ts-expect-error TS7031
export function SamlNoEmailOnboardingProvider({ children }) {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const intl = useIntl()
  const { locale } = intl
  const { handleSignOutNoRedirect } = useAuthentication()

  const heading = intl.formatMessage({ defaultMessage: "What is your email address?" })
  const subheading = intl.formatMessage({
    defaultMessage: "This is where Carrot will send you updates about your benefit, reminders, tips, and more."
  })

  // @ts-expect-error TS7031
  const onSubmit = async ({ email }) => {
    setError("")
    setLoading(true)

    try {
      const response = await client.lookup(email)

      if (response.status != null) {
        setError(response.status)
        setLoading(false)
        return
      }
      if (response?.employee != null) {
        setError(CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL)
        setLoading(false)
        return
      }
    } catch (error) {
      setError(CarrotErrorCodes.INTERNAL_SERVER_ERROR)
      setLoading(false)
      return
    }

    try {
      await client.samlNoEmailOnboardingAddEmailToAccount(email)
    } catch (error) {
      if (isHttpError(error)) {
        const { code } = await error.response.json()
        if (code === CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL || code === CarrotErrorCodes.EMPLOYEE_NOT_FOUND) {
          setError(code)
          setLoading(false)
          return
        }
      }
      setError(CarrotErrorCodes.TRY_AGAIN_LATER)
      setLoading(false)
      return
    }

    try {
      // This is an unfortunate workaround for our weird state of being authenticated by SAML (and having a session cookie),
      // but not actually being registered yet. This causes 403's from our api endpoints, which wreaks havok on our FE
      // logic.
      handleSignOutNoRedirect()

      await client.signUp(email, locale)

      history.push(`/signup-success?email=${encodeURIComponent(email)}`)
    } catch (error) {
      setError(CarrotErrorCodes.TRY_AGAIN_LATER)
      setLoading(false)
      return
    }
  }

  const getTranslatedErrorMessage = (errorCode: string): string | JSX.Element | (string | JSX.Element)[] => {
    if (errorCode === CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL) {
      return intl.formatMessage(getErrorMessageFromCode(errorCode), {
        link: (string) => (
          <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
            {string}
          </Link>
        )
      })
    }
    return intl.formatMessage(getErrorMessageFromCode(errorCode))
  }

  return (
    <SamlNoEmailOnboardingContext.Provider
      value={{ error, loading, onSubmit, heading, subheading, getTranslatedErrorMessage }}
    >
      {children}
    </SamlNoEmailOnboardingContext.Provider>
  )
}
