import React, { FC } from "react"
import { CarrotCardModuleBase, CarrotCardModuleProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"

const CarrotCardRestrictionPeriodModule: FC<CarrotCardModuleProps> = () => (
  <CarrotCardModuleBase
    backgroundColor={(theme) => ({ backgroundColor: theme.palette.error.light })}
    status="locked"
    body={
      <FormattedMessage defaultMessage="Due to your employer’s end-of-year payroll policies, your Carrot Card is locked until January 1." />
    }
  />
)

export { CarrotCardRestrictionPeriodModule }
