import { Asset } from "contentful"
import { ICantHave, ICantHaveCountry, IExpertBio, IHasAny, IHasCountry, IMustHave } from "contentful-types"

type AttributeContentfulCondition = ICantHave | IHasAny | IMustHave
type CountryContentfulCondition = ICantHaveCountry | IHasCountry
type RawContentfulCondition = AttributeContentfulCondition | CountryContentfulCondition

export type File = {
  url: string
}

export type Headshot = {
  file: File
}
export type ExpertBio = {
  id: string
  slug: string
  name: string
  credentials: string
  headshot: Headshot
  description: string
  conditions: RawContentfulCondition[]
}

const mapContentToHeadshot = ({ fields }: Asset): Headshot => {
  if (!fields) {
    return null
  }
  const { file } = fields
  return {
    file
  }
}
export const mapContentToExpertBio = ({ fields, sys }: IExpertBio): ExpertBio => {
  if (!fields || !sys) {
    return null
  }
  const { slug, name, credentials, headshot, description, conditions } = fields
  const { id } = sys

  return {
    id,
    slug,
    name,
    credentials,
    headshot: headshot && mapContentToHeadshot(headshot),
    description,
    conditions
  }
}
