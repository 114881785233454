import { Steps } from "./steps"
import { MemberDobSexStep, PartnerDobSexStep } from "../steps/insurance/DobSexStep"
import {
  PartnerProcessConnectInsuranceStep,
  PartnerProcessConnectInsuranceSuccessStep,
  MemberProcessConnectInsuranceSuccessStep,
  MemberProcessConnectInsuranceStep
} from "../steps/insurance/ProcessConnectInsuranceStep"
import PartnerFirstLastNameStep from "../steps/insurance/PartnerFirstLastNameStep"
import { MemberConnectHealthPlanStep, PartnerConnectHealthPlanStep } from "../steps/insurance/ConnectHealthPlanStep"
import {
  InformationalConnectPartnerInsuranceStep,
  InformationalConnectMemberInsuranceStep
} from "../steps/insurance/InformationalConnectInsuranceStep"
import { SkipMemberConnectInsurance } from "../steps/insurance/SkipConnectInsuranceStep"
import { SkipPartnerConnectInsurance } from "../steps/insurance/SkipPartnerConnectInsuranceStep"
import { FinalNoDeductibleStep, FinalStep } from "../steps/insurance/FinalStep"
import {
  PartnerProcessConnectInsuranceFailContainer,
  ProcessConnectInsuranceFailContainer,
  ProcessConnectInsuranceKnownOutageContainer
} from "../steps/insurance/ProcessConnectInsuranceStep/FailureContainer"
import {
  MemberShouldShowInsuranceStep,
  PartnerShouldShowInsuranceStep
} from "../steps/insurance/DecisionSteps/ShouldShowInsuranceStep"
import { MemberPlanCoverageDateStep, PartnerPlanCoverageDateStep } from "../steps/insurance/CoverageDateStep"
import { AreYouLegallyMarriedStep } from "../steps/AreYouLegallyMarried"
import { PartnerTaxDependentStep } from "../steps/PartnerTaxDependent"
import {
  MemberOnlyOrCombinedDeductibleStatusStep,
  MemberPartnerDeductibleStatusStep,
  PartnerOnlyDeductibleStatusStep
} from "../steps/insurance/DeductibleStatusStep"
import { PartnerShouldShowPlanCoverageDateStep } from "../steps/insurance/DecisionSteps/ShouldShowPlanCoverageDateStep"
import { ProcessMemberEnrollmentStep } from "../steps/DecisionSteps/ProcessMemberEnrollmentStep"
import { MemberCoverageTypeStep } from "../steps/MemberCoverageTypeStep"
import PeekBenefitEnrollmentStep from "../steps/DecisionSteps/PeekBenefitEnrollmentStep"
import { MemberHasHdhpFamilyStep, MemberHasHdhpIndividualStep, PartnerHasHdhpStep } from "../steps/HasHdhpStep"
import {
  MemberFamilyHsaContributionStatusStep,
  MemberIndividualHsaContributionStatusStep,
  PartnerHsaContributionStatusStep
} from "../steps/HsaContributionStatusStep"
import { PartnerCoverageTypeStep } from "../steps/PartnerCoverageTypeStep"

import { IntroToInsuranceFlow } from "../steps/IntroToInsuranceFlow"
import { MemberPlanSourceStep } from "../steps/MemberPlanSourceStep"
import ConnectInsuranceFlow from "../steps/insurance/ConnectInsuranceFlow"
import { ConnectInsuranceNotRequiredView } from "../steps/ConnectInsuranceNotRequiredView"
import { PartnerPlanSourceStep, PartnerPlanSourceACAStep } from "../steps/PartnerPlanSourceStep"
import { TalkToCarrotContainer } from "../steps/TalkToCarrotContainer"
import { ReEnrollIntroInsuranceFlow } from "../steps/ReEnrollIntroInsuranceFlow"
import { InsuranceDisclaimer } from "../steps/InsuranceDisclaimer"
import ProcessEnrollmentTypeStep from "../steps/DecisionSteps/ProcessEnrollmentTypeStep"
import FirstTimeOrReEnroll from "../steps/DecisionSteps/FirstTimeOrReEnrollStep"
import { HraIneligibleStep } from "../steps/HraIneligibleStep"
import {
  MemberHealthPlanNotListedContainer,
  PartnerHealthPlanNotListedContainer
} from "../steps/insurance/ProviderNotListedContainer"
import { PartnerEmdIneligible } from "../steps/EmdIneligibleStep"
import { MemberEmdIneligibleContainer } from "../steps/MemberEmdIneligibleContainer"

export const benefitEnrollmentSteps = [
  { name: Steps.INTRO_INFORMATIONAL, view: IntroToInsuranceFlow },
  { name: Steps.MEMBER_PLAN_SOURCE, view: MemberPlanSourceStep },
  { name: Steps.MEMBER_HDHP_INDIVIDUAL, view: MemberHasHdhpIndividualStep },
  { name: Steps.MEMBER_HDHP_FAMILY, view: MemberHasHdhpFamilyStep },
  { name: Steps.MEMBER_INDIVIDUAL_HSA_CONTRIBUTION, view: MemberIndividualHsaContributionStatusStep },
  { name: Steps.MEMBER_FAMILY_HSA_CONTRIBUTION, view: MemberFamilyHsaContributionStatusStep },
  { name: Steps.MEMBER_COVERAGE_TYPE, view: MemberCoverageTypeStep },
  { name: Steps.PARTNER_PLAN_SOURCE, view: PartnerPlanSourceStep },
  { name: Steps.PARTNER_PLAN_SOURCE_ACA, view: PartnerPlanSourceACAStep },
  { name: Steps.PARTNER_COVERAGE_TYPE, view: PartnerCoverageTypeStep },
  { name: Steps.PARTNER_HDHP, view: PartnerHasHdhpStep },
  { name: Steps.PARTNER_HSA_CONTRIBUTION, view: PartnerHsaContributionStatusStep },
  { name: Steps.PEEK_BENEFIT_ENROLLMENT, view: PeekBenefitEnrollmentStep },
  { name: Steps.PROCESS_MEMBER_ENROLLMENT, view: ProcessMemberEnrollmentStep },
  { name: Steps.TALK_TO_CARROT, view: TalkToCarrotContainer },
  { name: Steps.MEMBER_INFO_CONNECT_INSURANCE, view: InformationalConnectMemberInsuranceStep },
  { name: Steps.PARTNER_INFO_CONNECT_INSURANCE, view: InformationalConnectPartnerInsuranceStep },
  { name: Steps.SKIP_MEMBER_CONNECT_INSURANCE, view: SkipMemberConnectInsurance },
  { name: Steps.SKIP_PARTNER_CONNECT_INSURANCE, view: SkipPartnerConnectInsurance },
  { name: Steps.MEMBER_HEALTH_PLAN_CONNECT, view: MemberConnectHealthPlanStep },
  { name: Steps.MEMBER_DOB_SEX_CONNECT, view: MemberDobSexStep },
  { name: Steps.MEMBER_HEALTH_PLAN_NOT_LISTED, view: MemberHealthPlanNotListedContainer },
  { name: Steps.MEMBER_SHOULD_SHOW_INSURANCE, view: MemberShouldShowInsuranceStep },
  { name: Steps.MEMBER_PROCESS_CONNECT_INSURANCE, view: MemberProcessConnectInsuranceStep },
  { name: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_SUCCESS, view: MemberProcessConnectInsuranceSuccessStep },
  { name: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL, view: ProcessConnectInsuranceFailContainer },
  { name: Steps.MEMBER_PROCESS_CONNECT_INSURANCE_KNOWN_OUTAGE, view: ProcessConnectInsuranceKnownOutageContainer },
  { name: Steps.CONNECT_INSURANCE_NOT_REQUIRED, view: ConnectInsuranceNotRequiredView },
  { name: Steps.PARTNER_HEALTH_PLAN_CONNECT, view: PartnerConnectHealthPlanStep },
  { name: Steps.PARTNER_HEALTH_PLAN_NOT_LISTED, view: PartnerHealthPlanNotListedContainer },
  { name: Steps.PARTNER_FIRST_LAST_NAME_CONNECT, view: PartnerFirstLastNameStep },
  { name: Steps.PARTNER_DOB_SEX_CONNECT, view: PartnerDobSexStep },
  { name: Steps.PARTNER_SHOULD_SHOW_INSURANCE, view: PartnerShouldShowInsuranceStep },
  { name: Steps.PARTNER_PROCESS_CONNECT_INSURANCE, view: PartnerProcessConnectInsuranceStep },
  { name: Steps.PARTNER_PROCESS_CONNECT_INSURANCE_SUCCESS, view: PartnerProcessConnectInsuranceSuccessStep },
  { name: Steps.PARTNER_PROCESS_CONNECT_INSURANCE_FAIL, view: PartnerProcessConnectInsuranceFailContainer },
  { name: Steps.FINAL, view: FinalStep },
  { name: Steps.FINAL_NO_DEDUCTIBLE, view: FinalNoDeductibleStep },
  { name: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS, view: MemberOnlyOrCombinedDeductibleStatusStep },
  { name: Steps.MEMBER_PARTNER_DEDUCTIBLE_STATUS, view: MemberPartnerDeductibleStatusStep },
  { name: Steps.PARTNER_ONLY_DEDUCTIBLE_STATUS, view: PartnerOnlyDeductibleStatusStep },
  { name: Steps.PARTNER_SHOULD_SHOW_COLLECT_PLAN_COVERAGE, view: PartnerShouldShowPlanCoverageDateStep },
  { name: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE, view: MemberPlanCoverageDateStep },
  { name: Steps.PARTNER_DEDUCTIBLE_PERIOD_COVERAGE_DATE, view: PartnerPlanCoverageDateStep },
  { name: Steps.ARE_YOU_LEGALLY_MARRIED, view: AreYouLegallyMarriedStep },
  { name: Steps.PARTNER_TAX_DEPENDENT, view: PartnerTaxDependentStep },
  { name: Steps.CONNECT_INSURANCE_FLOW, view: ConnectInsuranceFlow },
  { name: Steps.RE_ENROLL_INTRO_INFORMATIONAL, view: ReEnrollIntroInsuranceFlow },
  { name: Steps.INSURANCE_DISCLAIMER, view: InsuranceDisclaimer },
  { name: Steps.PROCESS_ENROLLMENT_TYPE, view: ProcessEnrollmentTypeStep },
  { name: Steps.FIRST_TIME_OR_RE_ENROLL, view: FirstTimeOrReEnroll },
  { name: Steps.HRA_INELIGIBLE, view: HraIneligibleStep },
  { name: Steps.MEMBER_EMD_INELIGIBLE, view: MemberEmdIneligibleContainer },
  { name: Steps.PARTNER_EMD_INELIGIBLE, view: PartnerEmdIneligible }
]
