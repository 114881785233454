import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { ITileDisplay } from "contentful-types"
import { getDisabledSpecialistTileDisplay } from "lib/contentful/contentfulClient"

const disabledSpecialistDisplayQueryKey = "getDisabledSpecialistTileDisplay"

export function useGetDisabledSpecialistTileDisplay(locale: string): UseQueryResult<ITileDisplay, ResponseError> {
  return useQuery<ITileDisplay, ResponseError>(
    [disabledSpecialistDisplayQueryKey],
    () => getDisabledSpecialistTileDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch specialist tile with error: ${error}`)
    }
  )
}
