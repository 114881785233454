import React from "react"
import { ICategory } from "../../contentful-types"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { SubcategorySection } from "./SubcategorySection"
import { SubCategoryLink } from "./shared/ReadLearnLinks"
import { Stack, Typography } from "@carrotfertility/carotene-core"
import { Title } from "services/page-title/Title"

// @ts-expect-error TS7031
function SubCategoryNavigation({ subCategories, category }): JSX.Element {
  return (
    <Stack
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
      paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
      height={"auto"}
      flexWrap={"wrap"}
      flexDirection={"row"}
    >
      {/*
       // @ts-expect-error TS7006 */}
      {subCategories.map((subCategory) => (
        <ContentfulConditionalGate conditions={subCategory.fields.conditions} key={subCategory.fields.title}>
          <SubCategoryLink subCategory={subCategory} category={category} />
        </ContentfulConditionalGate>
      ))}
    </Stack>
  )
}

type CategoryPageContentProps = {
  category: ICategory
}

export function CategoryPageContent({ category }: CategoryPageContentProps): JSX.Element {
  const { subCategories } = category.fields
  return (
    <>
      <Title title={category.fields.title} productTitle="Read and learn" />
      <Typography variant="h2">{category.fields.title}</Typography>
      <SubCategoryNavigation {...{ category, subCategories }} />
      {subCategories.map((subCategory, i, subCategories) => (
        <ContentfulConditionalGate key={subCategory.fields.title} conditions={subCategory.fields.conditions}>
          <SubcategorySection subCategory={subCategory} category={category} isLast={i + 1 === subCategories.length} />
        </ContentfulConditionalGate>
      ))}
    </>
  )
}
