import { TileOrPhoneSupportActions } from "components/dynamic-content/util/dynamicContent"
import { ITalkToCarrotLanguage, ITile } from "contentful-types"
import React from "react"
import { getLanguageName, getPathForLanguage, getPathForTile, getTileTitle } from "../utils/mappingUtils"
import { Breadcrumb, BreadcrumbProps } from "@carrotfertility/carotene-core"

type PageBreadcrumbProps = {
  tile?: ITile
  language?: ITalkToCarrotLanguage
  baseUrl: string
  actions: TileOrPhoneSupportActions
  isCurrentPage?: boolean
}

const PageBreadcrumb = ({ tile, language, baseUrl, actions, isCurrentPage = false }: PageBreadcrumbProps) => {
  const breadcrumbText = tile ? getTileTitle(tile) : getLanguageName(language)
  const onClick = tile
    ? () => actions.gotoPageAction(getPathForTile({ tile, baseUrl }))
    : () => actions.gotoPageAction(getPathForLanguage({ language, baseUrl }))
  const fallbackText = "unknown"
  const breadcrumbProps = isCurrentPage
    ? { currentPage: true }
    : ({ component: "button", onClick: onClick } as BreadcrumbProps<"button">)

  return <Breadcrumb {...breadcrumbProps}>{breadcrumbText || fallbackText}</Breadcrumb>
}

export default PageBreadcrumb
