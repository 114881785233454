import React, { FC, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { reportError } from "utils/ErrorReporting"
import { DeepPartial, FormProvider, useForm } from "react-hook-form"
import { SendMessageForm } from "./SendMessageForm"
import { useDispatch } from "react-redux"
import { useCurrentUser } from "../../context/user/UserContext"
import { signOut } from "../../../actions/loginActions"
import { HttpErrors } from "../../../utils/HttpErrors"
import { StringParam, useQueryParam } from "use-query-params"
import { getHeap } from "../../../utils/heap"
import { SelectOptionType } from "@carrotfertility/carotene"
import { adminCarrotClient } from "utils/CarrotClient"
import { useIntl } from "react-intl"
import { SendMessageDescription, useSendMessageTranslator } from "./useSendMessageTranslator"
import { messageTopicsToSubjectMap } from "./SendMessageUtil"

const SendMessageFormContext = React.createContext<SendMessageContextProps>({})

interface SendMessageFormInputNames {
  message: string
  defaultMessage: string
}

type IsPartnerWorkaroundSelectOption = SelectOptionType & {
  isPartnerEmail?: boolean
}

export interface MessageReceivedModalBaseProps {
  // @ts-expect-error TS7008
  onExit
}

type SendMessageContextProps = {
  onSubmit?: () => Promise<void>
  onExit?: () => void
  subtitle?: string
  description?: SendMessageDescription
  sent?: boolean
  employeeEmail?: string
  personalEmail?: string
  partnerEmail?: string
  sentFromPartnerEmail?: boolean
  options?: SelectOptionType[]
  onClickButton?: (onSubmit: () => Promise<void>) => Promise<void>
}

interface SendMessageProps {
  BenefitEnrollmentView?: any
  onExit?: () => Promise<void> | void
  modalTopic?: string
  source?: string
  successModal?: React.FunctionComponent<MessageReceivedModalBaseProps>
  onClickButton?: (onSubmit: () => Promise<void>) => Promise<void>
  defaultValues?: DeepPartial<SendMessageFormInputNames>
}

export type SendTalkToCarrotMessagePayload = {
  message: string
  subject: string
  sendFromPartner: boolean
  topic: string
}

const SendMessage: FC<SendMessageProps> = ({
  BenefitEnrollmentView,
  onExit,
  modalTopic,
  source,
  successModal,
  defaultValues = {},
  onClickButton
}) => {
  const { personalEmail, email: employeeEmail, partnerEmail, preferredEmail } = useCurrentUser()
  const [sentFromPartnerEmail, setSentFromPartnerEmail] = useState(false)
  const [sent, setSent] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const intl = useIntl()
  const sendMessageTranslator = useSendMessageTranslator()

  const getInputOrSelectEmail = (email: IsPartnerWorkaroundSelectOption | string) => {
    if (typeof email === "string") {
      return email
    }
    return email?.value
  }

  function findWhoSentFrom(emailOption: IsPartnerWorkaroundSelectOption | string): "primary" | "personal" | "partner" {
    if (emailOption === "primary" || emailOption === "personal" || emailOption === "partner") {
      return emailOption
    }
    if (typeof emailOption !== "string" && emailOption?.isPartnerEmail) {
      return "partner"
    }
    const extractedEmail = getInputOrSelectEmail(emailOption)
    return extractedEmail === employeeEmail ? "primary" : "personal"
  }

  const options = [
    {
      label: preferredEmail,
      subLabel:
        personalEmail === preferredEmail
          ? intl.formatMessage({ defaultMessage: "(your preferred contact email)" })
          : intl.formatMessage({ defaultMessage: "(your account email)" }),
      id: preferredEmail,
      value: personalEmail === preferredEmail ? "personal" : "primary"
    },
    {
      label: partnerEmail,
      subLabel: intl.formatMessage({ defaultMessage: "(your partner's email)" }),
      isPartnerEmail: true,
      id: partnerEmail,
      value: "partner"
    }
  ] as SelectOptionType[]

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {
      message: "",
      defaultMessage: "Member sent a message with no content",
      ...defaultValues
    }
  })
  const { handleSubmit, setError } = formMethods

  const [enrollmentFlowError] = useQueryParam("enrollmentFlowError", StringParam)

  const { topic: topicFromParams } = useParams<{
    topic: string
  }>()

  const topic = modalTopic ? modalTopic : source ? source : topicFromParams

  const topicProperties = sendMessageTranslator.getTranslationsForTopic(topic)

  if (!topicProperties && !source && !BenefitEnrollmentView) {
    history.push("/talk-to-carrot")
    return null
  }

  const onSubmit = handleSubmit(
    async ({
      email: emailOption,
      message,
      defaultMessage
    }: {
      email: { label: string; subLabel: string; value: string }
      message: string
      defaultMessage: string
    }) => {
      try {
        const emailSentFrom = findWhoSentFrom(emailOption)
        const sendFromPartner = emailSentFrom === "partner"
        const sendMessage = message ? message : defaultMessage
        setSentFromPartnerEmail(sendFromPartner)
        await adminCarrotClient.sendTalkToCarrotMessage({
          message: sendMessage,
          subject: messageTopicsToSubjectMap[topic],
          sendFromPartner,
          topic
        })

        getHeap().track("TalkToCarrot", { EventName: emailSentFrom })
        if (enrollmentFlowError) {
          getHeap().track("EnrollmentFlowSupportRequest", { EventName: enrollmentFlowError })
        }
      } catch (error) {
        if (error.name === HttpErrors.UNAUTHORIZED) {
          dispatch(signOut())
          return
        }
        reportError(error)

        setError("message", {
          type: "internalError",
          message: intl.formatMessage({
            defaultMessage: "Something's not working. Please email your message to support@get-carrot.com."
          })
        })
        return
      }
      setSent(true)
    }
  )

  const context = {
    onSubmit,
    sent,
    options,
    employeeEmail: employeeEmail,
    personalEmail: personalEmail,
    preferredEmail: preferredEmail,
    partnerEmail: partnerEmail,
    sentFromPartnerEmail: sentFromPartnerEmail,
    subtitle: topicProperties?.heading,
    description: topicProperties?.description,
    onClickButton: onClickButton,
    onExit
  } as SendMessageContextProps

  return (
    <SendMessageFormContext.Provider value={context}>
      <FormProvider {...formMethods}>
        {BenefitEnrollmentView ? (
          <BenefitEnrollmentView onExit={onExit} />
        ) : (
          <SendMessageForm {...{ source, successModal }} />
        )}
      </FormProvider>
    </SendMessageFormContext.Provider>
  )
}

export { SendMessage, SendMessageFormContext }
