import React, { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getHasPendingIvfRequirements } from "derivedSelectors"
import { IvfRequirements } from "components/views/reimbursements/IvfRequirements"
import { StringParam, useQueryParam } from "use-query-params"
import { CarrotQueryParam, CarrotRoute } from "utils/CarrotUrls"
import { updatehasMetSignatureRequirementInMemory } from "../../../actions/ivfRequirementsActions"

// @ts-expect-error TS7006
const redirectToTransactionsPage = (history) => {
  history.push(CarrotRoute.Transactions.valueOf())
}

const IvfRequirementsContainer: FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const hasPendingIvfRequirements = useSelector(getHasPendingIvfRequirements)

  const [signed] = useQueryParam(CarrotQueryParam.Signed, StringParam)
  let backOnClick = history.goBack

  if (signed) {
    dispatch(updatehasMetSignatureRequirementInMemory())
    backOnClick = () => history.go(-4)
  }

  if (!hasPendingIvfRequirements) {
    redirectToTransactionsPage(history)
  }

  return <IvfRequirements backOnClick={backOnClick} doThisLaterOnClick={() => redirectToTransactionsPage(history)} />
}

export { IvfRequirementsContainer, redirectToTransactionsPage }
