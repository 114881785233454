import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { IPageGuidance } from "contentful-types"
import { getTalkToCarrotLocationGuidance } from "lib/contentful/contentfulClient"

const talkToCarrotLocationGuidance = "getTalkToCarrotLocationGuidance"

export function useGetTalkToCarrotLocationGuidance(locale: string): UseQueryResult<IPageGuidance, ResponseError> {
  return useQuery<IPageGuidance, ResponseError>(
    [talkToCarrotLocationGuidance],
    () => getTalkToCarrotLocationGuidance(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch the location page guidance with error: ${error}`)
    }
  )
}
