import React from "react"
import { useSignUpFlow } from "../context/signup/SignUpFlowContext"
import { useSignUpGetHelp } from "./context/SignUpGetHelpContext"
import SignUpGetHelpSuccess from "../../pages/signup-get-help-success"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { getErrorMessageFromCode } from "../../utils/CarrotErrors"
import { Alert, Box, Button, LeftIcon, Stack, Typography, dayjs, useTheme } from "@carrotfertility/carotene-core"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { Form, FormButton, FormDatePicker, FormTextField } from "@carrotfertility/carotene-core-x"
import { validateMemberDOB } from "services/common-forms"
import { useHistory } from "react-router"

function SignupGetHelpHeader(): JSX.Element {
  const { errorMessage } = useSignUpGetHelp()
  const { multipleEmployeesFound } = useSignUpFlow()
  const intl = useIntl()
  const theme = useTheme()
  const description = multipleEmployeesFound
    ? defineMessage({
        defaultMessage:
          "We’re having trouble finding your account. Please provide the information below and our Care Team will reach out with next steps."
      })
    : defineMessage({ defaultMessage: "Our Care Team will use this information to look for your account." })

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Typography variant="h1">
        <FormattedMessage defaultMessage="Get help" />
      </Typography>
      {errorMessage ? (
        <Alert severity="error">{intl.formatMessage(getErrorMessageFromCode(errorMessage))}</Alert>
      ) : (
        <Typography>{intl.formatMessage(description)}</Typography>
      )}
    </Stack>
  )
}

const SignUpGetHelpForm = (): JSX.Element => {
  const { shake, inputsEnabled, onSubmit, success, showSubmittingLabel } = useSignUpGetHelp()
  const { dateOfBirth, email, firstName, lastName } = useSignUpFlow()
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()

  return success ? (
    <SignUpGetHelpSuccess />
  ) : (
    <AppAccessPageCard shake={shake}>
      <Form reValidateMode="onSubmit" defaultValues={{ email, dateOfBirth, firstName, lastName }} onSubmit={onSubmit}>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <SignupGetHelpHeader />
          <FormTextField
            label={intl.formatMessage({ defaultMessage: "Employer Name" })}
            disabled={!inputsEnabled}
            name="employer"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
          />
          <FormTextField
            name="email"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            label={intl.formatMessage({ defaultMessage: "Email" })}
            id="email"
          />
          <FormTextField
            name="firstName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            id="firstName"
            label={intl.formatMessage({ defaultMessage: "First Name" })}
          />
          <FormTextField
            name="lastName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            id="lastName"
            label={intl.formatMessage({ defaultMessage: "Last Name" })}
          />
          <FormDatePicker
            name="dateOfBirth"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" }),
                validate: (value: dayjs.Dayjs) => {
                  const result = validateMemberDOB(value)
                  if (typeof result !== "boolean") {
                    return intl.formatMessage(result)
                  }
                  return result
                }
              }
            }}
            label={intl.formatMessage({ defaultMessage: "Date of birth" })}
          />
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Box display="flex" justifyContent={"space-between"}>
              <Button
                onClick={() =>
                  email ? history.push(`/signup-find-account?email=${encodeURIComponent(email)}`) : history.goBack()
                }
                variant="text"
                color="secondary"
                startIcon={<LeftIcon />}
              >
                <FormattedMessage defaultMessage="Back" />
              </Button>
              <FormButton disabled={showSubmittingLabel} type="submit">
                {intl.formatMessage({ defaultMessage: "Find account" })}
              </FormButton>
            </Box>
            <Box display="block" sx={{ alignSelf: "center" }}>
              <UnauthPreferredLocale />
            </Box>
          </Stack>
        </Stack>
      </Form>
    </AppAccessPageCard>
  )
}

const SignUpGetHelpPage = (): JSX.Element => {
  const intl = useIntl()
  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <SignUpGetHelpForm />
    </AppAccessPageLayout>
  )
}

export default SignUpGetHelpPage
