import React from "react"
import { IPageGuidance } from "contentful-types"
import { reportErrorMessage } from "../../../utils/ErrorReporting"

import { defaultMarkdownElements } from "services/markdown"
import { ContentfulRawMarkdownRenderer } from "services/contentful"

type GuidanceElementProps = {
  guidance: IPageGuidance
}

const GuidanceElement = ({ guidance }: GuidanceElementProps) => {
  if (!guidance || !guidance.fields) {
    if (guidance) {
      reportErrorMessage(`Missing fields data for Contentful page guidance with entityId: ${guidance?.sys?.id}`)
    }
    return null
  }
  const {
    fields: { title, description }
  } = guidance
  if (!title) {
    return null
  }
  return (
    <>
      <defaultMarkdownElements.h3.component {...defaultMarkdownElements.h3.props} component="h2">
        {title}
      </defaultMarkdownElements.h3.component>
      <ContentfulRawMarkdownRenderer>{description}</ContentfulRawMarkdownRenderer>
    </>
  )
}

export { GuidanceElement }
