import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import { InAppMessagingThread, UpdatedThread } from "../inAppMessagingTypes"
import ResponseError from "../../../types/responseError"
import { adminCarrotClient } from "../../../utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import { THREAD_MESSAGES_CACHE_KEY } from "./useGetThreadMessages"

export default function useUpdateThread(): UseMutationResult<InAppMessagingThread, ResponseError, UpdatedThread> {
  const queryClient = useQueryClient()
  return useMutation<InAppMessagingThread, ResponseError, UpdatedThread>(
    ({ message, threadId }: UpdatedThread) => adminCarrotClient.updateThread(threadId, message),
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      },
      onSuccess: async (data: InAppMessagingThread) => {
        await queryClient.refetchQueries(
          [THREAD_MESSAGES_CACHE_KEY, data.threadId],
          { type: "active", exact: true },
          { throwOnError: true }
        )
      }
    }
  )
}
