import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Container } from "@carrotfertility/carotene"
import { Steps } from "../workflow/steps"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useCurrentUser } from "../../context/user/UserContext"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor } from "react-intl"
import { returnApplicableYear } from "utils/Helpers"
import { irsLimitsText, hdhpIrsLimits } from "components/insurance-details/plan-information-table/insuranceQuestions"
import { Box, Typography } from "@carrotfertility/carotene-core"

export const enum HdhpSelectionStatusOptions {
  true = "true",
  false = "false",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const hdhpStatusIdToLabelMapping = {
  [HdhpSelectionStatusOptions.true]: defineMessage({ defaultMessage: "Yes" }),
  [HdhpSelectionStatusOptions.false]: defineMessage({ defaultMessage: "No" }),
  [HdhpSelectionStatusOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getHdhpStatusLabelFromId = (id: HdhpSelectionStatusOptions): MessageDescriptor =>
  hdhpStatusIdToLabelMapping[id]

interface HasHDHPStepProps {
  ViewName: string
  FieldName: string
  updateUserInfo: (val: string) => void
  title: string
  body: string
  hasHDHPValue?: string
}

function HasHdhpStep({
  FieldName,
  ViewName,
  updateUserInfo,
  title,
  body,
  hasHDHPValue
}: HasHDHPStepProps): JSX.Element {
  const intl = useIntl()
  useProgressTracker(1, intl.formatMessage({ defaultMessage: "YOUR INSURANCE COVERAGE" }))
  const formMethods = useForm()
  const { setNextStep } = useStateMachine(ViewName, hasHDHPValue)
  // @ts-expect-error TS7006
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" && (await updateUserInfo(val))
    setNextStep(ViewName, val)
  })

  const { handleSubmit } = formMethods
  const applicableYear = returnApplicableYear(hdhpIrsLimits)

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        {title}
      </Typography>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          {intl.formatMessage(irsLimitsText, {
            // @ts-expect-error TS7053
            DEDUCTIBLE_INDIVIDUAL_MIN: intl.formatNumber(hdhpIrsLimits[applicableYear]["DEDUCTIBLE_INDIVIDUAL_MIN"], {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            }),
            // @ts-expect-error TS7053
            DEDUCTIBLE_FAMILY_MIN: intl.formatNumber(hdhpIrsLimits[applicableYear]["DEDUCTIBLE_FAMILY_MIN"], {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            }),
            OUT_OF_POCKET_INDIVIDUAL_MAX: intl.formatNumber(
              // @ts-expect-error TS7053
              hdhpIrsLimits[applicableYear]["OUT_OF_POCKET_INDIVIDUAL_MAX"],
              {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 0
              }
            ),
            // @ts-expect-error TS7053
            OUT_OF_POCKET_FAMILY_MAX: intl.formatNumber(hdhpIrsLimits[applicableYear]["OUT_OF_POCKET_FAMILY_MAX"], {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })
          })}
        </Typography>
      </Container>
      <Container padding="none" stack="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>{body}</Typography>
      </Container>
      <Container padding="none" stack="giant">
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={hasHDHPValue}
          options={[
            {
              label: intl.formatMessage(getHdhpStatusLabelFromId(HdhpSelectionStatusOptions.true)),
              value: HdhpSelectionStatusOptions.true
            },
            {
              label: intl.formatMessage(getHdhpStatusLabelFromId(HdhpSelectionStatusOptions.false)),
              value: HdhpSelectionStatusOptions.false
            },
            {
              label: intl.formatMessage(getHdhpStatusLabelFromId(HdhpSelectionStatusOptions.IM_NOT_SURE)),
              value: HdhpSelectionStatusOptions.IM_NOT_SURE
            }
          ]}
        />
      </Container>
      <Box height="6rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
function MemberHasHdhpIndividualStep(): JSX.Element {
  const { memberHasHDHP } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()

  return (
    <HasHdhpStep
      FieldName={"memberHasHdhpValue"}
      ViewName={Steps.MEMBER_HDHP_INDIVIDUAL}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          memberHasHDHP: val
        })
      }
      hasHDHPValue={memberHasHDHP}
      title={intl.formatMessage({
        defaultMessage: "Are you currently enrolled in a high deductible health plan (HDHP)?"
      })}
      body={intl.formatMessage({
        defaultMessage:
          "For help understanding your plan details, we recommend contacting your insurance provider or benefits manager."
      })}
    />
  )
}

function MemberHasHdhpFamilyStep(): JSX.Element {
  const { memberHasHDHP } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()

  return (
    <HasHdhpStep
      FieldName={"memberHasHdhpValue"}
      ViewName={Steps.MEMBER_HDHP_FAMILY}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          memberHasHDHP: val
        })
      }
      hasHDHPValue={memberHasHDHP}
      title={intl.formatMessage({
        defaultMessage: "Are you currently enrolled in a high deductible health plan (HDHP)?"
      })}
      body={intl.formatMessage({
        defaultMessage:
          "For help understanding your plan details, we recommend contacting your insurance provider or benefits manager."
      })}
    />
  )
}

function PartnerHasHdhpStep(): JSX.Element {
  const { partnerHasHDHP } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()

  return (
    <HasHdhpStep
      FieldName={"partnerHasHdhpValue"}
      ViewName={Steps.PARTNER_HDHP}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          partnerHasHDHP: val
        })
      }
      hasHDHPValue={partnerHasHDHP}
      title={intl.formatMessage({
        defaultMessage: "Are they currently enrolled in a high deductible health plan (HDHP)?"
      })}
      body={intl.formatMessage({
        defaultMessage:
          "For help understanding their plan details, we recommend contacting their insurance provider or benefits manager."
      })}
    />
  )
}
export { MemberHasHdhpIndividualStep, MemberHasHdhpFamilyStep, PartnerHasHdhpStep }
