import React from "react"
import { Typography, Button, Box, Stack, useTheme } from "@carrotfertility/carotene-core"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { Link as RouterLink } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"

const ResetPasswordSuccess = (): JSX.Element => {
  const intl = useIntl()
  const theme = useTheme()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign in" })}>
      <AppAccessPageCard>
        <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Typography variant="h1">
            <FormattedMessage defaultMessage="Success" />
          </Typography>
        </Box>
        <Stack
          marginBottom={theme.spacing(theme.tokens.spacing.xl)}
          textAlign="center"
          display="block"
          spacing={theme.spacing(theme.tokens.spacing.lg)}
        >
          <Typography>
            <FormattedMessage defaultMessage="Your password has been reset." />
          </Typography>
          <Button component={RouterLink} to={"/login"}>
            <FormattedMessage defaultMessage="Sign in" />
          </Button>
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}

export default ResetPasswordSuccess
