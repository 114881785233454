import { createClient, Entry } from "contentful"
import Settings from "../../utils/CarrotConfig"
import {
  ICountry,
  IPageGuidance,
  ITalkToCarrotCountryV2,
  ITalkToCarrotLanguage,
  ITileDisplay,
  ITilePage
} from "../../contentful-types"
import ContentTypes from "../../utils/ContentTypes"
import { nestedRefs } from "./utils/contentfulClientUtils"
import { reportError, reportErrorMessage } from "utils/ErrorReporting"
import { getContentfulLocale } from "./utils/contenfulLocaleUtils"

enum StaticAssetID {
  TALK_TO_CARROT_LOCATION_GUIDANCE = "3ByERJy0buFOMf6f9BU6RQ",
  TALK_TO_CARROT_LANGUAGE_GUIDANCE = "1m5qiXAayKU40WZ2Ega5nE",
  TALK_TO_CARROT_NEED_HELP_GUIDANCE = "6HZUR0v8YJYCGeFG5ncCtm",
  TALK_TO_CARROT_PROVIDER_ELIGIBILITY = "3Tnl2nccRTKkk4oMC4ZmxB",
  TALK_TO_CARROT_REFRESH_CARROT_PLAN = "4oPn08X5v4mH61LsrqKT3H",
  TALK_TO_CARROT_GET_YOUR_CARROT_PLAN = "1n7FzTcMpdeJmMYir1FvHB",
  TALK_TO_CARROT_DELETE_ACCOUNT_ENGLISH = "4jq2bKHVWVg85SMRK6XEGd",
  TALK_TO_CARROT_EXPERT_PAGE_GUIDANCE = "1izopz7aGxJqPRfYi8pnNW",
  TALK_TO_CARROT_CARE_NAVIGATION_GUIDANCE = "d6DOvsVjsS8jwAi5WNxa3",
  TALK_TO_CARROT_CARE_NAV_DISPLAY = "2VsaJ0QmxiNMWStiSPDLWo",
  TALK_TO_CARROT_SPECIALIST_DISPLAY = "4HDGYmodsvm6zNiKXcYSQn",
  TALK_TO_CARROT_DISABLED_SPECIALIST_DISPLAY = "2ODqMLjncY3RpmpRv59KoU",
  TALK_TO_CARROT_SEND_A_MESSAGE_GUIDANCE = "5dRHHZv3CWk8jslQK3SOyS",
  TALK_TO_CARROT_SEND_A_MESSAGE_DISPLAY = "7pgPTNlFt1WrMY2Au6mizO",
  TALK_TO_CARRROT_PHONE_SUPPORT_DISPLAY = "6iXSWydnuzS4EfpfMQ1YrL",
  TALK_TO_SCHEDULE_A_VIDEO_CHAT_OR_CALL_DISPLAY = "4vJgIkbxzSoOevGPFu51uN",
  TALK_TO_CARROT_BENEFIT_CHAT_LANGUAGE_ENGLISH = "1mkdRSUsXWTtw9Y5gzgvP2",
  TALK_TO_CARROT_ZENDESK_MESSAGING_WIDGET_DISPLAY = "4XYXSfc7nZMR5kHqgkga6"
}

const getContentfulClient = () =>
  createClient({
    space: Settings.CONTENTFUL_SPACE_ID,
    accessToken: Settings.CONTENTFUL_ACCESS_TOKEN,
    host: Settings.CONTENTFUL_PREVIEW_MODE === "true" ? "preview.contentful.com" : "cdn.contentful.com"
  })

const getTalkToCarrotLocations = async (): Promise<ITalkToCarrotCountryV2[]> => {
  const client = getContentfulClient()
  const { items, total, limit } = await client.getEntries({
    content_type: ContentTypes.TALK_TO_CARROT_COUNTRY_V2,
    include: 1,
    limit: 300
  })
  if (total > limit) {
    reportErrorMessage(
      `Contentful request containted ${total} items exceeded the request limit of ${limit}. Please check the request and adjust the limit accordingly.`
    )
  }
  return items as ITalkToCarrotCountryV2[]
}

const getCountries = async (): Promise<ICountry[]> => {
  const client = getContentfulClient()
  const { items, total, limit } = await client.getEntries({
    content_type: ContentTypes.COUNTRY,
    include: 1,
    limit: 300
  })
  if (total > limit) {
    reportErrorMessage(
      `Contentful request containted ${total} items which exceeded the request limit of ${limit}. Please check the request and adjust the limit accordingly.`
    )
  }
  return items as ICountry[]
}

const getTalkToCarrotLanguages = async (): Promise<ITalkToCarrotLanguage[]> => {
  const client = getContentfulClient()
  const { items } = await client.getEntries({
    content_type: ContentTypes.TALK_TO_CARROT_LANGUAGE,
    include: 10
  })
  return items as ITalkToCarrotLanguage[]
}

const getEntryById = async (id: string, locale: string): Promise<Entry<unknown>> => {
  const client = getContentfulClient()
  const contentfulLocale = getContentfulLocale(locale)
  try {
    return await client.getEntry(id, { include: 10, locale: contentfulLocale })
  } catch (err) {
    if (err && err.sys) {
      const {
        sys: { id: errorId }
      } = err
      if (errorId === "NotFound") {
        const errorMsg = `Contentful resource not found for entityId: ${id}, this may just be a work in progress and in a draft state`
        reportErrorMessage(errorMsg)
        throw new ContentfulError(errorMsg)
      }
    }
    reportError(err)
    throw new ContentfulError(err)
  }
}

const getTilePageById = async (id: string, locale: string): Promise<ITilePage> => {
  const result = (await getEntryById(id, locale)) as ITilePage
  await nestedRefs(result, getEntryById)
  return result
}

const getGuidance = async (entryId: StaticAssetID, locale: string): Promise<IPageGuidance> => {
  const item = (await getEntryById(entryId, locale)) as IPageGuidance
  if (!item) {
    reportErrorMessage(`No Contentful page guidance for entityId: ${entryId}`)
  }
  return item
}

const getTileDisplay = async (entryId: StaticAssetID, locale: string): Promise<ITileDisplay> => {
  const item = (await getEntryById(entryId, locale)) as ITileDisplay
  if (!item) {
    reportErrorMessage(`No Contentful tile display for entityId: ${entryId}`)
  }
  return item
}

const getTalkToCarrotLanguageGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_LANGUAGE_GUIDANCE, locale)

const getTalkToCarrotLocationGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_LOCATION_GUIDANCE, locale)

const getTalkToCarrotNeedHelpGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_NEED_HELP_GUIDANCE, locale)

const getTalkToCarrotProviderEligibilityGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_PROVIDER_ELIGIBILITY, locale)

const getTalkToCarrotRefreshCarrotPlanGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_REFRESH_CARROT_PLAN, locale)

const getTalkToCarrotGetYourCarrotPlanGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_GET_YOUR_CARROT_PLAN, locale)

const getTalkToCarrotDeleteAccountGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_DELETE_ACCOUNT_ENGLISH, locale)

const getTalkToCarrotExpertGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_EXPERT_PAGE_GUIDANCE, locale)

const getTalkToCarrotCareNavigationGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_CARE_NAVIGATION_GUIDANCE, locale)

const getCareNavTileDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_CARROT_CARE_NAV_DISPLAY, locale)

const getSpecialistTileDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_CARROT_SPECIALIST_DISPLAY, locale)

const getDisabledSpecialistTileDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_CARROT_DISABLED_SPECIALIST_DISPLAY, locale)

const getTalkToCarrotSendMessageGuidance = async (locale: string): Promise<IPageGuidance> =>
  getGuidance(StaticAssetID.TALK_TO_CARROT_SEND_A_MESSAGE_GUIDANCE, locale)

const getTalkToCarrotSendMessageDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_CARROT_SEND_A_MESSAGE_DISPLAY, locale)

const getTalkToCarrotPhoneSupportDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_CARRROT_PHONE_SUPPORT_DISPLAY, locale)

const getScheduleAVideoChatOrCallDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_SCHEDULE_A_VIDEO_CHAT_OR_CALL_DISPLAY, locale)

const getZendeskMessagingWidgetDisplay = async (locale: string): Promise<ITileDisplay> =>
  getTileDisplay(StaticAssetID.TALK_TO_CARROT_ZENDESK_MESSAGING_WIDGET_DISPLAY, locale)

export {
  StaticAssetID,
  getContentfulClient,
  getEntryById,
  getTalkToCarrotLocations,
  getTalkToCarrotLocationGuidance,
  getTilePageById,
  getTalkToCarrotNeedHelpGuidance,
  getTalkToCarrotProviderEligibilityGuidance,
  getTalkToCarrotRefreshCarrotPlanGuidance,
  getTalkToCarrotGetYourCarrotPlanGuidance,
  getTalkToCarrotLanguageGuidance,
  getCountries,
  getTalkToCarrotLanguages,
  getTalkToCarrotDeleteAccountGuidance,
  getTalkToCarrotExpertGuidance,
  getTalkToCarrotCareNavigationGuidance,
  getCareNavTileDisplay,
  getSpecialistTileDisplay,
  getDisabledSpecialistTileDisplay,
  getTalkToCarrotSendMessageGuidance,
  getTalkToCarrotSendMessageDisplay,
  getTalkToCarrotPhoneSupportDisplay,
  getScheduleAVideoChatOrCallDisplay,
  getZendeskMessagingWidgetDisplay
}

export class ContentfulError extends Error {
  constructor(message: string) {
    super(message)
    Object.setPrototypeOf(this, ContentfulError.prototype)
  }
}
