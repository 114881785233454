import { Box, Button } from "@carrotfertility/carotene-core"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { getEmployeeId } from "reducers/userInfo"
import { getHeap } from "utils/heap"
import { PopupModal, useCalendlyEventListener } from "react-calendly"
import { CALENDLY_PRIMARY_COLOR } from "../../../lib/contentful/utils/mappingUtils"

export const FindASessionButton = ({
  sessionPickerUrl,
  groupSlugId
}: {
  sessionPickerUrl: string
  groupSlugId: string
}): JSX.Element => {
  const employeeId = useSelector(getEmployeeId)

  const [open, setOpen] = useState(false)

  useCalendlyEventListener({
    onEventScheduled: () => {
      getHeap().track("Group chat scheduled", {
        "Group Slug ID": groupSlugId
      })
    }
  })
  const CalendlyWidget = (): JSX.Element => {
    useEffect(() => {
      // @ts-expect-error TS7006
      const handleEsc = (event): void => {
        if (event.keyCode === 27) {
          setOpen(false)
        }
      }
      window.addEventListener("keydown", handleEsc)

      return () => {
        window.removeEventListener("keydown", handleEsc)
      }
    }, [])

    return (
      <PopupModal
        url={sessionPickerUrl}
        utm={{ utmCampaign: employeeId, utmSource: groupSlugId }}
        onModalClose={() => setOpen(false)}
        open={open}
        rootElement={document.getElementById("groups-find-group-session-calendly-widget")}
        pageSettings={{
          primaryColor: CALENDLY_PRIMARY_COLOR
        }}
      />
    )
  }

  return (
    <>
      <Button id="find-group-session-button" onClick={() => setOpen(true)}>
        <FormattedMessage defaultMessage="Find a session" />
      </Button>
      <Box id="groups-find-group-session-calendly-widget">
        <CalendlyWidget />
      </Box>
    </>
  )
}
