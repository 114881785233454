import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"

import { useDidMount } from "../../../utils/Hooks"
import { Body } from "../atoms/Bodies"
import { Spacer } from "../atoms/Atoms"
import { Button } from "../atoms/buttons/Button"
import { ButtonPrimary } from "../atoms/buttons/ButtonPrimary"
import { useParams, useHistory } from "react-router-dom"
import { MerchantDeclineLoading } from "./MerchantDeclineLoading"
import CarrotClient from "lib/carrot-api/index"
import Settings from "../../../utils/CarrotConfig"
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const defaultState = {
  isLoading: true
}

export const NeedsApproval = () => {
  const { token } = useParams()
  const [state, setState] = useState(defaultState)
  const history = useHistory()
  const intl = useIntl()

  useDidMount(() => {
    const checkIfMerchantValid = async () => {
      try {
        const result = await client.checkIfMerchantValid(token)

        if (result.isApproved) {
          history.push(`${token}/approve`)
          return
        }

        setState({
          ...state,
          companyAmount: result.companyAmount,
          companyCurrencyCode: result.companyCurrencyCode,
          merchantAmount: result.merchantAmount,
          merchantCurrencyCode: result.merchantCurrencyCode,
          merchantName: result.merchantName,
          isLoading: false
        })
      } catch {
        history.push("error")
      }
    }

    checkIfMerchantValid().then()
  })

  if (state.isLoading) {
    return <MerchantDeclineLoading />
  }

  const { companyAmount, companyCurrencyCode, merchantAmount, merchantCurrencyCode } = state
  let displayAmount = (
    <FormattedMessage
      defaultMessage="Amount: {displayMerchantAmount}"
      values={{
        displayMerchantAmount: merchantAmount
      }}
    />
  )

  if (merchantCurrencyCode && companyAmount) {
    const displayMerchantAmount = intl.formatNumber(merchantAmount, {
      style: "currency",
      currency: merchantCurrencyCode,
      currencyDisplay: "narrowSymbol"
    })

    const displayCompanyAmount = intl.formatNumber(companyAmount, {
      style: "currency",
      currency: companyCurrencyCode,
      currencyDisplay: "narrowSymbol"
    })

    displayAmount =
      merchantCurrencyCode && companyCurrencyCode && merchantCurrencyCode === companyCurrencyCode ? (
        <FormattedMessage
          defaultMessage="Amount: {displayMerchantAmount}"
          values={{
            displayMerchantAmount
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="Amount: {displayMerchantAmount} ({displayCompanyAmount})"
          description="currency amount displaying amount in the merchant's currency, followed by the same amount in the company's currency in parantheses"
          values={{
            displayMerchantAmount,
            displayCompanyAmount
          }}
        />
      )
  }

  return (
    <>
      <Spacer height={2} />
      <Typography variant="h3">
        <FormattedMessage defaultMessage="Carrot Card declined" />
      </Typography>
      <Spacer height={1} />
      <Body>
        <FormattedMessage defaultMessage="Your Carrot Card transaction was declined because we don't recognize this provider." />
      </Body>
      <Spacer height={1} />
      <Body additionalClasses="truncate">
        <FormattedMessage
          defaultMessage="Provider name: {merchantName}"
          values={{
            merchantName: state.merchantName
          }}
        />
      </Body>
      <Body>{displayAmount}</Body>
      <Spacer height={1} />
      <Body>
        <FormattedMessage defaultMessage="Is this provider an eligible Carrot provider?" />
      </Body>
      <Spacer height={1.5} />
      <div className="flex justify-end">
        <ButtonPrimary to={`${token}/approve`}>
          <FormattedMessage defaultMessage="Yes" />
        </ButtonPrimary>
        <Spacer width={1} />
        <Button outline to={`${token}/deny`}>
          <FormattedMessage defaultMessage="I'm not sure" />
        </Button>
      </div>
    </>
  )
}

NeedsApproval.propTypes = {
  status: PropTypes.string,
  amount: PropTypes.string,
  merchantName: PropTypes.string
}
