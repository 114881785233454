import React, { useEffect, useState } from "react"
import { ResendState, VerificationFormStep } from "components/views/account/phoneNumberUpdate/UpdatePhoneNumberModal"
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl"
import {
  Form,
  FormButton,
  FormButtonProps,
  FormOnSubmitHandler,
  FormSelect,
  FormTextField,
  FormTextFieldProps,
  useFormContext,
  useWatch
} from "@carrotfertility/carotene-core-x"
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  InputAdornment,
  Chip,
  Button,
  DialogTitle
} from "@carrotfertility/carotene-core"
import { CountryCode } from "content/CountryCode"
import { carrotClient } from "utils/CarrotClient"
import { useSelector } from "react-redux"
import { getCompanyInfo } from "reducers/companyInfo"
import { sleep } from "utils/Helpers"
import { HttpErrors } from "utils/HttpErrors"
import { usePhoneCodeSelectOptions } from "../../../phone-number-collection/usePhoneCodeSelectOptions"
import SmsMarketingTextOptIn from "../../../phone-number-collection/SmsMarketingTextOptIn"
import { getCountryCallingCode } from "libphonenumber-js"

const client = carrotClient

const ResentButtonCopy: Record<ResendState, MessageDescriptor> = {
  READY: defineMessage({ defaultMessage: "Resend code" }),
  SENDING: defineMessage({ defaultMessage: "Sending" }),
  SENT: defineMessage({ defaultMessage: "Code resent" })
}

type FormValues = {
  phoneNumber: string
  countryCode: CountryCode
  verificationCode: string
  allowsMarketingTexts: boolean
}

type VerificationCodeState = "NOT_SENT" | "SENT" | "AWAITING_VERIFICATION"

type ResendCode = ({ resendCode }: { resendCode: () => Promise<unknown> }) => Promise<void>

function addCountryCodeToPhoneNumber(phoneNumber: FormValues["phoneNumber"], countryCode: FormValues["countryCode"]) {
  const phoneCountryCode = getCountryCallingCode(countryCode)
  return `${phoneCountryCode}${phoneNumber}`
}

interface ResendButtonPros extends Omit<FormButtonProps, "variant" | "color" | "onClick"> {
  onResend: ResendCode
}

function ResendButton({ onResend, ...otherProps }: ResendButtonPros) {
  const { getValues } = useFormContext()

  async function resendCode() {
    const { phoneNumber, countryCode: countryCodeSelection } = getValues()

    const phoneNumberWithCountryCode = addCountryCodeToPhoneNumber(phoneNumber, countryCodeSelection)

    return client.startPhoneVerification(phoneNumberWithCountryCode, countryCodeSelection.value)
  }

  return <Button {...otherProps} variant="outlined" color="secondary" onClick={() => onResend({ resendCode })} />
}

function FormPhoneNumberTextInput(props: FormTextFieldProps) {
  const intl = useIntl()
  const countryCodeSelection = useWatch({ name: "countryCode" })
  const phoneCode = getCountryCallingCode(countryCodeSelection as CountryCode)
  return (
    <FormTextField
      {...props}
      InputProps={{
        startAdornment: phoneCode ? (
          <InputAdornment disableTypography={props.disabled} position="start">
            {intl.formatMessage({ defaultMessage: "+{phoneCode}" }, { phoneCode: phoneCode })}
          </InputAdornment>
        ) : null
      }}
    />
  )
}

function UpdatePhoneNumberFormStep({
  onClose,
  setVerificationFormStep,
  isAnnouncementModal
}: {
  onClose: () => void
  setVerificationFormStep: React.Dispatch<React.SetStateAction<VerificationFormStep>>
  isAnnouncementModal: boolean
}) {
  const intl = useIntl()
  const { countryCode } = useSelector(getCompanyInfo)
  const [resendState, setResentState] = useState<ResendState>("SENT")
  const [verificationCodeState, setVerificationCodeState] = useState<VerificationCodeState>("NOT_SENT")
  const PhoneCodeSelectOptions = usePhoneCodeSelectOptions()
  const enableResendButton = async () => {
    await sleep(3000)
    setResentState("READY")
  }

  useEffect(() => {
    if (resendState === "SENT") {
      enableResendButton()
    }
  }, [resendState])

  const onResend: ResendCode = async ({ resendCode }) => {
    setResentState("SENDING")
    await resendCode()
    setResentState("SENT")
    enableResendButton()
  }

  const onSubmit: FormOnSubmitHandler<FormValues> = async (formData, formMethods) => {
    if (verificationCodeState === "NOT_SENT") {
      const { phoneNumber, countryCode } = formData

      const phoneNumberWithCountryCode = addCountryCodeToPhoneNumber(phoneNumber, countryCode)

      try {
        await client.startPhoneVerification(phoneNumberWithCountryCode, countryCode)
        setVerificationCodeState("AWAITING_VERIFICATION")
      } catch (error) {
        if (error.name === HttpErrors.NOT_FOUND) {
          formMethods.setError("phoneNumber", {
            message: intl.formatMessage({ defaultMessage: "Please enter a valid phone number" })
          })
          return
        }

        reportError(error)
        setVerificationFormStep("FAILED")
      }
    } else {
      const { phoneNumber, countryCode, verificationCode, allowsMarketingTexts } = formData

      const phoneNumberWithCountryCode = addCountryCodeToPhoneNumber(phoneNumber, countryCode)

      try {
        await client.completePhoneVerification(
          phoneNumberWithCountryCode,
          verificationCode,
          countryCode,
          allowsMarketingTexts
        )
        setVerificationFormStep("COMPLETED")
      } catch (error) {
        if (error.name === HttpErrors.NOT_FOUND) {
          formMethods.setError("verificationCode", {
            message: intl.formatMessage({ defaultMessage: "Please enter a valid code" })
          })
          return
        }

        reportError(error)
        setVerificationFormStep("FAILED")
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={{
        countryCode,
        allowsMarketingTexts: false
      }}
    >
      {isAnnouncementModal ? (
        <Chip
          key={intl.formatMessage({ defaultMessage: "New" })}
          label={intl.formatMessage({ defaultMessage: "New" })}
          color="primary"
          sx={(theme) => ({
            marginBottom: theme.spacing(theme.tokens.spacing.xs)
          })}
        />
      ) : null}
      <DialogTitle>
        {!isAnnouncementModal ? (
          <FormattedMessage defaultMessage="Update your phone number" />
        ) : (
          <FormattedMessage defaultMessage="Get help faster" />
        )}
      </DialogTitle>
      <DialogContent>
        {!isAnnouncementModal ? (
          <DialogContentText>
            <FormattedMessage defaultMessage="We need to verify your mobile phone number before we can update it. We will never sell it or use it for spam calls." />
          </DialogContentText>
        ) : (
          <>
            <DialogContentText>
              <FormattedMessage defaultMessage="Now you can contact your Care Team via email, video chat, or our new phone line." />
            </DialogContentText>
            <DialogContentText>
              <FormattedMessage defaultMessage="Make sure your phone number is up to date so we know who you are and can support you quickly when you call." />
            </DialogContentText>
          </>
        )}

        <FormSelect
          fullWidth
          options={PhoneCodeSelectOptions}
          disabled={verificationCodeState === "AWAITING_VERIFICATION"}
          controllerProps={{
            rules: {
              required: intl.formatMessage({ defaultMessage: "Required" })
            }
          }}
          label={intl.formatMessage({ defaultMessage: "Country code" })}
          name="countryCode"
        />

        <FormPhoneNumberTextInput
          registerOptions={{
            required: intl.formatMessage({ defaultMessage: "Required" })
          }}
          disabled={verificationCodeState === "AWAITING_VERIFICATION"}
          label={intl.formatMessage({ defaultMessage: "Phone number" })}
          name="phoneNumber"
        />

        {countryCode === "US" && <SmsMarketingTextOptIn countryCodeFieldName="countryCode" />}

        {verificationCodeState === "AWAITING_VERIFICATION" && (
          <FormTextField
            name="verificationCode"
            label={intl.formatMessage({ defaultMessage: "Enter verification code" })}
            registerOptions={{
              required: intl.formatMessage({ defaultMessage: "Please enter the verification code" })
            }}
          />
        )}
      </DialogContent>

      <DialogActions>
        {verificationCodeState === "AWAITING_VERIFICATION" ? (
          <ResendButton onResend={onResend}>{intl.formatMessage(ResentButtonCopy[resendState])}</ResendButton>
        ) : null}

        {verificationCodeState === "NOT_SENT" && isAnnouncementModal ? (
          <Button color="secondary" variant="outlined" onClick={onClose}>
            <FormattedMessage defaultMessage="Not now" />
          </Button>
        ) : null}

        <FormButton type="submit">
          {verificationCodeState === "AWAITING_VERIFICATION" ? (
            <FormattedMessage defaultMessage="Confirm code" />
          ) : (
            <FormattedMessage defaultMessage="Send verification code" />
          )}
        </FormButton>
      </DialogActions>
    </Form>
  )
}

export { UpdatePhoneNumberFormStep }
