import CarrotClient from "../../../lib/carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from "@tanstack/react-query"
import { HttpStatusCodes } from "utils/HttpStatusCodes"
import ResponseError from "../../../types/responseError"
import { CarrotPlan } from "../../../types/carrotPlanTypes"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import useQueryExpirationHandler from "../../context/authentication/hooks/useQueryExpirationHandler"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const ENDPOINT = "GetCarrotPlan"

export function getCarrotPlanQueryKey(): string[] {
  return [ENDPOINT]
}

export type UseCarrotPlanReturn = {
  data: CarrotPlan
  error: ResponseError
  isError: boolean
  isLoading: boolean
  refetch: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<CarrotPlan, ResponseError>>
  hasCarrotPlan: boolean
}

export default function useCarrotPlan(): UseCarrotPlanReturn {
  const carrotPlanQuery = useQuery<CarrotPlan, ResponseError>(
    getCarrotPlanQueryKey(),
    () =>
      // @ts-expect-error TS7011
      client.getCarrotPlan().catch((error) => {
        if (error.statusCode === HttpStatusCodes.NOT_FOUND) {
          return null
        }
        throw error
      }),
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
  useQueryExpirationHandler(carrotPlanQuery)
  const { data, isLoading, isError, error, refetch } = carrotPlanQuery
  return {
    data,
    error,
    isLoading,
    isError,
    refetch,
    hasCarrotPlan: Boolean(carrotPlanQuery.data)
  }
}
