import React from "react"
import { Body, Button, FlexContainer, Container, SlidingDotsLoader, TextLink } from "@carrotfertility/carotene"
import { JsonSchemaForm } from "@carrotfertility/carotene-forms"
import useUpdateBankDetails from "./hooks/useUpdateBankDetails"
import { BankDetailsData } from "./hooks/useQueryBankDetails"
import { useCurrentUser } from "components/context/user/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { bankDetailTitleMessages } from "./BankDetailTitleMessages"
import { bankDetailValidationMessages } from "./BankDetailValidationMessages"
import { bankDetailDropdownMessages } from "./BankDetailDropdownMessages"

interface CountrySpecificBankDetailsProps {
  schema: any
  data: BankDetailsData
  isLoading: boolean
}

const CountrySpecificBankDetails = (props: CountrySpecificBankDetailsProps) => {
  const { schema, data } = props
  const { mutate } = useUpdateBankDetails()
  const { isUsa } = useCurrentUser()
  const [formData, setFormData] = React.useState({})
  const [showForm, setShowForm] = React.useState(false)
  const [selectedBankCountry, setSelectedBankCountry] = React.useState()
  const intl = useIntl()

  const uiSchema = {
    bankCountry: { props: { widthPercent: "50%", minWidth: "250px" } },
    bankName: { props: { widthPercent: "50%", minWidth: "250px" } }
  }

  // @ts-expect-error TS7006
  function handleOnSubmit(values): void {
    const dataToUpdate = {}

    for (const key in values.formData) {
      if (!values.formData[key]?.includes("*")) {
        // @ts-expect-error TS7053
        dataToUpdate[key] = values.formData[key]
      }
    }

    setFormData(values.formData)
    mutate(dataToUpdate)
  }

  // @ts-expect-error TS7006
  function clearBankCountryData(formData): void {
    const { bankCountry, nameOnAccount } = formData

    const clearedFormData = {
      bankCountry,
      nameOnAccount
    }
    setFormData(clearedFormData)
  }

  // @ts-expect-error TS7006
  function handleOnChange(values): void {
    const { formData } = values
    const isNewCountrySelected =
      formData?.bankCountry !== data.bankCountry && selectedBankCountry !== formData?.bankCountry

    if (isNewCountrySelected) {
      setSelectedBankCountry(formData?.bankCountry)
      clearBankCountryData(formData)
    }
  }

  React.useEffect(() => {
    if (data) {
      setFormData(data)
      setShowForm(true)
    }
  }, [data])

  return (
    <FlexContainer direction="column" height="100%" maxWidth="600px">
      <Container padding="12px 0 0 0">
        <Body>
          {isUsa ? (
            <FormattedMessage
              defaultMessage="To help us reimburse you as quickly as possible, please check your banking details 
                        each time you submit a request."
            />
          ) : (
            <FormattedMessage
              defaultMessage="To help us reimburse you as quickly as possible, please check your banking details 
                        each time you submit a request. <link>Learn which fields are required.</link>"
              values={{
                link: (linkContent) => (
                  <TextLink size="medium" target="_blank" to="https://get-carrot.zendesk.com/hc/articles/9760524807963">
                    {linkContent}
                  </TextLink>
                )
              }}
            />
          )}
        </Body>
      </Container>

      {schema && (
        <FlexContainer paddingTopBottom="giant">
          {showForm ? (
            <JsonSchemaForm
              schema={schema}
              uiSchema={uiSchema}
              formData={formData}
              // @ts-expect-error TS7053
              overrideTitle={(messageKey) => intl.formatMessage(bankDetailTitleMessages[messageKey])}
              overrideSelectLabel={(fieldName, labelValue) => {
                if (fieldName === "bankCountry") {
                  return intl.formatDisplayName(labelValue, { type: "region" })
                }
                // @ts-expect-error TS7053
                return intl.formatMessage(bankDetailDropdownMessages[`${fieldName}_${labelValue}`])
              }}
              overrideErrorMessage={(messageKey, vars) =>
                // @ts-expect-error TS7053
                intl.formatMessage(bankDetailValidationMessages[messageKey], vars)
              }
              onSubmit={handleOnSubmit}
              onChange={handleOnChange}
              usePlaceholders={true}
            >
              <FlexContainer justifyContent="flex-end" paddingTopBottom="small">
                <Button type="submit">
                  <FormattedMessage defaultMessage="Submit" />
                </Button>
              </FlexContainer>
            </JsonSchemaForm>
          ) : (
            <SlidingDotsLoader />
          )}
        </FlexContainer>
      )}
    </FlexContainer>
  )
}

export default CountrySpecificBankDetails
