import useBenefit from "components/dynamic-content/hooks/useGetBenefit"
import { useGetHasProviderTypeInCountry } from "./useGetHasProviderTypeInCountry"

// @ts-expect-error TS7006
export const useHasProviderFinder = (countryCode) => {
  const benefit = useBenefit()

  const {
    data: hasProviderTypes,
    isLoading: isLoadingProviderTypes,
    isError
  } = useGetHasProviderTypeInCountry(countryCode)
  return (
    !benefit.isLoading &&
    benefit.data?.providerFinder.showProviderFinder &&
    !isLoadingProviderTypes &&
    !isError &&
    hasProviderTypes?.hasProviders // the hook returns null when the session is expired, so the '?' is necessary here
  )
}
