import React, { FC } from "react"
import ContentTypes from "../../utils/ContentTypes"
import { Modal } from "./content-types/modal"
import {
  IBlockQuote,
  IContainer,
  IContentBlock,
  IContentBlockWithIcon,
  ITwoColumnBulletList,
  ITwoColumnLayout,
  IModal,
  IUnorderedList,
  IAccordion
} from "contentful-types"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { Markdown, MarkdownProps, defaultMarkdownElements } from "services/markdown"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DownIcon,
  Typography,
  UnorderedList
} from "@carrotfertility/carotene-core"
import {
  LinkModalContextProvider,
  LinkToModal,
  ModalFromLink,
  ArrowLink,
  AvaArrowLink,
  CalloutBox,
  PhoneSupportNumberLink,
  PhoneSupportNumberText,
  SelectedTalkToCarrotLocation,
  DoulaAttestationLink
} from "./custom-components"

import { PiiText } from "services/tracking"
import { useSelector } from "react-redux"
import { getCompanyDisplayName } from "reducers/companyInfo"
import useBenefit from "components/dynamic-content/hooks/useGetBenefit"
import { validateContentSource } from "./util/content-validation"
import { PayingForCareCoverageDetails } from "components/views/reimbursements/digital-benefit-guide/PayingForCare"
import { ContentfulContentProp } from "./content-types/shared-types"

export type ContentfulContentTypes =
  | IBlockQuote
  | IContainer
  | IContentBlock
  | IContentBlockWithIcon
  | ITwoColumnBulletList
  | ITwoColumnLayout
  | IModal
  | IUnorderedList
  | IAccordion

type RecursiveContentfulContentRendererProps = {
  contents: Array<ContentfulContentTypes>
  asList?: boolean | false
}

type ContentfulMarkdownOverrides = Omit<MarkdownProps, "children">

export interface ContentfulContentRendererProps
  extends RecursiveContentfulContentRendererProps,
    ContentfulMarkdownOverrides {}

const ContentfulMarkdownOverrideContext = React.createContext<ContentfulMarkdownOverrides>({})

export function ContentfulContentRenderer({ contents, asList, ...markdownProps }: ContentfulContentRendererProps) {
  return (
    <ContentfulMarkdownOverrideContext.Provider value={markdownProps || {}}>
      <LinkModalContextProvider>
        <RecursiveContentfulContentRenderer {...{ contents, asList }} />
      </LinkModalContextProvider>
    </ContentfulMarkdownOverrideContext.Provider>
  )
}

export function ContentfulRawMarkdownRenderer({ children, ...markdownProps }: MarkdownProps) {
  return (
    <ContentfulMarkdownOverrideContext.Provider value={markdownProps || {}}>
      <LinkModalContextProvider>
        <ContentfulMarkdown>{children}</ContentfulMarkdown>
      </LinkModalContextProvider>
    </ContentfulMarkdownOverrideContext.Provider>
  )
}

export const RecursiveContentfulContentRenderer: FC<RecursiveContentfulContentRendererProps> = ({
  contents,
  asList = false
}) => {
  return (
    <>
      {contents?.map(
        (content) =>
          content.fields && (
            <ContentfulConditionalGate conditions={content.fields.conditions} key={content.sys.id}>
              {asList ? (
                <defaultMarkdownElements.li.component {...defaultMarkdownElements.li.props}>
                  <Content content={content} />
                </defaultMarkdownElements.li.component>
              ) : (
                <Content content={content} />
              )}
            </ContentfulConditionalGate>
          )
      )}
    </>
  )
}

function ContentfulMarkdown(props: Pick<MarkdownProps, "children">) {
  const { overrides, ...otherMarkdownOverrides } = React.useContext(ContentfulMarkdownOverrideContext)

  const customMarkdownElements = {
    LinkToModal,
    ModalFromLink,
    ArrowLink,
    AvaArrowLink,
    CoverageAmountsDbg: PayingForCareCoverageDetails,
    CalloutBox,
    BodySmall: {
      ...defaultMarkdownElements.p,
      props: {
        ...defaultMarkdownElements.p?.props,
        variant: "body2",
        sx: {
          "> p": {
            fontSize: "inherit"
          }
        }
      }
    },
    CompanyName: () => {
      const companyNameInfo = useSelector(getCompanyDisplayName)
      return <PiiText>{companyNameInfo}</PiiText>
    },
    CustomCoverage: () => {
      const {
        data: {
          customizations: { memberCustomCoverageExplanationMarkdown }
        }
      } = useBenefit()

      return memberCustomCoverageExplanationMarkdown && <Markdown>{memberCustomCoverageExplanationMarkdown}</Markdown>
    },
    AdditionalEmployerBenefits: () => {
      const {
        data: {
          customizations: { additionalEmployerBenefitMarkdown }
        }
      } = useBenefit()

      return additionalEmployerBenefitMarkdown && <Markdown>{additionalEmployerBenefitMarkdown}</Markdown>
    },
    HealthPlan: () => {
      const {
        data: {
          customizations: { healthPlanMarkdown }
        }
      } = useBenefit()

      return healthPlanMarkdown && <Markdown>{healthPlanMarkdown}</Markdown>
    },
    PhoneSupportNumberLink,
    PhoneSupportNumberText,
    SelectedTalkToCarrotLocation,
    DoulaAttestationLink,
    ...overrides
  }

  const source = validateContentSource(props.children, customMarkdownElements)

  return (
    <Markdown overrides={customMarkdownElements} {...otherMarkdownOverrides} {...props}>
      {source}
    </Markdown>
  )
}

const Content: FC<ContentfulContentProp<ContentfulContentTypes>> = ({ content }) => {
  switch (content.sys.contentType.sys.id) {
    case ContentTypes.CONTENT_BLOCK_WITH_ICON:
      return (
        <Box
          display="flex"
          gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
          paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          justifyContent="space-between"
        >
          {(content as IContentBlockWithIcon).fields.icon?.fields && (
            <Box
              component="img"
              src={(content as IContentBlockWithIcon).fields.icon.fields.file?.url}
              height={60}
              width={60}
              alt=""
            />
          )}
          <Typography variant="body2">{(content as IContentBlockWithIcon).fields?.body}</Typography>
        </Box>
      )
    case ContentTypes.TWO_COLUMN_LAYOUT:
      return (
        <Box
          display="flex"
          gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
          flexWrap="wrap"
          sx={(theme) => ({
            "& > *": { width: { xs: "100%", md: `calc(50% - ${theme.spacing(theme.tokens.spacing.md)})` } }
          })}
        >
          <RecursiveContentfulContentRenderer contents={(content as ITwoColumnLayout).fields?.content} />
        </Box>
      )
    case ContentTypes.TWO_COLUMN_BULLET_LIST:
      return (
        <UnorderedList
          component="ul"
          sx={(theme) => ({
            display: "flex",
            gap: theme.spacing(theme.tokens.spacing.xxs),
            paddingTop: 0,
            paddingBottom: (theme) => theme.spacing(theme.tokens.spacing.xl),
            flexWrap: "wrap",
            li: {
              "&:nth-child(odd)": {
                paddingInlineEnd: (theme) => theme.spacing(theme.tokens.spacing.sm)
              },
              width: { xs: "100%", md: `calc(50% - ${theme.spacing(theme.tokens.spacing.md)})` }
            }
          })}
        >
          <RecursiveContentfulContentRenderer contents={(content as ITwoColumnBulletList).fields?.content} asList />
        </UnorderedList>
      )

    case ContentTypes.BLOCK_QUOTE:
      return (
        <defaultMarkdownElements.blockquote.component {...defaultMarkdownElements.blockquote.props}>
          <RecursiveContentfulContentRenderer contents={(content as IBlockQuote).fields?.content} />
        </defaultMarkdownElements.blockquote.component>
      )
    case ContentTypes.CONTAINER:
      return <RecursiveContentfulContentRenderer contents={(content as IContainer).fields?.content} />
    case ContentTypes.MODAL:
      return <Modal content={content as IModal} />
    case ContentTypes.CONTENT_BLOCK:
      return <ContentfulMarkdown>{(content as IContentBlock).fields?.body}</ContentfulMarkdown>
    case ContentTypes.UNORDERED_LIST:
      return (
        <defaultMarkdownElements.ul.component {...defaultMarkdownElements.ul.props}>
          <RecursiveContentfulContentRenderer contents={(content as IUnorderedList).fields?.content} asList />
        </defaultMarkdownElements.ul.component>
      )
    case ContentTypes.ACCORDION:
      return <ContentAccordion content={content as IAccordion} />
  }
  return null
}

function ContentAccordion({ content }: { content: IAccordion }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<DownIcon fontSize="large" />}
        aria-controls={`content-accordion-details-${content.sys.id}`}
        id={`content-accordion-${content.sys.id}`}
      >
        <ContentfulMarkdown>{content.fields?.summaryClickableElement}</ContentfulMarkdown>
      </AccordionSummary>
      <AccordionDetails>
        <RecursiveContentfulContentRenderer contents={content.fields?.content} />
      </AccordionDetails>
    </Accordion>
  )
}
