import { defineMessage } from "react-intl"

export const insuranceQuestions = {
  WHERE_DO_YOU_GET_HEALTH_INSURANCE: defineMessage({
    defaultMessage: "Where do you get your primary health insurance?"
  }),
  WHO_DOES_YOUR_PLAN_COVER: defineMessage({
    defaultMessage: "Does your health plan cover just you or does it cover other people as well?"
  }),
  DO_YOU_HAVE_AN_HDHP: defineMessage({
    defaultMessage: "Are you currently enrolled in a high deductible health plan? (HDHP)?"
  }),
  ARE_YOU_CONTRIBUTING_TO_AN_HSA: defineMessage({
    defaultMessage: "Are you or your employer contributing to a health savings account (HSA) associated with your HDHP?"
  }),
  ARE_YOU_MARRIED: defineMessage({ defaultMessage: "Are you legally married?" }),
  WHEN_IS_YOUR_DEDUCTIBLE_PERIOD: defineMessage({
    defaultMessage: "When does the annual deductible period on your medical plan start and end?"
  })
}

export const irsLimitsText = defineMessage({
  defaultMessage:
    "An HDHP is a major medical plan that meets minimum deductible and maximum out-of-pocket expense limitations set by the IRS each year. The current minimum deductible is at least {DEDUCTIBLE_INDIVIDUAL_MIN} for an individual or at least {DEDUCTIBLE_FAMILY_MIN} for a family, while out-of-pocket expenses can't total more than {OUT_OF_POCKET_INDIVIDUAL_MAX} for an individual or {OUT_OF_POCKET_FAMILY_MAX} for a family."
})

export const hdhpIrsLimits = {
  2022: {
    DEDUCTIBLE_INDIVIDUAL_MIN: 1400,
    DEDUCTIBLE_FAMILY_MIN: 2800,
    OUT_OF_POCKET_INDIVIDUAL_MAX: 7050,
    OUT_OF_POCKET_FAMILY_MAX: 14100
  },
  2023: {
    DEDUCTIBLE_INDIVIDUAL_MIN: 1500,
    DEDUCTIBLE_FAMILY_MIN: 3000,
    OUT_OF_POCKET_INDIVIDUAL_MAX: 7500,
    OUT_OF_POCKET_FAMILY_MAX: 15000
  },
  2024: {
    DEDUCTIBLE_INDIVIDUAL_MIN: 1600,
    DEDUCTIBLE_FAMILY_MIN: 3200,
    OUT_OF_POCKET_INDIVIDUAL_MAX: 8050,
    OUT_OF_POCKET_FAMILY_MAX: 16100
  }
}
