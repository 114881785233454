import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import CarrotClient from "carrot-api"
import { Alert, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Form, FormSelect, FormTextField } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { getCountryCode } from "reducers/companyInfo"
import Settings from "utils/CarrotConfig"
import { FormattedMessage, useIntl } from "react-intl"
import { CountryCodes } from "content/CountryCode"
import { HttpErrors } from "utils/HttpErrors"
import { getCountryCallingCode } from "libphonenumber-js"
import { TtcPaths } from "../../../utils/Paths"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const ConfirmPhoneNumber = () => {
  const intl = useIntl()

  const { completeConfirmPhoneNumber, isUsaCompany } = useContext(RequestCardContext)

  const theme = useTheme()

  const { canGoBack, send } = useStateMachine()

  const countryCode = useSelector(getCountryCode)

  const [state, setState] = useState({
    isInvalidPhoneNumber: false,
    isOtherError: false
  })

  // @ts-expect-error TS7006
  const onSubmission = async (formValues) => {
    const { phoneNumber, countryCode: countryCodeFormValue } = formValues
    const sanitizedPhoneNumber = phoneNumber.startsWith("+") ? phoneNumber.substring(1) : phoneNumber

    let countryCode = countryCodeFormValue
    countryCode = countryCode ?? "US"

    const phoneCountryCode = getCountryCallingCode(countryCode)
    const phoneNumberWithCountryCode = `${phoneCountryCode}${sanitizedPhoneNumber}`

    try {
      setState({
        ...state,
        isOtherError: false,
        isInvalidPhoneNumber: false
      })
      const response = await client.startPhoneVerification(phoneNumberWithCountryCode, countryCode)
      const standardizedPhoneNumber = await response.json()

      completeConfirmPhoneNumber(standardizedPhoneNumber, countryCode)
      send("")
    } catch (e) {
      if (e.name === HttpErrors.NOT_FOUND) {
        setState({ ...state, isOtherError: false, isInvalidPhoneNumber: true })
      } else {
        setState({
          ...state,
          isOtherError: true,
          isInvalidPhoneNumber: false
        })
      }
    }
  }

  const PhoneCodeSelectOptions: { label: string; value: string }[] = CountryCodes.map((countryCode) => ({
    label: `${intl.formatDisplayName(countryCode, { type: "region" })} (+${getCountryCallingCode(countryCode)})`,
    value: countryCode
  }))

  return (
    <Form onSubmit={onSubmission} defaultValues={{ countryCode }}>
      <RequestCardWrapper showBackButton={canGoBack} showContinueButton sectionNum={isUsaCompany ? 2 : 1}>
        <div>
          <Typography variant="h3" component="h2">
            <FormattedMessage defaultMessage="Confirm your phone number" />
          </Typography>
          <Stack spacing={theme.spacing(theme.tokens.spacing.xl)}>
            <Typography variant="body1">
              <FormattedMessage
                defaultMessage="We'll send text message alerts to this number if there are any issues with your Carrot Card. You can
              opt out at any time."
              />
            </Typography>
            <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
              {state.isInvalidPhoneNumber && (
                <Alert severity="error">
                  <FormattedMessage defaultMessage="Please enter a valid phone number." />
                </Alert>
              )}
              <FormSelect
                name={"countryCode"}
                label={intl.formatMessage({ defaultMessage: "Country Code" })}
                controllerProps={{
                  rules: { required: intl.formatMessage({ defaultMessage: "Please enter a phone number" }) }
                }}
                options={PhoneCodeSelectOptions}
                required={true}
              />
              <FormTextField
                name={"phoneNumber"}
                label={intl.formatMessage({ defaultMessage: "Phone Number" })}
                registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
                error={state.isInvalidPhoneNumber}
                required={true}
              />
            </Stack>
            {state.isOtherError && (
              <Alert severity="error">
                <FormattedMessage
                  defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
                  values={{
                    link: (linkContent) => (
                      <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Alert>
            )}
          </Stack>
        </div>
      </RequestCardWrapper>
    </Form>
  )
}

export { ConfirmPhoneNumber }
