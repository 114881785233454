import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useRoutingFlow } from "../../shared/RoutingFlowContainer"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Typography, Button, Link, RightIcon } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink, useHistory } from "react-router-dom"
import { RoutingFlowTitle } from "components/carrot-plans/shared"
import { ButtonProps, Container } from "@carrotfertility/carotene"
import { CenteredLoadingIndicator } from "components/views/molecules/Molecules"
import { useShouldShowArtMedicalRecordsConsent } from "./useShouldShowArtMedicalRecordsConsent"
import { useCheckRoutingInfoQueryCache } from "components/carrot-plans/shared/useQueryRoutingInfo"

export default function CarrotPlanOnTheWayStep(): JSX.Element {
  const { setShowProgressTracker, setHideCloseButton, setDisableButtons } = useRoutingFlow()
  const isRefreshRoutingFlow =
    window.location.href.includes("/carrot-plans/case-rate-renewal/routing") ||
    window.location.href.includes("/carrot-plans/journey-change/routing")
  const routingData = useCheckRoutingInfoQueryCache()
  const { shouldShowArtMedicalRecordsConsent, isLoadingShouldShowArtMedicalRecordsConsent } =
    useShouldShowArtMedicalRecordsConsent({ routingData })
  const intl = useIntl()
  const { send } = useStateMachine()

  useEffect(() => {
    setShowProgressTracker(false)
    if (isLoadingShouldShowArtMedicalRecordsConsent) {
      setDisableButtons(true)
    } else {
      setHideCloseButton(true)
    }
  }, [isLoadingShouldShowArtMedicalRecordsConsent, setDisableButtons, setHideCloseButton, setShowProgressTracker])

  const header = intl.formatMessage({
    defaultMessage: "Your Carrot Plan is on its way"
  })

  if (isLoadingShouldShowArtMedicalRecordsConsent) {
    return <LoadingIndicator />
  }

  return (
    <>
      <RoutingFlowTitle title={header} />
      <HeaderContent />
      <MainContent isRefreshRoutingFlow={isRefreshRoutingFlow} />
      {shouldShowArtMedicalRecordsConsent ? (
        <ArtMedicalRecordsConsentButton onClick={() => send("ART_MEDICAL_RECORDS_CONSENT")} />
      ) : (
        <ClosingContent isRefreshRoutingFlow={isRefreshRoutingFlow} />
      )}
    </>
  )
}

const LoadingIndicator = () => (
  <>
    <Container padding="large" stack="giant">
      <CenteredLoadingIndicator data-testid="carrot-plan-loader" />
    </Container>
  </>
)

const HeaderContent = () => (
  <>
    <Box display="flex" justifyContent="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
      <Box
        component="img"
        src={`/images/illo-underwater-dashboard.png`}
        srcSet={`/images/illo-underwater-dashboard-2x.png`}
        alt=""
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
        height={228}
        width={300}
      />
    </Box>
    <Typography
      color={(theme) => theme.palette.text.primary}
      variant="h3"
      component="h1"
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
    >
      <FormattedMessage defaultMessage="Your Carrot Plan is on its way" />
    </Typography>
  </>
)

const MainContent = ({ isRefreshRoutingFlow }: { isRefreshRoutingFlow: boolean }) => (
  <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
    {isRefreshRoutingFlow ? (
      <FormattedMessage defaultMessage="Thank you for updating us on your journey. We'll start working on your refreshed plan and will share it on your home page within one business day." />
    ) : (
      <FormattedMessage defaultMessage="Thank you for telling us about your journey and how we can help. We'll start working on your personalized Carrot Plan." />
    )}
  </Typography>
)

const ArtMedicalRecordsConsentButton = ({ onClick }: { onClick: ButtonProps["onClick"] }) => {
  return (
    <Box paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xl)} textAlign="right">
      <Button
        data-testid="next-button"
        size="medium"
        variant="outlined"
        color="secondary"
        endIcon={<RightIcon />}
        onClick={onClick}
      >
        <FormattedMessage defaultMessage="Next" />
      </Button>
    </Box>
  )
}

const ClosingContent = ({ isRefreshRoutingFlow }: { isRefreshRoutingFlow: boolean }) => {
  const history = useHistory()

  return (
    <>
      <Box
        marginBottom={(theme) =>
          theme.spacing(isRefreshRoutingFlow ? theme.tokens.spacing.lg : theme.tokens.spacing.xxxl)
        }
        textAlign="center"
      >
        <Button data-testid="go-to-carrot-home-button" onClick={() => history.push("/")}>
          <FormattedMessage defaultMessage="Go to Carrot home" />
        </Button>
      </Box>
      {isRefreshRoutingFlow ? (
        <Typography>
          <FormattedMessage
            defaultMessage="Have questions or want to share more details about your journey? <link>Schedule a chat</link>"
            values={{
              link: (linkContent) => (
                <Link data-testid="talk-to-carrot-link" component={ReactRouterLink} to="/talk-to-carrot">
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      ) : null}
    </>
  )
}
