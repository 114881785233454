import { Link as ReactRouterLink } from "react-router-dom"
import React from "react"
import { ICategory, ISubCategory } from "../../../contentful-types"
import { useReadLearnLinks } from "./ReadLearnLinks"
import { useIntl } from "react-intl"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"

type BreadcrumbsProps = {
  category: ICategory
  subcategory?: ISubCategory
}

export function ReadAndLearnBreadcrumbs({ category, subcategory }: BreadcrumbsProps): JSX.Element {
  const { categoryLink, subCategoryPageLink } = useReadLearnLinks({ category, subCategory: subcategory })
  const intl = useIntl()
  const formattedMainBreadcrumb = intl.formatMessage({ defaultMessage: "Read & learn" })

  const breadcrumbs = [
    <Breadcrumb key="read-learn-main-breadcrumb" component={ReactRouterLink} to="/read">
      {formattedMainBreadcrumb}
    </Breadcrumb>
  ]

  if (subcategory) {
    breadcrumbs.push(
      <Breadcrumb key="read-learn--cat-breadcrumb" component={ReactRouterLink} to={categoryLink}>
        {category?.fields?.title}
      </Breadcrumb>,
      <Breadcrumb key="read-learn--subcat-breadcrumb" component={ReactRouterLink} to={subCategoryPageLink}>
        {subcategory?.fields?.title}
      </Breadcrumb>
    )
  } else {
    breadcrumbs.push(
      <Breadcrumb key="read-learn--cat-breadcrumb" currentPage>
        {category?.fields?.title}
      </Breadcrumb>
    )
  }

  return <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
}
