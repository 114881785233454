import React, { FC } from "react"
import { CarrotCardModuleBase, CarrotCardModuleProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "utils/Paths"
import { ArrowLink } from "@carrotfertility/carotene"

const CarrotCardShippedModule: FC<CarrotCardModuleProps> = () => (
  <CarrotCardModuleBase
    backgroundColor={(theme) => ({ backgroundColor: theme.palette.background.default })}
    status="shipped"
    body={
      <FormattedMessage defaultMessage="Your card has shipped. Activate it when it arrives so you can start using it right away." />
    }
    links={[
      <>
        <ArrowLink href={DbgPaths.CARROT_CARD}>
          <FormattedMessage defaultMessage="Learn how Carrot Card works" />
        </ArrowLink>
      </>
    ]}
  />
)

export { CarrotCardShippedModule }
