import TalkToCarrotTilePage from "components/talk-to-carrot/TalkToCarrotTilePage"
import { ITilePage } from "contentful-types"
import React, { ReactElement } from "react"
import {
  TileNextActionType,
  fieldIsValid,
  getNextActionType,
  getNextTileAction,
  getPathForTile,
  sortByJourneySpecificOrder
} from "../utils/mappingUtils"
import { GuidanceElement } from "./GuidanceElement"
import { TileElement } from "./TileElement"
import { Redirect } from "react-router"
import { Paths } from "utils/Paths"
import { toCondition } from "../utils/conditionUtils"
import { SlidingDotsLoader } from "@carrotfertility/carotene"
import { useGetPhoneSupportNumberForUser } from "components/dynamic-content/hooks/useQueryPhoneSupport"
import { useDoesUserSatisfyConditions } from "services/contentful/conditional-gate"
import { Journey } from "../utils/journeyOrderMapping"
import { useGetMostRecentlyCompletedRouting } from "components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"

type TTCTilePageElementProps = {
  page: ITilePage
  baseUrl: string
}

const TTCTilePageElement = ({ page, baseUrl }: TTCTilePageElementProps): ReactElement<typeof TalkToCarrotTilePage> => {
  const {
    fields: { guidance, tiles }
  } = page
  const { doesUserSatisfyConditions, isLoading: isDoesUserSatisfyConditionsLoading } = useDoesUserSatisfyConditions()
  const { data: phoneSupportNumber, isError, isInitialLoading } = useGetPhoneSupportNumberForUser()
  const { data: routingData, isLoading: isRoutingInfoLoading } = useGetMostRecentlyCompletedRouting()
  const journey = routingData?.journey

  const hasPhoneSupportNumber = tiles.some(
    (tile) => getNextActionType(getNextTileAction(tile)) === TileNextActionType.PHONE_SUPPORT_NUMBER
  )

  if (isDoesUserSatisfyConditionsLoading || isRoutingInfoLoading || (hasPhoneSupportNumber && isInitialLoading)) {
    return <SlidingDotsLoader />
  }
  if (isError) {
    return <Redirect to="/talk-to-carrot/error" />
  }

  const conditionalAvailableTiles = tiles.filter(
    ({ fields: { conditions } }) => !conditions || doesUserSatisfyConditions(conditions.map(toCondition))
  )
  const availableTiles = conditionalAvailableTiles.filter((tile) =>
    getNextActionType(getNextTileAction(tile)) === TileNextActionType.PHONE_SUPPORT_NUMBER
      ? Boolean(phoneSupportNumber.e164FormatPhoneNumber)
      : true
  )

  if (availableTiles.length === 0) {
    return <Redirect to={Paths.TALK_TO_CARROT} />
  }
  return (
    <>
      {availableTiles && fieldIsValid(guidance) ? (
        <TalkToCarrotTilePage
          guidance={<GuidanceElement guidance={guidance} />}
          tiles={availableTiles
            .filter(fieldIsValid)
            .sort((a, b) => {
              return sortByJourneySpecificOrder((journey as Journey) ?? "DEFAULT", a, b)
            })
            .map((tile) => (
              <TileElement
                tile={tile}
                baseUrl={baseUrl}
                key={`${getPathForTile({ tile, baseUrl })}_tile`}
                doesUserSatisfyConditions={doesUserSatisfyConditions}
                memberJourney={journey}
              />
            ))}
        />
      ) : null}
    </>
  )
}

export { TTCTilePageElement }
