import React, { FC, useState } from "react"
import { CarrotCardModuleBase, CarrotCardModuleProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { RequestCardContainer } from "../../../../pages/request-carrot-card-flow"
import { useDispatch } from "react-redux"
import { fetchReimbursementDetails } from "../../../../actions/reimbursementDetailsActions"
import { Button, Typography } from "@carrotfertility/carotene-core"

const CarrotCardEligibleModule: FC<CarrotCardModuleProps> = ({ isVirtualCard }) => {
  const [openRequestCardModal, setOpenRequestCardModal] = useState(false)
  const dispatch = useDispatch()

  // @ts-expect-error TS7006
  const toggleRequestCardModal = (event) => {
    event.preventDefault()
    setOpenRequestCardModal(true)
  }

  const onClose = () => {
    dispatch(fetchReimbursementDetails())
    setOpenRequestCardModal(false)
  }

  return (
    <CarrotCardModuleBase
      backgroundColor={(theme) => ({ backgroundColor: theme.palette.background.default })}
      title={
        <Typography variant="h4" component="h3" color="text.primary" marginBottom={(theme) => theme.tokens.spacing.xs}>
          <FormattedMessage defaultMessage="Easily pay for care with Carrot Card®" />
        </Typography>
      }
      body={
        <FormattedMessage defaultMessage="The easiest way to pay for eligible services, debited directly from your account." />
      }
      links={[
        <>
          <Button
            onClick={toggleRequestCardModal}
            variant="outlined"
            color="secondary"
            id="carrot-card-module-request-card"
            sx={(theme) => ({ marginTop: theme.tokens.spacing.md })}
          >
            {isVirtualCard ? (
              <FormattedMessage defaultMessage="Get virtual card" />
            ) : (
              <FormattedMessage defaultMessage="Request a card" />
            )}
          </Button>
          <RequestCardContainer onOpen={openRequestCardModal} onExit={onClose} />
        </>
      ]}
    />
  )
}

export { CarrotCardEligibleModule }
