import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import { useCurrentUser } from "components/context/user/UserContext"
import useAboutYou from "components/carrot-plans/hooks/useAboutYou"
import { adminCarrotClient } from "utils/CarrotClient"
import { StepLayout } from "components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton } from "components/carrot-plans/shared/ButtonFooter"
import { FormattedMessage, useIntl } from "react-intl"
import { Box } from "@carrotfertility/carotene-core"
import { RoutingFlowTitle } from "components/carrot-plans/shared"
import { useTrackExperimentEvent } from "../../../views/register/hooks/useExperiments"

export default function AboutYouIntro(): JSX.Element {
  const intl = useIntl()
  const { data } = useAboutYou()
  const { refreshUserInfo, carrotPlansRoutingStarted } = useCurrentUser()
  const { send } = useStateMachine(data?.dateOfBirth && carrotPlansRoutingStarted ? "" : null)
  const { trackExperimentEvent } = useTrackExperimentEvent()

  async function onClickContinue(): Promise<void> {
    if (carrotPlansRoutingStarted === null) {
      try {
        await adminCarrotClient.startCarrotPlansEnrollment()
        refreshUserInfo()
      } catch (error) {
        reportError(error)
      }
    }
    send("")
  }

  // WQ-1587: Tracking for "show-registration-to-routing-cta" flag in Launch Darkly
  trackExperimentEvent("first-page-of-routing-visited")

  return (
    <>
      <RoutingFlowTitle
        title={intl.formatMessage({
          defaultMessage: "Tell us about your journey"
        })}
      />
      <Box display="flex" justifyContent="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <Box
          component="img"
          src={`/images/illo-underwater-checklist.png`}
          srcSet={`/images/illo-underwater-checklist-2x.png`}
          alt=""
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          height={228}
          width={228}
        />
      </Box>
      <StepLayout
        header={intl.formatMessage({ defaultMessage: "Tell us more" })}
        description={intl.formatMessage({
          defaultMessage:
            "So that we can create your Carrot Plan, please tell us more about yourself, where you are in your journey, and how we can help right now."
        })}
        formElements={
          <Form onSubmit={onClickContinue}>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
