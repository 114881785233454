import React, { useState, useEffect, createContext } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { withRouter } from "react-router-dom"
import CarrotClient from "lib/carrot-api/index"
import ResetPassword from "../../../pages/reset-password"
import Settings from "../../../utils/CarrotConfig"
import { useIntl } from "react-intl"
import { getErrorMessageFromCode } from "../../../utils/CarrotErrors"

const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

export const ResetPasswordContext = createContext()

const defaultState = {
  isGuidError: false,
  isResetPasswordError: false,
  isLoaded: false,
  isSubmitting: false,
  isSuccess: false
}

const ResetPasswordContainer = ({ match, history }) => {
  const intl = useIntl()
  const [state, setState] = useState(defaultState)
  const formMethods = useForm({ mode: "onSubmit" })
  const { setError, clearErrors, handleSubmit } = formMethods

  const setPasswordError = (message) => {
    setError("password", { message, type: "invalidPassword" })
    setError("confirmPassword", { message, type: "invalidPassword" })
  }

  const onSubmit = handleSubmit(async (formData) => {
    const isValid = await validateInputs(formData, clearErrors)
    const { password, confirmPassword } = formData

    if (isValid) {
      setState({ ...state, isSubmitting: true })
      try {
        await client.resetPassword(password, confirmPassword, match.params.guid)
      } catch (error) {
        setState({ ...state, isSubmitting: false })
        setPasswordError(
          intl.formatMessage({ defaultMessage: "An error occurred while trying to reset your password" })
        )
        return
      }

      history.push("/reset-password-success")
    }
  })

  const validateInputs = async (formData, clearErrors) => {
    const { password, confirmPassword } = formData

    if (password !== confirmPassword) {
      setPasswordError(intl.formatMessage({ defaultMessage: "Passwords do not match" }))
      return false
    }

    try {
      await client.validatePassword(password)
    } catch (error) {
      const { code } = await error.response.json()
      const errorMessage = getErrorMessageFromCode(code)
      setPasswordError(intl.formatMessage(errorMessage))
      return false
    }

    clearErrors()
    return true
  }

  useEffect(() => {
    const validateGuid = async () => {
      try {
        await client.validateResetPasswordGuid(match.params.guid)
      } catch (error) {
        setState((state) => ({ ...state, isGuidError: true }))
        return
      }
      setState((state) => ({ ...state, isLoaded: true }))
    }
    validateGuid()
  }, [match.params.guid])

  const context = {
    isSubmitting: state.isSubmitting,
    isLoaded: state.isLoaded,
    onSubmit: onSubmit,
    isGuidError: state.isGuidError
  }

  return (
    <FormProvider {...formMethods}>
      <ResetPasswordContext.Provider value={context}>
        <ResetPassword />
      </ResetPasswordContext.Provider>
    </FormProvider>
  )
}
export default withRouter(ResetPasswordContainer)
