import React from "react"
import { FlexContainer, H2, SelectOptionType, Stack, Text } from "@carrotfertility/carotene"
import ArrowSubmitButton from "features/legacy-components/ArrowSubmitButton"
import { BackArrow } from "components/views/atoms/Molecules"
import { Progress } from "components/views/modal/Progress"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "components/context/reimbursement/GetReimbursedContext"
import { getCurrencyCode } from "reducers/companyInfo"
import currencyCodesToAllowedDecimalPlacesMap from "../../../content/CurrencyCode"
import { useSelector } from "react-redux"
import { InputLabel, Link, TextField, Autocomplete } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { resolveCurrencyFormat } from "@sumup/intl"
import { NumericFormat } from "react-number-format"

export const AboutThisExpenseEligibleReimbursementAmount = (): JSX.Element => {
  const intl = useIntl()
  const { locale } = intl
  const { send, back } = useStateMachine()
  const {
    state,
    updateEligibleReimbursementAmount,
    updateEligibleReimbursementAmountString,
    updateEligibleReimbursementAmountCurrencyCode
  } = useReimbursementContext()
  const companyCurrencyCode = useSelector(getCurrencyCode)
  if (state.eligibleReimbursementAmountCurrencyCode == null) {
    updateEligibleReimbursementAmountCurrencyCode(companyCurrencyCode ?? "USD")
  }

  const canSubmit = state.eligibleReimbursementAmount && state.eligibleReimbursementAmountString.length !== 0

  // @ts-expect-error TS7006
  const handleCurrencySelectChange = (event, newValue): void => {
    if (newValue === null) return
    updateEligibleReimbursementAmountCurrencyCode(newValue?.value)
  }

  // @ts-expect-error TS7006
  const handleCurrencyInputChange = (event): void => {
    updateEligibleReimbursementAmount(event.value)
    updateEligibleReimbursementAmountString(event.formattedValue)
  }

  const handleContinueClick = (): void => {
    send("")
  }

  const format = resolveCurrencyFormat(locale, state.eligibleReimbursementAmountCurrencyCode, {
    style: "currency"
  })
  const suffix = format?.currencyPosition === "suffix" ? ` ${format?.currencySymbol}` : ""
  const prefix = format?.currencyPosition === "prefix" ? `${format?.currencySymbol} ` : ""

  const currencyOptions = currencySymbolsSelectValue(locale)
  return (
    <>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <Stack minHeight="500px" paddingTopBottom="huge">
        <H2>
          <FormattedMessage defaultMessage="How much did you pay for eligible care or services?" />
        </H2>
        <Text>
          <FormattedMessage defaultMessage="Please select the currency you paid in." />
        </Text>
        <Link target="_blank" href={"/your-benefit-guide/coverage"} color="inherit">
          <FormattedMessage defaultMessage="Learn what's eligible." />
        </Link>
        <Autocomplete
          id="currencySelect"
          defaultValue={currencyOptions.find(({ value }) => value === state.eligibleReimbursementAmountCurrencyCode)}
          onChange={handleCurrencySelectChange}
          options={currencyOptions}
          value={currencyOptions.find(({ value }) => value === state.eligibleReimbursementAmountCurrencyCode)}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              placeholder={intl.formatMessage({ defaultMessage: "Select currency" })}
              name="currencySelect"
              label={<FormattedMessage defaultMessage="Currency Code" />}
            />
          )}
        />
        <div>
          <InputLabel required={true} htmlFor="currencySelect">
            <FormattedMessage defaultMessage="Amount" />
          </InputLabel>
          <NumericFormat
            id="currencyAmountInput"
            name="currencyAmountInput"
            customInput={TextField}
            placeholder={intl.formatMessage({ defaultMessage: "Total amount" })}
            value={state.eligibleReimbursementAmountString}
            onValueChange={(event) => handleCurrencyInputChange(event)}
            prefix={prefix}
            suffix={suffix}
            thousandSeparator={format?.groupDelimiter}
            decimalSeparator={format?.decimalDelimiter}
            decimalScale={format?.maximumFractionDigits}
            allowNegative={false}
          />
        </div>
      </Stack>
      <FlexContainer paddingTopBottom="huge" justifyContent="flex-end">
        <ArrowSubmitButton enabled={canSubmit} onClick={handleContinueClick}>
          <FormattedMessage defaultMessage="Continue" />
        </ArrowSubmitButton>
      </FlexContainer>
    </>
  )
}

function currencySymbolsSelectValue(locale: string): SelectOptionType[] {
  // @ts-expect-error TS7006
  const getCurrencySymbolFromCode = (currencyCode): string => {
    const formatter = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyCode
    })

    let symbol
    formatter.formatToParts(0).forEach(({ type, value }) => {
      if (type === "currency") {
        symbol = value
      }
    })

    return symbol
  }

  const currencySymbols: SelectOptionType[] = []
  for (const currencyCode of Object.keys(currencyCodesToAllowedDecimalPlacesMap)) {
    const symbol = getCurrencySymbolFromCode(currencyCode)
    const label = symbol !== currencyCode ? `${symbol} ${currencyCode}` : `${symbol}`
    const option: SelectOptionType = {
      label: label,
      value: currencyCode
    }
    currencySymbols.push(option)
  }
  return currencySymbols
}
