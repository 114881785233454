import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Box, CloseIcon, IconButton, Progress, useTheme } from "@carrotfertility/carotene-core"
import { CarrotDocumentType, getSignatureForDocument, SignatureCompleteEvent } from "carrot-api/signatureClient"
import { Title } from "services/page-title/Title"
import { Redirect, useHistory } from "react-router"
import { useShouldShowArtMedicalRecordsConsent } from "components/carrot-plans/steps/journey/useShouldShowArtMedicalRecordsConsent"
import { useGetMostRecentlyCompletedRouting } from "components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { useCurrentUser } from "components/context/user/UserContext"
import { useGetIsExcludedFromMedicalRecordsConsent } from "services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { CountryCode } from "content/CountryCode"

function MedicalRecordsCollection({ documentType }: { documentType: CarrotDocumentType }): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)
  const header = intl.formatMessage({ defaultMessage: "Share some medical information" })
  const history = useHistory()
  const { data: routingData, isLoading: isRoutingInfoLoading } = useGetMostRecentlyCompletedRouting()
  const { isLoadingShouldShowArtMedicalRecordsConsent, shouldShowArtMedicalRecordsConsent } =
    useShouldShowArtMedicalRecordsConsent({ routingData })
  const { company } = useCurrentUser()
  const { countryCode: companyCountryCode, companyId, parentCompanyId } = company
  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  const isExcludedFromMedicalRecordsConsent = getIsExcludedFromMedicalRecordsConsent({
    companyId,
    parentCompanyId
  })

  const handleWindowEvent = useCallback(
    async (event: SignatureCompleteEvent) => {
      if (event?.data?.documentType === documentType) {
        window.removeEventListener("message", handleWindowEvent)
        history.push("/")
      }
    },
    [history, documentType]
  )

  const showArtConsentFormConditions =
    documentType === CarrotDocumentType.ArtMedicalRecordsConsentForm &&
    !isRoutingInfoLoading &&
    !isLoadingShouldShowArtMedicalRecordsConsent &&
    shouldShowArtMedicalRecordsConsent

  const showPregnancyConsentFormConditions =
    documentType === CarrotDocumentType.PregnancyMedicalRecordsConsentForm &&
    !isRoutingInfoLoading &&
    routingData?.journey === "PREGNANT" &&
    companyCountryCode === CountryCode.United_States &&
    !isExcludedFromMedicalRecordsConsent

  useEffect(() => {
    const showDocument = async () => {
      window.addEventListener("message", handleWindowEvent)
      // TODO: OP-1857 - Add state to store signatureId and use it when saving the document status
      const envelopeSignatureInfo = await getSignatureForDocument(documentType)
      setFrameSrc(envelopeSignatureInfo.docuSignUrl)
      setDocuSignIsReady(true)
    }
    if (documentType && (showArtConsentFormConditions || showPregnancyConsentFormConditions)) {
      showDocument()
    }
  }, [showArtConsentFormConditions, showPregnancyConsentFormConditions, documentType, handleWindowEvent])

  if (!isRoutingInfoLoading && !isLoadingShouldShowArtMedicalRecordsConsent) {
    if (
      (documentType === CarrotDocumentType.ArtMedicalRecordsConsentForm && !showArtConsentFormConditions) ||
      (documentType === CarrotDocumentType.PregnancyMedicalRecordsConsentForm && !showPregnancyConsentFormConditions)
    ) {
      return <Redirect to="/" />
    }
  }

  return (
    <>
      <Title title={header} />
      <Box height="100vh" width="100vw">
        <Box
          display="flex"
          justifyContent="flex-end"
          paddingTop={theme.tokens.spacing.lg}
          paddingInlineEnd={theme.tokens.spacing.lg}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={() => history.push("/")}>
            <CloseIcon fontSize={"medium"} color="secondary" />
          </IconButton>
        </Box>
        <Box marginTop={theme.tokens.spacing.xs} height="calc(100% - 40px)">
          {docuSignIsReady && (showArtConsentFormConditions || showPregnancyConsentFormConditions) ? (
            <iframe
              title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
              style={{ width: "100%", height: "100%" }}
              src={frameSrc}
              allowFullScreen
            />
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
              <Progress size={32} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export function ArtMedicalRecordsConsentPage() {
  return <MedicalRecordsCollection documentType={CarrotDocumentType.ArtMedicalRecordsConsentForm} />
}

export function PregnancyMedicalRecordsConsentPage() {
  return <MedicalRecordsCollection documentType={CarrotDocumentType.PregnancyMedicalRecordsConsentForm} />
}
