import { combineReducers } from "redux"
import actionTypes from "actions/actionTypes"
import moment from "moment"
import CardStates from "utils/CardStates"
import { createIsLoadedReducer, createReducer } from "reducers/shared"
import TestUserActionTypes from "actions/testUserActionTypes"

const { FETCH_USER_INFO_SUCCESS, FETCH_BENEFIT_ENROLLMENT_SUCCESS } = actionTypes

const reducers = {}

reducers.loaded = createIsLoadedReducer("FETCH_USER_INFO")

reducers.userInfoLoadError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_FAILURE:
      return action.response
    default:
      return state
  }
}

reducers.firstName = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.firstName
    default:
      return state
  }
}
reducers.lastName = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.lastName
    default:
      return state
  }
}

reducers.sex = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_SEX_SUCCESS:
      return action.response.sex
    default:
      return state
  }
}

reducers.genderIdentity = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_GENDER_IDENTITY_SUCCESS:
      return action.response.genderIdentity
    default:
      return state
  }
}

reducers.email = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.email
    default:
      return state
  }
}

reducers.personalEmail = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.personalEmail
    case actionTypes.UPDATE_PERSONAL_EMAILS_SUCCESS:
      return action.response.personalEmail
    default:
      return state
  }
}

reducers.emailOptIn = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.emailOptIn
    case actionTypes.UPDATE_EMAIL_OPT_IN_SUCCESS:
      return action.response.emailOptIn
    default:
      return state
  }
}
reducers.pushNotificationOptIn = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.pushNotificationOptIn
    default:
      return state
  }
}

reducers.partnerFirstName = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_NAME_SUCCESS:
      return action.response.partnerFirstName
    default:
      return state
  }
}

reducers.partnerLastName = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_NAME_SUCCESS:
      return action.response.partnerLastName
    default:
      return state
  }
}

reducers.partnerFullName = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.partnerFullName
    default:
      return state
  }
}

reducers.partnerDateOfBirth = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_DOB_SUCCESS:
      return action.response.partnerDateOfBirth
    default:
      return state
  }
}

reducers.memberCoverageStartDate = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_MEMBER_COVERAGE_START_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.memberCoverageStartDate
    default:
      return state
  }
}

reducers.memberCoverageEndDate = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_MEMBER_COVERAGE_END_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.memberCoverageEndDate
    default:
      return state
  }
}

reducers.partnerCoverageStartDate = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_COVERAGE_START_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.partnerCoverageStartDate
    default:
      return state
  }
}

reducers.partnerCoverageEndDate = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_COVERAGE_END_DATE:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.partnerCoverageEndDate
    default:
      return state
  }
}

reducers.legallyMarried = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_LEGALLY_MARRIED_SUCCESS:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.legallyMarried
    default:
      return state
  }
}

reducers.partnerAsDependent = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_AS_DEPENDENT_SUCCESS:
    case FETCH_BENEFIT_ENROLLMENT_SUCCESS:
      return action.response.partnerAsDependent
    default:
      return state
  }
}

reducers.partnerSex = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_SEX_SUCCESS:
      return action.response.partnerSex
    default:
      return state
  }
}

reducers.partnerEmail = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PERSONAL_EMAILS_SUCCESS:
    case actionTypes.UPDATE_PARTNER_EMAIL_SUCCESS:
      return action.response.partnerEmail
    default:
      return state
  }
}

reducers.partnerGenderIdentity = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
    case actionTypes.UPDATE_PARTNER_GENDER_IDENTITY_SUCCESS:
      return action.response.partnerGenderIdentity
    default:
      return state
  }
}

reducers.registrationCohort = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.registrationCohort
    default:
      return state
  }
}

reducers.subjectToAppLockdown = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.subjectToAppLockdown
    case TestUserActionTypes.TOGGLE_SUBJECT_TO_APP_LOCKDOWN:
      return !state
    default:
      return state
  }
}

reducers.isInAppLockdown = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.isInAppLockdown
    default:
      return state
  }
}

reducers.address1 = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.address1
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.address1
    default:
      return state
  }
}

reducers.address2 = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.address2
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.address2
    default:
      return state
  }
}

reducers.city = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.city
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.city
    default:
      return state
  }
}

reducers.state = (state = null, action) => {
  if (!action.response || !action.response.state) {
    return state
  }
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.state
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.state
    default:
      return state
  }
}

reducers.zip = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.zip
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return action.response.zip
    default:
      return state
  }
}

reducers.internationalCountry = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.internationalCountry
    default:
      return state
  }
}

reducers.internationalAddressLine = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.internationalAddressLine
    default:
      return state
  }
}

reducers.nonUsaAddress = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.nonUsaAddress
    default:
      return state
  }
}

reducers.isUsa = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.isUsa
    case TestUserActionTypes.TOGGLE_IS_USA:
      return !state
    default:
      return state
  }
}

reducers.eligibilityDate = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.eligibilityDate ? new moment(action.response.eligibilityDate) : null
    default:
      return state
  }
}

reducers.dateOfBirth = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.dateOfBirth
    case actionTypes.UPDATE_DATE_OF_BIRTH_SUCCESS:
      return action.response.dateOfBirth
    default:
      return state
  }
}

reducers.hasCardholderId = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.hasCardholderId
    default:
      return state
  }
}

reducers.cardState = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.cardState
    case actionTypes.ACTIVATE_CARD_SUCCESS:
      return CardStates.ACTIVE
    default:
      return state
  }
}

reducers.isActivateSubmitting = (state = false, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_CARD_REQUEST:
      return true
    case actionTypes.ACTIVATE_CARD_FAILURE:
      return false
    case actionTypes.ACTIVATE_CARD_SUCCESS:
      return false
    default:
      return state
  }
}

reducers.isActivateError = (state = false, action) => {
  switch (action.type) {
    case actionTypes.ACTIVATE_CARD_REQUEST:
      return false
    case actionTypes.ACTIVATE_CARD_FAILURE:
      return true
    case actionTypes.ACTIVATE_CARD_SUCCESS:
      return false
    default:
      return state
  }
}

reducers.isCarrotCardEligible = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_INFO_SUCCESS:
      return action.response.isCarrotCardEligible
    case TestUserActionTypes.TOGGLE_IS_CARROT_CARD_ELIGIBLE:
      return !state
    default:
      return state
  }
}

reducers.employeeId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.employeeId
})

reducers.carrotUserId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.carrotUserId
})

reducers.hasBankInfoFilledOut = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.hasBankInfoFilledOut
})

reducers.carrotUserCreatedDate = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) =>
    action.response.carrotUserCreatedDate ? new moment(action.response.carrotUserCreatedDate) : null
})

reducers.isLikelyToDoIvf = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.isLikelyToDoIvf
})

reducers.hasMetSignatureRequirement = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.hasMetSignatureRequirement,
  [actionTypes.UPDATE_HAS_MET_SIGNATURE_REQUIREMENT_IN_MEMORY]: (state, action) =>
    action.response.hasMetSignatureRequirement
})

reducers.hasAnUpcomingOrCompletedExpertChat = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.hasAnUpcomingOrCompletedExpertChat
})

reducers.phoneNumber = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.phoneNumber
})

reducers.planStarted = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.planStarted
})

reducers.deductibleStatus = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.deductibleStatus,
  [actionTypes.UPDATE_MEMBER_ENROLLMENTS_SUCCESS]: (state, action) => {
    return action.response.deductibleStatus
  }
})

reducers.memberHasLegalPartner = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberHasLegalPartner,
  [actionTypes.UPDATE_MEMBER_HAS_LEGAL_PARTNER_SUCCESS]: (state, action) => action.response,
  [TestUserActionTypes.TOGGLE_MEMBER_HAS_LEGAL_PARTNER]: (state) => !state,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberHasLegalPartner
})

reducers.memberCoverageType = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberCoverageType,
  [actionTypes.UPDATE_MEMBER_COVERAGE_TYPE_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberCoverageType
})

reducers.memberCoverageTypeSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberCoverageTypeSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberCoverageTypeSelectValue
})
reducers.memberPlanSource = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberPlanSource,
  [actionTypes.UPDATE_MEMBER_PLAN_SOURCE_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberPlanSource
})
reducers.memberPlanSourceSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberPlanSourceSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberPlanSourceSelectValue
})
reducers.memberHasHSA = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberHasHSA,
  [actionTypes.UPDATE_MEMBER_HAS_HSA_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberHasHSA
})
reducers.memberHsaContributionStatus = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberHsaContributionStatus,
  [actionTypes.UPDATE_MEMBER_HSA_CONTRIBUTION_STATUS_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberHsaContributionStatus
})
reducers.memberHasHDHP = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberHasHDHP,
  [actionTypes.UPDATE_MEMBER_HAS_HDHP_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberHasHDHP
})
reducers.partnerCoverageType = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerCoverageType,
  [actionTypes.UPDATE_PARTNER_COVERAGE_TYPE_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerCoverageType
})
reducers.partnerCoverageTypeSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerCoverageTypeSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerCoverageTypeSelectValue
})
reducers.partnerPlanSource = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerPlanSource,
  [actionTypes.UPDATE_PARTNER_PLAN_SOURCE_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerPlanSource
})
reducers.partnerPlanSourceSelectValue = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerPlanSourceSelectValue,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerPlanSourceSelectValue
})
reducers.partnerHasHSA = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHasHSA,
  [actionTypes.UPDATE_PARTNER_HAS_HSA_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHasHSA
})
reducers.partnerHsaContributionStatus = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHsaContributionStatus,
  [actionTypes.UPDATE_PARTNER_HSA_CONTRIBUTION_STATUS_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHsaContributionStatus
})
reducers.partnerHasHDHP = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHasHDHP,
  [actionTypes.UPDATE_PARTNER_HAS_HDHP_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHasHDHP
})
reducers.partnerHealthPlanMemberId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHealthPlanMemberId,
  [actionTypes.UPDATE_MEMBER_ID_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHealthPlanMemberId
})
reducers.memberId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberId,
  [actionTypes.UPDATE_MEMBER_ID_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberId
})
reducers.partnerHealthPlanSelectedTradingPartnerId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHealthPlanSelectedTradingPartnerId,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHealthPlanSelectedTradingPartnerId
})
reducers.partnerHealthPlanSelectedTradingPartnerPk = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHealthPlanSelectedTradingPartnerPk,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHealthPlanSelectedTradingPartnerPk
})
reducers.selectedTradingPartnerId = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.selectedTradingPartnerId,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.selectedTradingPartnerId
})
reducers.selectedTradingPartnerPk = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.selectedTradingPartnerPk,
  [actionTypes.UPDATE_SELECTED_TRADING_PARTNER_SUCCESS]: (state, action) => action.response,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.selectedTradingPartnerPk
})
reducers.memberHasSuccessfullySyncedInsurance = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.memberHasSuccessfullySyncedInsurance,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.memberHasSuccessfullySyncedInsurance
})
reducers.partnerHasSuccessfullySyncedInsurance = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerHasSuccessfullySyncedInsurance,
  [FETCH_BENEFIT_ENROLLMENT_SUCCESS]: (state, action) => action.response.partnerHasSuccessfullySyncedInsurance
})
reducers.isTestUser = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.isTestUser
})
reducers.isPosingAsMember = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.isPosingAsMember
})
reducers.genderIdentityOther = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.genderIdentityOther
})
reducers.partnerGenderIdentityOther = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerGenderIdentityOther
})
reducers.preferredName = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.preferredName
})
reducers.partnerPreferredName = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.partnerPreferredName
})
reducers.onCarrotCardNonQmeJourney = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.onCarrotCardNonQmeJourney
})
reducers.carrotPlansRoutingStarted = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.carrotPlansRoutingStarted
})
reducers.carrotPlansRoutingCompleted = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.carrotPlansRoutingCompleted
})
reducers.preferredEmail = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.preferredEmail
})
// eslint-disable-next-line no-restricted-syntax -- Handling the preferredLocale value included in the userInfo response
reducers.preferredLocale = createReducer(null, {
  // eslint-disable-next-line no-restricted-syntax
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.preferredLocale
})
reducers.dismissedPhoneNumberFeatureModal = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.dismissedPhoneNumberFeatureModal
})
reducers.locale = createReducer(null, {
  [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.locale
})

const userInfo = combineReducers(reducers)

export const getUserInfo = (state) => state.userInfo

export const getAddress1 = (state) => state.userInfo.address1
export const getAddress2 = (state) => state.userInfo.address2
export const getAddressState = (state) => state.userInfo.state
export const getCardState = (state) => state.userInfo.cardState
export const getCarrotUserCreatedDate = (state) => state.userInfo.carrotUserCreatedDate
export const getCity = (state) => state.userInfo.city
export const getDateOfBirth = (state) => state.userInfo.dateOfBirth
export const getEligibilityDate = (state) => state.userInfo.eligibilityDate
export const getEmail = (state) => state.userInfo.email
export const getEmailOptIn = (state) => state.userInfo.emailOptIn
export const getEmployeeId = (state) => state.userInfo.employeeId
export const getIsPosingAsMember = (state) => state.userInfo.isPosingAsMember
export const getGenderIdentity = (state) => state.userInfo.genderIdentity
export const getHasBankInfoFilledOut = (state) => state.userInfo.hasBankInfoFilledOut
export const getHasCardholderId = (state) => state.userInfo.hasCardholderId
export const getIsActivateError = (state) => state.userInfo.isActivateError
export const getIsActivateSubmitting = (state) => state.userInfo.isActivateSubmitting
export const getIsCarrotCardEligible = (state) => state.userInfo.isCarrotCardEligible
export const getIsUsa = (state) => state.userInfo.isUsa
export const getFirstName = (state) => state.userInfo.firstName
export const getLastName = (state) => state.userInfo.lastName
export const getNonUsaAddress = (state) => state.userInfo.nonUsaAddress
export const getPartnerDateOfBirth = (state) => state.userInfo.partnerDateOfBirth
export const getPartnerEmail = (state) => state.userInfo.partnerEmail
export const getPartnerFirstName = (state) => state.userInfo.partnerFirstName
export const getPartnerGenderIdentity = (state) => state.userInfo.partnerGenderIdentity
export const getPartnerLastName = (state) => state.userInfo.partnerLastName
export const getPartnerSex = (state) => state.userInfo.partnerSex
export const getPersonalEmail = (state) => state.userInfo.personalEmail
export const getSex = (state) => state.userInfo.sex
export const getUserInfoLoaded = (state) => state.userInfo.loaded
export const getZip = (state) => state.userInfo.zip
export const getCarrotUserId = (state) => state.userInfo.carrotUserId
export const getIsLikelyToDoIvf = (state) => state.userInfo.isLikelyToDoIvf
export const getHasMetSignatureRequirement = (state) => state.userInfo.hasMetSignatureRequirement
export const getHasAnUpcomingOrCompletedExpertChat = (state) => state.userInfo.hasAnUpcomingOrCompletedExpertChat
export const getPlanStarted = (state) => state.userInfo.planStarted
export const getDeductibleStatus = (state) => state.userInfo.deductibleStatus
export const getMemberHasLegalPartner = (state) => state.userInfo.memberHasLegalPartner
export const getMemberCoverageType = (state) => state.userInfo.memberCoverageType
export const getMemberPlanSource = (state) => state.userInfo.memberPlanSource
export const getMemberPlanSourceSelectValue = (state) => state.userInfo.memberPlanSourceSelectValue
export const getMemberHasHsa = (state) => state.userInfo.memberHasHSA
export const getPartnerCoverageType = (state) => state.userInfo.partnerCoverageType
export const getPartnerPlanSource = (state) => state.userInfo.partnerPlanSource
export const getPartnerPlanSourceSelectValue = (state) => state.userInfo.partnerPlanSourceSelectValue
export const getPartnerHasHsa = (state) => state.userInfo.partnerHasHSA
export const getMemberId = (state) => state.userInfo.memberId
export const getPartnerId = (state) => state.userInfo.partnerHealthPlanMemberId
export const getSelectedTradingPartnerId = (state) => state.userInfo.selectedTradingPartnerId
export const getSelectedTradingPartnerPk = (state) => state.userInfo.selectedTradingPartnerPk
export const getPartnersSelectedTradingPartnerId = (state) => state.userInfo.partnerHealthPlanSelectedTradingPartnerId
export const getPartnersSelectedTradingPartnerPk = (state) => state.userInfo.partnerHealthPlanSelectedTradingPartnerPk
export const getMemberHasSuccessfullySyncedInsurance = (state) => state.userInfo.memberHasSuccessfullySyncedInsurance
export const getPartnerHasSuccessfullySyncedInsurance = (state) => state.userInfo.partnerHasSuccessfullySyncedInsurance
export const getIsTestUser = (state) => state.userInfo.isTestUser
export const getGenderIdentityOther = (state) => state.userInfo.genderIdentityOther
export const getPartnerGenderIdentityOther = (state) => state.userInfo.partnerGenderIdentityOther
export const getPreferredName = (state) => state.userInfo.preferredName
export const getPartnerPreferredName = (state) => state.userInfo.partnerPreferredName
export const getOnCarrotCardNonQmeJourney = (state) => state.userInfo.onCarrotCardNonQmeJourney
export const getSubjectToAppLockdown = (state) => state.userInfo.subjectToAppLockdown
// eslint-disable-next-line no-restricted-syntax -- We use this selector for a PreferredLocale HeapUserProperty
export const getPreferredLocale = (state) => state.userInfo.preferredLocale

export default userInfo
