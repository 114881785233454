import React, { ReactNode, useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage, useIntl } from "react-intl"
import { Alert, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Form } from "@carrotfertility/carotene-core-x"
import { updateMemberAddress } from "actions"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { getUserInfo } from "reducers/userInfo"
import { UsaStates } from "content/UsaStateOptions"
import { Address } from "services/common-forms"
import { reportError } from "utils/ErrorReporting"
import { RequestCardContext } from "pages/request-carrot-card-flow"
import { TtcPaths } from "../../../utils/Paths"

type FormSelectOption = { value: string | number; label: ReactNode | ReactNode[] }

type MailingAddress = {
  address1: string
  address2: string
  city: string
  state: any
  zip: number
}

const DefaultRequestCardMailingAddressState = {
  isInError: false
}

const RequestCardMailingAddress = (): JSX.Element => {
  const userInfo = useSelector(getUserInfo)
  const dispatch = useDispatch()
  const theme = useTheme()
  const intl = useIntl()
  const [state, setState] = useState(DefaultRequestCardMailingAddressState)

  const { canGoBack, send } = useStateMachine(null)

  // @ts-expect-error TS7006
  const onSubmission = async (formValues) => {
    try {
      setState({ ...state, isInError: false })
      await dispatch(
        updateMemberAddress(
          formValues.address1,
          formValues.address2,
          formValues.city,
          formValues.state.value,
          formValues.zip
        )
      )
    } catch (e) {
      e.message =
        `Error for employeeId: "${userInfo.employeeId}" while confirming Carrot Card® address: RAW:` + e.message
      setState({ ...state, isInError: true })
      reportError(e)
      return
    }

    send("")
  }

  const UsaStatesOptionValues: FormSelectOption[] = UsaStates.map((state) => ({
    label: intl.formatMessage(state.label),
    value: state.id
  }))

  const selectedStateOption = UsaStatesOptionValues.find(({ value }) => value === userInfo.state)

  const showInformationScreen = useContext(RequestCardContext).showInformationScreen

  const defaultValues: MailingAddress = {
    address1: userInfo.address1,
    address2: userInfo.address2,
    city: userInfo.city,
    state: selectedStateOption?.value,
    zip: userInfo.zip
  }

  return (
    <Form onSubmit={onSubmission}>
      <RequestCardWrapper showBackButton={canGoBack || showInformationScreen} showContinueButton sectionNum={1}>
        <div>
          <Typography variant="h3" component="h2">
            <FormattedMessage defaultMessage="Confirm your address" />
          </Typography>
          <Stack spacing={theme.tokens.spacing.xl}>
            <Typography variant="body1">
              <FormattedMessage defaultMessage="Where do you want us to mail your Carrot Card? This will also be your billing address for the card." />
            </Typography>
            <Stack spacing={theme.tokens.spacing.sm}>
              <Address defaultValues={defaultValues} />
            </Stack>
            {state.isInError && (
              <Alert severity="error">
                <FormattedMessage
                  defaultMessage="An unexpected error occurred. Please <link>contact us</link> for assistance."
                  values={{
                    link: (linkContent) => (
                      <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Alert>
            )}
          </Stack>
        </div>
      </RequestCardWrapper>
    </Form>
  )
}

export { RequestCardMailingAddress }
