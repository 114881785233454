import { findCategoryBySlug, findGuidesBySlug, findSubcategoryForArticle } from "components/read/shared/Finder"
import { ICategory, IGuide, ISubCategory } from "contentful-types"
import { useContentfulConditionalGate } from "lib/contentful/components/ContentfulConditionGate"
import React from "react"
import { useHistory, useParams } from "react-router"
import { Guide, mapContentToGuide } from "./utils/utils"
import useGetCategories from "../../components/read/shared/useGetCategories"

export type GuideComponentProps = {
  guide: Guide
  category: ICategory
  subcategory: ISubCategory
}

export const GuideParser = ({
  GuideComponent
}: {
  GuideComponent: React.ComponentType<GuideComponentProps>
}): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ category: string; guide: string }>()

  const { data: categories } = useGetCategories()
  const category = findCategoryBySlug(categories, params.category)

  const guideContent = findGuidesBySlug(categories, params.guide) as IGuide[]
  const guideConditions = guideContent?.map((g) => {
    const guide = mapContentToGuide(g)
    const subcategory = findSubcategoryForArticle(categories, g)

    return {
      element: <GuideComponent guide={guide} category={category} subcategory={subcategory} />,
      conditions: guide.conditions
    }
  })

  const [guide] = useContentfulConditionalGate(guideConditions ? guideConditions : [])

  if (!guide || !category) {
    history.push("/read")
    return null
  }

  return <>{guide}</>
}
