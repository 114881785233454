import CarrotClient from "carrot-api"
import Settings from "../../../../utils/CarrotConfig"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "../../../../types/responseError"
import { HttpStatusCodes } from "../../../../utils/HttpStatusCodes"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const ENDPOINT = "GetCompanyComplianceConfigLineItemFilter"

const loadCompanyComplianceConfigLineItemFilter = async (): Promise<string> => {
  return client.getCompanyComplianceConfigLineItemFilter()
}

export const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useCompanyComplianceConfigLineItemFilter(): UseQueryResult<string, ResponseError> {
  return useQuery<string, ResponseError>(getBenefitCacheKey(), loadCompanyComplianceConfigLineItemFilter, {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    }
  })
}
