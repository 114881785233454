import { Steps } from "./steps"

export enum Workflows {
  REQUEST_CARD = "RequestCard"
}

const requestCardWorkflow = [
  { at: Steps.REQUEST_CARD_INFORMATION, given: "", goto: Steps.MAILING_ADDRESS },
  { at: Steps.MAILING_ADDRESS, given: "", goto: Steps.CONFIRM_PHONE_NUMBER },
  { at: Steps.CONFIRM_PHONE_NUMBER, given: "", goto: Steps.VERIFY_PHONE_NUMBER },
  { at: Steps.VERIFY_PHONE_NUMBER, given: "", goto: Steps.STRIPE_USER_AGREEMENT },
  { at: Steps.STRIPE_USER_AGREEMENT, given: "", goto: Steps.REQUEST_CARD_SUCCESS }
]

export const requestCardWorkflows = [{ name: Workflows.REQUEST_CARD, workflow: requestCardWorkflow }]
