import React, { useMemo } from "react"
import { useTrackView } from "../../../utils/heap"
import {
  Box,
  Button,
  ListItemText,
  Stack,
  Typography,
  UnorderedList,
  UnorderedListItem,
  useTheme
} from "@carrotfertility/carotene-core"
import { getPiiProps, PiiText } from "services/tracking"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { GetCategoryName } from "../reimbursements/ReimbursementCategoryMessages"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { continueToRouting } from "./RegistrationComplete"

interface ReimbursementCategoryNameProps {
  nameMessageKey?: string
}

interface ReimbursementCategoryProps {
  name?: string
  priority?: number
  reimbursementCategoryName?: ReimbursementCategoryNameProps
}

interface ReimbursementPlanProps {
  currencyCode?: string
  hasUnlimitedBenefit?: boolean
  reimbursementPercent?: number
  lifetimeMax?: number
  yearlyMax?: number
}

interface SinglePlanProps {
  reimbursementPlan: ReimbursementPlanProps
  reimbursementCategory: ReimbursementCategoryProps
}

interface MultipleCategoriesProps {
  companyDisplayName?: string
  currencyCode?: string
  companyPlans?: Array<SinglePlanProps>
  supportsOOPM?: boolean
  onBack: () => void
  onComplete: () => void
}

function DisplayMultipleCategoriesVaryingCostShare({
  companyDisplayName,
  companyPlans,
  currencyCode
}: MultipleCategoriesProps): JSX.Element {
  const planHasNoLifetimeMax = companyPlans.some((plan) => plan.reimbursementPlan.lifetimeMax === null)
  const hasQmeCategory = companyPlans.some(
    (plan) => plan.reimbursementCategory.reimbursementCategoryName.nameMessageKey === "QME"
  )
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography {...getPiiProps()}>
        {planHasNoLifetimeMax ? (
          <FormattedMessage
            defaultMessage="Your benefit covers eligible expenses from eligible providers to help make your journey more accessible. {companyDisplayName} provides up to an annual maximum of:"
            values={{
              companyDisplayName: <PiiText>{companyDisplayName}</PiiText>
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Your benefit covers eligible expenses from eligible providers to help make your journey more accessible. {companyDisplayName} provides up to a lifetime maximum of:"
            values={{
              companyDisplayName: <PiiText>{companyDisplayName}</PiiText>
            }}
          />
        )}
      </Typography>
      <UnorderedList>
        {companyPlans.map((currVal, iterator) => {
          return (
            <UnorderedListItem key={iterator}>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="{amount} for {category}"
                    values={{
                      category: GetCategoryName(
                        currVal.reimbursementCategory.reimbursementCategoryName.nameMessageKey,
                        hasQmeCategory
                      ).toLowerCase(),
                      amount: intl.formatNumber(
                        planHasNoLifetimeMax
                          ? currVal.reimbursementPlan.yearlyMax
                          : currVal.reimbursementPlan?.lifetimeMax,
                        {
                          style: "currency",
                          currency: currencyCode,
                          currencyDisplay: "narrowSymbol",
                          maximumFractionDigits: 0
                        }
                      )
                    }}
                  />
                }
              />
            </UnorderedListItem>
          )
        })}
      </UnorderedList>
      <Typography>
        {companyPlans.some((plan) => plan.reimbursementPlan.yearlyMax !== plan.reimbursementPlan.lifetimeMax) ? (
          <FormattedMessage defaultMessage="Annual limits and cost sharing apply. Funds you use may be taxed as income." />
        ) : (
          <FormattedMessage defaultMessage="Cost sharing applies, and funds you use may be taxed as income." />
        )}
      </Typography>
    </Stack>
  )
}

function DisplayMultipleCategorySameCostShare({
  companyDisplayName,
  companyPlans,
  currencyCode
}: MultipleCategoriesProps): JSX.Element {
  const companyCostShare = companyPlans[0].reimbursementPlan.reimbursementPercent
  const planHasNoLifetimeMax = companyPlans.some((plan) => plan.reimbursementPlan.lifetimeMax === null)
  const hasQmeCategory = companyPlans.some(
    (plan) => plan.reimbursementCategory.reimbursementCategoryName.nameMessageKey === "QME"
  )
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography {...getPiiProps()}>
        {planHasNoLifetimeMax ? (
          <FormattedMessage
            defaultMessage="Your benefit covers eligible expenses from eligible providers to help make your journey more accessible. {companyDisplayName} will pay for {companyCostSharePercent,number, ::percent} of your costs, up to an annual maximum of:"
            values={{
              companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
              companyCostSharePercent: companyCostShare / 100
            }}
          />
        ) : (
          <FormattedMessage
            defaultMessage="Your benefit covers eligible expenses from eligible providers to help make your journey more accessible. {companyDisplayName} will pay for {companyCostSharePercent,number, ::percent} of your costs, up to a lifetime maximum of:"
            values={{
              companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
              companyCostSharePercent: companyCostShare / 100
            }}
          />
        )}
      </Typography>
      <UnorderedList>
        {companyPlans.map((currVal, iterator) => {
          return (
            <UnorderedListItem key={iterator}>
              <ListItemText
                primary={
                  <FormattedMessage
                    defaultMessage="{amount} for {category}"
                    values={{
                      category: GetCategoryName(
                        currVal.reimbursementCategory.reimbursementCategoryName.nameMessageKey,
                        hasQmeCategory
                      ).toLowerCase(),
                      amount: intl.formatNumber(
                        planHasNoLifetimeMax
                          ? currVal.reimbursementPlan.yearlyMax
                          : currVal.reimbursementPlan?.lifetimeMax,
                        {
                          style: "currency",
                          currency: currencyCode,
                          currencyDisplay: "narrowSymbol",
                          maximumFractionDigits: 0
                        }
                      )
                    }}
                  />
                }
              />
            </UnorderedListItem>
          )
        })}
      </UnorderedList>
      <Typography>
        {companyPlans.some((plan) => plan.reimbursementPlan.yearlyMax !== plan.reimbursementPlan.lifetimeMax) ? (
          <FormattedMessage defaultMessage="Annual limits apply, and funds you use may be taxed as income." />
        ) : (
          <FormattedMessage defaultMessage="Funds you use may be taxed as income." />
        )}
      </Typography>
    </Stack>
  )
}

function SingleCategoryDisplay({
  companyDisplayName,
  companyPlans,
  currencyCode,
  supportsOOPM
}: MultipleCategoriesProps): JSX.Element {
  const planHasUnlimited = companyPlans.find((plan) => plan.reimbursementPlan.hasUnlimitedBenefit)
  const reimbursementPlan = planHasUnlimited ? planHasUnlimited?.reimbursementPlan : companyPlans[0].reimbursementPlan
  const planHasNoLifetimeMax = companyPlans.some((plan) => plan.reimbursementPlan.lifetimeMax === null)
  const intl = useIntl()
  const theme = useTheme()

  return (
    <>
      {supportsOOPM && (
        <Stack {...getPiiProps()} spacing={theme.spacing(theme.tokens.spacing.md)}>
          <Typography>
            <FormattedMessage
              defaultMessage="Your benefit covers eligible expenses from eligible providers to help make your journey more accessible. {companyDisplayName} provides up to a lifetime maxium of {amount}"
              values={{
                companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                amount: intl.formatNumber(reimbursementPlan?.lifetimeMax ? reimbursementPlan?.lifetimeMax : null, {
                  style: "currency",
                  currency: currencyCode,
                  currencyDisplay: "narrowSymbol",
                  maximumFractionDigits: 0
                })
              }}
            />
          </Typography>
          <Typography>
            <FormattedMessage defaultMessage="Cost sharing applies, and funds you use may be taxed as income." />
          </Typography>
        </Stack>
      )}
      {!supportsOOPM && (
        <Stack {...getPiiProps()} spacing={theme.spacing(theme.tokens.spacing.md)}>
          <Typography>
            <FormattedMessage defaultMessage="Your benefit covers eligible expenses from eligible providers to help make your journey more accessible." />
          </Typography>
          {reimbursementPlan.hasUnlimitedBenefit ? (
            <Typography>
              <FormattedMessage
                defaultMessage="{companyDisplayName} covers {reimbursementPercent,number, ::percent} for the cost of eligible care."
                values={{
                  companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                  reimbursementPercent: reimbursementPlan.reimbursementPercent / 100
                }}
              />
            </Typography>
          ) : planHasNoLifetimeMax ? (
            <Typography>
              <FormattedMessage
                defaultMessage="{companyDisplayName} will pay for {reimbursementPercent,number, ::percent} of your costs, up to an annual maximum of {amount}."
                values={{
                  companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                  reimbursementPercent: reimbursementPlan.reimbursementPercent / 100,
                  amount: intl.formatNumber(reimbursementPlan?.yearlyMax, {
                    style: "currency",
                    currency: currencyCode,
                    currencyDisplay: "narrowSymbol",
                    maximumFractionDigits: 0
                  })
                }}
              />
            </Typography>
          ) : (
            <Typography>
              <FormattedMessage
                defaultMessage="{companyDisplayName} will pay for {reimbursementPercent,number, ::percent} of your costs, up to a lifetime maximum of {amount}."
                values={{
                  companyDisplayName: <PiiText>{companyDisplayName}</PiiText>,
                  reimbursementPercent: reimbursementPlan.reimbursementPercent / 100,
                  amount: intl.formatNumber(reimbursementPlan?.lifetimeMax ?? null, {
                    style: "currency",
                    currency: currencyCode,
                    currencyDisplay: "narrowSymbol",
                    maximumFractionDigits: 0
                  })
                }}
              />
            </Typography>
          )}
          <Typography>
            {!reimbursementPlan.hasUnlimitedBenefit && reimbursementPlan.yearlyMax !== reimbursementPlan.lifetimeMax ? (
              <FormattedMessage defaultMessage="Annual limits apply, and funds you use may be taxed as income." />
            ) : (
              <FormattedMessage defaultMessage="Funds you use may be taxed as income." />
            )}
          </Typography>
        </Stack>
      )}
    </>
  )
}

export function FinancialSupport({
  companyDisplayName,
  companyPlans,
  currencyCode,
  supportsOOPM,
  onBack,
  onComplete
}: MultipleCategoriesProps): JSX.Element {
  useTrackView("FinancialSupportRegFlow", "Financial support screen")
  const intl = useIntl()
  const hasMultiplePlans = useMemo(() => companyPlans.length > 1, [companyPlans])

  const hasPlansWithVaryingCostShare = useMemo(() => {
    return companyPlans.some((cv, i, companyPlans) => {
      return cv.reimbursementPlan.reimbursementPercent !== companyPlans[0].reimbursementPlan.reimbursementPercent
    })
  }, [companyPlans])

  const hasAnyPlansWithUnlimitedBenefit = useMemo(() => {
    return companyPlans.some((cv) => {
      return cv.reimbursementPlan.hasUnlimitedBenefit !== false
    })
  }, [companyPlans])

  const companySupportsOOPM = useMemo(() => {
    return supportsOOPM
  }, [supportsOOPM])

  const header = intl.formatMessage({
    defaultMessage: "Get financial support"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-financial"
        description={
          <Box>
            {hasAnyPlansWithUnlimitedBenefit && (
              <SingleCategoryDisplay
                onBack={onBack}
                onComplete={onComplete}
                companyDisplayName={companyDisplayName}
                currencyCode={currencyCode}
                companyPlans={companyPlans}
                supportsOOPM={companySupportsOOPM}
              />
            )}
            {hasPlansWithVaryingCostShare && hasMultiplePlans && !hasAnyPlansWithUnlimitedBenefit && (
              <DisplayMultipleCategoriesVaryingCostShare
                onBack={onBack}
                onComplete={onComplete}
                companyPlans={companyPlans}
                companyDisplayName={companyDisplayName}
                currencyCode={currencyCode}
              />
            )}
            {!hasPlansWithVaryingCostShare && hasMultiplePlans && !hasAnyPlansWithUnlimitedBenefit && (
              <DisplayMultipleCategorySameCostShare
                companyDisplayName={companyDisplayName}
                companyPlans={companyPlans}
                onBack={onBack}
                onComplete={onComplete}
                currencyCode={currencyCode}
              />
            )}
            {!hasPlansWithVaryingCostShare && !hasMultiplePlans && !hasAnyPlansWithUnlimitedBenefit && (
              <SingleCategoryDisplay
                onBack={onBack}
                onComplete={onComplete}
                companyDisplayName={companyDisplayName}
                currencyCode={currencyCode}
                companyPlans={companyPlans}
                supportsOOPM={companySupportsOOPM}
              />
            )}
          </Box>
        }
        interactiveElements={
          <RegistrationButtonFooter
            onBack={onBack}
            getCarrotPlanButton={
              <Button onClick={continueToRouting} variant="text" color="secondary">
                <FormattedMessage defaultMessage="Get your Carrot Plan" />
              </Button>
            }
            continueButton={
              <ContinueButton onClick={onComplete}>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
          />
        }
      />
    </>
  )
}
