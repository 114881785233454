import { useFlags } from "launchdarkly-react-client-sdk"
import { useUserContext } from "../context/UserContext"
import { useGetMostRecentlyCompletedRouting } from "../../../components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { CountryCode } from "../../../content/CountryCode"
import { Journey } from "../../../lib/contentful/utils/journeyOrderMapping"
import useGetBenefit from "./useGetBenefit"

export type ProviderRecommendationConfig = {
  showProviderRecommendationsBanner: boolean
  showPediatricianRecommendationsBanner: boolean
  showPediatricianRecommendationsForm: boolean
  showObGynRecommendationsBanner: boolean
  showObGynRecommendationsForm: boolean
}

type UseShowProviderRecommendationsResult = {
  isLoading: boolean
  isError: boolean
  data: ProviderRecommendationConfig
}

export default function useShowProviderRecommendations(): UseShowProviderRecommendationsResult {
  const { displayGarnerHealth } = useFlags()
  const { companyCountryCode } = useUserContext()
  const { data: benefit, isLoading: isBenefitLoading, isError: isBenefitError } = useGetBenefit()
  const {
    data: routingData,
    isLoading: isRoutingInfoLoading,
    isError: isRoutingInfoError
  } = useGetMostRecentlyCompletedRouting()

  if (!displayGarnerHealth) {
    return {
      isLoading: false,
      isError: false,
      data: {
        showProviderRecommendationsBanner: false,
        showPediatricianRecommendationsBanner: false,
        showPediatricianRecommendationsForm: false,
        showObGynRecommendationsBanner: false,
        showObGynRecommendationsForm: false
      }
    }
  }

  const isUnitedStates = companyCountryCode === CountryCode.United_States
  const journey = !isRoutingInfoLoading ? routingData?.journey : null
  const providerFinderConfig = !isBenefitLoading ? benefit?.providerFinder : null

  const showPediatricianRecommendationsBanner =
    isUnitedStates && providerFinderConfig?.showPediatricianRecommendations && journey === Journey.PARENTING

  const showPediatricianRecommendationsForm = isUnitedStates && providerFinderConfig?.showPediatricianRecommendations

  const showObGynRecommendationsBanner =
    isUnitedStates && providerFinderConfig?.showObGynRecommendations && journey === Journey.PREGNANT

  const showObGynRecommendationsForm = isUnitedStates && providerFinderConfig?.showObGynRecommendations

  return {
    isLoading: isBenefitLoading || isRoutingInfoLoading,
    isError: isBenefitError || isRoutingInfoError,
    data: {
      showProviderRecommendationsBanner: showPediatricianRecommendationsBanner || showObGynRecommendationsBanner,
      showPediatricianRecommendationsBanner: showPediatricianRecommendationsBanner,
      showPediatricianRecommendationsForm: showPediatricianRecommendationsForm,
      showObGynRecommendationsBanner: showObGynRecommendationsBanner,
      showObGynRecommendationsForm: showObGynRecommendationsForm
    }
  }
}
