import CarrotClient from "carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import { BankDetailsData } from "./useQueryBankDetails"
import ResponseError from "types/responseError"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const GET_BANK_DETAILS_SCHEMA = "GET_BANK_DETAILS_SCHEMA"

const request = async (): Promise<void> => {
  return await client.getBankDetailsSchema()
}

export default function useQueryBankDetailsSchema(): UseQueryResult<BankDetailsData, ResponseError> {
  return useQuery([GET_BANK_DETAILS_SCHEMA], request, {
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })
}
