import CarrotClient from "./index"
import Settings from "../../utils/CarrotConfig"
import { IOSMobileBridgeClient } from "./mobileNativeClients/IOSMobileBridgeClient"
import { AndroidMobileBridgeClient } from "./mobileNativeClients/AndroidMobileBridgeClient"
import { NullMobileBridgeClient } from "./mobileNativeClients/NullMobileBridgeClient"
import semver from "semver"

const isIOSNativeApp = () => IOSMobileBridgeClient.isIOSMobileClient()
const isAndroidNativeApp = () => AndroidMobileBridgeClient.isAndroidMobileClient()
const isMobileApp = () => isIOSNativeApp() || isAndroidNativeApp()
const isBiometricsCapable = async () => await getBridge().isBiometricsCapable()
const isBiometricLoginAlreadyConfigured = () => getBridge().isBiometricLoginAlreadyConfigured()
const disableBiometrics = () => getBridge().disableBiometrics()

const targetAppVersion = isIOSNativeApp() ? Settings.IOS_TARGET_APP_VERSION : Settings.ANDROID_TARGET_APP_VERSION
const PRE_GETAPPVERSION_SUPPORT_VERSION = isIOSNativeApp() ? "1.0.4" : "1.0.2"

const isAppOutdated = async () => {
  let appVersion

  try {
    appVersion = await getBridge().getAppVersion()
  } catch (error) {
    appVersion = PRE_GETAPPVERSION_SUPPORT_VERSION
  }

  return semver.lt(appVersion ?? PRE_GETAPPVERSION_SUPPORT_VERSION, targetAppVersion)
}

const isPushPermissionsEnabled = async () => {
  try {
    return await getBridge().isPushPermissionsEnabled()
  } catch (error) {
    return null
  }
}

const isMobileAndSupportsPushNotifications = async () => {
  const permissionsEnabled = await isPushPermissionsEnabled()
  return isMobileApp() && typeof permissionsEnabled === "boolean"
}

const promptPushPermissions = () => getBridge().promptPushPermissions()

const setMobileNativeUserInfo = (email: string, employeeId: number) => {
  try {
    getBridge().setUserInfo(email, employeeId)
  } catch (error) {
    // Do nothing
  }
}

const IOS_APP_STORE_URL = "https://apps.apple.com/us/app/carrot-fertility/id1639608799"
const ANDROID_APP_STORE_URL = "https://play.google.com/store/apps/details?id=com.getcarrot.mobile&hl=en_US&pli=1"
const APP_STORE_URL = isIOSNativeApp() ? IOS_APP_STORE_URL : ANDROID_APP_STORE_URL

// @ts-expect-error TS7006
const attemptBiometricsCredentialsRetrieval = async (login) =>
  await getBridge().attemptBiometricsCredentialsRetrieval(login)
// @ts-expect-error TS7006
const showBiometricsEnrollment = (username, password, biometricEnrollmentSuccess, biometricEnrollmentFailure) =>
  getBridge().showBiometricsEnrollment(username, password, biometricEnrollmentSuccess, biometricEnrollmentFailure)

// @ts-expect-error TS7031
const IfNotMobileNative = ({ children }) => {
  if (isMobileApp()) {
    return null
  }

  return children
}

// @ts-expect-error TS7031
const IfMobileNative = ({ children }) => {
  if (!isMobileApp()) {
    return null
  }

  return children
}

// @ts-expect-error TS7031
const IfIosNative = ({ children }) => {
  if (!isIOSNativeApp()) {
    return null
  }

  return children
}

const isMobileNativeAndBiometricsCapable = async () => isMobileApp() && (await isBiometricsCapable())

const shouldAttemptBioAuthenticationLogin = async () =>
  isMobileApp() && (await isBiometricsCapable()) && (await isBiometricLoginAlreadyConfigured())

// @ts-expect-error TS7006
const goToMobileHelpCenter = async (link) => {
  // @ts-expect-error TS7009
  const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
  const token = await client.getZendeskMobileAuthToken()

  // parse link: sections/4410088379931-Carrot-Plans
  const [zdType, articleSlug] = link ? link.split("/") : []
  const [id] = articleSlug ? articleSlug.split("-") : []

  getBridge().helpCenter(token, zdType, id)
}

const getBridge = () => {
  if (!isMobileApp()) return new NullMobileBridgeClient()

  return isIOSNativeApp() ? new IOSMobileBridgeClient() : new AndroidMobileBridgeClient()
}

export {
  isMobileApp,
  goToMobileHelpCenter,
  isIOSNativeApp,
  isAndroidNativeApp,
  IfNotMobileNative,
  IfMobileNative,
  IfIosNative,
  isBiometricsCapable,
  isBiometricLoginAlreadyConfigured,
  disableBiometrics,
  attemptBiometricsCredentialsRetrieval,
  showBiometricsEnrollment,
  shouldAttemptBioAuthenticationLogin,
  isMobileNativeAndBiometricsCapable,
  isPushPermissionsEnabled,
  isAppOutdated,
  promptPushPermissions,
  isMobileAndSupportsPushNotifications,
  setMobileNativeUserInfo,
  APP_STORE_URL
}
