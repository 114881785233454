import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { ITileDisplay } from "contentful-types"
import { getTalkToCarrotSendMessageDisplay } from "lib/contentful/contentfulClient"

const sendMessageDisplay = "getSendMessageDisplay"

export function useGetSendMessageDisplay(locale: string): UseQueryResult<ITileDisplay, ResponseError> {
  return useQuery<ITileDisplay, ResponseError>([sendMessageDisplay], () => getTalkToCarrotSendMessageDisplay(locale), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: buildCarrotApiRetryPolicy(3),
    onError: (error) => reportErrorMessage(`Failed to fetch send message tile display with error: ${error}`)
  })
}
