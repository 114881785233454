import { useMutation, UseMutationResult } from "@tanstack/react-query"
import ResponseError from "../../../../types/responseError"
import { HttpStatusCodes } from "../../../../utils/HttpStatusCodes"
import CarrotClient from "carrot-api"
import Settings from "../../../../utils/CarrotConfig"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const getFormattedPhoneNumber = async (phoneNumberWithCountryCode: string, countryCode: string): Promise<string> => {
  const response = await client.registrationPhoneStandardization(phoneNumberWithCountryCode, countryCode)
  const responseText = await response.text()
  return JSON.parse(responseText).value
}

type FullPhoneNumberAndCountryCode = {
  phoneNumberCountryCode: string
  phoneNumberWithCountryCode: string
}

export default function useGetStandardizedPhoneNumber(): UseMutationResult<
  string,
  ResponseError,
  FullPhoneNumberAndCountryCode
> {
  return useMutation<string, ResponseError, FullPhoneNumberAndCountryCode>(
    ({ phoneNumberWithCountryCode, phoneNumberCountryCode }: FullPhoneNumberAndCountryCode) =>
      getFormattedPhoneNumber(phoneNumberWithCountryCode, phoneNumberCountryCode),
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 0
        }
      }
    }
  )
}
