import {
  Alert,
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography
} from "@carrotfertility/carotene-core"
import useCarrotPlan from "components/carrot-plans/hooks/useCarrotPlan"
import useCarrotPlanEmployeeSettings from "components/carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import useGetMemberBenefit from "../carrot-plans/hooks/useGetMemberBenefit"
import { useToggle } from "../../utils/Hooks"
import { useFlags } from "launchdarkly-react-client-sdk"
import moment from "moment"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory } from "react-router"
import { CarrotPlansPaths } from "utils/Paths"

export const EarlyCohortAlert = (): JSX.Element => {
  const history = useHistory()
  const { retireRegistrationCohorts } = useFlags()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings
  } = useCarrotPlanEmployeeSettings()
  const { data: carrotPlan, hasCarrotPlan } = useCarrotPlan()
  const { data: memberBenefit, isLoading: isMemberBenefitLoading } = useGetMemberBenefit()
  const [showLearnMoreModal, toggleLearnMoreModal] = useToggle(false)
  const [formattedDate, setFormattedDate] = React.useState<string>("")
  const lockdownDate = moment(retireRegistrationCohorts).toDate()
  const currentDate = moment().toDate()
  const intl = useIntl()
  const isCarrotLite = memberBenefit?.program.isCarrotLite
  const shouldSeeAlert =
    retireRegistrationCohorts &&
    !carrotPlan?.journeyCompleteTimestampUtc &&
    !carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested &&
    !isLoadingEmployeeSettings &&
    !isErrorEmployeeSettings &&
    !isMemberBenefitLoading &&
    lockdownDate > currentDate &&
    !hasCarrotPlan

  React.useEffect(() => {
    if (shouldSeeAlert) {
      setFormattedDate(
        intl.formatDate(lockdownDate, {
          year: "numeric",
          month: "long",
          day: "2-digit"
        })
      )
    }
  }, [intl, lockdownDate, shouldSeeAlert])

  const boldContent = (boldContent: React.ReactNode[]) => (
    <Typography component="span" fontWeight="bold">
      {boldContent}
    </Typography>
  )

  const learnMoreLink = (linkContent: React.ReactNode[]) => (
    <Link color="inherit" component="button" onClick={() => toggleLearnMoreModal(true)}>
      {linkContent}
    </Link>
  )

  return shouldSeeAlert ? (
    <Box>
      <Alert severity="warning">
        <Stack direction="row">
          <Box>
            {isCarrotLite ? (
              <FormattedMessage
                defaultMessage="<bold>Important update:</bold> Get your Carrot Plan by {formattedDate} to preserve access to all features of your benefit, including specialist chats. <link>Learn more</link>"
                values={{
                  bold: boldContent,
                  formattedDate: formattedDate,
                  link: learnMoreLink
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="<bold>Important update:</bold> Get your Carrot Plan by {formattedDate} to preserve access to financial support. <link>Learn more</link>"
                values={{
                  bold: boldContent,
                  formattedDate: formattedDate,
                  link: learnMoreLink
                }}
              />
            )}
          </Box>
          <Button
            size="medium"
            color="secondary"
            variant="text"
            onClick={() => history.push(CarrotPlansPaths.ENROLLMENT)}
          >
            <FormattedMessage defaultMessage="Get your Carrot Plan" />
          </Button>
        </Stack>
      </Alert>
      <LearnMoreModal
        open={showLearnMoreModal}
        onClose={toggleLearnMoreModal}
        isCarrotLite={isCarrotLite}
        lockDownDate={lockdownDate}
      />
    </Box>
  ) : null
}

type LearnMoreModalProps = {
  onClose: () => void
  open: boolean
  isCarrotLite: boolean
  lockDownDate: Date
}

const LearnMoreModal = ({ onClose, open, isCarrotLite, lockDownDate }: LearnMoreModalProps): JSX.Element => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <Dialog aria-labelledby="learn-more-modal" aria-describedby="learn-more-modal-description" open={open}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
        <DialogTitle>{intl.formatMessage({ defaultMessage: "Important update" })}</DialogTitle>
        {isCarrotLite ? <CoreModalContent /> : <ProModalContent lockdownDate={lockDownDate} />}
      </Stack>
      <DialogActions>
        <Button color="secondary" onClick={() => history.push(CarrotPlansPaths.ENROLLMENT)}>
          <FormattedMessage defaultMessage="Get your Carrot Plan" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ProModalContent = ({ lockdownDate }: { lockdownDate: Date }): JSX.Element => {
  const intl = useIntl()
  return (
    <DialogContent>
      <DialogContentText>
        <FormattedMessage
          defaultMessage="Starting on {formattedDate} you will need a Carrot Plan to continue accessing some features of your benefit, including financial support."
          values={{
            formattedDate: intl.formatDate(lockdownDate, {
              year: "numeric",
              month: "long",
              day: "2-digit"
            })
          }}
        />
      </DialogContentText>
      <DialogContentText>
        <FormattedMessage
          defaultMessage="After {formattedDate}, any previously submitted expenses will be processed normally, but you will need a Carrot Plan to see their status and make additional submissions."
          values={{
            formattedDate: intl.formatDate(lockdownDate, {
              month: "long",
              day: "2-digit"
            })
          }}
        />
      </DialogContentText>
      <DialogContentText>
        <FormattedMessage defaultMessage="You will still be able to explore the Read & learn library, benefit guide, and help center without a plan. " />
      </DialogContentText>
    </DialogContent>
  )
}

const CoreModalContent = (): JSX.Element => {
  return (
    <DialogContent>
      <DialogContentText>
        <FormattedMessage defaultMessage="Please answer a few questions so we can create a free Carrot Plan with customized next steps to help you move forward. You will still be able to explore the Read & learn library, benefit guide, and help center without a plan." />
      </DialogContentText>
    </DialogContent>
  )
}
