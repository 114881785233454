import actionTypes from "actions/actionTypes"
import CarrotClient from "lib/carrot-api/index"
import Settings from "../utils/CarrotConfig"
import { HttpErrors } from "utils/HttpErrors"

const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

export const fetchReimbursementDetails = () => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_REQUEST
  })

  dispatch({
    type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_STATUS,
    status: "PENDING"
  })

  let response

  try {
    response = await client.getReimbursementDetails()
  } catch (error) {
    dispatch({
      type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_STATUS,
      status: "FAILED"
    })

    if (error.name === HttpErrors.UNAUTHORIZED) {
      dispatch({
        type: actionTypes.SESSION_EXPIRED
      })
      return
    } else if (error.name === HttpErrors.NOT_FOUND || error.name === HttpErrors.FORBIDDEN) {
      return
    }

    dispatch({
      type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_FAILURE
    })

    throw error
  }

  if (response.insuranceSync) {
    dispatch({
      type: actionTypes.FETCH_INSURANCE_SYNC_SUCCESS,
      response: response.insuranceSync
    })
  }

  if (response.deductible) {
    dispatch({
      type: actionTypes.FETCH_DEDUCTIBLE_STATUS_SUCCESS,
      response: response.deductible
    })
  }

  dispatch({
    type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_SUCCESS,
    response
  })

  dispatch({
    type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_STATUS,
    status: "COMPLETE"
  })
}

export const updateReimbursementDetailsEnrollmentStarted = (isEnrollmentStarted) => async (dispatch) => {
  dispatch({
    type: actionTypes.FETCH_REIMBURSEMENT_DETAILS_ENROLLMENT_STARTED,
    startedEnrollment: isEnrollmentStarted
  })
}
