/* eslint formatjs/no-literal-string-in-jsx: off -- internal tool intentionally not translated */
import { getContentfulClient } from "../../lib/contentful/contentfulClient"
import { reportErrorMessage } from "../../utils/ErrorReporting"
import styled from "styled-components"
import React, { FC, useEffect, useState } from "react"
import { getIsDynamicContentToggleToolOpen } from "../../reducers/application"
import { toggleDymamicContentTogglesTool } from "../../actions/testUserActions"
import { useDispatch, useSelector } from "react-redux"
// @ts-ignore
import colors from "css/variables/_colors.scss"
import { Button } from "components/views/atoms/buttons/Button"
import { RootState } from "reducers"
import { IAttribute } from "contentful-types"
import { getCompanyInfo } from "reducers/companyInfo"
import { Checkbox, FlexContainer, Select, SelectOptionType } from "@carrotfertility/carotene"
import useGetContentulCountries, { Country } from "./hooks/useContentfulCountries"
import TestUserActionTypes from "actions/testUserActionTypes"
import { useIntl } from "react-intl"
import { useLocaleContext } from "components/context/user/LocaleContext"
import { useContentfulConditions } from "services/contentful/hooks/use-contentful-conditions"
import { useCurrentUser } from "../context/user/UserContext"
import { Locale, PseudoLocale, SupportedLocale } from "../../types/Locale"

type DynamicContentTogglesProps = unknown

const ToggleLabel = styled.label`
  width: 15rem;
  cursor: pointer;
`
const ToggleDiv = styled.div`
  padding: 0.25rem 1rem 0.25rem 0.25rem;
  background-color: ${colors.lightGray};
  border-bottom: 0.1rem solid white;
`
const ToggleDescLink = styled.a`
  float: right;
  font-size: 1rem;
  cursor: pointer;
`
const ToggleModal = styled.div`
  border: .15rem solid ${colors.mineralGrey};
  border-radius:.5rem;
  padding: 1rem;
  width: 20rem;
  overflow-auto;
  opacity: .85;
  position: fixed;
  top:1rem;
  left:1rem;
  z-index:20;
`
const TogglesDiv = styled.div`
  margin-top:.5rem;
  max-height: 35rem;
  overflow-auto;
  font-size:1rem;
`

export const DynamicContentToggles: FC<DynamicContentTogglesProps> = () => {
  const currentUser = useCurrentUser()
  if (!currentUser?.isTestUser) {
    return null
  } else {
    return <RenderedDynamicContentToggles />
  }
}

const RenderedDynamicContentToggles: FC<DynamicContentTogglesProps> = () => {
  const [attributes, setAttributes] = useState<IAttribute[]>(null)
  const [showAsKeys, setShowAsKeys] = useState<boolean>(false)
  const { data: countries, isLoading: isLoadingCountries } = useGetContentulCountries()
  const conditionMap = useContentfulConditions()
  const dispatch = useDispatch()

  const { isDynamicContentToggleToolOpen } = useSelector((state: RootState) => ({
    isDynamicContentToggleToolOpen: getIsDynamicContentToggleToolOpen(state)
  }))

  function toggleShowAsKeys(): void {
    setShowAsKeys(!showAsKeys)
  }

  const getAttributes = async (): Promise<IAttribute[]> => {
    const client = getContentfulClient()
    const { items } = await client.getEntries({ content_type: "attribute" })
    return items as IAttribute[]
  }

  useEffect(() => {
    async function loadAttributes(): Promise<void> {
      const dynamicAttribs = await getAttributes()
      setAttributes(dynamicAttribs.filter(({ fields: { key } }) => typeof conditionMap[key] !== "undefined"))
      dynamicAttribs
        .filter(({ fields: { key } }) => typeof conditionMap[key] === "undefined")
        .forEach(({ fields: { key } }) => reportErrorMessage(`No mapping for Contentful attribute with key: ${key}`))
    }
    if (!attributes) {
      loadAttributes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const sortByName = ({ fields: { name: nameA } }: IAttribute, { fields: { name: nameB } }: IAttribute): number => {
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }
  const sortByKey = ({ fields: { key: keyA } }: IAttribute, { fields: { key: keyB } }: IAttribute): number => {
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  }
  if (attributes && !isLoadingCountries) {
    return isDynamicContentToggleToolOpen ? (
      <ToggleModal className={`bg-white  DynamicContentToggles`}>
        <button
          className={`fr pointer bw0 bg-white black-60 hover-black animate-all`}
          onClick={() => dispatch(toggleDymamicContentTogglesTool())}
          style={{ lineHeight: 0 }}
        >
          <img alt="close" src="/images/icn-close-modal.svg" />
        </button>
        <Button onClick={toggleShowAsKeys}>{showAsKeys ? "Display as names" : "Display as keys"}</Button>
        <TogglesDiv className={`flex flex-column overflow-y-auto br3`}>
          <CountryToggle countries={countries} />
          <LocaleToggle />
          {attributes.length
            ? attributes.sort(showAsKeys ? sortByKey : sortByName).map((attrib) => (
                <AttribDisplay
                  attribute={attrib}
                  showAsKeys={showAsKeys}
                  key={`test_${attrib.fields.key}`}
                  onChange={() => {
                    conditionMap[attrib.fields.key].toggle()
                  }}
                  isOn={conditionMap[attrib.fields.key]?.value}
                />
              ))
            : "No Toggles Available"}
        </TogglesDiv>
      </ToggleModal>
    ) : null
  } else {
    return null
  }
}

const sortCountry = ({ name: nameA }: Country, { name: nameB }: Country): number => {
  if (nameA < nameB) return -1
  if (nameA > nameB) return 1
  return 0
}

const CountryToggle = ({ countries }: { countries: Country[] }): JSX.Element => {
  const { countryCode } = useSelector(getCompanyInfo)
  const dispatch = useDispatch()
  const options = countries.sort(sortCountry).map(({ name, code }) => ({ label: name, value: code }))
  const currentValue = options.find(({ value }) => value === countryCode)

  const updateCountryCode = ({ value }: SelectOptionType): void => {
    dispatch({
      type: TestUserActionTypes.UPDATE_COUNTRY_CODE,
      response: {
        countryCode: value.toString()
      }
    })
  }
  return (
    <ToggleDiv>
      <div>
        <ToggleLabel>Company Country</ToggleLabel>
        <Select options={options} value={currentValue} onChange={updateCountryCode} />
      </div>
    </ToggleDiv>
  )
}

const LocaleToggle = (): JSX.Element => {
  const { locale: localeString } = useIntl()
  const { setLocale } = useLocaleContext()

  const options: { value: string; label: string }[] = [
    // intentionally using English labels for easy identification by internal users
    { value: SupportedLocale.English_UnitedStates.toString(), label: "English (U.S.)" },
    { value: SupportedLocale.Spanish_UnitedStates.toString(), label: "Spanish (U.S.)" },
    { value: SupportedLocale.French_Canada.toString(), label: "French (Canada)" },
    { value: SupportedLocale.German_Germany.toString(), label: "German (Germany)" },
    { value: SupportedLocale.Portuguese_Brazil.toString(), label: "Portuguese (Brazil)" },
    { value: SupportedLocale.Hindi_India.toString(), label: "Hindi (India)" },
    { value: SupportedLocale.Thai_Thailand.toString(), label: "Thai (Thailand)" },
    { value: SupportedLocale.Turkish_Turkey.toString(), label: "Turkish (Turkey)" },
    { value: SupportedLocale.Italian_Italy.toString(), label: "Italian (Italy)" },
    { value: SupportedLocale.Spanish_Spain.toString(), label: "Spanish (Spain)" },
    { value: SupportedLocale.Serbian_Serbia.toString(), label: "Serbian (Serbia)" },
    { value: PseudoLocale.PseudoAccented.toString(), label: "Pseudo: Accented" },
    { value: PseudoLocale.PseudoBidrectional.toString(), label: "Pseudo: Bidirectional" },
    { value: PseudoLocale.PseudoElongated.toString(), label: "Pseudo: Elongated" }
  ]

  const currentValue = options.find(({ value }) => value === localeString)

  const updateLocale = ({ value }: SelectOptionType): void => {
    if (typeof value === "string") {
      setLocale(Locale.getLocaleFromString(value))
    }
  }

  return (
    <ToggleDiv>
      <div>
        <ToggleLabel>Locale</ToggleLabel>
        <Select options={options} value={currentValue} onChange={updateLocale} />
      </div>
    </ToggleDiv>
  )
}

type DynamicAttributeProps = {
  attribute: IAttribute
  showAsKeys: boolean
  onChange: (args: unknown) => unknown
  isOn: boolean
}
const AttribDisplay: FC<DynamicAttributeProps> = ({
  attribute: {
    fields: { name, description, key }
  },
  showAsKeys,
  onChange,
  isOn
}) => {
  const [showDescription, setShowDescription] = useState<boolean>(false)
  function toggleDescription(): void {
    setShowDescription(!showDescription)
  }
  // @ts-expect-error TS7006
  const shouldDisableCheckbox = (key) => ["has_carrot_plan", "elective_fertility_qme_line_item_filter"].includes(key)

  return (
    <ToggleDiv>
      <FlexContainer alignItems="center">
        <ToggleLabel onClick={onChange}>
          <Checkbox isDisabled={shouldDisableCheckbox(key)} isChecked={isOn} value={key}>
            {showAsKeys ? key : name}
          </Checkbox>
        </ToggleLabel>
        {description && (
          <ToggleDescLink onClick={toggleDescription}>
            <img
              className={`animate-all vmid ${showDescription ? "rotate-180" : ""}`}
              alt={showDescription ? "close" : "open"}
              src={`/images/icn-upwards-arrow.png`}
              style={{ minWidth: "17px" }}
            />
          </ToggleDescLink>
        )}
      </FlexContainer>
      {showDescription && <span>{description}</span>}
    </ToggleDiv>
  )
}
