import React, { useEffect, useMemo, useState } from "react"
import {
  fetchReimbursementDetails,
  updateReimbursementDetailsEnrollmentStarted
} from "actions/reimbursementDetailsActions"
import { fetchUserInfo, updateBenefitEnrollmentInfo } from "actions/userActions"
import { useDispatch, useSelector } from "react-redux"
import {
  getEnrollmentFlowRequestStarted,
  getReimbursementDetails,
  getReimbursementDetailsStatus
} from "reducers/reimbursementDetails"
import CarrotClient from "lib/carrot-api"
import Settings from "utils/CarrotConfig"
import { getHasDeductible } from "../../../derivedSelectors/deductibleSelectors"
import { useBenefitEnrollmentStatus } from "../enrollment/hooks/useBenefitEnrollmentStatus"
import { useMemberEnrollmentHistory } from "../enrollment/hooks/useMemberEnrollmentHistory"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

export const CMD_CONFIGURATION = {
  NEITHER: "NEITHER",
  MEMBER_ONLY: "MEMBER_ONLY",
  PARTNER_ONLY: "PARTNER_ONLY",
  MEMBER_AND_PARTNER_SEPARATE: "MEMBER_AND_PARTNER_SEPARATE",
  MEMBER_AND_PARTNER_COMBINED: "MEMBER_AND_PARTNER_COMBINED"
}

export const COMPANY_DEDUCTIBLE_STATUS = {
  CARROT_MANAGED_DEDUCTIBLE: "CARROT_SPECIFIC_DEDUCTIBLE",
  EXTERNAL: "EXTERNAL",
  NO_DEDUCTIBLE: "NO_DEDUCTIBLE"
}
const DeductibleContext = React.createContext(null)

export const useUserDeductible = () => React.useContext(DeductibleContext)

// @ts-expect-error TS7031
export function DeductibleProvider({ children }): JSX.Element {
  const dispatch = useDispatch()
  const [companyDeductibleStatusPreview, setCompanyDeductibleStatusPreview] = useState(null)
  const [cmdConfigurationPreview, setCmdConfigurationPreview] = useState(null)
  const [hraEligibility, setHraEligibility] = useState(null)
  const [enrollmentCreatedAndDataRefreshed, setEnrollmentCreatedAndDataRefreshed] = useState(false)

  const enrollmentStatus = useBenefitEnrollmentStatus()
  const memberEnrollmentHistory = useMemberEnrollmentHistory()
  const reimbursementDetails = useSelector(getReimbursementDetails)
  const reimbursementDetailsRequestStatus = useSelector(getReimbursementDetailsStatus)
  const enrollmentFlowRequestStarted = useSelector(getEnrollmentFlowRequestStarted)
  const hasDeductible = useSelector(getHasDeductible)
  const deductiblePreview = {
    companyDeductibleStatusPreview,
    cmdConfigurationPreview,
    hraEligibility: hraEligibility
  }

  const hasCMD = useMemo(() => {
    if (reimbursementDetails?.companyDeductibleStatus !== COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE)
      return false

    if (reimbursementDetails?.cmdConfiguration === null) {
      return reimbursementDetails?.deductible?.deductibleSnapshot?.totalAmount > 0
    }

    return reimbursementDetails?.cmdConfiguration !== CMD_CONFIGURATION.NEITHER
  }, [reimbursementDetails])

  const memberHasDeductibleRemaining = useMemo(() => {
    if (reimbursementDetails && reimbursementDetails.deductible && reimbursementDetails.deductible.deductibleSnapshot) {
      return (
        hasDeductible &&
        (reimbursementDetails.deductible.deductibleSnapshot.amountRemaining === null ||
          reimbursementDetails.deductible.deductibleSnapshot.amountRemaining > 0)
      )
    }

    // If we get here and companyDeductibleStatus has a value of EXTERNAL, it means that reimbursement details has
    // loaded but we don't have either a deductible or deductibleSnapshot (possibly due to a Change Healthcare failure,
    // etc.). In the case of an externally-managed deductible, we have no way of knowing for sure whether the member
    // has a deductible remaining or not in this case, so we default to true so that we assume the member does have a
    // deductible remaining.
    if (reimbursementDetails?.companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.EXTERNAL) {
      return true
    }

    return false
  }, [reimbursementDetails, hasDeductible])

  const hasActiveCmdPlanEnrollment = useMemo(() => {
    return (
      reimbursementDetails?.deductible?.deductibleSnapshot !== null ||
      reimbursementDetails?.partnerDeductible?.deductibleSnapshot !== null
    )
  }, [reimbursementDetails])

  const partnerHasDeductibleRemaining = useMemo(() => {
    if (
      reimbursementDetails &&
      reimbursementDetails.partnerDeductible &&
      reimbursementDetails.partnerDeductible.deductibleSnapshot
    ) {
      return (
        hasDeductible &&
        (reimbursementDetails.partnerDeductible.deductibleSnapshot.amountRemaining === null ||
          reimbursementDetails.partnerDeductible.deductibleSnapshot.amountRemaining > 0)
      )
    }
    return false
  }, [reimbursementDetails, hasDeductible])

  const memberPlanRequiresInsuranceProviderInfo = useMemo(() => {
    const cmdConfiguration = reimbursementDetails?.cmdConfiguration
    const hasLegacyCMD = hasCMD && cmdConfiguration === null
    return (
      [
        CMD_CONFIGURATION.MEMBER_AND_PARTNER_COMBINED,
        CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE,
        CMD_CONFIGURATION.MEMBER_ONLY
      ].includes(cmdConfiguration) || hasLegacyCMD
    )
  }, [hasCMD, reimbursementDetails])

  const partnerPlanRequiresInsuranceProviderInfo = useMemo(() => {
    const cmdConfiguration = reimbursementDetails?.cmdConfiguration
    return [CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE, CMD_CONFIGURATION.PARTNER_ONLY].includes(cmdConfiguration)
  }, [reimbursementDetails])

  async function retrieveDeductiblePreview(): Promise<void> {
    const payload = await client.getBenefitEnrollmentPreview()
    setCompanyDeductibleStatusPreview(payload.companyDeductibleStatus)
    setCmdConfigurationPreview(payload.cmdConfiguration)
    setHraEligibility(payload.hraEligibility)
  }

  function resetReimbursementDetailsEnrollmentStarted(): void {
    dispatch(updateReimbursementDetailsEnrollmentStarted(false))
  }

  useEffect(() => {
    setEnrollmentCreatedAndDataRefreshed(
      reimbursementDetailsRequestStatus === "COMPLETE" && enrollmentFlowRequestStarted
    )
  }, [reimbursementDetailsRequestStatus, enrollmentFlowRequestStarted])

  async function updateUserBenefitEnrollment(): Promise<void> {
    await client.enrollUserBenefitEnrollment()
    dispatch(fetchUserInfo())
    dispatch(fetchReimbursementDetails())
    dispatch(updateReimbursementDetailsEnrollmentStarted(true))
    await enrollmentStatus.refetch()
    await memberEnrollmentHistory.refetch()
  }

  async function refreshUserBenefitEnrollment(): Promise<void> {
    await Promise.allSettled([
      dispatch(fetchUserInfo()),
      dispatch(fetchReimbursementDetails()),
      enrollmentStatus.refetch(),
      memberEnrollmentHistory.refetch()
    ])
  }

  // @ts-expect-error TS7006
  const updateBenefitEnrollmentAnswers = async (userInfo): Promise<void> => {
    await dispatch(updateBenefitEnrollmentInfo(userInfo))
  }

  return (
    <DeductibleContext.Provider
      value={{
        retrieveDeductiblePreview,
        ...deductiblePreview,
        ...reimbursementDetails,
        updateUserBenefitEnrollment,
        refreshUserBenefitEnrollment,
        resetReimbursementDetailsEnrollmentStarted,
        enrollmentCreatedAndDataRefreshed,
        memberHasDeductibleRemaining,
        partnerHasDeductibleRemaining,
        hasActiveCmdPlanEnrollment,
        hasCMD,
        memberPlanRequiresInsuranceProviderInfo,
        partnerPlanRequiresInsuranceProviderInfo,
        updateBenefitEnrollmentAnswers
      }}
    >
      {children}
    </DeductibleContext.Provider>
  )
}
