import React, { useContext } from "react"
import { Button, LeftIcon } from "@carrotfertility/carotene-core"
import { FormButton } from "@carrotfertility/carotene-core-x"
import { useFormContext, useWatch } from "react-hook-form"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { FormattedMessage } from "react-intl"
import { RequestCardContext } from "pages/request-carrot-card-flow"

type BackButtonProps = {
  goBackToStepName?: string
}

export const BackButton = (props: BackButtonProps = {}) => {
  const { back, backToStep } = useStateMachine()
  const { goBackToStepName: stepName } = props
  return (
    <Button
      startIcon={<LeftIcon />}
      variant="text"
      color="secondary"
      onClick={() => (stepName ? backToStep(stepName) : back())}
    >
      <FormattedMessage defaultMessage="Back" />
    </Button>
  )
}

export const CancelButton = () => {
  const onClose = useContext(RequestCardContext).onClose

  return (
    <Button variant="outlined" color="secondary" onClick={() => onClose()}>
      <FormattedMessage defaultMessage="Cancel" />
    </Button>
  )
}

type ContinueButtonProps = {
  disableContinue?: string
}

export const ContinueButton = (props: ContinueButtonProps = {}) => {
  const { disableContinue: disableContinueValToWatch } = props

  const { control } = useFormContext()
  const fieldVal = useWatch({
    control,
    name: disableContinueValToWatch
  })

  return (
    <FormButton disabled={disableContinueValToWatch ? !fieldVal : false} type="submit">
      <FormattedMessage defaultMessage="Continue" />
    </FormButton>
  )
}
