export enum UpdateModalValues {
  NotNow = "Not now",
  Update = "Update"
}

export enum PushModalValues {
  NotNow = "Not now",
  TurnOn = "Turn on"
}
type CarrotModalStorage = {
  hasCrrModalTriggeredThisSession?: boolean
  appUpdateModalSelection?: UpdateModalValues
  appUpdateModalTimestamp?: number
  pushPermissionsModalSelection?: PushModalValues
}

export const CARROT_MODAL_STORAGE_KEY = "carrotModalStorage"

const getCarrotModalStorage = (): CarrotModalStorage => {
  try {
    return JSON.parse(localStorage.getItem(CARROT_MODAL_STORAGE_KEY)) ?? {}
  } catch {
    return {}
  }
}

export const getHasCrrModalTriggeredThisSession = (): boolean => {
  const modalStorage = getCarrotModalStorage()
  return modalStorage?.hasCrrModalTriggeredThisSession ?? false
}

export const updateHasCrrModalTriggeredThisSession = (shouldCrrModalRender: boolean): void => {
  const modalStorage = getCarrotModalStorage()
  modalStorage.hasCrrModalTriggeredThisSession = shouldCrrModalRender
  localStorage.setItem(CARROT_MODAL_STORAGE_KEY, JSON.stringify(modalStorage))
}

export const resetHasCrrModalTriggeredThisSession = (): void => {
  const modalStorage = getCarrotModalStorage()
  delete modalStorage.hasCrrModalTriggeredThisSession
  localStorage.setItem(CARROT_MODAL_STORAGE_KEY, JSON.stringify(modalStorage))
}

export const getAppUpdateModalSelection = (): UpdateModalValues | undefined => {
  const modalStorage = getCarrotModalStorage()
  return modalStorage.appUpdateModalSelection
}

export const getAppUpdateModalTimestamp = (): number | undefined => {
  const modalStorage = getCarrotModalStorage()
  return modalStorage.appUpdateModalTimestamp
}

export const updateAppUpdateModalSelection = (modalButtonValue: UpdateModalValues): void => {
  const modalStorage = getCarrotModalStorage()
  modalStorage.appUpdateModalSelection = modalButtonValue
  modalStorage.appUpdateModalTimestamp = Date.now()
  localStorage.setItem(CARROT_MODAL_STORAGE_KEY, JSON.stringify(modalStorage))
}

export const getPushPermissionsModalSelection = (): PushModalValues | undefined => {
  const modalStorage = getCarrotModalStorage()
  return modalStorage.pushPermissionsModalSelection
}

export const updatePushPermissionsModalSelection = (modalButtonValue: PushModalValues): void => {
  const modalStorage = getCarrotModalStorage()
  modalStorage.pushPermissionsModalSelection = modalButtonValue
  localStorage.setItem(CARROT_MODAL_STORAGE_KEY, JSON.stringify(modalStorage))
}
