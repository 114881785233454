import React from "react"
import { useSelector } from "react-redux"
import { Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { IlloCircleCarrotCards } from "components/views/atoms/Illustrations.js"
import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { getIsUsa } from "reducers/userInfo"
import { FeatureGate } from "utils/FeatureGate"
import { FormattedMessage } from "react-intl"
import { DbgPaths } from "utils/Paths"

const RequestCardSuccess = () => {
  const isUsa = useSelector(getIsUsa)

  return isUsa ? <RequestPhysicalCardSuccess /> : <RequestVirtualCardSuccess />
}

const RequestVirtualCardSuccess = () => {
  const theme = useTheme()

  // @ts-ignore
  return (
    <RequestCardWrapper showCloseButton sectionNum={3}>
      <Stack id="donut" spacing={theme.tokens.spacing.xl} alignItems="center" paddingTop="42px">
        <IlloCircleCarrotCards />
        <Stack spacing={theme.tokens.spacing.xxs} marginTop={0}>
          <Typography variant="h3" component="h2">
            <FormattedMessage defaultMessage="Your virtual card is ready to use." />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage defaultMessage="We’ve sent you an email and SMS confirmation. If you’re working with an eligible provider who accepts virtual cards, you can start using your card to pay for eligible care and services." />
          </Typography>
          <FormattedMessage
            defaultMessage="<link>View card details</link>"
            values={{
              link: (linkContent) => (
                <Link
                  target="_blank"
                  href="/transactions#card-details"
                  padding={theme.spacing(theme.tokens.spacing.xs, theme.tokens.spacing.none)}
                  color="inherit"
                >
                  {linkContent}
                </Link>
              )
            }}
          />
          <FeatureGate filter={(c) => c.isSupportCenterEnabled}>
            <FormattedMessage
              defaultMessage="<link>Learn more about Carrot Card</link>"
              values={{
                link: (linkContent) => (
                  <Link
                    padding={theme.spacing(theme.tokens.spacing.xs, theme.tokens.spacing.none)}
                    href={DbgPaths.CARROT_CARD}
                    target="_blank"
                    color="inherit"
                  >
                    {linkContent}
                  </Link>
                )
              }}
            />
          </FeatureGate>
        </Stack>
      </Stack>
    </RequestCardWrapper>
  )
}

const RequestPhysicalCardSuccess = () => {
  const theme = useTheme()

  return (
    <RequestCardWrapper sectionNum={4}>
      <Stack spacing={theme.tokens.spacing.sm} alignItems="center" paddingTop={theme.spacing(theme.tokens.spacing.xxl)}>
        <IlloCircleCarrotCards />
        <Stack spacing={theme.tokens.spacing.xxs} paddingBottom={theme.spacing(theme.tokens.spacing.sm)}>
          <Typography variant="h3" component="h2">
            <FormattedMessage defaultMessage="You're all set" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage defaultMessage="We're preparing to ship your Carrot Card. It should arrive within 1–2 weeks." />
          </Typography>
        </Stack>
      </Stack>
    </RequestCardWrapper>
  )
}

export { RequestCardSuccess }
