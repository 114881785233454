import CarrotClient from "../../../lib/carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const queryKey = "getMemberBankDetails"

export interface BankDetailsData {
  accountNumber: string
  accountType: string
  agencyCode: string
  bankCity: string
  bankCode: string
  bankCountry: string
  bankName: string
  branchNumber: string
  bsbCode: string
  employeeId: string
  iban: string
  ibanOrBsbCode: string
  indianFinancialSystemCode: string
  intermediaryBankSwiftOrBic: string
  nameOnAccount: string
  phoneNumber: string
  routingNumber: string
  sortCode: string
  swiftOrBic: string
  taxId: string
}

const request = async (): Promise<void> => {
  return await client.getBankDetails()
}

export default function useQueryBankDetails(): UseQueryResult<BankDetailsData, ResponseError> {
  return useQuery([queryKey], request, { retry: buildCarrotApiRetryPolicy(1), refetchOnWindowFocus: false })
}
