import CarrotClient from "../../../lib/carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { Benefit } from "../Benefit"
import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const ENDPOINT = "GetBenefit"

const loadBenefit = async (): Promise<Benefit> => {
  return client.getBenefit()
}

export const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useBenefit(): UseQueryResult<Benefit, ResponseError> {
  return useQuery<Benefit, ResponseError>(getBenefitCacheKey(), loadBenefit)
}

export function useCreateOverride(): (attributes: Benefit) => void {
  const queryClient = useQueryClient()
  return (attributes: Benefit) => {
    queryClient.setQueryDefaults(getBenefitCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getBenefitCacheKey(), attributes)
  }
}
