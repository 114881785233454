import React, { FC, useState } from "react"
import { ResponsiveWrapper } from "../molecules/Molecules"
import { ButtonContents, TalkToCarrotButton } from "../../talk-to-carrot/TalkToCarrotButton"
import { BodyLayout } from "../atoms/layouts/BodyLayout"
import { Spacer } from "../atoms/Atoms"
import { useSelector } from "react-redux"
import { getHasAnUpcomingOrCompletedExpertChat, getHasMetSignatureRequirement } from "reducers/userInfo"
import Settings from "../../../utils/CarrotConfig"
import EmployeeOnlySpaceContainer from "components/employee-only-containers/EmployeeOnlySpaceContainer"
import EmployeeOnlyModalContainer from "../../employee-only-containers/EmployeeOnlyModalContainer"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  Divider,
  IconButton,
  Progress,
  Typography
} from "@carrotfertility/carotene-core"
import { Title } from "services/page-title/Title"
import { PageLayout } from "features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "features/global-ui/page-header"
import {
  CarrotDocumentType,
  DocuSignEvent,
  getSignatureForDocument,
  SignatureCompleteEvent
} from "carrot-api/signatureClient"
import { CarrotQueryParam } from "../../../utils/CarrotUrls"

type BackButtonsProps = {
  backOnClick: () => void
  doThisLaterOnClick: () => void
}

const BackButtons: FC<BackButtonsProps> = ({ backOnClick, doThisLaterOnClick }: BackButtonsProps) => {
  return (
    <div className="flex flex-row justify-end">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={backOnClick} className="mr3">
        <Button color="secondary" variant="outlined">
          <FormattedMessage defaultMessage="Back" description="to the previous page" />
        </Button>
      </div>
      <Button color="primary" onClick={doThisLaterOnClick}>
        <FormattedMessage defaultMessage="Do this later" />
      </Button>
    </div>
  )
}

type SignAuthorizationFormButtonProps = {
  iconName?: string
  label: string
  onClick: () => void
  enabled?: boolean
}

const CenteredProgress = (): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <Progress />
    </Box>
  )
}

const SignAuthorizationFormButton: FC<SignAuthorizationFormButtonProps> = ({
  iconName,
  label,
  onClick,
  enabled = true
}: SignAuthorizationFormButtonProps) => {
  const onClickHandler = enabled ? onClick : null

  return (
    <Box
      component="button"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      sx={(theme) => ({
        cursor: enabled ? "pointer" : "default",
        backgroundColor: theme.palette.background.paper,
        padding: `${theme.spacing(theme.tokens.spacing.md)} ${theme.spacing(theme.tokens.spacing.lg)}`,
        opacity: enabled ? 1 : 0.6,
        outlineOffset: theme.spacing(theme.tokens.spacing.xxxs)
      })}
      border={(theme) =>
        enabled
          ? `solid ${theme.tokens.borderWidth.md} ${theme.palette.border.light}`
          : `solid ${theme.tokens.borderWidth.md} ${theme.palette.secondary.light}`
      }
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      onClick={onClickHandler}
      tabIndex={!enabled ? -1 : undefined}
    >
      <ButtonContents label={label} iconName={iconName} enabled={enabled} />
    </Box>
  )
}

type SignAuthorizationFormModalButtonProps = {
  enabled: boolean
}

const windowEventListeners: Array<(event: SignatureCompleteEvent) => void> = []

const SignAuthorizationFormModalButton: FC<SignAuthorizationFormModalButtonProps> = ({ enabled }) => {
  const intl = useIntl()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)

  const handleWindowEvent = (event: SignatureCompleteEvent) => {
    if (event?.data?.documentType === CarrotDocumentType.IvfMedicalRecordsConsentForm) {
      // TODO: Handle other DocuSign event types
      // For now, only disabled the tile (signed=true) upon a successful signing of the document.
      if (event?.data?.event === DocuSignEvent.SigningComplete) {
        onDocuSignSessionComplete()
        const separator = window.location.href.indexOf("?") === -1 ? "?" : "&"
        window.location.href = window.location.href + separator + `${CarrotQueryParam.Signed}=true`
      } else {
        onCloseModal()
      }
    }
  }

  const onDocuSignSessionComplete = () => {
    windowEventListeners.forEach((listener) => window.removeEventListener("message", listener))
    windowEventListeners.length = 0
    setFrameSrc("/blank.html")
    setDocuSignIsReady(false)
  }

  const onCloseModal = () => {
    onDocuSignSessionComplete()
    setModalIsOpen(false)
  }

  const showDocument = async () => {
    setModalIsOpen(true)
    windowEventListeners.push(handleWindowEvent)
    window.addEventListener("message", handleWindowEvent)
    const envelopeSignatureInfo = await getSignatureForDocument(CarrotDocumentType.IvfMedicalRecordsConsentForm)
    setFrameSrc(envelopeSignatureInfo.docuSignUrl)
    setDocuSignIsReady(true)
  }

  return (
    <>
      <SignAuthorizationFormButton
        enabled={enabled}
        iconName="icn-documents"
        label={intl.formatMessage({ defaultMessage: "Sign your document" })}
        onClick={showDocument}
      />
      <EmployeeOnlyModalContainer onClose={onCloseModal}>
        <Dialog
          aria-labelledby={intl.formatMessage({ defaultMessage: "Authorization Form Modal" })}
          onClose={onCloseModal}
          open={modalIsOpen}
          fullScreen={true}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            height="95%"
            paddingLeft={(theme) => theme.tokens.spacing.xxxl}
            paddingRight={(theme) => theme.tokens.spacing.xxxl}
          >
            <>
              {docuSignIsReady ? (
                <iframe
                  className="w-100 h-100 border-box ba b--black-30"
                  title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
                  src={frameSrc}
                  allowFullScreen
                />
              ) : (
                <CenteredProgress />
              )}
            </>
          </Box>
        </Dialog>
      </EmployeeOnlyModalContainer>
    </>
  )
}

type IvfRequirementsProps = {
  backOnClick: () => void
  doThisLaterOnClick: () => void
}

const RequirementsButtons = () => {
  const hasAnUpcomingOrCompletedExpertChat = useSelector(getHasAnUpcomingOrCompletedExpertChat)
  const hasMetSignatureRequirement = useSelector(getHasMetSignatureRequirement)
  const intl = useIntl()
  const localeForEnvVar = intl.locale.toUpperCase().replace("-", "_")
  const treatmentOptionsUrlPropertyName = `IVF_TREATMENT_OPTIONS_URL_${localeForEnvVar}`
  const treatmentOptionsUrl =
    treatmentOptionsUrlPropertyName in Settings
      ? // @ts-expect-error TS7053
        Settings[treatmentOptionsUrlPropertyName]
      : Settings.IVF_TREATMENT_OPTIONS_URL
  return (
    <>
      <TalkToCarrotButton
        enabled={!hasAnUpcomingOrCompletedExpertChat}
        useModal={true}
        to={treatmentOptionsUrl}
        iconName="icn-schedule-chat"
        label={intl.formatMessage({ defaultMessage: "Schedule your expert chat" })}
        chatType="IvfRequirementsChat"
      />
      <SignAuthorizationFormModalButton enabled={!hasMetSignatureRequirement} />
    </>
  )
}

const RequirementsSteps = () => (
  <ResponsiveWrapper padding={0.75}>
    <div>
      <Typography variant="h5" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
        <FormattedMessage defaultMessage="Step 1" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Meet with an IVF expert to discuss treatment options and what Carrot can cover, and complete a questionnaire before and after. The person undergoing the embryo transfer must attend the chat. If you’re pursuing IVF with a partner, we encourage both of you to attend." />
      </Typography>
    </div>
    <div>
      <Typography variant="h5" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
        <FormattedMessage defaultMessage="Step 2" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage defaultMessage="Allow Carrot to view relevant health information for the person(s) receiving an embryo transfer. Personal information is always kept secure, and we will never share it with your employer or any other third party." />
      </Typography>
    </div>
    <RequirementsButtons />
  </ResponsiveWrapper>
)

const IvfRequirements: FC<IvfRequirementsProps> = ({ backOnClick, doThisLaterOnClick }: IvfRequirementsProps) => {
  const intl = useIntl()
  return (
    <PageLayout
      header={
        <PageHeader
          startGlyph={
            <PageHeaderGlyph src="/images/icn-get-reimbursed.png" srcSet="/images/icn-get-reimbursed-2x.png" />
          }
          pageTitle={<FormattedMessage defaultMessage="How to use Carrot funds for IVF" />}
        />
      }
    >
      <Title title={intl.formatMessage({ defaultMessage: "How to use Carrot funds for IVF" })} />
      <EmployeeOnlySpaceContainer>
        <BodyLayout>
          <Typography variant="body1">
            <FormattedMessage defaultMessage="To support healthy outcomes, members are required to complete two steps before they can use Carrot funds for IVF-related expenses." />
          </Typography>
          <Spacer height={1.5} />
          <Typography variant="body1">
            <FormattedMessage defaultMessage="If you decide to pursue IVF, we recommend completing these as early as possible to ensure that you'll be able to use your Carrot funds." />
          </Typography>
          <Spacer height={2.5} />
          <RequirementsSteps />
          <Spacer height={3} />
          <BackButtons backOnClick={backOnClick} doThisLaterOnClick={doThisLaterOnClick} />
          <Spacer height={1.5} />
          <Divider />
        </BodyLayout>
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export { IvfRequirements }
