import styled, { css } from "styled-components"
// @ts-ignore
import colors from "css/variables/_colors.scss"

export type SharedInputCssProps = {
  readOnly?: boolean
  valid: boolean
}

const sharedInputCss = css<SharedInputCssProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgba(0, 0, 0, ${(props) => (props.readOnly ? 0.4 : 0.8)});
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "text")};
  line-height: 1.5;
  padding: 14px 0 14px 14px;
  width: 100%;
  background-color: ${(props) => (props.readOnly ? props.theme.disabledBackgroundColor : "white")};
  border: 2px solid ${(props) => (props.valid ? props.theme.borderColor : colors.invalidRed)};
  &:hover,
  &:focus {
    border: 2px solid ${(props) => (props.readOnly ? props.theme.borderColor : colors.carrotOrange)};
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`

const StyledInput = styled.input`
  ${sharedInputCss};
`

const StyledInputWithUnit = styled.input`
  ${sharedInputCss};
  padding-right: 32px;
`

const StyledTextArea = styled.textarea`
  ${sharedInputCss};
  padding-right: 14px;
  resize: none;
`

const StyledSelect = styled.select`
  ${sharedInputCss};
  cursor: pointer;
`

const StyledSelectButton = styled.button`
  ${sharedInputCss};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ClickExpandStyledInputBox = styled.div`
  border: 2px solid #e48400;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 12px 8px 8px 8px;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.15s cubic-bezier(0.8, 0, 0.2, 1);
  cursor: pointer;
`

const ClickExpandStyledInput = styled.input`
  background: #f5f5f5;
  border-radius: 12px;
  outline: none;
  border: none;
  width: 100%;
  padding: 8px 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`

export {
  StyledInput,
  StyledInputWithUnit,
  StyledTextArea,
  StyledSelect,
  StyledSelectButton,
  sharedInputCss,
  ClickExpandStyledInputBox,
  ClickExpandStyledInput
}
