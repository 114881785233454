import React, { FC, useState } from "react"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { RoundedRectangle } from "../views/atoms/roundedrectangle/RoundedRectangle"
import { Container, Drawer, FlexContainer, Icon, IconButton, useBreakpointComponent } from "@carrotfertility/carotene"
import { IPage } from "../../contentful-types"
import { Paths } from "../../utils/Paths"
import { PageNav } from "./DBGNavLinks"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, NavigationIcon } from "@carrotfertility/carotene-core"

type DigitalBenefitsGuideNavFields = {
  pages: IPage[]
}

const DigitalBenefitsGuideNavLinks: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  return (
    <>
      {pages?.map(
        (page) =>
          page.fields && (
            <ContentfulConditionalGate conditions={page.fields.conditions} key={page.sys.id}>
              <PageNav page={page} baseSlug={`${Paths.YOUR_BENEFIT_GUIDE}`} top={pages[0] === page} />
            </ContentfulConditionalGate>
          )
      )}
    </>
  )
}

const DigitalBenefitsGuideDesktopNav: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  return (
    <nav className="flex flex-column flex-row-l">
      <RoundedRectangle backgroundColor="mint-alternate" className={"w-220px-l"}>
        <DigitalBenefitsGuideNavLinks pages={pages} />
      </RoundedRectangle>
    </nav>
  )
}

const MobileNavButton = ({
  displayNavDrawer,
  setDisplayNavDrawer
}: {
  displayNavDrawer: boolean
  setDisplayNavDrawer: (inputValue: boolean) => void
}): JSX.Element => {
  return (
    <Button
      variant="outlined"
      onClick={() => setDisplayNavDrawer(!displayNavDrawer)}
      startIcon={<NavigationIcon />}
      color="secondary"
    >
      <FlexContainer gap="12px" justifyContent="center" alignItems="center">
        <FormattedMessage defaultMessage="Benefit guide menu" />
      </FlexContainer>
    </Button>
  )
}

// @ts-expect-error TS7006
export const DrawerCloseButton = (props) => {
  const intl = useIntl()
  const ariaLabel = intl.formatMessage({
    defaultMessage: "Close drawer",
    description:
      "Drawer refers to a website navigation drawer (sidebar menu) on this page that opens when 'Benefit guide menu' is clicked. The drawer only appears on smaller screen sizes (mobile and some tablets)."
  })
  return (
    <FlexContainer paddingLeftRight="16px" sx={{ paddingTop: "16px" }} justifyContent="flex-end">
      <IconButton aria-label={ariaLabel} icon={<Icon size="small" color="black80" name="x" />} {...props} />
    </FlexContainer>
  )
}

const DigitalBenefitsGuideMobileNavDrawer: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  const [displayNavDrawer, setDisplayNavDrawer] = useState(false)

  return (
    <>
      <FlexContainer
        justifyContent="flex-end"
        direction="row"
        alignItems="center"
        gap="small"
        sx={{ marginTop: ["-8px", "-8px", "-16px", "0"], marginBottom: "24px" }}
      >
        <MobileNavButton setDisplayNavDrawer={setDisplayNavDrawer} displayNavDrawer={displayNavDrawer} />
      </FlexContainer>
      <Drawer
        background="mintAlternate"
        placement="right"
        width={["256px", "320px", "320px", "0"]}
        open={displayNavDrawer}
        onClose={() => setDisplayNavDrawer(false)}
        closeButton={DrawerCloseButton}
      >
        <Container sx={{ padding: "12px 24px 12px 24px" }}>
          <DigitalBenefitsGuideNavLinks pages={pages} />
        </Container>
      </Drawer>
    </>
  )
}

export const DigitalBenefitsGuideNav: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  const element = useBreakpointComponent([
    <DigitalBenefitsGuideMobileNavDrawer pages={pages} key={"small"} />,
    <DigitalBenefitsGuideMobileNavDrawer pages={pages} key={"medium"} />,
    <DigitalBenefitsGuideMobileNavDrawer pages={pages} key={"large"} />,
    <DigitalBenefitsGuideDesktopNav pages={pages} key={"x-large"} />
  ])
  return element || <></>
}
