import { fetchWrapper } from "../FetchWrapper/FetchWrapper"
import Settings from "../../utils/CarrotConfig"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "types/responseError"
import { carrotClient } from "utils/CarrotClient"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import { DocumentAction, DocumentStatusResponse } from "components/medical-records-consent/utils/DocumentStatusApi"

// Matches the DocumentType C# enum value defined in NetCoreApp/NetCoreApp/Data/Signature.cs
// However, the values are strings because they are used in URL query params
// Also, the name is different because DocumentType is a global object that already exists.
// https://developer.mozilla.org/en-US/docs/Web/API/DocumentType
export enum CarrotDocumentType {
  IvfMedicalRecordsConsentForm = "1",
  PregnancyMedicalRecordsConsentForm = "2",
  ArtMedicalRecordsConsentForm = "4"
}

// https://developers.docusign.com/docs/esign-rest-api/reference/envelopes/envelopeviews/createrecipient/
export enum DocuSignEvent {
  SigningComplete = "signing_complete",
  FinishLater = "cancel",
  Decline = "decline",
  Exception = "exception",
  FaxPending = "fax_pending",
  SessionTimeout = "session_timeout",
  TokenExpired = "ttl_expired",
  ViewingComplete = "viewing_complete"
}

interface SignatureCompleteMessage {
  event: DocuSignEvent
  documentType: CarrotDocumentType
}

export interface SignatureCompleteEvent {
  data: SignatureCompleteMessage
}

export interface SignatureEnvelopeInfo {
  signatureId: number
  docuSignUrl: string
}

export async function getSignatureForDocument(documentType: CarrotDocumentType): Promise<SignatureEnvelopeInfo> {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")
  const requestUrl = `${Settings.CARROT_BACKEND_URL}/api/v2/signature/medical-records-consent`
  const response = await fetchWrapper(requestUrl, {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({ documentType })
  })
  return JSON.parse(await response.text())
}

const GET_ACTIVE_SIGNATURES_QUERY_KEY = "getActiveSignatures"

interface SignatureResponse {
  createdDate: string
  signedDate: string
  expirationDate: string
  documentType: number
}

interface SignaturesResponse {
  signatures: SignatureResponse[]
}

interface Signature {
  createdDate: Date
  signedDate: Date
  expirationDate: Date
  documentType: CarrotDocumentType
}

function buildSignatureFromSignatureResponse(signatureResponse: SignatureResponse): Signature {
  return {
    createdDate: signatureResponse?.createdDate ? new Date(signatureResponse.createdDate) : null,
    signedDate: signatureResponse?.signedDate ? new Date(signatureResponse.signedDate) : null,
    expirationDate: signatureResponse?.expirationDate ? new Date(signatureResponse.expirationDate) : null,
    documentType: signatureResponse?.documentType?.toString() as CarrotDocumentType
  }
}

export function useQueryActiveSignatures({
  enabled
}: {
  enabled: boolean
}): UseQueryResult<Signature[], ResponseError> {
  return useQuery(
    [GET_ACTIVE_SIGNATURES_QUERY_KEY],
    async () => {
      const signaturesResponse: SignaturesResponse = await carrotClient.getActiveSignatures()
      return signaturesResponse.signatures.map(buildSignatureFromSignatureResponse)
    },
    {
      retry: buildCarrotApiRetryPolicy(1),
      refetchOnWindowFocus: false,
      enabled
    }
  )
}

export async function getDocumentStatus(documentType: CarrotDocumentType): Promise<DocumentStatusResponse> {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const response = await fetchWrapper(`${Settings.CARROT_BACKEND_URL}/api/v2/document-status/${documentType}`, {
    method: "GET",
    headers,
    credentials: "include"
  })

  const responseText = await response.text()
  return JSON.parse(responseText)
}

export async function getAllDocumentStatuses(): Promise<DocumentStatusResponse[]> {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const response = await fetchWrapper(`${Settings.CARROT_BACKEND_URL}/api/v2/document-status`, {
    method: "GET",
    headers,
    credentials: "include"
  })

  const responseText = await response.text()
  return JSON.parse(responseText)
}

export async function updateDocumentStatus(documentType: CarrotDocumentType, documentAction: DocumentAction) {
  const headers = new Headers()
  headers.append("Content-Type", "application/json")

  const body = JSON.stringify({ documentAction })

  const response = await fetchWrapper(`${Settings.CARROT_BACKEND_URL}/api/v2/document-status/${documentType}`, {
    method: "PUT",
    headers,
    body,
    credentials: "include"
  })

  const responseText = await response.text()
  return JSON.parse(responseText)
}
