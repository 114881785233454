import React, { useContext } from "react"

type CompanyContextValues = {
  companyId: string
}

const CompanyContext = React.createContext<CompanyContextValues>(null)
export const useCompanyContext = (): CompanyContextValues => useContext(CompanyContext)

type CompanyContextProviderProps = {
  children: React.ReactNode
  companyId: string
}

export function CompanyContextProvider({ children, companyId }: CompanyContextProviderProps): JSX.Element {
  return (
    <CompanyContext.Provider
      value={{
        companyId
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}
