import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { adminCarrotClient } from "../../../utils/CarrotClient"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import { AboutYouPartial, ENDPOINT } from "./useAboutYou"

export default function useMutationAboutYou(): UseMutationResult<AboutYouPartial, ResponseError, AboutYouPartial> {
  const queryClient = useQueryClient()
  return useMutation<AboutYouPartial, ResponseError, AboutYouPartial>(
    (data) => {
      return adminCarrotClient.updateAboutYou(data)
    },
    {
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([ENDPOINT])
      }
    }
  )
}
