import { Category } from "components/read/shared/CategoryMapper"
import { IArticle, IGroupSession, IGuide, ITile, IVideo } from "../contentful-types"

enum ContentTypes {
  ARTICLE = "article",
  ATTRIBUTE = "attribute",
  ACCORDION = "accordion",
  BLOCK_QUOTE = "blockQuote",
  CALENDLY_LINK = "calendlyLink",
  CANT_HAVE = "cantHave",
  CARROT_PLAN_MENU_ITEM = "carrotPlanMenuItem",
  CARROT_PLAN_MENU_ITEM_CATEGORIES = "carrotPlanMenuItemCategories",
  CARROT_PLAN_MENU_ITEM_CATEGORY = "carrotPlanMenuItemCategory",
  CATEGORY = "category",
  CONTAINER = "container",
  CONTENT_BLOCK = "contentBlock",
  CONTENT_BLOCK_WITH_ICON = "contentBlockWithIcon",
  COUNTRY = "country",
  EXPERTBIO = "expertBio",
  GUIDE = "guide",
  GROUP_SESSION = "groupSession",
  HAS_ANY = "hasAny",
  ICON = "icon",
  MODAL = "modal",
  MUST_HAVE = "mustHave",
  PAGE = "page",
  PAGE_GUIDANCE = "pageGuidance",
  PAGE_SET = "pageSet",
  PHONE_SUPPORT_NUMBER = "phoneSupportNumber",
  SUB_CATEGORY = "subCategory",
  TALK_TO_CARROT_COUNTRY = "talkToCarrotCountry",
  TALK_TO_CARROT_SEND_A_MESSAGE = "talkToCarrotSendAMessage",
  TALK_TO_CARROT_LANGUAGE_PAGE = "talkToCarrotLanguagePage",
  TALK_TO_CARROT_LANGUAGE = "talkToCarrotLanguage",
  TILE = "tile",
  TILE_DISPLAY = "tileDisplay",
  TILE_PAGE = "tilePage",
  TWO_COLUMN_BULLET_LIST = "twoColumnBulletList",
  TWO_COLUMN_LAYOUT = "twoColumnLayout",
  VIDEO = "video",
  UNORDERED_LIST = "unorderedList",
  TALK_TO_CARROT_COUNTRY_V2 = "talkToCarrotCountryV2"
}

export type ReadLearnArticleItem = IArticle | IGuide | IVideo | IGroupSession

export const isArticle = (obj: ReadLearnArticleItem): boolean => obj.sys.contentType.sys.id === ContentTypes.ARTICLE
export const isVideo = (obj: ReadLearnArticleItem): boolean => obj.sys.contentType.sys.id === ContentTypes.VIDEO
export const isGuide = (obj: ReadLearnArticleItem): boolean => obj.sys.contentType.sys.id === ContentTypes.GUIDE
export const isGroupSession = (obj: ReadLearnArticleItem): boolean =>
  obj.sys.contentType.sys.id === ContentTypes.GROUP_SESSION

export type SortableContent = Category | ITile
export const isCategory = (obj: SortableContent): boolean =>
  (obj as Category).sys.contentType.sys.id === ContentTypes.CATEGORY
export const isTile = (obj: SortableContent): boolean => (obj as ITile).sys.contentType.sys.id === ContentTypes.TILE

export default ContentTypes
