import { LanguageOption, PageOption } from "components/dynamic-content/util/dynamicContent"
import { reportErrorMessage } from "../../../utils/ErrorReporting"
import { Asset, Entry } from "contentful"
import {
  ICalendlyLink,
  IPhoneSupportNumber,
  ITalkToCarrotCountryV2,
  ITalkToCarrotLanguage,
  ITalkToCarrotLanguagePage,
  ITalkToCarrotSendAMessage,
  ITile,
  ITilePage
} from "contentful-types"
import slugify from "slugify"
import { toCondition } from "./conditionUtils"
import { Condition } from "services/contentful/conditional-gate"
import {
  ContentfulOrderTag,
  Journey,
  Order,
  ReadAndLearnTag,
  TalkToCarrotTag,
  journeyOrderMapping
} from "./journeyOrderMapping"
import { SortableContent, isCategory, isTile } from "utils/ContentTypes"
import { Category, Tag } from "components/read/shared/CategoryMapper"

enum TileNextActionType {
  TILE_PAGE = "tilePage",
  LANGUAGE_PAGE = "talkToCarrotLanguagePage",
  CALENDLY_LINK = "calendlyLink",
  TALK_TO_CARROT_SEND_A_MESSAGE = "talkToCarrotSendAMessage",
  PHONE_SUPPORT_NUMBER = "phoneSupportNumber"
}

const fieldIsValid = (entry: Entry<any>): boolean => {
  return entry && entry.sys.type !== "Link"
}

const getTileTitle = (tile: ITile): string => {
  if (fieldIsValid(tile)) {
    const {
      fields: { display }
    } = tile
    if (fieldIsValid(display)) {
      const {
        fields: { title }
      } = display
      return title
    }
  }
  return null
}

const getTileSlug = (tile: ITile): string => {
  if (fieldIsValid(tile)) {
    const {
      fields: { display }
    } = tile
    if (fieldIsValid(display)) {
      const {
        fields: { slug }
      } = display
      return slug
    }
  }
  return null
}

const getLanguageName = (language: ITalkToCarrotLanguage): string => {
  if (fieldIsValid(language)) {
    const {
      fields: { name }
    } = language
    return name
  }
  return null
}

const getPathForTile = ({ tile, baseUrl }: { tile: ITile; baseUrl: string }): string => {
  const tileSlug = getTileSlug(tile)
  const slug = tileSlug ?? "unknown"

  return `${baseUrl}/${slug}`
}

const getPathForLanguage = ({ language, baseUrl }: { language: ITalkToCarrotLanguage; baseUrl: string }): string => {
  const languageName = getLanguageName(language)
  const slug = languageName ? slugify(languageName).toLowerCase() : "unknown"
  return `${baseUrl}/${slug}`
}

const getNextTileAction = (
  tile: ITile
): ITilePage | ICalendlyLink | ITalkToCarrotSendAMessage | ITalkToCarrotLanguagePage | IPhoneSupportNumber => {
  const {
    fields: { nextAction }
  } = tile
  return nextAction
}

const getNextLanguageAction = (language: ITalkToCarrotLanguage): ITilePage | ICalendlyLink => {
  const {
    fields: { nextAction }
  } = language
  return nextAction
}

const getNextActionType = (
  nextAction: ITilePage | ICalendlyLink | ITalkToCarrotSendAMessage | ITalkToCarrotLanguagePage | IPhoneSupportNumber
): TileNextActionType => {
  if (nextAction) {
    const {
      sys: {
        contentType: {
          sys: { id }
        }
      }
    } = nextAction
    return Object.values(TileNextActionType).find((type) => type === id)
  }
  return null
}
const getIconUrl = (icon: Asset): string => {
  if (icon) {
    const {
      fields: { file }
    } = icon
    return file ? file.url : null
  }
  return null
}

const getTTCLocationOption = ({ fields: { country, page } }: ITalkToCarrotCountryV2): PageOption => {
  if (fieldIsValid(country) && fieldIsValid(page)) {
    const {
      fields: { name, alpha2CountryCode }
    } = country
    const {
      sys: { id }
    } = page
    return {
      slug: slugify(name).toLowerCase(),
      label: name,
      value: alpha2CountryCode,
      pageId: id
    }
  } else {
    return null
  }
}

const getTTCLanguageOption = ({
  fields: { name, iso639LanguageCode, nextAction },
  sys: { id }
}: ITalkToCarrotLanguage): LanguageOption => {
  if (fieldIsValid(nextAction)) {
    return {
      label: name,
      value: iso639LanguageCode,
      nextAction: nextAction,
      entryId: id
    }
  } else {
    return null
  }
}

const END_ACTIONS = [
  TileNextActionType.CALENDLY_LINK,
  TileNextActionType.TALK_TO_CARROT_SEND_A_MESSAGE,
  TileNextActionType.LANGUAGE_PAGE,
  TileNextActionType.PHONE_SUPPORT_NUMBER
]

const isTileDeadEnd = (tile: ITile, conditionsCheckFn: (conditions: Condition[]) => boolean): boolean => {
  const action = getNextTileAction(tile)
  const type = getNextActionType(action)
  if (END_ACTIONS.includes(type)) {
    return false
  } else if (type === TileNextActionType.TILE_PAGE) {
    const {
      fields: { tiles }
    } = action as ITilePage

    const userMeetsConditions = ({ fields: { conditions } }: ITile): boolean => {
      return !conditions || conditionsCheckFn(conditions.map(toCondition))
    }
    const tileIsNotDeadend = (tile: ITile): boolean => !isTileDeadEnd(tile, conditionsCheckFn)

    const allowedTilesWithEndActions = tiles.filter(userMeetsConditions).filter(tileIsNotDeadend)
    return allowedTilesWithEndActions.length === 0
  } else if (type !== null) {
    reportErrorMessage(`Unexpected next action type for Talk to Carrot tile. NextActionType: ${type}`)
  }
  // null is allowed.  Those are our intentionally disabled tiles.
  return true
}

const CALENDLY_PRIMARY_COLOR = "1A1A1A"

const getAllTagIds = (entry: SortableContent): string[] => {
  if (isCategory(entry)) {
    return (entry as Category).tags.map((tag: Tag) => tag.id)
  } else {
    return (entry as ITile).metadata.tags.map((tag) => tag.sys.id)
  }
}

const getOrderTagId = (entry: SortableContent): ContentfulOrderTag => {
  const tagId = getAllTagIds(entry).find((tagId) =>
    Object.values(ContentfulOrderTag).includes(tagId as ContentfulOrderTag)
  )
  return tagId as ContentfulOrderTag
}

const getJourneySpecificOrder = (journey: Journey | "DEFAULT", entry: SortableContent): number => {
  const tagId = getOrderTagId(entry)

  if (isCategory(entry)) {
    return journeyOrderMapping[journey][Order.READ_AND_LEARN].indexOf(tagId as ReadAndLearnTag)
  } else if (isTile(entry)) {
    return journeyOrderMapping[journey][Order.TALK_TO_CARROT].indexOf(tagId as TalkToCarrotTag)
  }
  return null
}

const sortByJourneySpecificOrder = (journey: Journey | "DEFAULT", a: SortableContent, b: SortableContent): number => {
  const aOrder = getOrderTagId(a) ? getJourneySpecificOrder(journey, a) : Infinity
  const bOrder = getOrderTagId(b) ? getJourneySpecificOrder(journey, b) : Infinity
  return aOrder - bOrder
}

export {
  TileNextActionType,
  getTileTitle,
  getLanguageName,
  getPathForTile,
  getPathForLanguage,
  fieldIsValid,
  getNextTileAction,
  getNextLanguageAction,
  getIconUrl,
  getNextActionType,
  getTTCLanguageOption,
  isTileDeadEnd,
  getTTCLocationOption,
  CALENDLY_PRIMARY_COLOR,
  getAllTagIds,
  getOrderTagId,
  getJourneySpecificOrder,
  sortByJourneySpecificOrder
}
