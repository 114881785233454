import { HttpErrors } from "utils/HttpErrors"
import { reportError } from "utils/ErrorReporting"
import React from "react"
import CarrotClient from "carrot-api"
import Settings from "utils/CarrotConfig"
import { useTrackView } from "../../../utils/heap"
import { getErrorMessageFromCode } from "../../../utils/CarrotErrors"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, TextField } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, ContinueButton, SkipButton } from "./RegistrationButtons"
import { Form, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

interface SetPasswordProps {
  email?: string
  onBack: () => void
  onComplete: (password: string) => void
  allowsSaml: boolean
}

function SetPasswordContinueButton({ allowsSaml }: { allowsSaml: boolean }): JSX.Element {
  const {
    formState: { isValid, isDirty }
  } = useFormContext()

  return (
    <ContinueButton disabled={!allowsSaml && (!isValid || !isDirty)}>
      <FormattedMessage defaultMessage="Continue" />
    </ContinueButton>
  )
}

export function SetPassword({ email, onBack, onComplete, allowsSaml }: SetPasswordProps): JSX.Element {
  useTrackView("SetPasswordRegFlow", "Set password screen")
  const intl = useIntl()

  // @ts-expect-error TS7006
  const onSubmit = (formData) => {
    onComplete(formData.password)
  }

  // @ts-expect-error TS7006
  const validatePassword = async (password) => {
    if (!password) return true
    try {
      await client.validatePassword(password)
    } catch (error) {
      if (error.name === HttpErrors.BAD_REQUEST) {
        const { code } = await error.response.json()
        return intl.formatMessage(getErrorMessageFromCode(code))
      }
      reportError(error)
    }
    return true
  }

  const header = intl.formatMessage({ defaultMessage: "Create a password" })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Box
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
          >
            <TextField label={<FormattedMessage defaultMessage="account email" />} defaultValue={email} disabled />
          </Box>
        }
        interactiveElements={
          <Form onSubmit={onSubmit} mode="onChange">
            <FormTextField
              name="password"
              registerOptions={{ validate: validatePassword, required: !allowsSaml }}
              label={intl.formatMessage({ defaultMessage: "Password" })}
              type="password"
            />
            <RegistrationButtonFooter
              onBack={onBack}
              continueButton={<SetPasswordContinueButton allowsSaml />}
              skipButton={
                allowsSaml ? (
                  <SkipButton onClick={() => onComplete(null)}>
                    <FormattedMessage defaultMessage="Skip to sign in with SSO" />
                  </SkipButton>
                ) : null
              }
            />
          </Form>
        }
      />
    </>
  )
}
