import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { IPageGuidance } from "contentful-types"
import { getTalkToCarrotSendMessageGuidance } from "lib/contentful/contentfulClient"

const sendMessageGuidance = "getSendMessageGuidance"

export function useGetSendMessageGuidance(locale: string): UseQueryResult<IPageGuidance, ResponseError> {
  return useQuery<IPageGuidance, ResponseError>(
    [sendMessageGuidance],
    () => getTalkToCarrotSendMessageGuidance(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch send a message page guidance with error: ${error}`)
    }
  )
}
