import { ITileDisplay } from "contentful-types"
import { getIconUrl } from "lib/contentful/utils/mappingUtils"

export type TileDisplay = {
  id: string
  title: string
  description: string
  secondaryDescription: string
  iconUrl: string
}

export const mapContentToTileDisplay = ({ fields, sys }: ITileDisplay): TileDisplay => {
  if (!fields || !sys) {
    return null
  }
  const { id } = sys
  const { title, description, secondaryDescription, icon } = fields
  const iconUrl = getIconUrl(icon)

  return {
    id,
    title,
    description,
    secondaryDescription,
    iconUrl
  }
}
