export enum ProgramType {
  PRO = "PRO",
  CORE = "CORE",
  WTW_ENGAGE = "WTW_ENGAGE"
}

export type Benefit = {
  program: {
    type: ProgramType
    isCarrotLite: boolean
  }
  journeys: {
    fertilityCare: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
      eligibleExpensesDetail: {
        isCoveredWithInfertilityDiagnosis: boolean
        infertilityEligibleExpensesDetail: {
          hasInsuranceCoverageForQmes: boolean
          canUseCarrotFundsAfterInsuranceExhausted: boolean
        }
        isElectiveCareCovered: boolean
        ivfRequirementsApply: boolean
        hasDonorAssistance: boolean
        areTravelCostsForTerminationCovered: boolean
        isRxCovered: boolean
      }
      isAvaEnabled: boolean
      isMiraEnabled: boolean
    }
    fertilityPreservation: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
      eligibleExpensesDetail: {
        isMedicallyNecessaryPreservationCovered: boolean
        medicallyNecessaryPreservationDetails: {
          hasInsuranceCoverageForQmes: boolean
          canUseCarrotFundsAfterInsuranceExhausted: boolean
        }
        isElectivePreservationCovered: boolean
        isRxCovered: boolean
      }
    }
    adoption: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
    }
    gestationalCarrier: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
      eligibleExpensesDetail: {
        medicalAndAncillaryServicesCovered: boolean
        legalAndAgencyServicesCovered: boolean
      }
    }
    pregnancy: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
      eligibleExpensesDetail: {
        doulaExpensesCovered: boolean
        additionalPregnancyExpensesCovered: boolean
      }
    }
    parenting: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
    }
    menopause: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
    }
    lowTestosterone: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
    }
    genderAffirmingCare: {
      isAppSupportEnabled: boolean
      hasEligibleExpenses: boolean
    }
  }
  carrotRx: {
    isCarrotRxActive: boolean
    isCarrotRxRequired: boolean
  }
  providerFinder: {
    showProviderFinder: boolean
    showFertilityClinics: boolean
    showReproductiveUrology: boolean
    showCryobanks: boolean
    showAdoption: boolean
    showDonorAssistance: boolean
    showGestationalSurrogacy: boolean
    showMenopause: boolean
    showLowTestosterone: boolean
    showGenderAffirmingHormoneTherapy: boolean
    showFertilityWrapAroundMessage: boolean
    showPediatricianRecommendations: boolean
    showObGynRecommendations: boolean
  }
  customizations: {
    additionalEmployerBenefitMarkdown: string
    memberCustomCoverageExplanationMarkdown: string
    healthPlanMarkdown: string
  }
  generalSupport: {
    showLgbtqContent: boolean
    showDonorContent: boolean
  }
  supportCenter: {
    enabled: boolean
  }
}
