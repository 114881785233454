import React, { useEffect } from "react"
import { useHistory, useLocation } from "react-router"
import queryString from "query-string"
import { Typography, Link, Stack, useTheme, Box } from "@carrotfertility/carotene-core"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { trackMatomoPageView } from "utils/matomo"
import { FormattedMessage, useIntl } from "react-intl"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { EmployeeSupportUrl } from "../../utils/EmployeeSupportLink"
import { PiiText } from "../../services/tracking"

// @ts-expect-error TS7031
function SentEmailAuthCard({ email }) {
  const theme = useTheme()
  return (
    <AppAccessPageCard>
      <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <Typography variant="h1">
          <FormattedMessage defaultMessage="Check your inbox" />
        </Typography>
      </Box>
      <Stack
        spacing={theme.spacing(theme.tokens.spacing.xl)}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        <Stack spacing={theme.spacing(theme.tokens.spacing.xs)} textAlign="center">
          <Typography>
            <FormattedMessage
              defaultMessage="We sent an activation link to {email}. Please be sure to check your spam folder too."
              values={{ email: <PiiText>{email}</PiiText> }}
            />
          </Typography>
          <Typography>
            <FormattedMessage
              defaultMessage="If you have any trouble, feel free to <link>contact us</link>."
              values={{
                link: (linkContent) => (
                  <Link href={EmployeeSupportUrl} target={"_blank"}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Typography>
        </Stack>
        <Stack display="block" textAlign="center">
          <UnauthPreferredLocale />
        </Stack>
      </Stack>
    </AppAccessPageCard>
  )
}

const signupPath = "/signup"

export default function SignUpSuccessPage() {
  const location = useLocation()
  const history = useHistory()
  const { email } = queryString.parse(location.search)
  const intl = useIntl()

  useEffect(() => {
    if (!email) {
      history.push(signupPath)
      trackMatomoPageView(signupPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Check your inbox" })}>
      <SentEmailAuthCard {...{ email }} />
    </AppAccessPageLayout>
  )
}
