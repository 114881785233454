import React, { FC } from "react"
import { Redirect, Route } from "react-router-dom"
import ActivateContainer from "components/containers/activate/ActivateContainer"
import ActivateDone from "components/views/activate/ActivateDone"
import ActivateSuccess from "../activate/ActivateSuccess"
import { useSelector } from "react-redux"
import { getCarrotLite } from "reducers/companyInfo"
import { IvfRequirementsContainer } from "components/containers/reimbursements/IvfRequirementsContainer"
import { CarrotRoute } from "utils/CarrotUrls"
import { PageNotFound } from "../error/PageNotFound"
import { Switch } from "services/routing/Switch"

const ReimbursementsRouter: FC = () => {
  const carrotLite = useSelector(getCarrotLite)

  return (
    <Switch>
      {carrotLite && <Redirect to="/" />}
      <Redirect path="/reimbursements/upload" to="/transactions" />
      <Redirect exact path="/reimbursements" to="/transactions" />
      <Route exact path="/reimbursements/activate" component={ActivateContainer} />
      <Route exact path="/reimbursements/activate/done" component={ActivateDone} />
      <Route exact path="/reimbursements/activate/success" component={ActivateSuccess} />
      <Route exact path="/reimbursements/ivf-requirements/:expenseId?" render={() => <IvfRequirementsContainer />} />
      <Route
        exact
        path={`${CarrotRoute.IvfRequirementsSigned}/:expenseId?`}
        render={() => <IvfRequirementsContainer />}
      />
      <Route component={PageNotFound} />
    </Switch>
  )
}

export { ReimbursementsRouter }
