import { Steps } from "../../steps"

// prettier-ignore
export default [
  {forStep: Steps.PROCESS_ENROLLMENT_TYPE,                            memberSelects: '',                             goto: Steps.FIRST_TIME_OR_RE_ENROLL},
  {forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                       memberSelects: Steps.RE_ENROLL_INTRO_INFORMATIONAL, goto: Steps.RE_ENROLL_INTRO_INFORMATIONAL},
  {forStep: Steps.FIRST_TIME_OR_RE_ENROLL,                       memberSelects: Steps.INTRO_INFORMATIONAL ,     goto: Steps.INTRO_INFORMATIONAL},
  {forStep: Steps.RE_ENROLL_INTRO_INFORMATIONAL,                  memberSelects: '',                             goto: Steps.INSURANCE_DISCLAIMER},
  {forStep: Steps.INTRO_INFORMATIONAL,                            memberSelects: '',                             goto: Steps.INSURANCE_DISCLAIMER},
  {forStep: Steps.INTRO_INFORMATIONAL,                            memberSelects: 'SKIP_THIS_FLOW',               goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.INSURANCE_DISCLAIMER,                           memberSelects: '',                             goto: Steps.MEMBER_PLAN_SOURCE},
  {forStep: Steps.MEMBER_PLAN_SOURCE,                             memberSelects: 'EMPLOYER',                     goto: Steps.MEMBER_COVERAGE_TYPE},
  {forStep: Steps.MEMBER_PLAN_SOURCE,                             memberSelects: 'PARENT',                       goto: Steps.MEMBER_HDHP_INDIVIDUAL},
  {forStep: Steps.MEMBER_PLAN_SOURCE,                             memberSelects: 'OTHER',                        goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.MEMBER_PLAN_SOURCE,                             memberSelects: 'NOT_INSURED',                  goto: Steps.HRA_INELIGIBLE},
  {forStep: Steps.MEMBER_PLAN_SOURCE,                             memberSelects: 'PARTNER',                      goto: Steps.PARTNER_HDHP},
  {forStep: Steps.MEMBER_PLAN_SOURCE,                             memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_ONLY',                  goto: Steps.MEMBER_HDHP_INDIVIDUAL},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_DEPENDENTS_NOT_PARTNER',goto: Steps.MEMBER_HDHP_INDIVIDUAL},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_AND_PARTNER',           goto: Steps.MEMBER_HDHP_FAMILY},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'MEMBER_PARTNER_DEPENDENTS',    goto: Steps.MEMBER_HDHP_FAMILY},
  {forStep: Steps.MEMBER_COVERAGE_TYPE,                           memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.MEMBER_HDHP_INDIVIDUAL,                         memberSelects: 'true',                         goto: Steps.MEMBER_INDIVIDUAL_HSA_CONTRIBUTION},
  {forStep: Steps.MEMBER_HDHP_INDIVIDUAL,                         memberSelects: 'false',                        goto: Steps.ARE_YOU_LEGALLY_MARRIED},
  {forStep: Steps.MEMBER_HDHP_INDIVIDUAL,                         memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.MEMBER_HDHP_FAMILY,                             memberSelects: 'true',                         goto: Steps.MEMBER_FAMILY_HSA_CONTRIBUTION},
  {forStep: Steps.MEMBER_HDHP_FAMILY,                             memberSelects: 'false',                        goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.MEMBER_HDHP_FAMILY,                             memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.MEMBER_INDIVIDUAL_HSA_CONTRIBUTION,             memberSelects: 'MEMBER_ANSWER',                goto: Steps.ARE_YOU_LEGALLY_MARRIED},
  {forStep: Steps.MEMBER_INDIVIDUAL_HSA_CONTRIBUTION,             memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.MEMBER_FAMILY_HSA_CONTRIBUTION,                 memberSelects: 'MEMBER_ANSWER',                goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.MEMBER_FAMILY_HSA_CONTRIBUTION,                 memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.ARE_YOU_LEGALLY_MARRIED,                        memberSelects: 'true',                         goto: Steps.PARTNER_PLAN_SOURCE},
  {forStep: Steps.ARE_YOU_LEGALLY_MARRIED,                        memberSelects: 'false',                        goto: Steps.PARTNER_TAX_DEPENDENT},
  {forStep: Steps.PARTNER_TAX_DEPENDENT,                          memberSelects: 'true',                         goto: Steps.PARTNER_PLAN_SOURCE},
  {forStep: Steps.PARTNER_TAX_DEPENDENT,                          memberSelects: 'false',                        goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.PARTNER_TAX_DEPENDENT,                          memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PARTNER_PLAN_SOURCE,                            memberSelects: 'EMPLOYER',                     goto: Steps.PARTNER_COVERAGE_TYPE},
  {forStep: Steps.PARTNER_PLAN_SOURCE,                            memberSelects: 'PARENT',                       goto: Steps.PARTNER_HDHP},
  {forStep: Steps.PARTNER_PLAN_SOURCE,                            memberSelects: 'OTHER',                        goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.PARTNER_PLAN_SOURCE,                            memberSelects: 'NOT_INSURED',                  goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.PARTNER_PLAN_SOURCE,                            memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PARTNER_COVERAGE_TYPE,                          memberSelects: 'PARTNER_ONLY',                 goto: Steps.PARTNER_HDHP},
  {forStep: Steps.PARTNER_COVERAGE_TYPE,                          memberSelects: 'PARTNER_DEPENDENTS_NOT_MEMBER',goto: Steps.PARTNER_HDHP},
  {forStep: Steps.PARTNER_COVERAGE_TYPE,                          memberSelects: 'MEMBER_AND_PARTNER',           goto: Steps.PARTNER_HDHP},
  {forStep: Steps.PARTNER_COVERAGE_TYPE,                          memberSelects: 'MEMBER_PARTNER_DEPENDENTS',    goto: Steps.PARTNER_HDHP},
  {forStep: Steps.PARTNER_COVERAGE_TYPE,                          memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PARTNER_HDHP,                                   memberSelects: 'true',                         goto: Steps.PARTNER_HSA_CONTRIBUTION},
  {forStep: Steps.PARTNER_HDHP,                                   memberSelects: 'false',                        goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.PARTNER_HDHP,                                   memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PARTNER_HSA_CONTRIBUTION,                       memberSelects: 'PARTNER_ANSWER',               goto: Steps.PEEK_BENEFIT_ENROLLMENT},
  {forStep: Steps.PARTNER_HSA_CONTRIBUTION,                       memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PEEK_BENEFIT_ENROLLMENT,                        memberSelects: 'CMD',                          goto: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE},
  {forStep: Steps.PEEK_BENEFIT_ENROLLMENT,                        memberSelects: 'EXTERNAL',                     goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.PEEK_BENEFIT_ENROLLMENT,                        memberSelects: 'NO_DEDUCTIBLE',                goto: Steps.FINAL_NO_DEDUCTIBLE},
  {forStep: Steps.PEEK_BENEFIT_ENROLLMENT,                        memberSelects: 'HRA_INELIGIBLE',               goto: Steps.HRA_INELIGIBLE},
  {forStep: Steps.HRA_INELIGIBLE,                                 memberSelects: 'CMD',                          goto: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE},
  {forStep: Steps.HRA_INELIGIBLE,                                 memberSelects: 'EXTERNAL',                     goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.HRA_INELIGIBLE,                                 memberSelects: 'NO_DEDUCTIBLE',                goto: Steps.PROCESS_MEMBER_ENROLLMENT},
  {forStep: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE,         memberSelects: '',                             goto: Steps.PARTNER_SHOULD_SHOW_COLLECT_PLAN_COVERAGE},
  {forStep: Steps.MEMBER_DEDUCTIBLE_PERIOD_COVERAGE_DATE,         memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PARTNER_SHOULD_SHOW_COLLECT_PLAN_COVERAGE,      memberSelects: 'true',                         goto: Steps.PARTNER_DEDUCTIBLE_PERIOD_COVERAGE_DATE},
  {forStep: Steps.PARTNER_SHOULD_SHOW_COLLECT_PLAN_COVERAGE,      memberSelects: 'false',                        goto: Steps.PROCESS_MEMBER_ENROLLMENT},
  {forStep: Steps.PARTNER_DEDUCTIBLE_PERIOD_COVERAGE_DATE,        memberSelects: '',                             goto: Steps.PROCESS_MEMBER_ENROLLMENT},
  {forStep: Steps.PARTNER_DEDUCTIBLE_PERIOD_COVERAGE_DATE,        memberSelects: 'IM_NOT_SURE',                  goto: Steps.TALK_TO_CARROT},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'MEMBER_AND_PARTNER_SEPARATE',  goto: Steps.MEMBER_PARTNER_DEDUCTIBLE_STATUS},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'MEMBER_ONLY',                  goto: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'PARTNER_ONLY',                 goto: Steps.PARTNER_ONLY_DEDUCTIBLE_STATUS},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'MEMBER_AND_PARTNER_COMBINED',  goto: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'EXTERNAL',                     goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'ALREADY_ENROLLED',             goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'NEITHER',                      goto: Steps.CONNECT_INSURANCE_NOT_REQUIRED},
  {forStep: Steps.PROCESS_MEMBER_ENROLLMENT,                      memberSelects: 'NO_DEDUCTIBLE',                goto: Steps.CONNECT_INSURANCE_NOT_REQUIRED},
  {forStep: Steps.MEMBER_PARTNER_DEDUCTIBLE_STATUS,               memberSelects: '',                             goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.MEMBER_ONLY_OR_COMBINED_DEDUCTIBLE_STATUS,      memberSelects: '',                             goto: Steps.CONNECT_INSURANCE_FLOW},
  {forStep: Steps.PARTNER_ONLY_DEDUCTIBLE_STATUS,                 memberSelects: '',                             goto: Steps.CONNECT_INSURANCE_FLOW}
]
