import React, { FC, useEffect } from "react"
import { FlexContainer, Stack } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import { Steps } from "../workflow/steps"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { IlloCalendar } from "../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../shared"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import { FormattedMessage } from "react-intl"

const ViewName = Steps.INSURANCE_DISCLAIMER

const InsuranceDisclaimer: FC = () => {
  const { needsBenefitEnrollment } = useBenefitEnrollment()
  const { setNextStep } = useStateMachine(ViewName, null)

  const { hideProgress } = useProgressTracker()

  useEffect(() => {
    hideProgress()
  }, [hideProgress])

  return (
    <>
      <Stack spacing="medium">
        <FlexContainer justifyContent="center" padding="none">
          <IlloCalendar height={228} width={228} />
        </FlexContainer>
        <Typography id="step-heading" hidden={true}>
          <FormattedMessage defaultMessage="Insurance Disclaimer" />
        </Typography>
        <FormattedMessage
          defaultMessage="<header>Note:</header> <body>The accuracy of your answers may have legal and financial implications. If you're not sure about something,
          please check with your insurance provider, benefits portal, and/or HR department.</body>"
          values={{
            header: (content) => (
              <Typography
                variant="h2"
                color={(theme) => theme.palette.text.primary}
                paddingBottom={(theme) => theme.tokens.spacing.xs}
              >
                {content}
              </Typography>
            ),
            body: (content) => <Typography color={(theme) => theme.palette.text.secondary}>{content}</Typography>
          }}
        />
        <BenefitEnrollmentModalContinueButton
          onClick={() => setNextStep(ViewName, needsBenefitEnrollment ? "" : "SKIP_THIS_FLOW")}
        />
      </Stack>
    </>
  )
}

export { InsuranceDisclaimer }
