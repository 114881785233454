import React, { FC, ReactNode } from "react"
import CardStates, { CardState } from "utils/CardStates"
import { CarrotCardEligibleModule } from "components/views/molecules/carrotcardmodules/CarrotCardEligibleModule"
import { CarrotCardLockedModule } from "components/views/molecules/carrotcardmodules/CarrotCardLockedModule"
import { CarrotCardShippedModule } from "components/views/molecules/carrotcardmodules/CarrotCardShippedModule"
import { CarrotCardActiveModule } from "components/views/molecules/carrotcardmodules/CarrotCardActiveModule"
import { useSelector } from "react-redux"
import { getIsUsa } from "reducers/userInfo"
import { CarrotCardDeductibleNotMetLockedModule } from "./carrotcardmodules/CarrotCardDeductibleNotMetLockedModule"
import { CarrotCardEligibleWithDeductibleModule } from "./carrotcardmodules/CarrotCardEligibleWithDeductibleModule"
import { CarrotCardNeedsEnrollmentModule } from "./carrotcardmodules/CarrotCardNeedsEnrollmentModule"
import { getReimbursementDetails, getReimbursementDetailsStatus } from "../../../reducers/reimbursementDetails"
import { CarrotCardLoadingModule } from "./carrotcardmodules/CarrotCardLoadingModule"
import { CarrotCardRestrictionPeriodModule } from "./carrotcardmodules/CarrotCardRestrictionPeriodModule"
import { Box, Chip, Theme, Typography } from "@carrotfertility/carotene-core"
import { RetinaImage } from "../atoms/images/RetinaImage"
import { FormattedMessage, useIntl } from "react-intl"

const carrotCardModules: Partial<Record<CardState, FC<CarrotCardModuleProps>>> = {
  [CardStates.LOCKED]: CarrotCardLockedModule,
  [CardStates.ACTIVE]: CarrotCardActiveModule,
  [CardStates.SHIPPED]: CarrotCardShippedModule,
  [CardStates.ELIGIBLE]: CarrotCardEligibleModule,
  [CardStates.ELIGIBLE_WITH_DEDUCTIBLE_NOT_MET]: CarrotCardEligibleWithDeductibleModule,
  [CardStates.DEDUCTIBLE_NOT_MET]: CarrotCardDeductibleNotMetLockedModule,
  [CardStates.NEEDS_ENROLLMENT]: CarrotCardNeedsEnrollmentModule,
  [CardStates.INELIGIBLE]: null,
  [CardStates.RESTRICTION_PERIOD]: CarrotCardRestrictionPeriodModule,
  [CardStates.MANUAL_LOCK]: CarrotCardLockedModule
}
type CarrotCardModuleContainerProps = {
  onlyShowRequestModule?: boolean
}
export type CarrotCardModuleProps = {
  isVirtualCard: boolean
}
export type CarrotCardModuleBaseProps = {
  backgroundColor: (theme: Theme) => { backgroundColor: string }
  status?: "active" | "shipped" | "locked"
  title?: ReactNode
  body: ReactNode
  links?: ReactNode[]
}

/**
 * A connected factory component that returns the module for a given card state
 *
 * This component returns null if no view corresponds to the given cardState
 * or the member is part of a carrot lite company
 */
export const CarrotCardModuleContainer: FC<CarrotCardModuleContainerProps> = () => {
  const reimbursementDetails = useSelector(getReimbursementDetails)
  const reimbursementDetailsRequestStatus = useSelector(getReimbursementDetailsStatus)
  const isUsa = useSelector(getIsUsa)

  // This check ensures that the dependencies we use to determine deductibleRemaining have been loaded prior to rendering.
  // The implication is that for CMD/EMD members, there could be a delay in rendering the module until we have a
  // deductible snapshot, which can sometimes take 5-30 seconds, but is necessary to prevent members from requesting
  // a CC before they have met their deductible
  if (!reimbursementDetails) {
    return null
  }

  const ModuleComponent =
    reimbursementDetailsRequestStatus !== "PENDING"
      ? // @ts-expect-error TS7053
        carrotCardModules[reimbursementDetails.carrotCardState]
      : CarrotCardLoadingModule

  return ModuleComponent ? <ModuleComponent isVirtualCard={!isUsa} /> : null
}

export const CarrotCardModuleBase: FC<CarrotCardModuleBaseProps> = ({
  backgroundColor,
  status,
  title = <FormattedMessage defaultMessage="Carrot Card®" />,
  body,
  links = []
}: CarrotCardModuleBaseProps) => {
  const intl = useIntl()
  let statusChip: ReactNode = null
  switch (status) {
    case "active":
      statusChip = <Chip color="success" label={intl.formatMessage({ defaultMessage: "Active" })} />
      break
    case "shipped":
      statusChip = <Chip color="info" label={intl.formatMessage({ defaultMessage: "Shipped" })} />
      break
    case "locked":
      statusChip = <Chip color="error" label={intl.formatMessage({ defaultMessage: "Locked" })} />
      break
  }

  return (
    <Box
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.tokens.spacing.lg}
      sx={backgroundColor}
    >
      <div className="flex flex-row justify-between items-center">
        <Box
          padding={(theme) => theme.tokens.spacing.xs}
          borderRadius={(theme) => theme.tokens.borderRadius.round}
          height="40px"
          width="40px"
          sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
        >
          <RetinaImage name="icn-carrot-cards" height="24px" width="24px" />
        </Box>
        {statusChip}
      </div>
      <Typography
        variant="h5"
        component="h2"
        color="text.primary"
        marginTop={(theme) => theme.tokens.spacing.xs}
        marginBottom={(theme) => theme.tokens.spacing.xs}
      >
        {title}
      </Typography>
      <Typography variant="body1">{body}</Typography>
      {links.map((link) => (
        <>
          <Box marginTop={(theme) => theme.tokens.spacing.xs}>{link}</Box>
        </>
      ))}
    </Box>
  )
}
