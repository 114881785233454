import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { ITileDisplay } from "contentful-types"
import { getZendeskMessagingWidgetDisplay } from "lib/contentful/contentfulClient"

const zendeskMessagingWidgetDisplay = "getZendeskMessagingWidgetDisplay"

export function useGetTalkToCarrotZendeskMessagingWidgetDisplay(
  locale: string
): UseQueryResult<ITileDisplay, ResponseError> {
  return useQuery<ITileDisplay, ResponseError>(
    [zendeskMessagingWidgetDisplay],
    () => getZendeskMessagingWidgetDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch Zendesk Messaging Widget tile with error: ${error}`)
    }
  )
}
