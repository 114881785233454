import { Container } from "@carrotfertility/carotene"
import React from "react"
import { UnderwaterPlants, UnderwaterPlantsRight, UnderwaterSeaweed, Person } from "./BackgroundImages"
import { useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

export const BlueBottomBackground = (): JSX.Element => {
  const theme = useTheme()
  const showDesktopBackground = useMediaQuery(theme.breakpoints.up("lg"))
  const mobileBackground = (
    <Container background="patternsBlue" width="100%" height="302px" sx={{ position: "relative" }}>
      <UnderwaterPlants sx={{ position: "absolute", top: "-35px", left: "20px" }} />
      <UnderwaterSeaweed sx={{ position: "absolute", left: "120px" }} />
    </Container>
  )
  const desktopBackground = (
    <>
      <Container
        background="patternsBlue"
        width="100%"
        height="calc(100% - 600px)"
        sx={{ position: "absolute", top: "600px" }}
      >
        <UnderwaterPlants sx={{ position: "absolute", top: "-35px", left: "calc(50% - 670px)" }} />
        <UnderwaterSeaweed sx={{ position: "absolute", top: "35px", left: "calc(50% - 580px)" }} />
        <UnderwaterPlantsRight sx={{ position: "absolute", top: "-50px", right: "calc(50% - 720px)" }} />
      </Container>
      <Person sx={{ position: "absolute", top: "415px", right: "calc(50% - 710px)" }} />
    </>
  )
  return showDesktopBackground ? desktopBackground : mobileBackground
}
