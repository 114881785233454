import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Stack, Typography, UnorderedList, UnorderedListItem, useTheme } from "@carrotfertility/carotene-core"
import { Form, FormCheckbox } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"

import { getIsAvenuesMember, getIsNBAMember, getIsRomanHealthNYMandateMember, getIsWNBAMember } from "derivedSelectors"
import { useProgressTracker } from "components/views/modal/ProgressTracker"

import RequestCardWrapper from "pages/request-carrot-card-flow/requestCardWrapper"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import { informationText } from "./RequestCardInformationText"

const RequestCardInformation = () => {
  const isAvenuesMember = useSelector(getIsAvenuesMember)
  const isNBAMember = useSelector(getIsNBAMember)
  const isRomanHealthNYMandateMember = useSelector(getIsRomanHealthNYMandateMember)
  const isWNBAMember = useSelector(getIsWNBAMember)

  const intl = useIntl()
  const theme = useTheme()
  const { send } = useStateMachine(null)
  const { hideProgress } = useProgressTracker()

  useEffect(() => {
    hideProgress()
  }, [hideProgress])

  // @ts-expect-error TS7006
  const processSubText = (displayText) => {
    const { subText = "", bulletPoints = [] } = displayText

    let formattedText

    if (!bulletPoints.length && !Array.isArray(subText)) {
      formattedText = (
        <Typography variant="body1">
          <FormattedMessage {...subText} values={{ br: <br /> }} />
        </Typography>
      )
    } else {
      const list = (
        <UnorderedList disablePadding={true}>
          {/*
           // @ts-expect-error TS7006 */}
          {bulletPoints.map((point, i) => (
            <UnorderedListItem key={`bullet-point-${i}`}>
              <Typography variant="body1">
                <FormattedMessage {...point} />
              </Typography>
            </UnorderedListItem>
          ))}
        </UnorderedList>
      )

      formattedText = (
        <React.Fragment>
          <Typography variant="body1">
            <FormattedMessage {...subText[0]} />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage {...subText[1]} />
          </Typography>
          {list}
          <Typography variant="body1">
            <FormattedMessage {...subText[2]} />
          </Typography>
        </React.Fragment>
      )
    }

    return formattedText
  }

  let displayInfoText = { subText: {} || [{}], checkboxText: {} }

  if (isNBAMember) {
    displayInfoText = informationText.NBA
  } else if (isWNBAMember) {
    displayInfoText = informationText.WNBA
  } else if (isAvenuesMember) {
    displayInfoText = informationText.Avenues
  } else if (isRomanHealthNYMandateMember) {
    displayInfoText = informationText.RomanHealth
  }

  const onSubmission = () => {
    send("")
  }

  return (
    <Form onSubmit={onSubmission} defaultValues={{ stripeUserAgreementCheckbox: false }}>
      <RequestCardWrapper showCancelButton showContinueButton disableContinue={"requestCardInformationCheckbox"}>
        <div>
          <Typography variant="h3" component="h2">
            <FormattedMessage defaultMessage="Before you request a Carrot Card" />
          </Typography>
          <Stack spacing={theme.tokens.spacing.xl}>
            {processSubText(displayInfoText)}
            <Stack spacing={theme.tokens.spacing.lg}>
              <FormCheckbox
                name={"requestCardInformationCheckbox"}
                label={intl.formatMessage(displayInfoText.checkboxText)}
                required={true}
              />
            </Stack>
          </Stack>
        </div>
      </RequestCardWrapper>
    </Form>
  )
}

export { RequestCardInformation }
