import React, { useEffect, useContext, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useSignUpFlow } from "components/context/signup/SignUpFlowContext"
import { useHistory } from "react-router"
import CarrotClient from "lib/carrot-api"
import Settings from "utils/CarrotConfig"
import { isHttpError } from "utils/HttpErrors"
import { CarrotErrorCodes, getErrorMessageFromCode } from "utils/CarrotErrors"
import { Link } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"

interface SignUpFindAccountSuccessProviderProps {
  onSubmit: ({ email }: { email: any }) => Promise<void>
  getTranslatedErrorMessage: (errorCode: string) => string | JSX.Element | (string | JSX.Element)[]
  error: string
  loading: boolean
}

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const SignUpFindAccountSuccessContext = React.createContext<SignUpFindAccountSuccessProviderProps>(null)

export const useSignUpFindAccountSuccess = () => useContext(SignUpFindAccountSuccessContext)

// @ts-expect-error TS7031
export function SignUpFindAccountSuccessProvider({ children }) {
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const formMethods = useForm({ reValidateMode: "onSubmit" })
  const history = useHistory()
  const intl = useIntl()
  const { locale } = intl
  const { getHasFoundAccount, lookupAccountByEmail, firstName, lastName, dateOfBirth } = useSignUpFlow()

  useEffect(() => {
    if (!getHasFoundAccount()) {
      history.push("/signup")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  // @ts-expect-error TS7031
  const onSubmit = async ({ email }) => {
    setError("")
    setLoading(true)

    const lookupResponseStatus = await lookupAccountByEmail(email)

    if (lookupResponseStatus != null) {
      setError(lookupResponseStatus)
      return
    }

    try {
      await client.signUpAddEmailToAccount(email, firstName, lastName, dateOfBirth.format("YYYY-MM-DD"))
    } catch (error) {
      if (isHttpError(error)) {
        const { code } = await error.response.json()
        if (code === CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL) {
          setError(code)
          setLoading(false)
          return
        }
      }
      setError(CarrotErrorCodes.TRY_AGAIN_LATER)
      setLoading(false)
      return
    }

    try {
      await client.signUp(email, locale)
      history.push(`/signup-success?email=${encodeURIComponent(email)}`)
    } catch (error) {
      setError(CarrotErrorCodes.TRY_AGAIN_LATER)
      setLoading(false)
      return
    }
  }

  // @ts-expect-error TS7006
  const getTranslatedErrorMessage = (errorCode) => {
    if (errorCode === "MEMBER_INACTIVE") {
      return intl.formatMessage(getErrorMessageFromCode(errorCode), {
        link: (string) => (
          <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
            {string}
          </Link>
        )
      })
    }
    if (errorCode === CarrotErrorCodes.EMPLOYEE_ALREADY_HAS_EMAIL) {
      return intl.formatMessage(getErrorMessageFromCode(errorCode), {
        link: (string) => (
          <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
            {string}
          </Link>
        )
      })
    }
    return intl.formatMessage(getErrorMessageFromCode(errorCode))
  }

  return (
    <FormProvider {...formMethods}>
      <SignUpFindAccountSuccessContext.Provider value={{ error, loading, onSubmit, getTranslatedErrorMessage }}>
        {children}
      </SignUpFindAccountSuccessContext.Provider>
    </FormProvider>
  )
}
