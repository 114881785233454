import React, { useEffect, useState } from "react"
import SignUpFindAccountSuccessPage from "../components/signup-find-account-success"
import {
  SamlNoEmailOnboardingProvider,
  useSamlNoEmailOnboardingContext
} from "../components/saml-no-email-onboarding/context/SamlNoEmailOnboardingContext"
import CarrotClient from "carrot-api"
import Settings from "../utils/CarrotConfig"
import { HttpErrors } from "../utils/HttpErrors"
import Helpers from "../utils/Helpers"
import { CarrotErrorCodes } from "../utils/CarrotErrors"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
export default function SamlNoEmailOnboardingCaptureEmail(): JSX.Element {
  const [isValidUser, setIsValidUser] = useState(false)

  useEffect(() => {
    // Returns invalid status code if member is not valid.
    const checkIsValue = async () => {
      try {
        await client.IsValidSamlNoEmailOnboardingMember()
        setIsValidUser(true)
      } catch (error) {
        setIsValidUser(false)
        await client.logout()
        const errorCode =
          error.name === HttpErrors.NOT_FOUND
            ? CarrotErrorCodes.EMPLOYEE_NOT_FOUND
            : CarrotErrorCodes.INTERNAL_SERVER_ERROR

        Helpers.browserRedirect(`/?errorCode=${errorCode}`)
        return
      }
    }

    checkIsValue()
  }, [])

  function ContextProvidingWrapper(): JSX.Element {
    const contextValue = useSamlNoEmailOnboardingContext()

    return isValidUser && <SignUpFindAccountSuccessPage {...contextValue} />
  }

  return (
    <SamlNoEmailOnboardingProvider>
      <ContextProvidingWrapper />
    </SamlNoEmailOnboardingProvider>
  )
}
