import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import React from "react"
import { Container, FlexContainer } from "@carrotfertility/carotene"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { Spacer } from "../../../views/atoms/Atoms"
import { IlloUnderwaterPlants } from "../../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../../shared"
import { ZendeskLink } from "../../../views/atoms/links/ZendeskTextLink"
import { FormattedMessage } from "react-intl"

export function SkipPartnerConnectInsurance(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.SKIP_PARTNER_CONNECT_INSURANCE, null)

  function onClickContinue(): void {
    setNextStep(Steps.SKIP_PARTNER_CONNECT_INSURANCE, "")
  }

  return (
    <div>
      <Spacer height={3} />
      <FlexContainer justifyContent="center" padding="none" stack="large">
        <IlloUnderwaterPlants />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="You've skipped connecting to their insurance provider" />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage
            defaultMessage="We'll take it from here and will let you know if we have any more questions about their insurance. If we determine that they have a deductible, you'll see the status on the <link>Transactions tab</link>. 
                You can read more about deductibles in our <zendeskLink>Support Center</zendeskLink>"
            values={{
              link: (linkContent) => (
                <Link href="/transactions" target="_blank">
                  {linkContent}
                </Link>
              ),
              zendeskLink: (linkContent) => <ZendeskLink color={"inherit"}>{linkContent}</ZendeskLink>
            }}
          />
        </Typography>
      </Container>
      <BenefitEnrollmentModalContinueButton onClick={onClickContinue} />
    </div>
  )
}
