import React from "react"
import { isVideo } from "utils/ContentTypes"
import { useHistory, useParams } from "react-router-dom"
import { useContentfulConditionalGate } from "../../../lib/contentful/components/ContentfulConditionGate"
import ReadLearnArticle from "../../read/ReadLearnArticle"
import { findArticlesBySlug, findCategoryBySlug, findSubcategoryForArticle } from "../../read/shared/Finder"
import { IArticle, IVideo } from "../../../contentful-types"
import useGetCategories from "../../read/shared/useGetCategories"

const ReadArticleContainer = (): JSX.Element => {
  const history = useHistory()
  const params = useParams<{ category: string; article: string }>()

  const { data: categories } = useGetCategories()

  const articles = findArticlesBySlug(categories, params.article)
  const category = findCategoryBySlug(categories, params.category)
  const articleConditions = articles?.map((article) => {
    const subCategory = findSubcategoryForArticle(categories, article)
    return {
      element: (
        <ReadLearnArticle
          articleBody={(article as IArticle).fields.body}
          articleTitle={article.fields.title}
          articleContent={(article as IArticle).fields.content}
          category={category}
          isVideo={isVideo(article)}
          lastUpdated={article.sys.updatedAt}
          subCategory={subCategory}
          videoUrl={(article as IVideo).fields.url}
        />
      ),
      conditions: article.fields.conditions
    }
  })

  const [article] = useContentfulConditionalGate(articleConditions ?? [])
  if (!article || !category) {
    history.push("/read")
    return null
  }

  return <>{article}</>
}

export { isVideo }

export default ReadArticleContainer
