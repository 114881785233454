import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import styled from "styled-components"
import { Paths } from "utils/Paths"
import { FormattedMessage } from "react-intl"
import { ITileDisplay } from "contentful-types"
import { Tile } from "lib/contentful/components/TileElement"
import { mapContentToTileDisplay } from "./utils"
import { Breadcrumb } from "@carrotfertility/carotene-core"

// @ts-expect-error TS7031
export const getHelpPath = ({ baseUrl }) => `${baseUrl}/i-need-help`
// @ts-expect-error TS7031
export const getProviderEligibilityPath = ({ baseUrl }) => `${baseUrl}/provider-eligibility`
// @ts-expect-error TS7031
export const getRefreshCarrotPlanPath = ({ baseUrl }) => `${baseUrl}/refresh-carrot-plan`
// @ts-expect-error TS7031
export const getGetYourCarrotPlanPath = ({ baseUrl }) => `${baseUrl}/get-your-carrot-plan`
// @ts-expect-error TS7031
export const getAccountDeletionPath = ({ baseUrl }) => `${baseUrl}/delete-account`

export const RootBreadcrumb = () => (
  <Breadcrumb component={ReactRouterLink} to={Paths.TALK_TO_CARROT}>
    <FormattedMessage defaultMessage="Talk to Carrot" />
  </Breadcrumb>
)

export const SmallHeaderOverride = styled.div`
  margin-top: 32px;
`

interface TileElementProps {
  tileDisplay: ITileDisplay
  onClick: () => void
  showArrow?: boolean
  isDisabled?: boolean
}
export const createTileElement = ({
  tileDisplay,
  onClick,
  showArrow = true,
  isDisabled = false
}: TileElementProps): JSX.Element => {
  const { id: entryId, title, description, secondaryDescription, iconUrl } = mapContentToTileDisplay(tileDisplay)

  return (
    <Tile
      key={entryId}
      title={title}
      description={description}
      entryId={entryId}
      secondaryDescription={secondaryDescription}
      iconUrl={iconUrl}
      disabled={isDisabled}
      showArrow={showArrow}
      onClick={onClick}
    />
  )
}
