import React from "react"
import { Button, InternalIcon, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { useDigitalFrontDoorBreakpointViews } from "../hooks/useDigitalFrontDoorBreakpointViews"

export function handleGetACarrotPlanButtonClick() {
  const baseUrl = window.location.origin

  const carrotPlanUrl = `${baseUrl}/carrot-plans/enrollment`
  window.open(carrotPlanUrl, "_blank")
}

export function GetACarrotPlanButton(): JSX.Element {
  const theme = useTheme()
  const { isMobileView } = useDigitalFrontDoorBreakpointViews()

  return (
    <Button
      id="get-a-carrot-plan-button"
      onClick={handleGetACarrotPlanButtonClick}
      variant="outlined"
      fullWidth={false}
      endIcon={<InternalIcon />}
      sx={{
        width: "fit-content",
        background: (theme) => theme.palette.background.paper,
        color: (theme) => theme.palette.text.primary,
        marginTop: isMobileView ? theme.tokens.spacing.none : theme.tokens.spacing.md,
        fontSize: isMobileView ? "0.813rem" : "1rem",
        fontWeight: 325,
        padding: "1rem 1.5rem 1rem 2rem",
        "& .MuiButton-endIcon": {
          color: theme.palette.primary.main
        }
      }}
    >
      <FormattedMessage defaultMessage="Get a Carrot Plan" />
    </Button>
  )
}
