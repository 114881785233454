import { Divider, FlexContainer } from "@carrotfertility/carotene"
import { Typography, useTheme } from "@carrotfertility/carotene-core"
import React from "react"
import { FormattedMessage } from "react-intl"

export const LegalFooter = (): JSX.Element => {
  const theme = useTheme()
  return (
    <FlexContainer direction="column" gap="medium" sx={{ marginBottom: "90px" }}>
      <Divider color="lightGrey" />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        <FormattedMessage
          defaultMessage="Legality and availability of services vary from country to country and often even within a country. Contact your
      Care Team for help understanding what services are available to you domestically or through cross-border care."
          description="Legal disclaimer for Carrot resources. Care Team refers to the care navigators that help Carrot members navigate their fertility journey"
        />
      </Typography>
      <Divider color="gray" sx={{ width: "47px" }} />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        <FormattedMessage
          defaultMessage="We strive to be as clear and inclusive as possible. When appropriate, we use terminology that reflects current
      medical practice. We recognize that these terms do not capture every individual's experience."
        />
      </Typography>
      <Divider color="gray" sx={{ width: "47px" }} />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        <FormattedMessage defaultMessage="Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately." />
      </Typography>
    </FlexContainer>
  )
}
