import React, { ReactElement } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Box,
  Button,
  Card,
  InternalIcon,
  Link,
  Stack,
  Tile,
  Typography,
  useTheme,
  Divider
} from "@carrotfertility/carotene-core"
import { DbgPaths } from "../../../utils/Paths"
import { useBenefitHighlights, ValueProposition } from "./hooks/useBenefitHighlights"
import { useFlags } from "launchdarkly-react-client-sdk"
import { ProgramType } from "../../carrot-plans/Benefit"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { useStartRouting } from "../../carrot-plans/shared/useQueryStartRouting"
import { useStartRoutingInfo } from "../../carrot-plans/shared/useQueryRoutingInfo"
import { caseRateRenewalRoutingFlowUrl } from "../../carrot-plans/case-rate-renewals/workflow"
import { useHistory } from "react-router-dom"
import { useCurrentUser } from "../../context/user/UserContext"
import useCarrotPlanEmployeeSettings from "../../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import useCarrotPlan from "../../carrot-plans/hooks/useCarrotPlan"

const LaunchDarklyBenefitHighlightMvpContainer = ({ children }: { children: ReactElement }): ReactElement => {
  const { benefitHighlightMvp } = useFlags()

  if (!benefitHighlightMvp) {
    return null
  }

  return children
}

function ExperimentalTileVariation() {
  const { carrotPlansRoutingStarted } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()
  const { startRouting } = useStartRouting(useStartRoutingInfo(), caseRateRenewalRoutingFlowUrl)
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    isDoneForNow
  } = useCarrotPlanEmployeeSettings()
  const history = useHistory()

  if (isLoadingEmployeeSettings) {
    return null
  }

  const buttonText =
    carrotPlansRoutingStarted && !isErrorEmployeeSettings && carrotPlanEmployeeSettings.routingInfoId ? (
      <FormattedMessage defaultMessage="Continue" />
    ) : (
      <FormattedMessage defaultMessage="Get your Carrot Plan" />
    )
  const messageText =
    carrotPlansRoutingStarted && !isErrorEmployeeSettings && carrotPlanEmployeeSettings.routingInfoId ? (
      <FormattedMessage defaultMessage="Continue telling us about your journey so we can create your Carrot Plan." />
    ) : (
      <FormattedMessage defaultMessage="When you're ready to unlock full access to your benefit, tell us about your journey and we'll craft a free, personalized plan to support you." />
    )

  async function handleClick(): Promise<Promise<void> | void> {
    if (isDoneForNow && !isPartnerAccessingAccount) {
      await startRouting()
    } else {
      history.push("/carrot-plans/about-you")
    }
  }

  return (
    <>
      <Stack
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      >
        <Divider />
      </Stack>
      <Typography margin={0} variant="body1" color={(theme) => theme.palette.text.secondary}>
        {messageText}
      </Typography>
      <Stack alignItems={"start"}>
        <Button onClick={handleClick} color="secondary" variant="contained" fullWidth={false}>
          {buttonText}
        </Button>
      </Stack>
    </>
  )
}

function BenefitHighlightsTile(): ReactElement {
  const theme = useTheme()
  const { isBenefitLoading, programTypeAllowsBenefitHighlight, filteredValueProps } = useBenefitHighlights()
  const { benefitHighlightDesignVariation } = useFlags()
  const { data: carrotPlan, isLoading: isLoadingCarrotPlan } = useCarrotPlan()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    appIsUnlockedAndPendingCarrotPlan
  } = useCarrotPlanEmployeeSettings()

  const carrotPlanIsOnTheWay =
    !isErrorEmployeeSettings &&
    !carrotPlan?.journeyCompleteTimestampUtc &&
    (carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested || appIsUnlockedAndPendingCarrotPlan)

  const shouldShowExperiment = benefitHighlightDesignVariation && !carrotPlanIsOnTheWay

  if (isBenefitLoading || !programTypeAllowsBenefitHighlight || isLoadingCarrotPlan || isLoadingEmployeeSettings) {
    return null
  }

  return (
    <Tile id="benefit-highlights-tile" color="info">
      <Stack
        spacing={theme.spacing(theme.tokens.spacing.md)}
        paddingBottom={
          benefitHighlightDesignVariation
            ? theme.spacing(theme.tokens.spacing.sm)
            : theme.spacing(theme.tokens.spacing.md)
        }
      >
        <Typography variant="h3">
          <FormattedMessage defaultMessage="Benefit highlights" />
        </Typography>
        <BenefitHighlightsInner filteredValueProps={filteredValueProps} />
        {shouldShowExperiment ? <ExperimentalTileVariation /> : null}
      </Stack>
    </Tile>
  )
}
function BenefitHighlightsCard(): ReactElement {
  const intl = useIntl()
  const { isBenefitLoading, programTypeAllowsBenefitHighlight, filteredValueProps, programType } =
    useBenefitHighlights()

  if (isBenefitLoading || !programTypeAllowsBenefitHighlight) {
    return null
  }

  return (
    <Card
      id="benefit-highlights-sidebar"
      color="info"
      title={intl.formatMessage({
        defaultMessage: "Benefit highlights"
      })}
      primaryAction={
        programType === ProgramType.PRO && (
          <Button variant="outlined" color="secondary" href={DbgPaths.CARROT_FUNDS} component="a">
            <FormattedMessage defaultMessage="Learn to use Carrot funds" />
          </Button>
        )
      }
    >
      <BenefitHighlightsInner useCompactSpacing filteredValueProps={filteredValueProps} />
    </Card>
  )
}

function BenefitHighlightsInner({ useCompactSpacing, filteredValueProps }: BenefitHighlightsProps): ReactElement {
  const theme = useTheme()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} paddingTop={theme.spacing(theme.tokens.spacing.md)}>
      {filteredValueProps.map((valueProp: ValueProposition) => (
        <ValuePropDisplay
          valueProp={valueProp}
          stackSpacing={theme.spacing(useCompactSpacing ? theme.tokens.spacing.md : theme.tokens.spacing.lg)}
          key={valueProp.valuePropId}
        />
      ))}
    </Stack>
  )
}

function ValuePropDisplay({ valueProp, stackSpacing }: ValuePropDisplayProps): ReactElement {
  const theme = useTheme()
  const intl = useIntl()
  return (
    <Stack direction="row" alignItems={"center"} spacing={stackSpacing}>
      <Box
        component="img"
        src={`/images/${valueProp.iconId}.png`}
        srcSet={`/images/${valueProp.iconId}-2x.png`}
        alt=""
        height={36}
        width={36}
        margin={theme.spacing(theme.tokens.spacing.sm)}
      />
      <Link
        href={valueProp.link}
        color={theme.palette.text.primary}
        endIcon={<InternalIcon fontSize="small" color="secondary" />}
      >
        {intl.formatMessage(valueProp.valuePropMessage)}
      </Link>
    </Stack>
  )
}

interface BenefitHighlightsProps {
  useCompactSpacing?: boolean
  filteredValueProps: ValueProposition[]
}

interface ValuePropDisplayProps {
  valueProp: ValueProposition
  stackSpacing: string
  key: any
}
export { LaunchDarklyBenefitHighlightMvpContainer, BenefitHighlightsTile, BenefitHighlightsCard }
