import React, { useRef } from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { adminCarrotClient } from "../../../utils/CarrotClient"
import { MedicalInfo } from "../../../types/medicalInfo"

const MedicalInfoContext = React.createContext(null)
export const useMedicalInfo = () => React.useContext(MedicalInfoContext)
export const MedicalInfoQueryKey = "carrotPlansMedicalInfo"

// To minimize exposure of PHI, this hook should only be called inside the Medical workflow
export const useGetMedicalInfo = () => {
  return useQuery([MedicalInfoQueryKey], () => adminCarrotClient.getCarrotPlansMedicalInfo(), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: false
  })
}

// @ts-expect-error TS7031
export function MedicalInfoContextProvider({ children }): JSX.Element {
  const dedupeRef = useRef(false)
  const queryClient = useQueryClient()

  // @ts-expect-error TS7006
  async function startMedicalInfo(payload = null): Promise<void> {
    if (!dedupeRef.current) {
      dedupeRef.current = true
      await adminCarrotClient.startMedicalInfoFlow(payload).catch(() => (dedupeRef.current = false))
      await queryClient.refetchQueries({ queryKey: [MedicalInfoQueryKey] }, { throwOnError: true })
      dedupeRef.current = false
    }
  }

  // @ts-expect-error TS7006
  async function updateMedicalInfo(payload, retry = true): Promise<void> {
    // @ts-expect-error TS7006
    await adminCarrotClient.updateMedicalInfo(payload).catch(async (error) => {
      if (retry) {
        await startMedicalInfo()
        const medicalInfo: MedicalInfo = queryClient.getQueryData([MedicalInfoQueryKey])
        const catchUp = medicalInfo || {}
        await updateMedicalInfo({ ...catchUp, ...payload }, false)
      } else {
        throw error
      }
    })
    await queryClient.refetchQueries({ queryKey: [MedicalInfoQueryKey] }, { throwOnError: true })
  }

  function hasQuestionBeenSkipped(questionKey: string): boolean {
    const medicalInfo: MedicalInfo = queryClient.getQueryData([MedicalInfoQueryKey])
    return (medicalInfo?.skipFields || []).includes(questionKey)
  }

  return (
    <MedicalInfoContext.Provider
      value={{
        updateMedicalInfo,
        startMedicalInfo,
        hasQuestionBeenSkipped
      }}
    >
      {children}
    </MedicalInfoContext.Provider>
  )
}
