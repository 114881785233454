import React from "react"
import { Redirect, useHistory } from "react-router-dom"
import { LocationDetails } from "../types/Location"
import { PARENT_ID_MAPPING } from "./parentIdMapping"

export const providerFinderRootPath = "/providerfinder"

export const ProviderFinderParam = {
  Latitude: "lat",
  Longitude: "long",
  Country: "country",
  Search: "search",
  Page: "page",
  RadiusInKm: "radiusInKm",
  CarrotPartner: "carrotPartner",
  IsVirtual: "isVirtual",
  ProviderType: "providerType",
  Keyword: "keyword"
} as const

type ProviderFinderParams = {
  [ProviderFinderParam.Latitude]?: string
  [ProviderFinderParam.Longitude]?: string
  [ProviderFinderParam.Country]?: string
  [ProviderFinderParam.Search]: string
  [ProviderFinderParam.Page]: number
  [ProviderFinderParam.RadiusInKm]?: number
  [ProviderFinderParam.CarrotPartner]?: boolean
  [ProviderFinderParam.IsVirtual]?: boolean
  [ProviderFinderParam.ProviderType]?: string | string[]
  [ProviderFinderParam.Keyword]?: string
}

function buildProviderFinderSearchURL(params: ProviderFinderParams): URL {
  const newURL = new URL(`${providerFinderRootPath}/providers`, window.location.href)

  params.lat && newURL.searchParams.set(ProviderFinderParam.Latitude, params.lat)
  params.long && newURL.searchParams.set(ProviderFinderParam.Longitude, params.long)
  params.country && newURL.searchParams.set(ProviderFinderParam.Country, params.country)
  newURL.searchParams.set(ProviderFinderParam.Search, params.search)
  newURL.searchParams.set(ProviderFinderParam.Page, params.page?.toString())

  if (params.keyword) {
    newURL.searchParams.set(ProviderFinderParam.Keyword, params.keyword)
  }

  if (params.carrotPartner) {
    newURL.searchParams.set(ProviderFinderParam.CarrotPartner, params.carrotPartner.toString())
  }

  if (params.isVirtual) {
    newURL.searchParams.set(ProviderFinderParam.IsVirtual, params.isVirtual.toString())
  }

  if (params.radiusInKm) {
    newURL.searchParams.set(ProviderFinderParam.RadiusInKm, params.radiusInKm.toFixed(1))
  }

  if (params.providerType && params.providerType.length) {
    const parsedProviderTypeString =
      typeof params.providerType === "string"
        ? params.providerType
        : params.providerType.filter((parentId) => Object.keys(PARENT_ID_MAPPING).indexOf(parentId) >= 0).join(",")
    newURL.searchParams.set(ProviderFinderParam.ProviderType, parsedProviderTypeString)
  }

  return newURL
}

function usePushUpdatedSearchParams() {
  const history = useHistory()

  return (updateSearchParams: (searchParamsToUpdate: URLSearchParams) => void) => {
    const searchParams = new URLSearchParams(history.location.search)
    updateSearchParams(searchParams)
    searchParams.set(ProviderFinderParam.Page, "1")
    history.location.search = searchParams.toString()
    history.push(history.location)
  }
}

export function useStringSearchParam(searchParam: keyof ProviderFinderParams): [string, (value: string) => void] {
  const history = useHistory()
  const pushUpdatedSearchParams = usePushUpdatedSearchParams()

  const currentSearchParams = new URLSearchParams(history.location.search)
  const searchParamValue = currentSearchParams.get(searchParam)

  const setSearchParam = (value: string) => {
    pushUpdatedSearchParams((searchParamsToUpdate: URLSearchParams) => {
      if (value) {
        searchParamsToUpdate.set(searchParam, value)
      } else {
        searchParamsToUpdate.delete(searchParam)
      }
    })
  }

  return [searchParamValue, setSearchParam]
}

const BOOLEAN_SEARCH_PARAM_TRUE_VALUE = "true"

export function useBooleanSearchParam(searchParam: keyof ProviderFinderParams): [boolean, (value: boolean) => void] {
  const history = useHistory()
  const pushUpdatedSearchParams = usePushUpdatedSearchParams()

  const currentSearchParams = new URLSearchParams(history.location.search)
  const searchParamValue =
    currentSearchParams.has(searchParam) && currentSearchParams.get(searchParam) === BOOLEAN_SEARCH_PARAM_TRUE_VALUE

  const setSearchParam = (value: boolean) => {
    pushUpdatedSearchParams((searchParamsToUpdate: URLSearchParams) => {
      if (value) {
        searchParamsToUpdate.set(searchParam, BOOLEAN_SEARCH_PARAM_TRUE_VALUE)
      } else {
        searchParamsToUpdate.delete(searchParam)
      }
    })
  }

  return [searchParamValue, setSearchParam]
}

const NO_PROVIDER_TYPES = "NONE"

export function useProviderTypeSearchParam(): [Set<string>, (providerTypes: Set<string>) => void] {
  const history = useHistory()
  const pushUpdatedSearchParams = usePushUpdatedSearchParams()
  const currentSearchParams = new URLSearchParams(history.location.search)

  const providerTypeIds = new Set(
    currentSearchParams.has(ProviderFinderParam.ProviderType) &&
    currentSearchParams.get(ProviderFinderParam.ProviderType) !== NO_PROVIDER_TYPES
      ? currentSearchParams.get(ProviderFinderParam.ProviderType).split(",")
      : []
  )

  const setProviderTypeSearchParam = (providerTypeIds: Set<string>) => {
    pushUpdatedSearchParams((searchParamsToUpdate: URLSearchParams) => {
      searchParamsToUpdate.set(
        ProviderFinderParam.ProviderType,
        providerTypeIds?.size > 0 ? Array.from(providerTypeIds.values()).sort().join(",") : NO_PROVIDER_TYPES
      )
    })
  }

  return [providerTypeIds, setProviderTypeSearchParam]
}

type getProviderFinderURLSearchParams = {
  searchLocation?: string
  locationDetails?: LocationDetails
  radiusInKm?: number | string
  companyCountryCode?: string
  carrotPartner?: boolean
  isVirtual?: boolean
  providerType?: string | string[]
  keyword?: string
}

export const getProviderFinderSearchURL = ({
  searchLocation,
  radiusInKm,
  locationDetails,
  carrotPartner,
  isVirtual,
  providerType,
  keyword
}: getProviderFinderURLSearchParams): URL => {
  const params: ProviderFinderParams = {
    [ProviderFinderParam.Search]: searchLocation,
    [ProviderFinderParam.Page]: 1,
    [ProviderFinderParam.Country]: locationDetails?.countryCode,
    [ProviderFinderParam.CarrotPartner]: carrotPartner,
    [ProviderFinderParam.IsVirtual]: isVirtual,
    [ProviderFinderParam.ProviderType]: providerType,
    [ProviderFinderParam.RadiusInKm]: Number(radiusInKm),
    [ProviderFinderParam.Keyword]: keyword
  }
  params[ProviderFinderParam.Latitude] = locationDetails?.latitude && locationDetails.latitude.toString()
  params[ProviderFinderParam.Longitude] = locationDetails?.longitude && locationDetails.longitude.toString()

  return buildProviderFinderSearchURL(params)
}

export const RedirectToProviderFinderURL = ({ url }: { url: URL }): JSX.Element => {
  if (!url) {
    return null
  }

  return <Redirect push to={{ pathname: url.pathname, search: url.search }} />
}

export const DEFAULT_IMPERIAL_RADIUS_IN_KM = 80.5
export const DEFAULT_METRIC_RADIUS_IN_KM = 50
