import styled from "styled-components"
import { IPage } from "../../contentful-types"
import React, { FC, useState } from "react"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { useLocation } from "react-router-dom"
import { RRNavLink } from "../views/atoms/roundedrectangle/RRNavLink"
import { Container, FlexContainer, Icon } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"

const ChildPagesAnimation = styled.div`
  max-height: 0;
  transition: 0.25s ease-out;
  overflow-y: hidden;
  &.show-child-links {
    max-height: 500px;
    transition: max-height ease-in 0.25s;
  }
`
// We had to set the overflow-x in a child div so that the little white line to the left would show when it's active.
// Setting overflow-x and overflow-y on the same div didn't work :'(
// https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
const ChildPagesWrapper = styled.div`
  margin-left: 16px;
  overflow-x: visible;
`
type PageNavFields = {
  page: IPage
  baseSlug: string
  top?: boolean | false
}
export const PageNav: FC<PageNavFields> = ({ page, baseSlug, top = false }) => {
  const { pathname } = useLocation()

  const {
    fields: { navigationText, slug, childPages }
  } = page

  const linkTo = `${baseSlug}/${slug}`
  const active = linkTo === pathname
  const [open, setOpen] = useState(active)

  const getShowChildLinksClass = () =>
    (active && open) || childPages?.some((page) => `${linkTo}/${page.fields.slug}` === pathname)
      ? "show-child-links"
      : ""

  return (
    <>
      <RRNavLink to={linkTo} width="w-100" active={active} top={top}>
        {childPages ? (
          <FlexContainer
            onClick={() => setOpen(!open)}
            style={{ cursor: "pointer" }}
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              color={(theme) => theme.palette.text.secondary}
              sx={{
                ":hover, :focus": {
                  color: (theme) => theme.palette.text.primary
                }
              }}
            >
              {navigationText}
            </Typography>
            <Container paddingLeftRight={"3px"}>
              <Icon size="tiny" name={open ? "up" : "down"} />
            </Container>
          </FlexContainer>
        ) : (
          <>
            <Typography
              variant="body2"
              color={(theme) => theme.palette.text.secondary}
              sx={{
                ":focus, :hover": {
                  color: (theme) => theme.palette.text.primary
                }
              }}
            >
              {navigationText}
            </Typography>
          </>
        )}
      </RRNavLink>
      {getShowChildLinksClass() === "show-child-links" ? (
        <ChildPagesAnimation className={getShowChildLinksClass()}>
          <ChildPagesWrapper>
            {childPages?.map((page) => (
              <ChildPageNav page={page} baseSlug={linkTo} key={page.sys.id} />
            ))}
          </ChildPagesWrapper>
        </ChildPagesAnimation>
      ) : (
        <></>
      )}
    </>
  )
}

type ChildPageNavFields = {
  page: IPage
  baseSlug: string
}

const ChildPageNav: FC<ChildPageNavFields> = ({ page, baseSlug }) =>
  page.fields && (
    <ContentfulConditionalGate conditions={page.fields.conditions}>
      <PageNav page={page} baseSlug={baseSlug} />
    </ContentfulConditionalGate>
  )
