import { getContentfulClient } from "../../../lib/contentful/contentfulClient"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { ICarrotPlanMenuItem } from "../../../contentful-types"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import ResponseError from "../../../types/responseError"
import { ReadLearnArticleItem } from "../../../utils/ContentTypes"
import { CarrotPlanStep } from "../../../types/carrotPlanTypes"
import { reportError } from "../../../utils/ErrorReporting"
import { getContentfulLocale } from "../../../lib/contentful/utils/contenfulLocaleUtils"

const CACHE_KEY = "CarrotPlanMenuItems"

type MenuItem = {
  entityId: string
  displayText: string
  defaultUrl: string
}

type PreContentfulReadLearnArticleItem = {
  entityId: string
  readLearnArticleText: string
}

export interface CarrotPlanStepMenuItem extends CarrotPlanStep {
  displayText: string
  defaultUrl: string
  readLearnArticleText: string
  readLearnArticleEntityId: string
}

const client = getContentfulClient()

const getCarrotPlanStepMenuItems = async (
  carrotPlanSteps: CarrotPlanStep[],
  stepMenuItemIds: string,
  locale: string
): Promise<CarrotPlanStepMenuItem[]> => {
  const { items } = await client.getEntries({
    content_type: "carrotPlanMenuItem",
    "sys.id[in]": stepMenuItemIds,
    locale: getContentfulLocale(locale)
  })
  const planMenuItems = items as ICarrotPlanMenuItem[]

  const planMenuItemsLite: MenuItem[] = planMenuItems.map((item) => {
    if (!item.fields) {
      return null
    }
    const {
      sys: { id },
      fields: { displayText, defaultUrl }
    } = item
    if (!displayText || !id) {
      return null
    }

    return {
      entityId: id,
      displayText,
      defaultUrl
    }
  })

  // Merge the CarrotPlanStep data and the MenuItem data returned from Contentful into a CarrotPlanStepMenuItem
  // by applying the Contentful information on an associated Read & Learn Article, if present
  const carrotPlanStepMenuItems: CarrotPlanStepMenuItem[] = []
  for (const step of carrotPlanSteps) {
    const mappedMenuItem = planMenuItemsLite?.find((menuItem) => menuItem.entityId === step.menuItemId)

    let readLearnArticleItem: PreContentfulReadLearnArticleItem
    if (step.entryId != null) {
      readLearnArticleItem = await getPreContentfulReadLearnArticleItem(step.entryId, locale)
    }

    const item: CarrotPlanStepMenuItem = {
      ...step,
      ...mappedMenuItem,
      readLearnArticleEntityId: readLearnArticleItem?.entityId,
      readLearnArticleText: readLearnArticleItem?.readLearnArticleText
    }
    carrotPlanStepMenuItems.push(item)
  }

  return carrotPlanStepMenuItems
}

// @ts-expect-error TS7006
const getPreContentfulReadLearnArticleItem = async (entityId, locale): Promise<PreContentfulReadLearnArticleItem> => {
  try {
    const item = (await client.getEntry(entityId, { include: 10, locale })) as ReadLearnArticleItem
    if (!item?.fields) {
      return null
    }
    const {
      sys: { id },
      fields: { title }
    } = item
    if (!title || !id) {
      return null
    }
    return {
      entityId: id,
      readLearnArticleText: title
    }
  } catch (err) {
    if (err && err.sys) {
      const {
        sys: { id: errorId }
      } = err
      if (errorId === "NotFound") {
        // The contentful resource could not be retrieved (the status could be: archived, draft, in progress, doesn't exist)
        // Not reporting the error because it's inaction-able
        return {
          entityId: entityId,
          readLearnArticleText: null
        }
      }
    }

    reportError(err)
    return {
      entityId: entityId,
      readLearnArticleText: null
    }
  }
}

export default function useCarrotPlanMenuItems(
  steps: CarrotPlanStep[],
  locale: string
): UseQueryResult<CarrotPlanStepMenuItem[], ResponseError> {
  const stepMenuItemIds = steps.map(({ menuItemId }) => menuItemId).join(",")
  const cacheKey = stepMenuItemIds + locale
  return useQuery<CarrotPlanStepMenuItem[], ResponseError>(
    [CACHE_KEY, cacheKey],
    () => getCarrotPlanStepMenuItems(steps, stepMenuItemIds, locale),
    {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        switch (error.statusCode) {
          case HttpStatusCodes.UNAUTHORIZED:
          case HttpStatusCodes.BAD_REQUEST:
          case HttpStatusCodes.NOT_FOUND:
            return false
          default:
            return failureCount <= 3
        }
      }
    }
  )
}
