import { Provider, ProviderCount } from "../types/Providers"
import { Country } from "./Countries"
import { defineMessage, FormattedMessage, FormattedList, MessageDescriptor, useIntl } from "react-intl"
import React from "react"
import { useIntlSort } from "../../../utils/hooks/useIntlSort"
import { Benefit } from "../types/Benefit"
import { Typography } from "@carrotfertility/carotene-core"

interface ChildrenProviderTypeIds {
  [providerTypeId: string]: { tag: MessageDescriptor }
}
interface parentIdDisplay {
  fullDisplayName: MessageDescriptor
  shortDisplayName: MessageDescriptor
  combinedDisplayName: MessageDescriptor
  tag?: MessageDescriptor
  getSecondaryText?: ({ countryCode }: { countryCode: string }) => MessageDescriptor
  filter?: (benefit: Benefit) => boolean
  childrenProviderTypeIds?: ChildrenProviderTypeIds
}

export const PARENT_ID_MAPPING: { [id: string]: parentIdDisplay } = {
  1: {
    fullDisplayName: defineMessage({ defaultMessage: "Fertility clinics" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Fertility clinics" }),
    shortDisplayName: defineMessage({ defaultMessage: "Fertility" }),
    tag: defineMessage({ defaultMessage: "Fertility" }),
    filter: ({ providerFinder: { showFertilityClinics } }) => showFertilityClinics
  },
  2: {
    fullDisplayName: defineMessage({ defaultMessage: "Reproductive urology" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Reproductive urology" }),
    shortDisplayName: defineMessage({ defaultMessage: "Urology" }),
    tag: defineMessage({ defaultMessage: "Urology" }),
    filter: ({ providerFinder: { showReproductiveUrology } }) => showReproductiveUrology
  },
  3: {
    fullDisplayName: defineMessage({ defaultMessage: "Adoption" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Adoption agencies, legal services, and social workers" }),
    shortDisplayName: defineMessage({ defaultMessage: "Adoption" }),
    tag: defineMessage({ defaultMessage: "Adoption" }),
    getSecondaryText: () => defineMessage({ defaultMessage: "agencies, legal services, and social workers" }),
    filter: ({ providerFinder: { showAdoption } }) => showAdoption
  },
  5: {
    fullDisplayName: defineMessage({ defaultMessage: "Cryobanks/storage facilities" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Cryobanks/storage facilities" }),
    shortDisplayName: defineMessage({ defaultMessage: "Cryobanks" }),
    tag: defineMessage({ defaultMessage: "Cryobanks" }),
    filter: ({ providerFinder: { showCryobanks } }) => showCryobanks
  },
  6: {
    fullDisplayName: defineMessage({ defaultMessage: "Low testosterone" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Low testosterone endocrinologists and urologists" }),
    shortDisplayName: defineMessage({ defaultMessage: "Low T specialists" }),
    getSecondaryText: () => defineMessage({ defaultMessage: "endocrinologists and urologists" }),
    childrenProviderTypeIds: {
      35: {
        tag: defineMessage({ defaultMessage: "LOW T - ENDOCRINOLOGIST" })
      },
      36: {
        tag: defineMessage({ defaultMessage: "LOW T - UROLOGIST" })
      }
    },
    filter: ({ providerFinder: { showLowTestosterone } }) => showLowTestosterone
  },
  7: {
    fullDisplayName: defineMessage({ defaultMessage: "Menopause specialists" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Menopause specialists" }),
    shortDisplayName: defineMessage({ defaultMessage: "Menopause specialists" }),
    tag: defineMessage({ defaultMessage: "Menopause" }),
    filter: ({ providerFinder: { showMenopause } }) => showMenopause
  },
  34: {
    fullDisplayName: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    shortDisplayName: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    tag: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    filter: ({ providerFinder: { showGenderAffirmingHormoneTherapy } }) => showGenderAffirmingHormoneTherapy
  },
  35: {
    fullDisplayName: defineMessage({ defaultMessage: "Donor assistance" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Donor assistance" }),
    shortDisplayName: defineMessage({ defaultMessage: "Donor assistance" }),
    tag: defineMessage({ defaultMessage: "Donor assistance" }),
    filter: ({ providerFinder: { showDonorAssistance } }) => showDonorAssistance
  },
  36: {
    fullDisplayName: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Gestational surrogacy agencies, legal services, and more" }),
    shortDisplayName: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    tag: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    getSecondaryText: ({ countryCode }) =>
      countryCode === Country.INDIA.code
        ? defineMessage({ defaultMessage: "clinics, legal services, and more" })
        : defineMessage({ defaultMessage: "agencies, legal services, and more" }),
    filter: ({ providerFinder: { showGestationalSurrogacy } }) => showGestationalSurrogacy
  }
}
export const PARENT_ID_TO_PROVIDER_COUNT_MAPPING: Record<string, keyof ProviderCount> = {
  1: "hasFertilityProviders",
  2: "hasUrologyProviders",
  3: "hasAdoptionProviders",
  5: "hasCryobanksProviders",
  6: "hasLowTProviders",
  7: "hasMenopauseProviders",
  34: "hasGenderAffirmingHormoneTherapyProviders",
  35: "hasDonorAssistedReproductionProviders",
  36: "hasGestationalCarrierProviders"
}

export function FormattedProviderTypesList(providerTypes: string[]): JSX.Element {
  const intl = useIntl()
  const { localeComparator } = useIntlSort()

  if (!providerTypes?.length) {
    return (
      <Typography color={(theme) => theme.palette.text.disabled}>
        <FormattedMessage defaultMessage="Select a provider type" />
      </Typography>
    )
  }
  const providerTypeList = providerTypes
    .map((parentId) => intl.formatMessage(PARENT_ID_MAPPING[parentId].shortDisplayName))
    .sort(localeComparator)

  return (
    <Typography component="span" overflow="hidden" textOverflow="ellipsis">
      <FormattedList value={providerTypeList} type="unit" />
    </Typography>
  )
}

export function filterParentIdByBenefitConfig(benefit: Benefit): (parentId: string | number) => boolean {
  return (parentId) => {
    if (PARENT_ID_MAPPING[parentId]) {
      return !PARENT_ID_MAPPING[parentId].filter || PARENT_ID_MAPPING[parentId].filter(benefit)
    }
    return false
  }
}

export function filterProviderByBenefitConfig(benefit: Benefit): (provider: Provider) => boolean {
  return (provider) => provider.parentIds.some(filterParentIdByBenefitConfig(benefit))
}

export function getAvailableParentIdsByBenefitConfig(benefit: Benefit): string[] {
  return Object.keys(PARENT_ID_MAPPING).filter(filterParentIdByBenefitConfig(benefit))
}
