import React, { useState } from "react"
import { ProviderFinderParam, useStringSearchParam } from "pages/provider-finder/utils/providerFinderSearchUrl"
import { KeywordAutocomplete } from "../keyword-search/KeywordAutocomplete"
import { RightIcon } from "@carrotfertility/carotene-core"

export function SidebarKeywordSearch(): JSX.Element {
  const [providerNameSearchValue, setProviderNameSearchValue] = useState(null)
  const [keyword, setKeyword] = useStringSearchParam(ProviderFinderParam.Keyword)

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setKeyword(providerNameSearchValue)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <KeywordAutocomplete
          keyword={keyword}
          popupIcon={
            <RightIcon
              fontSize="medium"
              sx={(theme) => ({
                marginLeft: theme.spacing(theme.tokens.spacing.xxs),
                marginRight: theme.spacing(theme.tokens.spacing.xxs)
              })}
              onClick={handleSubmit}
            />
          }
          startAdornment={null}
          setProviderNameValue={setProviderNameSearchValue}
          sx={{
            ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent"
            },
            "& .MuiAutocomplete-popupIndicator": { transform: "none" }
          }}
        />
      </form>
    </>
  )
}
