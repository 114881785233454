import React from "react"
import { Box, Container, Link as TextLink, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { useUserRole } from "../partner-access/hooks/usePartnerAccess"
import { employeeOnlyHeaderText, employeeOnlyText, employeeOnlyLink } from "./employeeOnlySpaceContent"
import { FormattedMessage, useIntl } from "react-intl"

const EmployeeOnlySpaceContainer = ({ ...props }): JSX.Element => {
  const { isPartnerAccessingAccount, isUserRoleLoading } = useUserRole()
  const intl = useIntl()
  const theme = useTheme()

  if (isUserRoleLoading) {
    return null
  }

  if (isPartnerAccessingAccount) {
    return (
      <Container maxWidth="md">
        <Box bgcolor={theme.palette.secondary.light} padding={"3rem"} borderRadius={".5rem"}>
          <Stack
            spacing={theme.spacing(theme.tokens.spacing.sm)}
            borderRadius={(theme) => theme.tokens.borderRadius.md}
          >
            <Typography variant="h3" component="h1" color={(theme) => theme.palette.text.primary}>
              {intl.formatMessage(employeeOnlyHeaderText)}
            </Typography>
            <Box>
              <Typography variant="body1" color={(theme) => theme.palette.text.secondary}>
                {intl.formatMessage(employeeOnlyText)}
              </Typography>
            </Box>
            <Box>
              <Typography>
                <FormattedMessage
                  defaultMessage="Questions? <link>Contact us.</link>"
                  values={{
                    link: (linkContent) => (
                      <TextLink color="primary" component={RouterLink} to={employeeOnlyLink}>
                        {linkContent}
                      </TextLink>
                    )
                  }}
                />
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Container>
    )
  }

  return <div>{props.children}</div>
}

export default EmployeeOnlySpaceContainer
