import React, { FC, ReactNode } from "react"
import AriaModal, { AriaModalProps } from "react-aria-modal"
import { useWindowInnerHeight } from "utils/Hooks"
import styled from "styled-components"
import { useIntl } from "react-intl"

const StyledButton = styled.button<{ backgroundColor: string }>`
  padding: 24px 24px 20px;
  cursor: pointer;
  background: none;
  color: rgba(0, 0, 0, 0.6);
  border-width: 0;
  transition: all 0.15s cubic-bezier(0.8, 0, 0.2, 1);
  float: right;
  line-height: 0;
  border-radius: 0.5rem;
`

const getApplicationNode = () => document.getElementById("body") as Element

type ModalProps = {
  titleText: string
  children: ReactNode
  fullscreen?: boolean
  backgroundColor?: string
  showCloseButton?: boolean
} & Readonly<AriaModalProps & { titleId?: undefined } & { titleText: string }>

const Modal: FC<ModalProps> = ({
  onExit,
  titleText,
  children,
  fullscreen = false,
  backgroundColor = "white",
  showCloseButton = true,
  ...props
}: ModalProps) => {
  const [windowInnerHeight] = useWindowInnerHeight()
  const intl = useIntl()

  return (
    <AriaModal
      getApplicationNode={getApplicationNode}
      underlayColor="rgba(0, 0, 0, 0.3)"
      verticallyCenter
      focusDialog
      titleText={titleText}
      onExit={onExit}
      {...props}
    >
      <div
        // This was done to be extra sure that we don't get a React error for not having focusable elements
        // This isn't the best solution, which would be converting everything over to carotene-core Dialog
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={`relative vw-100 ${
          fullscreen
            ? "pa55-l pa35"
            : // Use ph0-ns so mw-600 actually equals our design standard of 600px
              "pt3 ph3-s ph0-ns mw-600"
        }`}
        style={{
          height: fullscreen ? windowInnerHeight : null
        }}
      >
        <div className={`flex flex-column overflow-visible bg-${backgroundColor} shadow-modal br3 h-100`}>
          {showCloseButton && (
            <div>
              <StyledButton className={`input-reset hover-black`} onClick={onExit} backgroundColor={backgroundColor}>
                <img alt={intl.formatMessage({ defaultMessage: "close" })} src="/images/icn-close-modal.svg" />
              </StyledButton>
            </div>
          )}
          {children}
        </div>
      </div>
    </AriaModal>
  )
}

export { Modal }
