import React from "react"
import { getHeap, useTrackView } from "../../../utils/heap"
import { CarrotErrorCodes, getErrorMessageFromCode } from "../../../utils/CarrotErrors"
import CarrotClient from "carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, SkipButton, ContinueButton } from "./RegistrationButtons"
import { Form, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { emailValidationOptionalCaroteneCore } from "utils/Regexes"
import { useIntlValidator } from "utils/hooks/useIntlValidator"
import ResponseError from "types/responseError"
import { parseErrorBody } from "utils/HttpErrors"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

// @ts-expect-error TS7010
function ServerErrorShim({ serverError }) {
  const { setError } = useFormContext()
  React.useEffect(() => {
    setError("personalEmail", { type: "custom", message: serverError })
  }, [serverError, setError])
  return null
}

async function findErrorMessage(error: ResponseError): Promise<{ defaultMessage: string }> {
  const body = await parseErrorBody(error)
  if (body) {
    const code = body?.code
    if (code === CarrotErrorCodes.PERSONAL_EMAIL_ALREADY_EXISTS) {
      getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
      return defineMessage({
        defaultMessage: "That email address is already in use"
      })
    }
    return getErrorMessageFromCode(code)
  }
  return defineMessage({
    defaultMessage: "Oops, something went wrong"
  })
}

interface PersonalEmailProps {
  personalEmail?: string
  email: string
  setPersonalEmail: (newValue: string) => void
  onComplete: () => void
  onSkip: () => void
  onBack: () => void
}

export function PersonalEmail({
  personalEmail,
  email,
  onComplete,
  onSkip,
  onBack,
  setPersonalEmail
}: PersonalEmailProps): JSX.Element {
  useTrackView("PersonalEmailRegFlow", "Personal email screen")
  const intl = useIntl()
  const intlEmailValidation = useIntlValidator(emailValidationOptionalCaroteneCore)
  const [serverError, setServerError] = React.useState<string>(null)

  function validateEmailIsAbleToBeUsed(personalEmail: string): string | boolean {
    const sanitizedPersonalEmail = personalEmail.toLowerCase()
    if (sanitizedPersonalEmail === email) {
      return intl.formatMessage({
        defaultMessage: "This is your account address. Either provide a different address or skip this step."
      })
    }
    return intlEmailValidation(personalEmail)
  }

  // @ts-expect-error TS7006
  async function onCompleteSetPersonalEmail(formValues): Promise<void> {
    const sanitizedPersonalEmail = formValues?.personalEmail.toLowerCase()
    if (formValues?.personalEmail) {
      try {
        await client.checkEmailUniqueness(sanitizedPersonalEmail)
      } catch (err) {
        const errorMessage = await findErrorMessage(err)
        setServerError(intl.formatMessage(errorMessage))
        return
      }
      setPersonalEmail(formValues?.personalEmail)
      onComplete()
    } else {
      onSkip()
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "Should we contact you at a different email address?"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Box>
            <Typography>
              <FormattedMessage
                defaultMessage="The address you use to sign in won't change, but if you'd like us to
       communicate with you at a different address, share that here.
       You can also update this later in your account settings."
              />
            </Typography>
          </Box>
        }
        interactiveElements={
          <Form onSubmit={onCompleteSetPersonalEmail}>
            <ServerErrorShim serverError={serverError} />
            <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
              <FormTextField
                name="personalEmail"
                label={intl.formatMessage({ defaultMessage: "Contact email address" })}
                defaultValue={personalEmail}
                registerOptions={{
                  validate: validateEmailIsAbleToBeUsed
                }}
              />
              <RegistrationButtonFooter
                onBack={onBack}
                skipButton={
                  <SkipButton onClick={onSkip}>
                    <FormattedMessage defaultMessage="Skip" />
                  </SkipButton>
                }
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Box>
          </Form>
        }
      />
    </>
  )
}
