const RegisteringUserModel = class {
  constructor(
    firstName = null,
    email = null,
    companyName = null,
    coveragePercent = null,
    coverageAmount = null,
    currencySymbol = null,
    carrotLite = null,
    hasSaml = null
  ) {
    this.firstName = firstName
    this.email = email
    this.companyName = companyName
    this.coveragePercent = coveragePercent
    this.coverageAmount = coverageAmount
    this.currencySymbol = currencySymbol
    this.carrotLite = carrotLite
    this.hasSaml = hasSaml
  }
}

const DeductibleStatusModel = class {
  constructor(
    amountAppliedToDeductible = null,
    deductibleAmount = null,
    deductibleRemaining = null,
    planStartDate = null,
    planEndDate = null
  ) {
    this.amountAppliedToDeductible = amountAppliedToDeductible
    this.deductibleAmount = deductibleAmount
    this.deductibleRemaining = deductibleRemaining
    this.planStartDate = planStartDate
    this.planEndDate = planEndDate
  }
}

const ReimbursementDetailsModel = class {
  constructor(insuranceSync = null, subsidy = null, deductible = null) {
    this.insuranceSync = insuranceSync
    this.subsidy = subsidy
    this.deductible = deductible
  }
}

const MerchantValidModel = class {
  constructor(
    isApproved = null,
    companyAmount = null,
    companyCurrencyCode = null,
    merchantAmount = null,
    merchantCurrencyCode = null,
    merchantName = null
  ) {
    this.isApproved = isApproved
    this.companyAmount = companyAmount
    this.companyCurrencyCode = companyCurrencyCode
    this.merchantAmount = merchantAmount
    this.merchantCurrencyCode = merchantCurrencyCode
    this.merchantName = merchantName
  }
}

export { RegisteringUserModel, DeductibleStatusModel, MerchantValidModel, ReimbursementDetailsModel }
