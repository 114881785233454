import { Asset, Sys, TagLink } from "contentful"
import {
  ICantHave,
  ICantHaveCountry,
  ICategory,
  IContentBlock,
  IHasAny,
  IHasCountry,
  IMustHave,
  ISubCategory
} from "contentful-types"

type AttributeContentfulCondition = ICantHave | IHasAny | IMustHave
type CountryContentfulCondition = ICantHaveCountry | IHasCountry
type RawContentfulCondition = AttributeContentfulCondition | CountryContentfulCondition

export type File = {
  url: string
}

export type Icon = {
  file: File
}
export type Tag = {
  id: string
}

export type Category = {
  id: string
  title: string
  subCategories: ISubCategory[]
  order: number
  navTitle: string
  conditions: RawContentfulCondition[]
  icon: Icon
  description: IContentBlock[]
  tags: Tag[]
  sys: Sys
}

const mapContentToIcon = ({ fields }: Asset): Icon => {
  if (!fields) {
    return null
  }
  const { file } = fields
  return {
    file
  }
}

const mapContentToTag = ({ sys }: TagLink): Tag => {
  if (!sys) {
    return null
  }
  const { id } = sys
  return {
    id
  }
}

export const mapContentToCategory = ({ fields, metadata, sys }: ICategory): Category => {
  if (!fields || !metadata || !sys) {
    return null
  }
  const { title, subCategories, order, navTitle, conditions, icon, description } = fields
  const { id } = sys
  const { tags } = metadata

  return {
    id,
    title,
    subCategories,
    order,
    navTitle,
    conditions,
    icon: icon && mapContentToIcon(icon),
    description,
    tags: tags?.map(mapContentToTag),
    sys: sys
  }
}
