import React, { useState } from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, Button, Dialog, LeftIcon, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RegistrationActionBar, ContinueButton, SkipButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { RegisteringLoadingIndicator } from "./RegistrationLoadingIndicator"
import {
  CarrotDocumentType,
  DocuSignEvent,
  getSignatureForDocument,
  SignatureCompleteEvent
} from "carrot-api/signatureClient"
import { MemberActions } from "../../../services/memberActionTracking/memberActions"
import useUpdateMemberActionTracking from "../../../services/memberActionTracking/useUpdateMemberActionTracking"
import { useTrackExperimentEvent } from "./hooks/useExperiments"

interface PregnancyMedicalRecordsCollectionProps {
  onComplete: () => void
}

const windowEventListeners: Array<(event: SignatureCompleteEvent) => void> = []

export function PregnancyMedicalRecordsCollection({ onComplete }: PregnancyMedicalRecordsCollectionProps): JSX.Element {
  useTrackView("PregnancyMedicalRecordsCollectionRegFlow", "Pregnancy medical records collection screen")
  const { trackExperimentEvent } = useTrackExperimentEvent()
  const theme = useTheme()
  const intl = useIntl()
  const { mutate: updateMemberActions } = useUpdateMemberActionTracking()
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)
  const [memberViewedDocusign, setMemberViewedDocusign] = useState(false)
  const header = intl.formatMessage({ defaultMessage: "Share some medical information" })

  const handleWindowEvent = async (event: SignatureCompleteEvent) => {
    if (event?.data?.documentType === CarrotDocumentType.PregnancyMedicalRecordsConsentForm) {
      onCloseModal()
      switch (event.data.event) {
        case DocuSignEvent.SigningComplete:
          await trackExperimentEvent("fle-medical-consent-ds-complete")
          onCompleteStep(MemberActions.PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_SIGNED)
          break
        case DocuSignEvent.FinishLater:
          await trackExperimentEvent("fle-medical-consent-ds-finish-later")
          onCompleteStep(MemberActions.PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_FINISH_LATER)
          break
        case DocuSignEvent.Decline:
          await trackExperimentEvent("fle-medical-consent-ds-decline")
          onCompleteStep(MemberActions.PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_DECLINED)
          break
        default:
          onCompleteStep(MemberActions.PREGNANCY_MEDICAL_RECORD_CONSENT_REVIEWED_NOT_SIGNED_OTHER)
          break
      }
    }
  }

  const onCloseModal = () => {
    windowEventListeners.forEach((listener) => window.removeEventListener("message", listener))
    windowEventListeners.length = 0
    setModalIsOpen(false)
    setFrameSrc("/blank.html")
    setDocuSignIsReady(false)
  }

  const onCompleteStep = (actionTaken: MemberActions) => {
    updateMemberActions(actionTaken)
    if (onComplete) {
      onComplete()
    }
  }

  const showDocument = async () => {
    setModalIsOpen(true)
    windowEventListeners.push(handleWindowEvent)
    window.addEventListener("message", handleWindowEvent)
    // TODO: OP-1857 - Add state to store signatureId and use it when saving the document status
    const envelopeSignatureInfo = await getSignatureForDocument(CarrotDocumentType.PregnancyMedicalRecordsConsentForm)
    setFrameSrc(envelopeSignatureInfo.docuSignUrl)
    setMemberViewedDocusign(true)
    setDocuSignIsReady(true)
  }

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-doctor-with-clipboard"
        description={
          <Stack spacing={theme.tokens.spacing.md} marginTop={theme.tokens.spacing.xs}>
            <Typography>
              <FormattedMessage defaultMessage="Please review and sign the following authorization to share your pregnancy-related medical records with us. This allows us to better support you and use anonymized health information to improve birth outcomes for all our members." />
            </Typography>
            <Typography>
              <FormattedMessage defaultMessage="Personal information is always kept secure, and we will never share it with your employer or any other third party." />
            </Typography>
          </Stack>
        }
        interactiveElements={
          <RegistrationActionBar
            primaryAction={
              <ContinueButton onClick={showDocument}>
                <FormattedMessage defaultMessage="Review authorization" />
              </ContinueButton>
            }
            tertiaryAction={
              <SkipButton
                onClick={async () => {
                  await trackExperimentEvent(
                    memberViewedDocusign ? "fle-medical-consent-skip-after-ds-view" : "fle-medical-consent-skip"
                  )
                  onCompleteStep(MemberActions.PREGNANCY_MEDICAL_RECORD_CONSENT_SKIPPED)
                }}
              >
                <FormattedMessage defaultMessage="Skip" />
              </SkipButton>
            }
          />
        }
      />
      <Dialog
        aria-labelledby={intl.formatMessage({ defaultMessage: "Authorization Form Modal" })}
        onClose={onCloseModal}
        open={modalIsOpen}
        fullScreen
        PaperProps={{
          sx: {
            borderRadius: theme.tokens.borderRadius.none,
            padding: theme.tokens.spacing.xs,
            [theme.breakpoints.only("md")]: {
              padding: theme.tokens.spacing.md
            },
            [theme.breakpoints.up("md")]: {
              padding: theme.tokens.spacing.xl
            }
          }
        }}
      >
        <Button onClick={onCloseModal} variant="text" color="secondary" startIcon={<LeftIcon />}>
          <FormattedMessage defaultMessage="Back" />
        </Button>
        <Box marginTop={theme.tokens.spacing.xs} height="95%">
          {docuSignIsReady ? (
            <iframe
              title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
              style={{ width: "100%", height: "100%" }}
              src={frameSrc}
              allowFullScreen
            />
          ) : (
            <RegisteringLoadingIndicator isModal={true} />
          )}
        </Box>
      </Dialog>
    </>
  )
}
