import React from "react"
import { UploadFileModalStep, FileTypes } from "./UploadFile"
import { Progress } from "../../views/modal/Progress"
import FileCategories from "../../../utils/FileCategories"
import { FlexContainer, H3, Text, colors, Stack, H2 } from "@carrotfertility/carotene"
import { useSelector } from "react-redux"
import { getCurrencyCode } from "../../../reducers/companyInfo"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import styled from "styled-components"
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { DbgPaths } from "../../../utils/Paths"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import Settings from "../../../utils/CarrotConfig"

const StyledText = styled(Text)`
  font-size: 11px;
  text-align: left;
  color: ${colors.base.black40} !important;
`

export const UploadStatement = (): JSX.Element => {
  const intl = useIntl()
  const fileCategory = FileCategories.STATEMENT
  const { updateStatementFiles, expenseId, state } = useReimbursementContext()
  const isCardExpense = !!expenseId
  const { send } = useStateMachine()
  const maxFileSize = Settings.MAX_FILE_SIZE / 1000000

  const UploadStatementBody = () => {
    return (
      <Stack spacing="medium">
        <Text>
          <FormattedMessage
            defaultMessage="We can accept jpg, jpeg, png, pdf, HEIC, doc, docx, txt, xlsx, and zip files up to {maxFileSize} MB."
            values={{ maxFileSize }}
          />
        </Text>
        <Link href={DbgPaths.REQUIRED_DOCS} target="_blank" color="inherit">
          <FormattedMessage defaultMessage="What documents do I need to include?" />
        </Link>
        <FlexContainer>{expenseId && <Details />}</FlexContainer>
      </Stack>
    )
  }

  const UploadStatementHeader = () => {
    return (
      <H2>
        <FormattedMessage defaultMessage="Upload itemized statement" />
      </H2>
    )
  }

  const handleContinueClick = (attachments: FileTypes): void => {
    updateStatementFiles(attachments)
    send(!isCardExpense ? "ABOUT_EXPENSE_ELIGIBLE_REIMBURSEMENT_AMOUNT" : "")
  }

  return (
    <>
      <Progress
        numSteps={3}
        step={1}
        label={intl.formatMessage({ defaultMessage: "Upload Statement" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <UploadFileModalStep
        fileCategory={fileCategory}
        header={<UploadStatementHeader />}
        body={<UploadStatementBody />}
        onContinueClick={handleContinueClick}
        files={state.statementFiles}
        useArrowButton
      />
    </>
  )
}

const Details = () => {
  const currencyCode = useSelector(getCurrencyCode)
  const { transAmount, transDate } = useReimbursementContext()
  const formattedAmount = Number(transAmount) / 100
  return (
    <>
      <FlexContainer direction="column">
        <StyledText>
          <FormattedMessage defaultMessage="TRANSACTION DATE" />
        </StyledText>
        <H3>
          <FormattedDate value={transDate} year="numeric" month="short" day="2-digit" />
        </H3>
      </FlexContainer>
      <FlexContainer direction="column" paddingLeftRight="120px">
        <StyledText>
          <FormattedMessage defaultMessage="AMOUNT" />
        </StyledText>
        <H3>
          <FormattedNumber
            value={formattedAmount}
            style="currency"
            currency={currencyCode}
            currencyDisplay="narrowSymbol"
          />
        </H3>
      </FlexContainer>
    </>
  )
}
