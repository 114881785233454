import { combineReducers } from "redux"
import actionTypes from "actions/actionTypes"
import { createReducer } from "reducers/shared"
import { CountryCode } from "content/CountryCode"
import { RootState } from "reducers/index"
import TestUserActionTypes from "actions/testUserActionTypes"

const { FETCH_USER_INFO_SUCCESS } = actionTypes

const reducers = {
  medicalPlanStartDate: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.medicalPlanStartDate
  }),

  medicalPlanEndDate: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.medicalPlanEndDate
  }),

  isAvaActive: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.isAvaActive,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_IS_AVA_ACTIVE]: (state) => !state
  }),

  carrotLite: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.carrotLite,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_IS_CARROT_LITE]: (state) => !state
  }),

  countryCode: createReducer<CountryCode>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.countryCode,
    // @ts-expect-error TS7006
    [TestUserActionTypes.UPDATE_COUNTRY_CODE]: (state, action) => {
      return action.response.countryCode
    }
  }),

  companyId: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.companyId
  }),

  parentCompanyOrCompanyName: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.parentCompanyOrCompanyName
  }),

  companyDisplayName: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.companyDisplayName
  }),

  companyName: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.companyName
  }),

  parentCompanyName: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.parentCompanyName
  }),

  currencySymbol: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.currencySymbol
  }),

  isSamlActive: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.isSamlActive,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_IS_SAML_ACTIVE]: (state) => !state
  }),

  supportsDeductiblePlans: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.supportsDeductiblePlans,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_SUPPORTS_DEDUCTIBLE_PLANS]: (state) => !state
  }),

  hasIvfRequirements: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.hasIvfRequirements,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_ARE_COVERAGE_IVF_REQUIREMENTS_ENABLED]: (state) => !state
  }),

  hasAca: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.hasACA,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_HAS_ACA]: (state) => !state
  }),

  launchDate: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.launchDate
  }),

  currencyCode: createReducer<string>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.currencyCode
  }),

  isMiraActive: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.isMiraActive,
    // @ts-expect-error TS7006
    [TestUserActionTypes.TOGGLE_IS_MIRA_ACTIVE]: (state) => !state
  }),

  parentCompanyId: createReducer<number>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.parentCompanyId
  }),

  isExcludedFromMetrics: createReducer<boolean>(null, {
    // @ts-expect-error TS7006
    [FETCH_USER_INFO_SUCCESS]: (state, action) => action.response.company.isExcludedFromMetrics
  })
}

type CompanyInfoReducers = typeof reducers
const companyInfo = combineReducers<CompanyInfoReducers>(reducers)

type CompanyInfo = {
  [Property in keyof CompanyInfoReducers]: ReturnType<CompanyInfoReducers[Property]>
}

export const getCompanyInfo = (state: RootState): CompanyInfo => state.companyInfo
export const getCompanyId = (state: RootState): string => state.companyInfo.companyId
export const getCarrotLite = (state: RootState): boolean => state.companyInfo.carrotLite
export const getCurrencySymbol = (state: RootState): string => state.companyInfo.currencySymbol
export const getCompanyCurrencyCode = (state: RootState): string => state.companyInfo.currencyCode
export const getCountryCode = (state: RootState): CountryCode => state.companyInfo.countryCode
export const getParentCompanyOrCompanyName = (state: RootState): string => state.companyInfo.parentCompanyOrCompanyName
export const getCompanyDisplayName = (state: RootState): string => state.companyInfo.companyDisplayName
export const getParentCompanyId = (state: RootState): number => state.companyInfo.parentCompanyId
export const getCompanyName = (state: RootState): string => state.companyInfo.companyName
export const getParentCompanyName = (state: RootState): string => state.companyInfo.parentCompanyName
export const getIsAvaActive = (state: RootState): boolean => state.companyInfo.isAvaActive
export const getIsSamlActive = (state: RootState): boolean => state.companyInfo.isSamlActive
export const getSupportsDeductiblePlans = (state: RootState): boolean => state.companyInfo.supportsDeductiblePlans
export const getCompanyHasIvfRequirements = (state: RootState): boolean => state.companyInfo.hasIvfRequirements
export const getHasAca = (state: RootState): boolean => state.companyInfo.hasAca
export const getLaunchDate = (state: RootState): string => state.companyInfo.launchDate
export const getCurrencyCode = (state: RootState): string => state.companyInfo.currencyCode
export const getIsMiraActive = (state: RootState): boolean => state.companyInfo.isMiraActive
export const getIsExcludedFromMetrics = (state: RootState): boolean => state.companyInfo.isExcludedFromMetrics

export default companyInfo
