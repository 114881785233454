import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { getSubsidies } from "../../../../reducers/subsidies"
import { PayingForCareCoverageModal } from "./PayingForCareModal"
import { useBenefitEnrollment } from "../../../context/enrollment/BenefitEnrollmentContext"
import { FormatNumberOptions, FormattedMessage, useIntl } from "react-intl"
import { GetCategoryName } from "../ReimbursementCategoryMessages"
import { getCurrencyCode } from "../../../../reducers/companyInfo"
import subsidyStatus from "utils/CarrotPropTypes"
import { PlanInfoBadgeContainer } from "../../../containers/planinfo/PlanInfoBadgeContainer"
import { Box, Stack } from "@carrotfertility/carotene-core"
import { getCategoryCoverage } from "../../../../utils/CategoryCoverage"

const BenefitDisplayFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`

const BenefitDisplayHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50%;
  margin-left: 50%;
`
const BenefitDisplayHeaderText = styled.label`
  font-family: "Gotham A", "Gotham B", "Noto Sans", "Noto Sans Thai Looped", sans-serif;
  font-weight: 450;
  line-height: 1.5;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 5px;
`

const BenefitDisplayPlanContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  margin: 1rem 0;
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
`

const CategoryItemStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`

const CategoryTitle = styled.p`
  margin-left: 1rem;
  width: 100%;
`

const DeductibleCostShareStack = styled.div`
  margin: 0 0 0 1rem;
  @media (max-width: 600px) {
    margin: 0.5rem 0 0 0;
  }
`

const BenefitDisplayValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: self-start;
  width: 50%;
`

const BenefitDisplayValuesH3 = styled.h3`
  font-family: "Gotham A", "Gotham B", "Noto Sans", "Noto Sans Thai Looped", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 1rem;
`

const BlockQuoteCustomComponentBody = styled.div`
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 8px;
  line-height: 150%;
  font-family: "Gotham A", "Gotham B", "Noto Sans", "Noto Sans Thai Looped", sans-serif;
  color: rgba(0, 0, 0, 0.8);
  &:last-child {
    margin-bottom: 0;
  }
  background-color: rgba(250, 249, 247, 1);
`

const SinglePlanValues = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 2rem;
`

const CategoryImage = styled.img`
  @media (max-width: 600px) {
    display: none;
  }
`

// @ts-expect-error TS7031
const SinglePlanDisplay = ({ element, currencyCode }): JSX.Element => {
  const intl = useIntl()
  const hasUnlimitedBenefit = useMemo(() => element?.hasUnlimitedBenefit, [element?.hasUnlimitedBenefit])
  const yearlyBenefit = useMemo(() => element?.yearlyAmounts.max, [element?.yearlyAmounts.max])
  const lifetimeMax = useMemo(() => element?.lifetimeAmounts.max, [element?.lifetimeAmounts.max])
  const supportsOOPM = useBenefitEnrollment()?.companySupportsOOPM
  const reimbursementPercentage = element?.reimbursementPercent / 100
  const yearlyBenefitWithoutCents = yearlyBenefit / 100
  const lifetimeMaxWithoutCents = lifetimeMax / 100
  const formattedYearlyBenefit = intl.formatNumber(yearlyBenefitWithoutCents, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })
  const formattedLifetimeMax = intl.formatNumber(lifetimeMaxWithoutCents, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  if (hasUnlimitedBenefit) {
    return (
      <BlockQuoteCustomComponentBody>
        <SinglePlanValues>
          <FormattedMessage defaultMessage="Unlimited eligible expenses" />
        </SinglePlanValues>
      </BlockQuoteCustomComponentBody>
    )
  } else {
    return (
      <BlockQuoteCustomComponentBody>
        {!supportsOOPM && (
          <>
            <SinglePlanValues>
              <FormattedMessage
                defaultMessage="{reimbursementPercentage, number, ::percent} of eligible expenses"
                values={{ reimbursementPercentage }}
              />
            </SinglePlanValues>
          </>
        )}
        {yearlyBenefit !== lifetimeMax && (
          <SinglePlanValues>
            <FormattedMessage
              defaultMessage="{formattedYearlyBenefit} per benefit year"
              values={{ formattedYearlyBenefit }}
            />
          </SinglePlanValues>
        )}
        {lifetimeMax && (
          <SinglePlanValues>
            <FormattedMessage
              defaultMessage="{formattedLifetimeMax} lifetime maximum"
              values={{ formattedLifetimeMax }}
            />
          </SinglePlanValues>
        )}
        <>
          <PlanInfoBadgeContainer
            subsidy={element}
            allSubsidies={[element]}
            sx={(theme) => ({ paddingTop: theme.tokens.spacing.xs })}
          />
          <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)} />
        </>
      </BlockQuoteCustomComponentBody>
    )
  }
}

// @ts-expect-error TS7031
const MultiplePlanDisplay = ({ elements, lifetimeMaxOnly, currencyCode }): JSX.Element => {
  const intl = useIntl()
  const hasQmeCategory = getCategoryCoverage(elements).qme
  return (
    <>
      <BenefitDisplayHeaderContainer>
        {lifetimeMaxOnly && (
          <BenefitDisplayHeaderText>
            <FormattedMessage defaultMessage="Annual Max" />
          </BenefitDisplayHeaderText>
        )}
        <BenefitDisplayHeaderText>
          <FormattedMessage defaultMessage="Lifetime Max" />
        </BenefitDisplayHeaderText>
      </BenefitDisplayHeaderContainer>
      {elements.map((element: typeof subsidyStatus, index: number) => {
        const categoryName = GetCategoryName(element.reimbursementCategoryNameMessageKey.toString(), hasQmeCategory)
        const benefitFormatOptions: FormatNumberOptions = {
          style: "currency",
          currency: currencyCode,
          currencyDisplay: "narrowSymbol",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }
        // @ts-expect-error TS2339
        const yearlyBenefitWithoutCents = element.yearlyAmounts.max / 100
        // @ts-expect-error TS2339
        const lifetimeBenefitWithoutCents = element.lifetimeAmounts.max / 100
        const formattedYearlyBenefit = intl.formatNumber(yearlyBenefitWithoutCents, benefitFormatOptions)
        const formattedLifetimeBenefit = lifetimeBenefitWithoutCents
          ? intl.formatNumber(lifetimeBenefitWithoutCents, benefitFormatOptions)
          : intl.formatMessage({ defaultMessage: "Unlimited" })

        return (
          <>
            <BenefitDisplayPlanContainer key={index}>
              <CategoryItemStack>
                <CategoryContainer>
                  <CategoryImage
                    src={
                      // @ts-expect-error TS2339
                      element.reimbursementCategoryIconName
                        ? // @ts-expect-error TS2339
                          `/images/${element.reimbursementCategoryIconName}.png`
                        : `/images/icn-expenses-other.png`
                    }
                    height={36}
                    width={36}
                    // @ts-expect-error TS2339
                    alt={element.reimbursementCategoryName || "icon-for-expense-category"}
                  />
                  <CategoryTitle>{categoryName}</CategoryTitle>
                </CategoryContainer>
              </CategoryItemStack>
              <BenefitDisplayValueContainer>
                {lifetimeMaxOnly && <BenefitDisplayValuesH3>{formattedYearlyBenefit}</BenefitDisplayValuesH3>}
                <BenefitDisplayValuesH3>{formattedLifetimeBenefit}</BenefitDisplayValuesH3>
              </BenefitDisplayValueContainer>
            </BenefitDisplayPlanContainer>
            <Stack direction="row">
              <Box width="36px" />
              <DeductibleCostShareStack>
                <PayingForCareCoverageModal element={element} />
                <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)} />
                <PlanInfoBadgeContainer subsidy={element} allSubsidies={elements} />
              </DeductibleCostShareStack>
            </Stack>
          </>
        )
      })}
    </>
  )
}

const PayingForCareCoverageDetails = (): JSX.Element => {
  const subsidies = useSelector(getSubsidies)
  const currencyCode = useSelector(getCurrencyCode)
  const hasMultiplePlans = useMemo(() => subsidies?.length > 1, [subsidies])
  const lifetimeMaxOnly = useMemo(
    // @ts-expect-error TS7006
    () => subsidies?.some((plan) => plan.yearlyAmounts.max !== plan.lifetimeAmounts.max),
    [subsidies]
  )

  return (
    <BenefitDisplayFlexContainer>
      {hasMultiplePlans ? (
        <MultiplePlanDisplay elements={subsidies} lifetimeMaxOnly={lifetimeMaxOnly} currencyCode={currencyCode} />
      ) : (
        <SinglePlanDisplay element={subsidies?.[0]} currencyCode={currencyCode} />
      )}
    </BenefitDisplayFlexContainer>
  )
}

export { PayingForCareCoverageDetails }
