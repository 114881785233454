import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { ITileDisplay } from "contentful-types"
import { getTalkToCarrotPhoneSupportDisplay } from "lib/contentful/contentfulClient"

const phoneSupportDisplay = "getTalkToCarrotPhoneSupportDisplay"

export function useGetTalkToCarrotPhoneSupportDisplay(locale: string): UseQueryResult<ITileDisplay, ResponseError> {
  return useQuery<ITileDisplay, ResponseError>(
    [phoneSupportDisplay],
    () => getTalkToCarrotPhoneSupportDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch phone support tile with error: ${error}`)
    }
  )
}
