import React, { FC } from "react"
import { useToggle } from "../../../utils/Hooks"
import { RecursiveContentfulContentRenderer } from "../content-renderer"
import { IModal } from "contentful-types"
import { ContentfulContentProp } from "./shared-types"
import { ContentfulDialog } from "../shared/contentful-modal"
import { ContentfulArrowLink } from "../shared/contentful-arrow-link"
import { ModalContentContext } from "../shared/modal-content-context"

export const Modal: FC<ContentfulContentProp<IModal>> = ({ content }) => {
  const {
    fields: { linkText, content: internalContent, title }
  } = content
  const [showModal, toggleShowModal] = useToggle(false)

  return (
    <>
      <ContentfulArrowLink component="button" onClick={toggleShowModal}>
        {linkText}
      </ContentfulArrowLink>
      <ContentfulDialog title={title} open={showModal} onClose={toggleShowModal}>
        <ModalContentContext.Provider value={{ isModalContent: true }}>
          <RecursiveContentfulContentRenderer contents={internalContent} />
        </ModalContentContext.Provider>
      </ContentfulDialog>
    </>
  )
}
