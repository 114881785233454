import React, { useCallback, useEffect, useRef } from "react"
import { Container, FlexContainer, Grid, GridItem, Icon, useBreakpointValue } from "@carrotfertility/carotene"
import {
  DefaultThreadMessage,
  StyledThreadsList,
  ThreadCardContainer,
  ThreadSideTick,
  ThreadSubjectAndDate
} from "./Threads"
import { CenteredLoadingIndicator } from "../views/molecules/Molecules"
import { InAppMessagingCreateNewThreadForm, NewMessageButtonContainer } from "./NewThread"
import { MessagingErrorContainer, ThreadMessagesContainer } from "./Messages"
import { InAppMessagingThread } from "./inAppMessagingTypes"
import { MessageReplyContainer } from "./MessageReplyContainer"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import useGetThreads from "./hooks/useGetThreads"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"

function DefaultThreadCard(): JSX.Element {
  const { selectedThread, setSelectedThread, showNewThreadForm, setShowNewThreadForm, setShowOnlyThreadsList } =
    useInAppMessaging()
  const intl = useIntl()

  return (
    <Container stack="138px" sx={{ borderBottom: "rgba(0, 0, 0, 0.1) 1px solid" }}>
      <ThreadCardContainer
        key={-1}
        onClick={() => {
          // canned response
          setSelectedThread({ threadId: -1 })
          setShowNewThreadForm(false)
          setShowOnlyThreadsList(false)
        }}
      >
        <ThreadSideTick active={selectedThread?.threadId === -1 && !showNewThreadForm} />
        <ThreadSubjectAndDate
          thread={{
            date: intl.formatMessage({ defaultMessage: "From Carrot" }),
            subject: intl.formatMessage({ defaultMessage: "Get started with Messages" })
          }}
        />
      </ThreadCardContainer>
    </Container>
  )
}

function MemberMessagesContainer(): JSX.Element {
  const { thread: threadParam } = useParams<{ thread: string }>()
  const history = useHistory()
  const hideColumn = useBreakpointValue<boolean>([true, true, true, false])
  const intl = useIntl()
  const {
    showNewThreadForm,
    setShowNewThreadForm,
    setShowOnlyThreadsList,
    showOnlyThreadsList,
    selectedThread,
    setSelectedThread
  } = useInAppMessaging()

  const {
    data: threadsData,
    isLoading: isGetThreadsLoading,
    isError: isGetThreadsError,
    isSuccess: isGetThreadsSuccess
  } = useGetThreads()

  useEffect(() => {
    if (isGetThreadsSuccess && selectedThread == null && !showNewThreadForm) {
      if (threadParam) {
        const linkedThread = threadsData.threads.find((t) => t.threadId === parseInt(threadParam, 10))
        if (!linkedThread) {
          history.replace("/messages")
          setSelectedThread(threadsData.threads[0])
        }
        setSelectedThread(linkedThread)
      } else if (threadsData.threads[0]) {
        setSelectedThread(threadsData.threads[0])
      } else {
        setSelectedThread({ threadId: -1 })
      }
    }
  }, [threadParam, selectedThread, setSelectedThread, showNewThreadForm, isGetThreadsSuccess, threadsData, history])

  const mapThreads = useCallback(
    (threads: InAppMessagingThread[]) => {
      return threads.map((thread: InAppMessagingThread) => {
        return (
          <ThreadCardContainer
            key={thread.threadId}
            onClick={() => {
              setSelectedThread(thread)
              setShowNewThreadForm(false)
              setShowOnlyThreadsList(false)
            }}
          >
            <ThreadSideTick active={selectedThread?.threadId === thread.threadId && !showNewThreadForm} />
            <FlexContainer justifyContent="space-between" width="100%">
              <ThreadSubjectAndDate
                thread={{
                  date: intl.formatDate(thread.updatedAt, { month: "short", day: "numeric" }),
                  subject: thread.subject
                }}
              />
              {thread.hasUnreadMessages && (
                <FlexContainer width="9px" justifyContent="center" alignItems="center" sx={{ marginTop: "4px" }}>
                  <Icon name="dot" color="carrotOrange" size="tiny" />
                </FlexContainer>
              )}
            </FlexContainer>
          </ThreadCardContainer>
        )
      })
    },
    [selectedThread?.threadId, setSelectedThread, showNewThreadForm, setShowNewThreadForm, setShowOnlyThreadsList, intl]
  )

  const threadsListScrollRef = useRef(null)
  function scrollThreadsListToTop(): void {
    threadsListScrollRef.current.scrollIntoView()
  }

  return (
    <>
      {isGetThreadsLoading && <CenteredLoadingIndicator />}
      {isGetThreadsError && <MessagingErrorContainer />}
      {isGetThreadsSuccess && (
        <Grid
          gap="none"
          height={["calc(100vh - 219px)", "calc(100vh - 248px)", null, null]}
          templateRows={["1fr"]}
          templateColumns={["1fr", null, null, "1fr 2fr"]}
        >
          <Container
            minWidth={["100%", null, null, "232px"]}
            height="inherit"
            stack="medium"
            sx={{ display: hideColumn && !showOnlyThreadsList ? "none" : "block" }}
          >
            <StyledThreadsList>
              <div ref={threadsListScrollRef} />
              <GridItem as="nav" colStart={1} colSpan={1} rowStart={1}>
                <Container height="100%" sx={{ marginTop: "17px" }} />
                {mapThreads(threadsData.threads)}
                <DefaultThreadCard />
              </GridItem>
            </StyledThreadsList>
            <NewMessageButtonContainer
              disabled={showNewThreadForm}
              onClick={() => {
                setShowOnlyThreadsList(false)
                setShowNewThreadForm(true)
              }}
            />
          </Container>
          <Container
            minWidth={["100%", null, null, "385px"]}
            sx={{ display: hideColumn && showOnlyThreadsList ? "none" : "block" }}
          >
            <GridItem>
              {showNewThreadForm ? (
                <InAppMessagingCreateNewThreadForm
                  newestThread={!threadsData.threads[0]?.threadId ? { threadId: -1 } : threadsData.threads[0]}
                  scrollThreadsListToTop={scrollThreadsListToTop}
                />
              ) : selectedThread?.threadId === -1 ? (
                <DefaultThreadMessage />
              ) : (
                <FlexContainer
                  direction="column"
                  justifyContent="space-between"
                  height={["calc(100vh - 216px)", "calc(100vh - 248px)", null, null]}
                >
                  <Container
                    height="100%"
                    stack="small"
                    sx={{ overflowX: "hidden", overflowY: "auto", marginLeft: [false, null, null, "small"] }}
                  >
                    <ThreadMessagesContainer />
                  </Container>
                  <MessageReplyContainer thread={selectedThread} scrollThreadsListToTop={scrollThreadsListToTop} />
                </FlexContainer>
              )}
            </GridItem>
          </Container>
        </Grid>
      )}
    </>
  )
}

export default MemberMessagesContainer
