import { BodySmall } from "components/views/atoms/Bodies"
import { CircleImage } from "components/views/atoms/images/CircleImage"
import React from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

const LegalFooter = styled.div`
  display: flex;
  margin-top: 40px;
  margin-bottom: 48px;
  padding-top: 12px;
  border-top: 1px solid #ebebeb;
  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  display: flex;
  margin: 0 16px 12px 0;
`

const TalkToCarrotLegal = () => (
  <LegalFooter>
    <ImageContainer>
      <CircleImage iconName="icn-help" backgroundColor="light-gray" />
    </ImageContainer>
    <div>
      <BodySmall color="black-60">
        <FormattedMessage defaultMessage="Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately." />
      </BodySmall>
    </div>
  </LegalFooter>
)

export { TalkToCarrotLegal }
