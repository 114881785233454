import React, { useState, useEffect, ReactElement } from "react"
import usePartnerAccess, {
  useSendInvite,
  useResendInvite,
  useGetPartnerInviteStatus,
  PARTNER_INVITE_STATUS_CACHE_KEY
} from "../hooks/usePartnerAccess"
import { useSelector } from "react-redux"
import { getCarrotLite } from "../../../reducers/companyInfo"
import { useCurrentUser } from "../../context/user/UserContext"
import usePartnerInformation from "../../views/account/hooks/usePartnerInformation"
import { PartnerInviteStatus } from "../partnerAccessTypes"
import { HttpErrors } from "utils/HttpErrors"
import { useQueryClient } from "@tanstack/react-query"
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Link,
  Divider,
  Button,
  Checkbox,
  FormControlLabel
} from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { EmailWithChip } from "components/EmailVerification/EmailAddressesAccountSettings"
import { FormattedMessage, useIntl } from "react-intl"

export function PartnerAccessAccountSettingsFooter({ addPartnerInfo }: { addPartnerInfo: boolean }): JSX.Element {
  const { showPartnerAccess } = usePartnerAccess()
  const { inviteData, isInviteStatusLoading } = useGetPartnerInviteStatus()
  const { hasProvidedInfoRequiredForPartnerAccess, hasProvidedAnyPartnerInfo } = usePartnerInformation()

  const showPartnerAccessPrompt =
    !hasProvidedInfoRequiredForPartnerAccess &&
    (hasProvidedAnyPartnerInfo || addPartnerInfo) &&
    inviteData?.status === PartnerInviteStatus.NONE

  const { partnerEmail } = useCurrentUser()
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (!isInviteStatusLoading) {
      queryClient.setQueryData([PARTNER_INVITE_STATUS_CACHE_KEY], {
        status: PartnerInviteStatus.NONE
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [partnerEmail])

  return inviteData && showPartnerAccess ? (
    <>
      {showPartnerAccessPrompt && <PartnerAccessPrompt />}
      {hasProvidedInfoRequiredForPartnerAccess && inviteData?.status !== PartnerInviteStatus.ACTIVE && (
        <PartnerAccessSendInvite partnerEmail={partnerEmail} inviteData={inviteData} />
      )}
    </>
  ) : null
}

function PartnerAccessContainer({
  title,
  children
}: {
  title: string
  children: ReactElement | ReactElement[]
}): JSX.Element {
  const theme = useTheme()
  return (
    <Box
      bgcolor={(theme) => theme.palette.primary.light}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.xl)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    >
      <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <Typography variant="h6" component="h3">
          {title}
        </Typography>
        {children}
      </Stack>
    </Box>
  )
}

function PartnerAccessPrompt(): JSX.Element {
  const intl = useIntl()
  return (
    <PartnerAccessContainer
      title={intl.formatMessage({ defaultMessage: "Would you like to invite your partner to access your account?" })}
    >
      <Typography>
        <FormattedMessage defaultMessage="If so, save their information above and you'll have the option to invite them to sign in with their own email address and password." />
      </Typography>
    </PartnerAccessContainer>
  )
}

// @ts-expect-error TS7031
function PartnerAccessSendInvite({ partnerEmail, inviteData }): JSX.Element {
  const theme = useTheme()
  const {
    mutate: sendInvite,
    isLoading: sendInviteIsLoading,
    error: sendInviteError,
    reset: resetSendInviteError
  } = useSendInvite()
  const { mutate: resendInvite, isLoading: resendInviteIsLoading } = useResendInvite()
  const [isConsentChecked, setIsConsentChecked] = useState(false)
  const intl = useIntl()
  // BAD_REQUEST indicates a non-unique email that we want to alert user to
  // All other errors would be UNPROCESSABLE_ENTITY and user doesn't need to know about them
  const isDuplicateEmail = (sendInviteError as Error)?.name === HttpErrors.BAD_REQUEST

  function getPartnerAccessContainerTitle(): string {
    switch (inviteData?.status) {
      case PartnerInviteStatus.NONE:
        return intl.formatMessage({ defaultMessage: "Would you like to invite your partner to access your account?" })
      case PartnerInviteStatus.INVITED:
        return intl.formatMessage({ defaultMessage: "Your partner has been invited to access your account" })
      default:
        return ""
    }
  }

  async function handleSendInvite(): Promise<void> {
    sendInvite(isConsentChecked)
    setIsConsentChecked(false)
  }

  useEffect(() => {
    resetSendInviteError()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [partnerEmail])

  if (inviteData) {
    return (
      <PartnerAccessContainer title={getPartnerAccessContainerTitle()}>
        <PartnerAccessSendInviteIntro inviteStatus={inviteData?.status} />
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <EmailWithChip email={partnerEmail} chipLabel={intl.formatMessage({ defaultMessage: "partner sign-in" })} />
          {isDuplicateEmail && (
            <Box width="100%" display="flex" justifyContent="flex-start">
              <Typography color={(theme) => theme.palette.error.main} component="p">
                <FormattedMessage
                  defaultMessage="That email address is already in use. Please save a different email address for your partner or <link>send us a message</link>."
                  values={{
                    link: (content) => (
                      <Link component={RouterLink} to={"/send-a-message"}>
                        <Typography sx={{ display: "inline" }} color={(theme) => theme.palette.error.main}>
                          {content}
                        </Typography>
                      </Link>
                    )
                  }}
                />
              </Typography>
            </Box>
          )}
          {inviteData?.status === PartnerInviteStatus.NONE && (
            <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <Divider />
              <FormControlLabel
                label={intl.formatMessage({
                  defaultMessage:
                    "Yes, I would like to invite my partner. I give my consent for them to access my account as described above."
                })}
                control={
                  <Checkbox
                    name={"consentCheck"}
                    checked={isConsentChecked}
                    onChange={(e) => {
                      setIsConsentChecked(e.target.checked)
                    }}
                  />
                }
              />
              <Typography>
                <FormattedMessage defaultMessage="You may withdraw this consent at any time." />
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleSendInvite} disabled={sendInviteIsLoading || !isConsentChecked}>
                  <FormattedMessage defaultMessage="Send invite" />
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>
        {inviteData?.status === PartnerInviteStatus.INVITED && (
          <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
            <Typography fontStyle="italic" marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}>
              <FormattedMessage defaultMessage="Need to change your partner's sign-in? Enter and save the new email address in the Partner information section above, then resend the invite." />
            </Typography>
            <Box display="flex" justifyContent="flex-end">
              <Button onClick={() => resendInvite({})} disabled={resendInviteIsLoading}>
                <FormattedMessage defaultMessage="Resend invite" />
              </Button>
            </Box>
          </Stack>
        )}
      </PartnerAccessContainer>
    )
  }

  return null
}

function PartnerAccessSendInviteIntro({ inviteStatus }: { inviteStatus: PartnerInviteStatus }): JSX.Element {
  const isCarrotLite = useSelector(getCarrotLite)
  const theme = useTheme()
  return (
    <Box>
      {inviteStatus === PartnerInviteStatus.NONE && (
        <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
          <Typography>
            <FormattedMessage defaultMessage="This will let them sign in with their own email address and password." />
          </Typography>
          <Typography>
            {!isCarrotLite ? (
              <FormattedMessage defaultMessage="They'll be able to access educational resources, benefit details, and more, but they won't be able to edit your account information, access Financial support, or see your Messages. They may be able to see personal information like gender identity." />
            ) : (
              <FormattedMessage defaultMessage="They'll be able to access educational resources, benefit details, and more, but they won't be able to edit your account information or see your Messages. They may be able to see personal information like gender identity." />
            )}
          </Typography>
        </Stack>
      )}
      {inviteStatus === PartnerInviteStatus.INVITED && (
        <Typography>
          {!isCarrotLite ? (
            <FormattedMessage defaultMessage="Once they accept, they'll be able to sign in with their own email address and password. They'll be able to access educational resources, benefit details, and more, but they won't be able to edit your account information, access Financial support, or see your Messages. They may be able to see personal information like gender identity." />
          ) : (
            <FormattedMessage defaultMessage="Once they accept, they'll be able to sign in with their own email address and password. They'll be able to access educational resources, benefit details, and more, but they won't be able to edit your account information or see your Messages. They may be able to see personal information like gender identiy." />
          )}
        </Typography>
      )}
      <Typography margin={(theme) => theme.spacing(theme.tokens.spacing.md, theme.tokens.spacing.none)}>
        <FormattedMessage defaultMessage="You can also remove partner access later from this page." />
      </Typography>
    </Box>
  )
}
