import React from "react"
import { useTrackView } from "../../../utils/heap"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { RegistrationButtonFooter } from "./RegistrationButtons"
import { Box, Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { continueToRouting, goToHomePage } from "./RegistrationComplete"

interface GuidanceInformationalProps {
  onBack: () => void
  isCarrotLite?: boolean
  hasProviderAccess?: boolean
}

export function GuidanceInformational({
  onBack,
  isCarrotLite,
  hasProviderAccess
}: GuidanceInformationalProps): JSX.Element {
  useTrackView("GuidanceInformationalRegFlow", "Guidance informational screen")
  const theme = useTheme()
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "Enjoy personal, dynamic guidance"
  })
  const bodyCopy = intl.formatMessage(getModuleCopy(hasProviderAccess, isCarrotLite))

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={<FormattedMessage defaultMessage="Enjoy personal, dynamic guidance" />}
        imageName="illo-underwater-checklist"
        description={
          <Box>
            <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <Typography>{bodyCopy}</Typography>
              <Typography>
                <FormattedMessage defaultMessage="As your journey evolves, so will your plan." />
              </Typography>
            </Stack>
          </Box>
        }
        interactiveElements={
          <RegistrationButtonFooter
            onBack={onBack}
            exploreBenefitButton={
              <Button onClick={goToHomePage} color="secondary" variant="outlined">
                <FormattedMessage defaultMessage="Explore your benefit" />
              </Button>
            }
            getCarrotPlanButton={
              <Button onClick={continueToRouting} color="primary" variant="contained">
                <FormattedMessage defaultMessage="Get your Carrot Plan" />
              </Button>
            }
          />
        }
      />
    </>
  )
}

function getModuleCopy(hasProviderAccess: boolean, isCarrotLite: boolean): MessageDescriptor {
  if (!hasProviderAccess) {
    return defineMessage({
      defaultMessage:
        "When you’re ready to access Care Navigators, specialists, and more, simply tell us about your journey and we'll design a personalized plan."
    })
  } else if (isCarrotLite) {
    return defineMessage({
      defaultMessage:
        "When you're ready to access experts, providers, and more, simply tell us about your journey and we'll design a personalized plan."
    })
  }
  return defineMessage({
    defaultMessage:
      "When you're ready to access your Carrot funds, experts, and providers, simply tell us about your journey and we'll design a personalized plan."
  })
}
