import { fetchUserInfo, updateUserInfo } from "actions/userActions"
import { useLDClient } from "launchdarkly-react-client-sdk"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCompanyInfo } from "reducers/companyInfo"
import { getBenefitStarted } from "../../../derivedSelectors"
import { getUserInfo } from "reducers/userInfo"
import { UserInfo } from "lib/carrot-api/types/UserInfo"
import { LaunchDarklyUser } from "../../../types/launchDarklyUser"

const removePropertyFromObject = (property: string, obj: Record<string, any>): Record<string, any> => {
  const { [property]: _, ...rest } = obj
  return rest
}

const UserContext = React.createContext(null)

type RegistrationCohortHelper = "showRegPostCarrotPlansSoftLaunchFeatures" | "showRegPreCarrotPlansRoutingFlowFeatures"

export const useCurrentUser = () => React.useContext(UserContext)

export const REGISTRATION_COHORTS = {
  ONE: 1,
  TWO: 2,
  THREE: 3
}

/*
  We set what cohort a member should be in when registering below. This is set in the frontend and sent to the backend to
  be saved on the Employee record because these cohorts change based on code release. And we toggle features based on
  what cohort the member registers in. This is currently only used for Carrot Plans.
*/
export const currentlySetRegistrationCohort = REGISTRATION_COHORTS.THREE

// @ts-expect-error TS7031
export function UserContextProvider({ children }): JSX.Element {
  const dispatch = useDispatch()
  // We don't want preferredLocale to be used, so we remove it from the userInfo object
  const userInfo: Omit<Partial<UserInfo>, "preferredLocale"> = removePropertyFromObject(
    "preferredLocale",
    useSelector(getUserInfo)
  )
  const company = useSelector(getCompanyInfo)
  const benefitStarted = useSelector(getBenefitStarted)
  const ldClient = useLDClient()

  const loadLDFlagsForUser: () => Promise<boolean> = React.useCallback(async () => {
    if (ldClient && userInfo.email) {
      await ldClient.waitUntilReady()

      const ldUser: LaunchDarklyUser = {
        key: userInfo.email,
        email: userInfo.email,
        custom: {
          companyId: company.companyId,
          isPartner: false,
          isEnrolled: true,
          isEngaged: !userInfo.isInAppLockdown,
          registrationCohort: userInfo.registrationCohort
        }
      }

      if (company.parentCompanyId) {
        // @ts-expect-error TS7053
        ldUser.custom["parentCompanyId"] = company.parentCompanyId
      }

      await ldClient.identify(ldUser)
      return true
    }
    return false
  }, [
    ldClient,
    userInfo.email,
    userInfo.isInAppLockdown,
    company.companyId,
    company.parentCompanyId,
    userInfo.registrationCohort
  ])

  function refreshUserInfo() {
    return dispatch(fetchUserInfo())
  }

  const getFirstName = React.useCallback(
    (): string => userInfo?.preferredName || userInfo?.firstName,
    [userInfo?.firstName, userInfo?.preferredName]
  )

  function getRegistrationCohort(): Record<RegistrationCohortHelper, boolean> {
    const cohort = {
      showRegPostCarrotPlansSoftLaunchFeatures: false,
      showRegPreCarrotPlansRoutingFlowFeatures: false
    }

    if (userInfo.registrationCohort >= 1) {
      cohort.showRegPostCarrotPlansSoftLaunchFeatures = true
    }
    if (userInfo.registrationCohort < 2) {
      cohort.showRegPreCarrotPlansRoutingFlowFeatures = true
    }
    return cohort
  }

  return (
    <UserContext.Provider
      value={{
        refreshUserInfo,
        // @ts-expect-error TS7019
        updateUserInfo: (...args) => dispatch(updateUserInfo(...args)),
        company,
        benefitStarted,
        getFirstName,
        loadLDFlagsForUser,
        ...getRegistrationCohort(),
        ...userInfo
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
