import React from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { Box, Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { continueToRouting } from "./RegistrationComplete"

interface AccessProps {
  isCarrotLite: boolean
  journeyOptions: any
  onBack: () => void
  onComplete: () => void
}

interface ModularJourneyContentProps {
  journeyOptions: any
  isCarrotLite: boolean
}

export function Access({ isCarrotLite, journeyOptions, onBack, onComplete }: AccessProps): JSX.Element {
  useTrackView("AccessInformationalRegFlow", "Access screen reg flow")
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "Find a provider you can trust"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-searching"
        description={
          <Box>
            <ModularJourneyContent journeyOptions={journeyOptions} isCarrotLite={isCarrotLite} />
          </Box>
        }
        interactiveElements={
          <RegistrationButtonFooter
            onBack={onBack}
            getCarrotPlanButton={
              <Button onClick={continueToRouting} variant="text" color="secondary">
                <FormattedMessage defaultMessage="Get your Carrot Plan" />
              </Button>
            }
            continueButton={
              <ContinueButton onClick={onComplete}>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
          />
        }
      />
    </>
  )
}

// @ts-expect-error TS7031
function DefaultContent({ isCarrotLite }): JSX.Element {
  const theme = useTheme()
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography>
        {isCarrotLite ? (
          <FormattedMessage defaultMessage="Get help finding the best provider for your journey." />
        ) : (
          <FormattedMessage defaultMessage="Get help finding the best provider for your journey, or confirm your current provider’s eligibility." />
        )}
      </Typography>
      <Typography>
        <FormattedMessage defaultMessage="Plus, enjoy priority appointments and generous discounts at Carrot partner providers in select countries." />
      </Typography>
    </Stack>
  )
}

function ModularJourneyContent({ journeyOptions, isCarrotLite }: ModularJourneyContentProps): JSX.Element {
  // @ts-expect-error TS7006
  const journeys = journeyOptions.map((journey) => journey.value)
  const isOnlyMltEnabledForMember =
    journeyOptions.length === 3 && journeys.includes("MENOPAUSE") && journeys.includes("LOW_TESTOSTERONE")

  if (isOnlyMltEnabledForMember) {
    return (
      <Typography>
        <FormattedMessage defaultMessage="We’ll help you find providers who specialize in menopause and low testosterone, so you can get the right care when you need it." />
      </Typography>
    )
  }
  return <DefaultContent isCarrotLite={isCarrotLite} />
}
