import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Link, Typography } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"

export function NoResultMessage({ isCountryCodeNotMatching }: { isCountryCodeNotMatching: boolean }): JSX.Element {
  return !isCountryCodeNotMatching ? <UnmatchedGeoText /> : <NoResultsText />
}

function UnmatchedGeoText(): JSX.Element {
  const intl = useIntl()
  return (
    <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.lg)} width={363}>
      <Typography variant="body1" color="secondary">
        <FormattedMessage
          defaultMessage="We're adding new providers all the time, {br} so <Link>contact us</Link> and we'll help you connect to the provider you need."
          values={{
            br: <br />,
            Link: (textLinkContent) => (
              <Link
                component={ReactRouterLink}
                color="inherit"
                to="/talk-to-carrot/sendmessage/helpfindingprovider"
                title={intl.formatMessage({
                  defaultMessage: "Contact our Care Navigation team about finding a provider"
                })}
              >
                {textLinkContent}
              </Link>
            )
          }}
        />
      </Typography>
      <Typography variant="body1" color="secondary">
        <FormattedMessage defaultMessage="You may also want to try widening your search parameters." />
      </Typography>
    </Box>
  )
}

function NoResultsText(): JSX.Element {
  const intl = useIntl()
  return (
    <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.lg)} width={363}>
      <Typography variant="body1" color="secondary">
        <FormattedMessage
          defaultMessage="That's because you're searching outside of the country where you're employed. {br}{br} Cross-border care is complex, so <Link>get in touch</Link> and we'll help you connect to the provider you need."
          values={{
            br: <br />,
            Link: (chunks) => (
              <Link
                component={ReactRouterLink}
                color="inherit"
                to="/talk-to-carrot/sendmessage/helpfindingprovider"
                title={intl.formatMessage({
                  defaultMessage: "Contact our Care Navigation team about finding a provider"
                })}
              >
                {chunks}
              </Link>
            )
          }}
        />
      </Typography>
    </Box>
  )
}
