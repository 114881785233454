import { useMutation, UseMutationResult } from "@tanstack/react-query"
import ResponseError from "../../types/responseError"
import { adminCarrotClient } from "../../utils/CarrotClient"

export default function useUpdateMemberActionTracking(): UseMutationResult<void, ResponseError, string> {
  return useMutation<void, ResponseError, string>(
    (memberActionTaken: string) => adminCarrotClient.createMemberAction(memberActionTaken),
    {
      retry: false
    }
  )
}
