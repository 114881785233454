import {
  Box,
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link
} from "@carrotfertility/carotene-core"
import useCarrotPlanEmployeeSettings from "components/carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory } from "react-router"
import { useUserRole } from "../../../../partner-access/hooks/usePartnerAccess"
import { useStartRouting } from "../../../../carrot-plans/shared/useQueryStartRouting"
import { useStartRoutingInfo } from "../../../../carrot-plans/shared/useQueryRoutingInfo"
import { caseRateRenewalRoutingFlowUrl } from "../../../../carrot-plans/case-rate-renewals/workflow"
interface AppLockedDownModalProps {
  onClose: () => void
  open: boolean
  modalType: LockedDownModalType
}

export const enum LockedDownModalType {
  FinancialSupport,
  ProviderFinder
}

export function AppLockedDownModal({ onClose, open, modalType }: AppLockedDownModalProps) {
  const { appIsUnlockedAndPendingCarrotPlan, data: carrotPlanEmployeeSettings } = useCarrotPlanEmployeeSettings()
  const planIsOnTheWay = carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested || appIsUnlockedAndPendingCarrotPlan
  const intl = useIntl()
  const history = useHistory()
  const imageSrc = "/images/illo-underwater-desktop"
  const { startRouting } = useStartRouting(useStartRoutingInfo(), caseRateRenewalRoutingFlowUrl)
  const { isDoneForNow } = useCarrotPlanEmployeeSettings()
  const { isPartnerAccessingAccount } = useUserRole()

  async function goToRouting(): Promise<Promise<void> | void> {
    if (isDoneForNow && !isPartnerAccessingAccount) {
      await startRouting()
    } else {
      history.push("/carrot-plans/about-you")
    }
  }

  return (
    <Dialog
      aria-labelledby="lock-down-modal-title"
      aria-describedby="lock-down-modal-description"
      onClose={onClose}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton onClick={onClose} aria-label={intl.formatMessage({ defaultMessage: "close" })}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center" padding={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Box
          component="img"
          src={imageSrc}
          srcSet={`${imageSrc}-2x.png`}
          alt=""
          maxHeight={"237px"}
          maxWidth={"100%"}
        />
      </Box>
      <DialogTitle id="lock-down-modal-title">
        {modalType === LockedDownModalType.FinancialSupport && (
          <FormattedMessage defaultMessage="To access Financial support, get your Carrot Plan" />
        )}
        {modalType === LockedDownModalType.ProviderFinder && (
          <FormattedMessage defaultMessage="To access Provider finder, get your Carrot Plan" />
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="lock-down-modal-description">
          {planIsOnTheWay && (
            <>
              {modalType === LockedDownModalType.FinancialSupport && (
                <FormattedMessage
                  defaultMessage="You’ll have access to Financial support as soon as your Carrot Plan is ready. {br}{br} In the meantime, you can <link>explore your benefit guide</link>, where you’ll find information on your employer-provided funds and what care and services are covered."
                  values={{
                    br: <br />,
                    link: (linkContent) => (
                      <Link href={"/your-benefit-guide"} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              )}
              {modalType === LockedDownModalType.ProviderFinder && (
                <FormattedMessage
                  defaultMessage="You’ll have access to Provider finder as soon as your Carrot Plan is ready. {br}{br} In the meantime, you can <link>explore your benefit guide</link> to learn more about your benefit."
                  values={{
                    br: <br />,
                    link: (linkContent) => (
                      <Link href={"/your-benefit-guide"} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              )}
            </>
          )}
          {!planIsOnTheWay && (
            <>
              {modalType === LockedDownModalType.ProviderFinder && (
                <FormattedMessage defaultMessage="To search for providers, get a personalized Carrot Plan and unlock full access to your benefit. You’ll have access to Provider finder as soon as your Carrot Plan is ready. " />
              )}
              {modalType === LockedDownModalType.FinancialSupport && (
                <FormattedMessage defaultMessage="To access your employer-provided funds, get a personalized Carrot Plan and unlock full access to your benefit. You’ll have access to Financial support as soon as your Carrot Plan is ready. " />
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={onClose}>
          <FormattedMessage defaultMessage="Go back" />
        </Button>
        {!planIsOnTheWay && (
          <Button onClick={goToRouting}>
            <FormattedMessage defaultMessage="Get your Carrot Plan" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
