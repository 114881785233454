import { Icon, shadows, colors } from "@carrotfertility/carotene"
import React from "react"
import styled from "styled-components"

const StyledButton = styled.button`
  background: white;
  border: 2px solid transparent;
  border-radius: 2px;
  display: block;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  background-origin: border-box;
  box-shadow: ${shadows.light};
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 24px 10px;
`

const StyledIcon = styled(Icon)`
  ${StyledButton}:hover & {
    fill: ${colors.base.black80};
  }
`

export function RecenterButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <StyledButton onClick={onClick} id="provider-finder-recenter-map-button">
      <StyledIcon name="recenter" size="24px" color="black60" />
    </StyledButton>
  )
}
