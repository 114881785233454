import React, { FC, useRef } from "react"
import { Redirect, Route, useLocation } from "react-router-dom"
import Home from "components/views/home/Home"
import { Title } from "services/page-title/Title"
import Transactions from "components/views/reimbursements/Transactions"
import ResetPasswordContainer from "../../containers/login/ResetPasswordContainer"
import ResetPasswordSuccess from "../../../pages/reset-password-success"
import { TalkToCarrotRouter } from "../../talk-to-carrot/TalkToCarrotRouter"
import MerchantDeclineContainer from "../../containers/merchantdecline/MerchantDeclineContainer"
import { SupportCenterLoginContainer } from "../../containers/login/SupportCenterLoginContainer"
import { SupportCenterLogoutContainer } from "../../containers/logout/SupportCenterLogoutContainer"
import { ReimbursementsRouter } from "components/views/reimbursements/ReimbursementsRouter"
import { BenefitNotStartedContainer } from "components/views/modal/BenefitNotStarted"
import { PageNotFound } from "components/views/error/PageNotFound"
import { LibraryRouter } from "components/containers/library/LibraryRouter"
import ActivateContainer from "../../containers/activate/ActivateContainer"
import { YourBenefitGuide } from "../../../pages/your-benefit-guide"
import { DynamicContentToggles } from "components/dynamic-content/Toggles"
import {
  DBGRevamp2022Q4Redirects,
  Paths,
  ReadAndLearnGestationalSurrogacyRenameRedirects,
  ReadAndLearnPregnancyRenameRedirects,
  DBGJourneyModularityPhase3Redirects
} from "../../../utils/Paths"
import { CarrotPlansRouter } from "components/carrot-plans/CarrotPlansRouter"
import LockedDownRoute from "./LockedDownRoute"
import { LockedDownGetHelpPage } from "../../talk-to-carrot/TalkToCarrotLockedDownGetHelp"
import InsuranceDetailsPage from "pages/insurance-details"
import CarrotPlanRequiredRedirect from "../../../pages/carrot-plan-required-redirect"
import { getBenefitStarted } from "derivedSelectors"
import { useSelector } from "react-redux"
import { TalkToCarrotHelpCenter } from "../../talk-to-carrot/TalkToCarrotHelpCenter"
import { ProviderFinderAdapter } from "./domainIsolation/ProviderFinderAdapter"
import { SendMessagePage } from "pages/talk-to-carrot/send-a-message"
import MessagesContainer from "../../../pages/in-app-messaging"
import VerifyPersonalEmail from "../../EmailVerification/VerifyPersonalEmail"
import TokenExpired from "../../EmailVerification/TokenExpired"
import WrongPersonalEmail from "../../EmailVerification/WrongPersonalEmail"
import NoPersonalEmail from "../../EmailVerification/NoPersonalEmail"
import { ImmersiveGuide } from "pages/guides/ImmersiveGuide"
import { MessagingEmailVerificationModal } from "../../EmailVerification/MessagingEmailVerificationModal"
import Settings from "../../../utils/CarrotConfig"
import BankDetailsPage from "pages/bank-details/BankDetailsPage"
import UpdateRoutingFlow from "../../carrot-plans/shared/UpdateRoutingFlow"
import MedicalQuestionsMenuItemRoutingFlow from "components/carrot-plans/steps/medical-questions/MedicalQuestionsMenuItemRoutingFlow"
import { GatedExitRoutingFlowWarning } from "components/carrot-plans/case-rate-renewals/GatedExitRoutingFlowWarning"
import { CarrotToaster } from "components/toasts/CarrotToaster"
import { AccountDeletionPage } from "components/account-deletion"
import { isMobileApp, setMobileNativeUserInfo } from "lib/carrot-api/MobileNativeClient"
import { InboundMemberPaymentsAdapter } from "pages/inbound-member-payments/InboundMemberPaymentsAdapter"
import { SettingsContainer } from "../../../pages/account"
import { trackMatomoPageView } from "utils/matomo"
import { AppLoginModalContainer } from "../modal/AppLoginModalContainer"
import { NavLayout } from "./navigation/NavLayout"
import { PhoneSupportRouter } from "./PhoneSupportRouter"
import { Switch } from "../../../services/routing/Switch"
import { DigitalFrontDoorPage } from "pages/carrot-rx/DigitalFrontDoorPage"
import { CarrotPartnerBooking } from "pages/provider-finder/components/results/BookingExperience/CarrotPartnerBooking"
import { DoulaAttestationLandingPage } from "../../doula-attestation/DoulaAttestationLandingPage"
import { useCurrentUser } from "components/context/user/UserContext"
import { MobileAppModalContainer } from "../modal/MobileAppModalContainer"
import {
  ArtMedicalRecordsConsentPage,
  PregnancyMedicalRecordsConsentPage
} from "components/medical-records-consent/MedicalRecordsConsentPage"

function useMatomoLocationTracking(): void {
  const { pathname, search, hash } = useLocation()

  React.useEffect(() => {
    trackMatomoPageView(`${pathname}${search}${hash}`)
  }, [pathname, search, hash])
}

function useAnchorLinkScrollFix(): void {
  const { pathname, hash, key } = useLocation()
  const priorPathname = useRef(null)
  React.useEffect(() => {
    // Scrolling to anchor links is apparently not supported in react-router
    // Here we can scroll to ID'd elements that match our hash (Ex. #bankdetails will scroll to the element with id: bankdetails)
    // Fix lifted from: https://github.com/remix-run/react-router/issues/394#issuecomment-220221604
    if (hash !== "") {
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          const id = hash.replace("#", "")
          const element = document.getElementById(id)
          if (element) {
            element.scrollIntoView()
          }
        }, 0)
      })
    } else if (priorPathname.current !== pathname) {
      //Scrolls the page to the top only if the pathname changes
      window.scrollTo(0, 0)
    }
    priorPathname.current = pathname
  }, [pathname, hash, key, priorPathname])
}

function useRedirectOnBenefitNotStarted(): boolean {
  const benefitStarted = useSelector(getBenefitStarted)
  const location = useLocation()
  const onHomePage = React.useMemo(() => location.pathname === "/", [location.pathname])
  const shouldRedirect = React.useMemo(() => !onHomePage && !benefitStarted, [benefitStarted, onHomePage])
  return shouldRedirect
}

function useSetMobileNativeUserInfo() {
  const { email, employeeId } = useCurrentUser()
  if (isMobileApp()) {
    setMobileNativeUserInfo(email, employeeId)
  }
}

const App: FC = () => {
  const shouldRedirect = useRedirectOnBenefitNotStarted()

  useMatomoLocationTracking()
  useAnchorLinkScrollFix()
  useSetMobileNativeUserInfo()
  const location = useLocation<{ showVerifyEmailModal: boolean }>()
  return (
    <Switch allowedRouters={[LockedDownRoute]}>
      {/* Routes here are for authenticated pages that do not have side navigation */}
      {shouldRedirect && <Redirect to="/" />}
      {/* We ran into an issue where the type definition doesn't match the version of react-router-dom we're using. */}
      {}
      {/* @ts-ignore */}
      <Route from="/reset-password/:guid" component={ResetPasswordContainer} />
      {}
      {/* @ts-ignore */}
      <Route from="/reset-password-success" component={ResetPasswordSuccess} />
      <Route exact path="/carrot-plans/case-rate-renewal/routing" component={UpdateRoutingFlow} />
      <Route exact path="/carrot-plans/journey-change/routing" component={UpdateRoutingFlow} />
      <Route exact path="/medical-questions/:journey" component={MedicalQuestionsMenuItemRoutingFlow} />
      <Route exact path="/refresh-carrot-plan-leave-warning" component={GatedExitRoutingFlowWarning} />
      <Route path="/carrot-plans" component={CarrotPlansRouter} />
      <Route exact path="/token-expired" component={TokenExpired} />
      <Route exact path="/wrong-personal-email" component={WrongPersonalEmail} />
      <Route exact path="/no-personal-email" component={NoPersonalEmail} />
      <Route path="/services/login" component={SupportCenterLoginContainer} />
      <Route path="/services/logout" component={SupportCenterLogoutContainer} />
      <Route path="/confirm/:token" component={MerchantDeclineContainer} />
      <LockedDownRoute path={["/read/:category/guide/:guide/:section"]} component={ImmersiveGuide} />
      <Route exact path={"/carrot-rx"} component={DigitalFrontDoorPage} />
      <LockedDownRoute exact path="/art-records-consent" component={ArtMedicalRecordsConsentPage} />
      <LockedDownRoute exact path="/pregnancy-records-consent" component={PregnancyMedicalRecordsConsentPage} />

      <Route
        render={() => (
          <>
            <NavLayout>
              <Title />
              <DynamicContentToggles />
              <BenefitNotStartedContainer />
              <AppLoginModalContainer />
              {location.state?.showVerifyEmailModal && <MessagingEmailVerificationModal />}
              <CarrotToaster />
              {isMobileApp() && <MobileAppModalContainer />}
              <Switch allowedRouters={[LockedDownRoute, PhoneSupportRouter]}>
                <Route exact path="/" component={Home} />
                <Route exact path="/doula-attestation" component={DoulaAttestationLandingPage} />
                {DBGRevamp2022Q4Redirects.map(({ from, to }) => (
                  <Redirect key={from} {...{ from, to }} />
                ))}
                {ReadAndLearnPregnancyRenameRedirects.map(({ from, to }) => (
                  <Redirect key={from} {...{ from, to }} />
                ))}
                {ReadAndLearnGestationalSurrogacyRenameRedirects.map(({ from, to }) => (
                  <Redirect key={from} {...{ from, to }} />
                ))}
                {DBGJourneyModularityPhase3Redirects.map(({ from, to }) => (
                  <Redirect key={from} {...{ from, to }} />
                ))}
                {/* Routes here are for authenticated pages with side navigation */}
                <Route exact path="/insurance-details" component={InsuranceDetailsPage} />
                <Route exact path="/talk-to-carrot/get-your-carrot-plan" component={TalkToCarrotRouter} />
                <Route path="/help-center/contact-care-team" component={TalkToCarrotHelpCenter} />
                <Redirect from="/help-center/send-message" to="/send-a-message" />
                <Route path="/send-a-message" component={SendMessagePage} />

                <Route path="/get-help" component={LockedDownGetHelpPage} />
                <LockedDownRoute path="/ava-bracelet" component={TalkToCarrotRouter} />
                <Redirect from="/journeywelcome" to="/" />
                <Redirect from="/journey" to="/" />
                <Redirect from="/journey/article/:id" to="/" />
                <LockedDownRoute path="/transactions" component={Transactions} />
                <LockedDownRoute path="/reimbursements" component={ReimbursementsRouter} />
                {isMobileApp() && <Route exact path="/account/delete" component={AccountDeletionPage} />}
                <Route exact path="/account" component={SettingsContainer} />
                <Route exact path="/bank-details" component={BankDetailsPage} />
                <Route path="/make-a-payment" component={InboundMemberPaymentsAdapter} />
                <Route exact path="/messages" component={MessagesContainer} />
                <Route exact path="/messages/:thread" component={MessagesContainer} />
                <Redirect from="/library" to="/read" />
                <Route path="/read" component={LibraryRouter} />
                <Redirect from="/about" to="/your-benefit-guide/about-your-benefit" />
                <Redirect from="/eligible-expenses" to="/your-benefit-guide/coverage" />
                <LockedDownRoute
                  exact
                  path="/providerfinder/requestappointment/:providerName"
                  component={CarrotPartnerBooking}
                />
                <LockedDownRoute path="/providerfinder" component={ProviderFinderAdapter} />
                <Route path={Paths.YOUR_BENEFIT_GUIDE} component={YourBenefitGuide} />
                <Route exact path="/verify-personal-email/:token" component={VerifyPersonalEmail} />
                <Redirect exact path="/register/:guid" to={"/"} />
                <Redirect exact path="/signin" to={"/"} />
                <Redirect exact path="/signup" to={"/"} />
                <Redirect exact path="/sign-up" to={"/"} />
                <Redirect exact path="/login" to={"/"} />
                <Redirect exact path="/request-reset-password" to={"/"} />
                <LockedDownRoute path="/activate" component={ActivateContainer} />
                <Route path="/carrot-plan-required" component={CarrotPlanRequiredRedirect} />
                <PhoneSupportRouter path="/chat" component={TalkToCarrotRouter} />
                <PhoneSupportRouter path={Paths.TALK_TO_CARROT} component={TalkToCarrotRouter} />
                <Route
                  path="/support"
                  // @ts-expect-error TS7011
                  component={() => {
                    window.location.replace(Settings.ZENDESK_GUIDE)
                    return null
                  }}
                />

                {/* 404 catch all */}
                <Route component={PageNotFound} />
              </Switch>
            </NavLayout>
          </>
        )}
      />
    </Switch>
  )
}

export { App }
