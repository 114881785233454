import { ExpertBio, mapContentToExpertBio } from "components/read/shared/ExpertBioMapper"
import { Asset } from "contentful"
import { ICantHave, ICantHaveCountry, IGroupSession, IHasAny, IHasCountry, IMustHave } from "contentful-types"

type AttributeContentfulCondition = ICantHave | IHasAny | IMustHave
type CountryContentfulCondition = ICantHaveCountry | IHasCountry
type RawContentfulCondition = AttributeContentfulCondition | CountryContentfulCondition

export type File = {
  url: string
}
export type Thumbnail = {
  file: File
}

export type Group = {
  id: string
  thumbnail: Thumbnail
  title: string
  slug: string
  overview: string
  description: string
  topics: string
  conditions: RawContentfulCondition[]
  calendlyUrl: string
  moderators: ExpertBio[]
}
const mapContentToThumbnail = ({ fields }: Asset): Thumbnail => {
  if (!fields) {
    return null
  }
  const { file } = fields
  return {
    file
  }
}
export const mapContentToGroup = ({ fields, sys }: IGroupSession): Group => {
  if (!fields || !sys) {
    return null
  }
  const { slug, title, description, conditions, overview, topics, calendlyUrl, thumbnail, moderators } = fields
  const { id } = sys
  return {
    id,
    title,
    slug,
    description,
    calendlyUrl,
    conditions,
    overview,
    topics,
    thumbnail: thumbnail && mapContentToThumbnail(thumbnail),
    moderators: moderators?.map(mapContentToExpertBio)
  }
}
