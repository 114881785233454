import { useFlags } from "launchdarkly-react-client-sdk"
import { getBenefitConfiguration } from "reducers/benefitConfiguration"
import { useSelector } from "react-redux"
import { useQuery, useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import { reportErrorMessage } from "../../../utils/ErrorReporting"
import { carrotClient } from "../../../utils/CarrotClient"
import { PartnerInviteStatus, UserRole } from "../partnerAccessTypes"
import { getHeap } from "../../../utils/heap"
import { usePartnerRegistrationFlow } from "../partner-registration/PartnerRegistrationFlow"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { BenefitConfiguration } from "../../../types/benefitConfiguration"

export const PARTNER_INVITE_STATUS_CACHE_KEY = "getInviteStatus"
const USER_ROLE_CACHE_KEY = "getCurrentUserRole"
const REGISTERING_PARTNER_CACHE_KEY = "useGetRegisteringPartner"
const PARTNER_EMAIL_OPT_IN_CACHE_KEY = "getPartnerEmailOptIn"

export default function usePartnerAccess() {
  const { partnerAccessDarkRelease } = useFlags() //TODO: when we remove this, update PartnerAccessDecisionStep test
  const { featureConfig } = useSelector(getBenefitConfiguration)
  const isPartnerCredentialAccessEnabledAtCompanyLevel = featureConfig?.isPartnerCredentialAccessEnabled === true

  const showPartnerAccess = isPartnerCredentialAccessEnabledAtCompanyLevel && partnerAccessDarkRelease

  return {
    showPartnerAccess
  }
}

export function useUserRole() {
  // @ts-expect-error TS7006
  const useUserRoleQuery = (select) =>
    useQuery({
      queryKey: [USER_ROLE_CACHE_KEY],
      queryFn: () => carrotClient.getCurrentUserRole(),
      retry: buildCarrotApiRetryPolicy(3),
      staleTime: Infinity,
      select
    })

  const { data: isPartnerAccessingAccount, isLoading: isUserRoleLoading } = useUserRoleQuery(
    // @ts-expect-error TS7006
    (data) => data.role === UserRole.PARTNER
  )

  // @ts-expect-error TS7006
  const { data: partnerAccessId } = useUserRoleQuery((data) => data.partnerAccessId)

  return {
    isPartnerAccessingAccount,
    isUserRoleLoading,
    partnerAccessId
  }
}

export function useGetPartnerInviteStatus() {
  const { data: inviteData, isLoading: isInviteStatusLoading } = useQuery(
    [PARTNER_INVITE_STATUS_CACHE_KEY],
    () => carrotClient.getPartnerInviteStatus(),
    {
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Could not load partner invite status, error: ${error}`)
    }
  )

  const hasInviteBeenSent =
    inviteData?.status === PartnerInviteStatus.INVITED || inviteData?.status === PartnerInviteStatus.ACTIVE

  return {
    inviteData,
    isInviteStatusLoading,
    hasInviteBeenSent
  }
}

export function useResendInvite(): UseMutationResult {
  return useMutation(async () => carrotClient.resendPartnerInvite())
}

export function useSendInvite(): UseMutationResult {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (isConsentChecked) => carrotClient.sendPartnerInvite(isConsentChecked),
    onSuccess: () => {
      queryClient.setQueryData([PARTNER_INVITE_STATUS_CACHE_KEY], { status: PartnerInviteStatus.INVITED })
    }
  })
}

interface RegisteringPartner {
  firstName: string
  preferredName: string
  email: string
  companyName: string
  companyDisplayName: string
  companyId: number
  parentCompanyId: number
  benefitConfiguration: BenefitConfiguration
  isUsa: boolean
  countryCode: string
  isInitialCarrotPlanRoutingCompleted: boolean
  registrationCohort: number
}

export function useGetRegisteringPartner(guid: string) {
  const {
    data: registeringUser,
    error: getRegisteringPartnerError,
    isLoading: isRegisteringUserLoading
  } = useQuery([REGISTERING_PARTNER_CACHE_KEY], () => carrotClient.getRegisteringPartner(guid), {
    select: (data) => data as RegisteringPartner,
    retry: buildCarrotApiRetryPolicy(1),
    staleTime: 30000
  })
  return {
    registeringUser,
    getRegisteringPartnerError,
    isRegisteringUserLoading
  }
}

export function usePartnerRegFlowComplete(emailOptIn: boolean): UseMutationResult {
  const { guid, dataConsent, readTerms, readPolicy, password } = usePartnerRegistrationFlow()
  const { send } = useStateMachine(null)

  return useMutation(
    () => carrotClient.createPartnerAccount(guid, password, emailOptIn, readTerms, readPolicy, dataConsent),
    {
      retry: buildCarrotApiRetryPolicy(1),
      onSuccess: async () => {
        getHeap().track("PartnerRegistrationCompleted", {
          EventName: "PartnerRegistrationCompleted"
        })
        send("")
      },
      onError: () => {
        window.location.href = "/"
      }
    }
  )
}

export function useGetPartnerEmailOptIn() {
  const { data: partnerEmailOptIn, isLoading: isPartnerEmailOptInLoading } = useQuery(
    [PARTNER_EMAIL_OPT_IN_CACHE_KEY],
    () => carrotClient.getPartnerEmailOptIn(),
    {
      retry: buildCarrotApiRetryPolicy(3),
      select: (data) => data.partnerEmailOptIn
    }
  )

  return {
    partnerEmailOptIn,
    isPartnerEmailOptInLoading
  }
}

export function useUpdatePartnerEmailOptIn(): UseMutationResult {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (partnerEmailOptIn) => carrotClient.updatePartnerEmailOptIn(partnerEmailOptIn),
    onSuccess: () => {
      queryClient.fetchQuery([PARTNER_EMAIL_OPT_IN_CACHE_KEY])
    }
  })
}
