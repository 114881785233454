import { Asset } from "contentful"
import {
  ICantHave,
  ICantHaveCountry,
  IGuide,
  IGuidePage,
  IGuideSection,
  IHasAny,
  IHasCountry,
  IMustHave
} from "contentful-types"

type AttributeContentfulCondition = ICantHave | IHasAny | IMustHave
type CountryContentfulCondition = ICantHaveCountry | IHasCountry
type RawContentfulCondition = AttributeContentfulCondition | CountryContentfulCondition

export type Page = {
  name: string
  body: string
}
export type Section = {
  pages: Page[]
  title: string
  slug: string
}
export type File = {
  url: string
}
export type CompanionDocument = {
  file: File
}
export type Guide = {
  id: string
  title: string
  slug: string
  companionDocument: CompanionDocument
  description: string
  audience: string
  overview: string
  afterGuideModal: string
  conditions: RawContentfulCondition[]
  sections: Section[]
}

const mapContentToPage = ({ fields }: IGuidePage): Page => {
  if (!fields) {
    return null
  }
  const { name, body } = fields
  return {
    name,
    body
  }
}
const mapContentToSection = ({ fields }: IGuideSection): Section => {
  if (!fields) {
    return null
  }
  const { title, pages, slug } = fields
  return {
    title,
    slug,
    pages: pages.map(mapContentToPage)
  }
}

const mapContentToCompanionDocument = (companionDocument: Asset): CompanionDocument => {
  if (!companionDocument || !companionDocument.fields) {
    return null
  }
  const { file } = companionDocument.fields
  return {
    file
  }
}

export const mapContentToGuide = ({ fields, sys }: IGuide): Guide => {
  if (!fields || !sys) {
    return null
  }
  const { sections, slug, title, description, audience, afterGuideModal, conditions, overview, companionDocument } =
    fields
  const { id } = sys
  return {
    id,
    title,
    slug,
    description,
    audience,
    afterGuideModal,
    conditions,
    overview,
    sections: sections.map(mapContentToSection),
    companionDocument: mapContentToCompanionDocument(companionDocument)
  }
}
