import {
  Box,
  Link,
  Stack,
  Alert,
  Typography,
  useTheme,
  DialogContentText,
  Button,
  FormControl
} from "@carrotfertility/carotene-core"
import {
  Form,
  FormAutocomplete,
  FormButton,
  FormSelect,
  FormOnSubmitHandler,
  InfoDialog
} from "@carrotfertility/carotene-core-x"
import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { FormTextFieldWithCharacterCount } from "services/common-forms/FormTextFieldWithCharacterCount"
import useSubmitProviderRequest from "pages/provider-finder/hooks/useSubmitProviderRequest"
import { Helmet } from "react-helmet"
import { RequestedProviderType, GenderIdentity } from "pages/provider-finder/types/ProviderRequest"
import { GenderIdentityFormSelect } from "./GenderIdentityFormSelect"
import { Redirect, useHistory } from "react-router-dom"
import healthInsuranceOptions from "./health-insurance-options.json"
import useShowProviderRecommendations from "../../hooks/useShowProviderRecommendations"
import { Loading } from "../Loading"
import { providerFinderRootPath } from "../../utils/providerFinderSearchUrl"
import { Controller, useFormContext } from "react-hook-form"
import { LocationPicker } from "../location-picker/LocationPicker"
import { renderLabelWithPlaceholder } from "pages/provider-finder/utils/renderLabelUtil"

type HealthInsuranceAutocompleteOption = {
  label: string
  value: string
}

type GarnerHealthProviderRequestFormValues = {
  providerType: RequestedProviderType
  healthInsurance: HealthInsuranceAutocompleteOption
  language: string
  location: string
  genderIdentity: GenderIdentity
  genderIdentityOther: string
  additionalDetails: string
}

type FormLocationPickerProps = {
  name: string
  label: string
  setLocationPickerValue: (input: string) => void
}

function FormLocationPicker({ name, label, setLocationPickerValue }: FormLocationPickerProps): JSX.Element {
  const {
    formState: { errors, isSubmitting },
    control
  } = useFormContext()

  const hasError = Boolean(errors[name])

  return (
    <FormControl error={hasError}>
      <Controller
        {...{ control, name }}
        rules={{ required: "Required" }}
        render={({ field }) => (
          <LocationPicker
            label={label}
            setLocationPickerValue={setLocationPickerValue}
            hasError={hasError}
            helperText={hasError ? (errors[name]?.message as string) : null}
            isDisabled={isSubmitting}
            isRequired
            onChange={(_, option) => field.onChange(option)}
          />
        )}
      />
    </FormControl>
  )
}

function getLanguageOptionLabel(locale: string): string {
  return new Intl.DisplayNames([locale], { type: "language" }).of(locale)
}

export function GarnerHealthProviderRequestForm({
  providerRequested
}: {
  providerRequested: RequestedProviderType
}): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()
  const [locationPickerValue, setLocationPickerValue] = useState("")
  const { mutateAsync, isError, isSuccess } = useSubmitProviderRequest()
  const { data: providerRecommendationsConfig, isLoading: isProviderRecommendationsLoading } =
    useShowProviderRecommendations()

  if (isProviderRecommendationsLoading) {
    return <Loading />
  }

  const isRequestingPediatrician = providerRequested === "Pediatrician"
  const isRequestObGyn = providerRequested === "OBGYN"

  if (
    (isRequestingPediatrician && !providerRecommendationsConfig.showPediatricianRecommendationsForm) ||
    (isRequestObGyn && !providerRecommendationsConfig.showObGynRecommendationsForm)
  ) {
    return <Redirect to={providerFinderRootPath} />
  }

  const pageTitle = isRequestingPediatrician
    ? intl.formatMessage({ defaultMessage: "Find a trusted pediatrician | Talk to Carrot" })
    : intl.formatMessage({ defaultMessage: "Find a trusted OB/GYN | Talk to Carrot" })

  const pageHeading = isRequestingPediatrician
    ? intl.formatMessage({ defaultMessage: "Find a trusted pediatrician" })
    : intl.formatMessage({ defaultMessage: "Find a trusted OB/GYN" })

  const pageDescription = isRequestingPediatrician
    ? intl.formatMessage({
        defaultMessage:
          "Please provide the information below to receive personalized pediatrician recommendations within 24-48 hours."
      })
    : intl.formatMessage({
        defaultMessage:
          "Please provide the information below to receive personalized OB/GYN recommendations within 24-48 hours."
      })
  const additionalDetailsLabel = isRequestingPediatrician
    ? intl.formatMessage({ defaultMessage: "Additional Preferences for a Pediatrician" })
    : intl.formatMessage({ defaultMessage: "Additional Preferences for an OB/GYN" })
  const additionalDetailsPlaceholder = isRequestingPediatrician
    ? intl.formatMessage({
        defaultMessage: "Specialized in genetic disorders, developmental delays, etc."
      })
    : intl.formatMessage({ defaultMessage: "Specialized in high risk pregnancies, delivering twins, etc." })

  const onSubmitHandler: FormOnSubmitHandler<GarnerHealthProviderRequestFormValues> = async (formFields) => {
    const requestBody = {
      providerType: providerRequested,
      healthInsurance: formFields.healthInsurance.value,
      location: locationPickerValue,
      language: formFields.language,
      genderIdentity: formFields.genderIdentity,
      genderIdentityOther: formFields.genderIdentityOther,
      additionalDetails: formFields.additionalDetails
    }
    try {
      await mutateAsync(requestBody)
    } catch {
      // https://github.com/react-hook-form/react-hook-form/issues/9821
      // ignored - we use the isError state from useSubmitProviderRequest() to handle errors
    }
  }

  const onClose = () => {
    history.push("/")
  }

  function buildLanguageOptions(): { label: string; value: string }[] {
    const optionsWithoutEnglishAndNoPreference = [
      { label: getLanguageOptionLabel("ar"), value: "Arabic" },
      { label: getLanguageOptionLabel("zh"), value: "Chinese" },
      { label: getLanguageOptionLabel("fil"), value: "Filipino" },
      { label: getLanguageOptionLabel("fr"), value: "French" },
      { label: getLanguageOptionLabel("de"), value: "German" },
      { label: getLanguageOptionLabel("hi"), value: "Hindi" },
      { label: getLanguageOptionLabel("ko"), value: "Korean" },
      { label: getLanguageOptionLabel("ru"), value: "Russian" },
      { label: getLanguageOptionLabel("es"), value: "Spanish" },
      { label: getLanguageOptionLabel("vi"), value: "Vietnamese" }
    ]
    const sortedOptions = optionsWithoutEnglishAndNoPreference.sort((a, b) => a.label.localeCompare(b.label))

    return [
      { label: "English", value: "English" },
      ...sortedOptions,
      { label: intl.formatMessage({ defaultMessage: "No preference" }), value: "No preference" }
    ]
  }
  const languageOptions = buildLanguageOptions()

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Stack direction="column" spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Typography variant="h2">{pageHeading}</Typography>
        <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
          <Typography>{pageDescription}</Typography>
        </Box>
        {isError && (
          <Alert severity="error">
            <FormattedMessage
              defaultMessage="Something went wrong on our end. Please try again. If the problem persists, <link>contact us.</link>"
              values={{
                link: (linkText) => (
                  <Link color="inherit" target="_blank" href={"/talk-to-carrot/care-navigation"}>
                    {linkText}
                  </Link>
                )
              }}
            />
          </Alert>
        )}
        <Form onSubmit={onSubmitHandler}>
          <Stack direction="column" spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <FormAutocomplete
              name="healthInsurance"
              label={intl.formatMessage({ defaultMessage: "Health Insurance" })}
              controllerProps={{ rules: { required: "Required" } }}
              placeholder={intl.formatMessage({ defaultMessage: "Select option" })}
              options={healthInsuranceOptions
                .map((name) => ({
                  label: name,
                  value: name
                }))
                .sort((a, b) => a.label.localeCompare(b.label))}
              helperText={intl.formatMessage(
                {
                  defaultMessage: "If you don't see your insurance provider listed, please  <link>contact us</link>"
                },
                {
                  link: (linkContent) => (
                    <Link
                      id="provider-recommendations-insurance-not-listed-contact-link"
                      variant="inherit"
                      color="inherit"
                      target="_blank"
                      href="/talk-to-carrot/care-navigation"
                    >
                      {linkContent}
                    </Link>
                  )
                }
              )}
            />
            <FormLocationPicker
              name="locationPicker"
              label={intl.formatMessage({ defaultMessage: "Preferred Location" })}
              setLocationPickerValue={setLocationPickerValue}
            />
            <FormSelect
              name="language"
              label={intl.formatMessage({ defaultMessage: "Language" })}
              renderValue={renderLabelWithPlaceholder(
                languageOptions,
                intl.formatMessage({ defaultMessage: "Select option" })
              )}
              options={languageOptions}
            />
            <GenderIdentityFormSelect />
            <FormTextFieldWithCharacterCount
              maxLength={1600}
              rows={4}
              label={additionalDetailsLabel}
              placeholder={additionalDetailsPlaceholder}
              name="additionalDetails"
            />
            <Box
              display="flex"
              flexDirection="row-reverse"
              marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
            >
              <FormButton type="submit">
                <FormattedMessage defaultMessage="Submit request" />
              </FormButton>
            </Box>
          </Stack>
        </Form>
        <InfoDialog
          title={intl.formatMessage({ defaultMessage: "Request submitted" })}
          open={isSuccess}
          primaryAction={
            <Button onClick={onClose}>
              <FormattedMessage defaultMessage="Close" />
            </Button>
          }
        >
          <DialogContentText>
            <FormattedMessage defaultMessage="We'll send you a message with your recommendations within one business day." />
          </DialogContentText>
        </InfoDialog>
      </Stack>
    </>
  )
}
