import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { getContentfulClient } from "../../../lib/contentful/contentfulClient"
import { ICategory } from "../../../contentful-types"
import { getHeap } from "../../../utils/heap"
import now from "performance-now"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import { useIntl } from "react-intl"
import { getContentfulLocale } from "../../../lib/contentful/utils/contenfulLocaleUtils"

const client = getContentfulClient()
const ENDPOINT = "GetCategories"

const loadCategories = async (locale: string): Promise<ICategory[]> => {
  const beforeFetch = now()

  const { items } = await client.getEntries({
    content_type: "category",
    include: 10,
    locale: getContentfulLocale(locale)
  })

  const afterFetch = now()

  getHeap().track("Contentful Category Load Time", {
    "Library Categories Load Time In Milliseconds": afterFetch - beforeFetch
  })

  return items as ICategory[]
}

export const getCategoriesCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useGetCategories(): UseQueryResult<ICategory[], ResponseError> {
  const intl = useIntl()
  const { locale } = intl

  return useQuery<ICategory[], ResponseError>(getCategoriesCacheKey(), () => loadCategories(locale), {
    refetchOnWindowFocus: false,
    retry: buildCarrotApiRetryPolicy(3)
  })
}
