import React, { useEffect, useState } from "react"
import { FlexContainer } from "@carrotfertility/carotene"
import { Typography } from "@carrotfertility/carotene-core"
import { CenteredLoadingIndicator } from "components/views/molecules/Molecules"
import { useStateMachine } from "components/context/stateMachine/StateMachineContext"
import { Steps } from "components/cmd-enrollment/workflow/steps"
import { EnrollmentType, useEnrollmentFlowModal } from "components/cmd-enrollment/EnrollmentModal"
import { adminCarrotClient } from "utils/CarrotClient"
import { useBenefitEnrollment } from "components/context/enrollment/BenefitEnrollmentContext"
import { useUserDeductible } from "components/context/user/DeductibleContext"
import { FormattedMessage } from "react-intl"

export default function ProcessEnrollmentTypeStep(): JSX.Element {
  const viewName = Steps.PROCESS_ENROLLMENT_TYPE
  const { needsBenefitEnrollment } = useBenefitEnrollment()
  const { refreshUserBenefitEnrollment } = useUserDeductible()
  const { enrollmentType } = useEnrollmentFlowModal()

  const { setNextStep } = useStateMachine(viewName, null)
  const [loading, setLoading] = useState(true)

  const resetBenefitEnrollment = async (): Promise<void> => {
    if (!needsBenefitEnrollment) {
      await adminCarrotClient.triggerQleEnrollment()
      await refreshUserBenefitEnrollment()
    }
  }

  const resetInsuranceEnrollment = async (): Promise<void> => {
    await adminCarrotClient.triggerInsuranceEdit()
    await refreshUserBenefitEnrollment()
  }

  useEffect(() => {
    const execute = async (): Promise<void> => {
      switch (enrollmentType) {
        case EnrollmentType.INSURANCE_EDIT:
          await resetInsuranceEnrollment()
          break
        case EnrollmentType.QLE_RE_ENROLLMENT:
          await resetBenefitEnrollment()
          break
        default:
          break
      }
      setLoading(false)
    }
    execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading) {
      setNextStep(viewName, "")
    }
  }, [loading, setNextStep, viewName])

  return (
    <FlexContainer alignItems="center" direction="column" paddingTopBottom="large">
      <CenteredLoadingIndicator />
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.lg}
      >
        <FormattedMessage defaultMessage="We're working on your request." />
      </Typography>
    </FlexContainer>
  )
}
