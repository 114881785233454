import { Benefit } from "../types/Benefit"
import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { useCarrotBenefitClient } from "./useCarrotBenefitClient"

const ENDPOINT = "GetBenefit"

export const getBenefitCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useBenefit(): UseQueryResult<Benefit, ResponseError> {
  const client = useCarrotBenefitClient()
  const benefitCall = async (): Promise<Benefit> => {
    const benefit = await client.getBenefit()
    return benefit
  }
  return useQuery<Benefit, ResponseError>(getBenefitCacheKey(), benefitCall)
}

export function useCreateOverride(): (attributes: Benefit) => void {
  const queryClient = useQueryClient()
  return (attributes: Benefit) => {
    queryClient.setQueryDefaults(getBenefitCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getBenefitCacheKey(), attributes)
  }
}
