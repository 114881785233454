import actionTypes from "actions/actionTypes"

// @ts-expect-error TS7006
const updatehasMetSignatureRequirementInMemory = () => async (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_HAS_MET_SIGNATURE_REQUIREMENT_IN_MEMORY,
    response: {
      hasMetSignatureRequirement: true
    }
  })
}

export { updatehasMetSignatureRequirementInMemory }
