import React, { useMemo } from "react"
import slugify from "slugify"
import { useHistory, useParams } from "react-router-dom"

import { Container, SimpleGrid } from "@carrotfertility/carotene"
import ArticleThumbnail from "./ArticleThumbnail"
import { ICategory, ISubCategory } from "../../contentful-types"
import { useContentfulConditionalGate } from "lib/contentful/components/ContentfulConditionGate"
import { useWindowEvent } from "../../utils/hooks/useWindowEvent"
import { ReadAndLearnBreadcrumbs } from "./shared/ReadAndLearnBreadcrumbs"
import useGetCategories from "./shared/useGetCategories"
import { Typography } from "@carrotfertility/carotene-core"
import { Title } from "services/page-title/Title"
import { PageLayout } from "features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "features/global-ui/page-header"

// @ts-expect-error TS7006
function findSubCategoryBySlug(categories, categorySlug, subCategorySlug): SubCategoryBreadcrumbsProps {
  // @ts-expect-error TS7006
  const category = categories.find((c) => slugify(c.fields?.navTitle) === categorySlug)
  // @ts-expect-error TS7006
  const subCategory = category?.fields?.subCategories?.find((c) => slugify(c.fields?.navTitle) === subCategorySlug)
  return { category, subCategory }
}

type SubCategoryBreadcrumbsProps = {
  category: ICategory
  subCategory: ISubCategory
}

function SubcategoryPageContent(): JSX.Element {
  const [windowWidth, setWindowWidth] = React.useState({
    width: window.innerWidth
  })

  const handleResize = (): void => {
    setWindowWidth({
      width: window.innerWidth
    })
  }

  useWindowEvent("resize", handleResize)

  const params = useParams<{ category: string; subCategory: string }>()
  const { data: categories } = useGetCategories()
  const { category, subCategory } = findSubCategoryBySlug(categories, params.category, params.subCategory)

  const subCategoryAndConditions = useMemo(() => {
    return subCategory?.fields?.articles
      ?.map((article) => ({
        element: (
          <ArticleThumbnail
            key={article?.sys?.id}
            contentType={article?.sys?.contentType?.sys?.id}
            article={article}
            category={category}
          />
        ),
        conditions: article?.fields?.conditions
      }))
      .filter((article) => article !== null)
  }, [subCategory?.fields?.articles, category])

  const filteredArticles = useContentfulConditionalGate(subCategoryAndConditions)

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: (theme) => theme.spacing(theme.tokens.spacing.xxxl) }}>
        {subCategory?.fields?.title}
      </Typography>
      <Container>
        <SimpleGrid columns={[1, 1, 1, windowWidth.width < 1200 ? 2 : 3]} spacing={"32px"}>
          {filteredArticles}
        </SimpleGrid>
      </Container>
    </>
  )
}

export default function SubcategoryPage(): JSX.Element {
  const history = useHistory()
  const params = useParams<{ category: string; subCategory: string }>()
  const { data: categories } = useGetCategories()

  const { category, subCategory } = findSubCategoryBySlug(categories, params.category, params.subCategory)
  if (!subCategory || !category) {
    history.push("/read")
    return null
  }
  return (
    <PageLayout
      header={
        <PageBreadcrumbHeader breadcrumbs={<ReadAndLearnBreadcrumbs category={category} subcategory={subCategory} />} />
      }
    >
      <Title title={category.fields.title} productTitle="Read and learn" />
      <SubcategoryPageContent />
    </PageLayout>
  )
}
