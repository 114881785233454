/* eslint formatjs/no-literal-string-in-jsx: "error" */
import { FormattedMessage, useIntl } from "react-intl"
import { H2 } from "@carrotfertility/carotene"
import { PlanInfoBadgeContainer } from "../../../../containers/planinfo/PlanInfoBadgeContainer"
import { Spacer } from "../../../atoms/Atoms"
import React from "react"
import {
  getCompletedReimbursementsList,
  getPendingReimbursementsList,
  getReimbursementsRequiringActionList
} from "../ReimbursementListUtils"
import { Legend, ReimbursementsListSection, ReimbursementSummary, TableHeader } from "../SharedReimbursementElements"
import { useSelector } from "react-redux"
import { getCardState } from "../../../../../reducers/userInfo"
import CardStates from "../../../../../utils/CardStates"
import { ExpenseState } from "../../../../../utils/ExpenseState"
import { Badge, Box, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RetinaImage } from "../../../atoms/images/RetinaImage"

const EmptyReimbursementsList = () => {
  const isCardEligible = useSelector(getCardState) !== CardStates.INELIGIBLE
  return (
    <div>
      <Box paddingTop={(theme) => theme.tokens.spacing.md} />
      <table className="w-100 collapse">
        <thead>
          <tr>
            <TableHeader />
          </tr>
        </thead>
        <tbody>
          {/* This color should be specified using a carotene-core palette color, but that kind of requires refactoring
              this whole table into a carotene-core Table which is going to be nontrivial. See LG-1524. */}
          <tr className="bt bw1" style={{ borderColor: "#b9b9b9" }}>
            <td colSpan="3">
              <div className="w-100 flex justify-center">
                <div className="flex flex-column items-center" style={{ maxWidth: "360px" }}>
                  <Box
                    padding={(theme) => theme.tokens.spacing.md}
                    borderRadius={(theme) => theme.tokens.borderRadius.round}
                    marginTop={(theme) => theme.tokens.spacing.xxl}
                    height="84px"
                    width="84px"
                    sx={(theme) => ({ backgroundColor: theme.palette.secondary.light })}
                  >
                    <RetinaImage name="icn-transaction" height="50px" width="50px" />
                  </Box>
                  <Typography
                    variant="body1"
                    color="tertiary"
                    align="center"
                    marginTop={(theme) => theme.tokens.spacing.lg}
                  >
                    {isCardEligible ? (
                      <FormattedMessage defaultMessage="Your Carrot Card® transactions and reimbursement requests will appear here." />
                    ) : (
                      <FormattedMessage defaultMessage="Your reimbursement requests will appear here." />
                    )}
                  </Typography>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const SingleCategoryReimbursementsList = ({ reimbursementsList, subsidy }) => {
  const intl = useIntl()
  const theme = useTheme()
  const reimbursementsRequiringAction = getReimbursementsRequiringActionList(reimbursementsList)
  const reimbursementsPending = getPendingReimbursementsList(reimbursementsList)
  const reimbursementsCompleted = getCompletedReimbursementsList(reimbursementsList)

  return (
    <>
      {subsidy.hasUnlimitedBenefit || (
        <>
          <Typography
            variant="h3"
            component="h2"
            color={theme.palette.text.primary}
            paddingBottom={(theme) => theme.tokens.spacing.md}
          >
            <FormattedMessage defaultMessage="Available funds" description="money available to be spent" />
          </Typography>
          <ReimbursementSummary
            subsidy={subsidy}
            reimbursementsCount={reimbursementsList ? reimbursementsList.length : 0}
            showRemainingAnnual={
              !subsidy.hasUnlimitedBenefit && subsidy.yearlyAmounts.max !== subsidy.lifetimeAmounts.max
            }
            showRemainingLifetime={!subsidy.hasUnlimitedBenefit && subsidy.lifetimeAmounts.max !== null}
            paddingTop={(theme) => theme.tokens.spacing.md}
            paddingBottom={(theme) => theme.tokens.spacing.md}
          />
          <PlanInfoBadgeContainer
            subsidy={subsidy}
            categorySupportsDeductibles={true}
            sx={(theme) => ({ paddingTop: theme.tokens.spacing.md })}
          />
          <Spacer height={3} />
        </>
      )}
      {reimbursementsList?.length > 0 ? (
        <>
          <H2 variant="primary">
            <FormattedMessage defaultMessage="Activity" description="things that have been done" />
          </H2>
          <Spacer height={0.5} />
          <Legend />
          <ReimbursementsListSection
            title={
              <Badge badgeContent={intl.formatNumber(reimbursementsRequiringAction.length)} color="error">
                <Typography variant="H5" component="H3" color={(theme) => theme.palette.text.secondary}>
                  <FormattedMessage defaultMessage="Needs Attention" />
                </Typography>
              </Badge>
            }
            emptyText={intl.formatMessage({ defaultMessage: "No transactions need attention" })}
            items={reimbursementsRequiringAction}
            showAlert={reimbursementsRequiringAction.find((e) => e.expenseState === ExpenseState.NEEDS_MEMBER_UPLOADS)}
          />
          <ReimbursementsListSection
            title={
              <Typography variant="H5" component="H3" color={(theme) => theme.palette.text.secondary}>
                <FormattedMessage defaultMessage="Pending" />
              </Typography>
            }
            emptyText={intl.formatMessage({ defaultMessage: "No pending transactions" })}
            items={reimbursementsPending}
          />
          <ReimbursementsListSection
            title={
              <Typography variant="H5" component="H3" color={(theme) => theme.palette.text.secondary}>
                <FormattedMessage defaultMessage="Complete" />
              </Typography>
            }
            emptyText={intl.formatMessage({ defaultMessage: "No completed transactions" })}
            items={reimbursementsCompleted}
          />
        </>
      ) : (
        <EmptyReimbursementsList />
      )}
    </>
  )
}
