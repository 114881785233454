import React from "react"
import { CircleImage } from "../CircleImage"
import { SupportCenterLinks } from "../../../../utils/SupportCenterLinks"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Link, Typography } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"
import { getSupportCenterLink, goToHelpCenter } from "carrot-api/HelpCenterClient"

export function SidebarHelp(): JSX.Element {
  const intl = useIntl()
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      sx={(theme) => ({
        backgroundColor: theme.palette.success.light,
        borderRadius: theme.tokens.borderRadius.md
      })}
    >
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
        <CircleImage iconName="icn-help" alt="" />
        <Typography variant="body1" color="secondary" fontWeight="bold">
          <FormattedMessage defaultMessage="Can we help?" />
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.sm)}>
        <Link
          to="/talk-to-carrot/sendmessage/helpfindingprovider"
          color="inherit"
          variant="body2"
          component={ReactRouterLink}
          title={intl.formatMessage({ defaultMessage: "Contact our Care Navigation team about finding a provider" })}
        >
          <FormattedMessage defaultMessage="Contact our Care Navigation team" />
        </Link>
        <Link
          color="inherit"
          variant="body2"
          href={getSupportCenterLink(SupportCenterLinks.CARROT_PROVIDERS)}
          onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
            goToHelpCenter(event, SupportCenterLinks.CARROT_PROVIDERS)
          }
          target={"_blank"}
          title={intl.formatMessage({ defaultMessage: "Learn more about Carrot providers" })}
        >
          <FormattedMessage defaultMessage="Learn more" />
        </Link>
      </Box>
    </Box>
  )
}
