import { Box, Button, LeftIcon, RightIcon, Stack, useTheme } from "@carrotfertility/carotene-core"
import { FormButton, useFormContext, useFormState } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "components/context/stateMachine/StateMachineV2"
import React, { ReactNode, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useRoutingFlow } from "./RoutingFlowContainer"

// @ts-expect-error TS7031
export function SkipButton({ onClick }) {
  const { handleSubmit } = useFormContext()

  return (
    <FormButton onClick={handleSubmit(onClick)} color="secondary" variant="text">
      <FormattedMessage defaultMessage="Skip" />
    </FormButton>
  )
}

export function ContinueButton({ children }: { children: ReactNode | ReactNode[] }): JSX.Element {
  return (
    <FormButton type="submit" color="secondary" variant="outlined" endIcon={<RightIcon />}>
      {children}
    </FormButton>
  )
}

export function ConfirmButton({ children }: { children: ReactNode | ReactNode[] }): JSX.Element {
  return <FormButton type="submit">{children}</FormButton>
}

export function ButtonFooterLayout({
  continueButton,
  skipButton
}: {
  continueButton: ReactNode
  skipButton?: ReactNode
}): JSX.Element {
  const theme = useTheme()
  const { setDisableButtons } = useRoutingFlow()
  const { back, canGoBack } = useStateMachine()
  const { isSubmitting } = useFormState()

  useEffect(() => {
    // This is the only place we can do this for all steps
    // in order to tell buttons outside of the top level <Form>
    // that we're submitting
    setDisableButtons(isSubmitting)
  }, [isSubmitting, setDisableButtons])

  return (
    <Box
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}
      display="flex"
      justifyContent={canGoBack ? "space-between" : "flex-end"}
    >
      {canGoBack ? (
        <Button disabled={isSubmitting} onClick={back} variant="text" color="secondary" startIcon={<LeftIcon />}>
          <FormattedMessage defaultMessage="Back" />
        </Button>
      ) : null}
      <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.sm)}>
        {skipButton}
        {continueButton}
      </Stack>
    </Box>
  )
}
