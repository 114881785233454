import { ICantHave, IHasAny, IMustHave, ICantHaveCountry, IHasCountry, IAttribute, ICountry } from "contentful-types"
import { AttributeConditionType, Condition, CountryConditionType } from "services/contentful/conditional-gate"

export type AttributeContentfulCondition = ICantHave | IHasAny | IMustHave
export type CountryContentfulCondition = ICantHaveCountry | IHasCountry
export type RawContentfulCondition = AttributeContentfulCondition | CountryContentfulCondition

export function toCondition(condition: RawContentfulCondition): Condition {
  const conditionType = condition.sys.contentType.sys.id as string

  if (Object.values(AttributeConditionType).includes(conditionType as AttributeConditionType)) {
    const attributes = toAttributes(condition as AttributeContentfulCondition)
    return { attributes, conditionType: conditionType as AttributeConditionType }
  } else if (Object.values(CountryConditionType).includes(conditionType as CountryConditionType)) {
    const attributes = toCountries(condition as CountryContentfulCondition)
    return { attributes, conditionType: conditionType as CountryConditionType }
  }

  return null
}

function toAttributes(condition: AttributeContentfulCondition): Array<string> {
  const conditionAttributes = condition.fields?.attributes || []
  return conditionAttributes.filter(({ fields }: IAttribute) => fields).map(({ fields: { key } }: IAttribute) => key)
}

function toCountries(condition: CountryContentfulCondition): Array<string> {
  const conditionCountryList = condition.fields?.countryList || []
  return conditionCountryList
    .filter(({ fields }: ICountry) => fields)
    .map(({ fields: { alpha2CountryCode } }: ICountry) => alpha2CountryCode)
}
