import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { IPageGuidance } from "contentful-types"
import { getTalkToCarrotExpertGuidance } from "lib/contentful/contentfulClient"

const expertGuidanceQueryKey = "getExpertGuidance"

export function useGetExpertGuidance(locale: string): UseQueryResult<IPageGuidance, ResponseError> {
  return useQuery<IPageGuidance, ResponseError>([expertGuidanceQueryKey], () => getTalkToCarrotExpertGuidance(locale), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: false,
    retry: buildCarrotApiRetryPolicy(3),
    onError: (error) => reportErrorMessage(`Failed to fetch landing page guidance with error: ${error}`)
  })
}
