import CarrotClient from "carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { useQuery, UseQueryResult, useQueryClient } from "@tanstack/react-query"
import ResponseError from "../../../types/responseError"
import { UserConditionSubset } from "../types/UserInfo"
import { HttpErrors } from "utils/HttpErrors"
import { useAuthentication } from "components/context/authentication/AuthenticationProvider"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const ENDPOINT = "GetUserInfoConditionsSubset"

const loadUserInfo = async (handleSessionExpiration: () => void): Promise<UserConditionSubset> => {
  try {
    return client.getCurrentUserConditionsSubset()
  } catch (error) {
    if (error.name === HttpErrors.UNAUTHORIZED || error.name === HttpErrors.FORBIDDEN) {
      handleSessionExpiration()
    }
    return null
  }
}

export const getUserInfoConditionSubsetCacheKey = (): (string | number)[] => [ENDPOINT]

export default function useGetUserInfoConditionsSubset(): UseQueryResult<UserConditionSubset, ResponseError> {
  const { handleSessionExpiration } = useAuthentication()
  return useQuery<UserConditionSubset, ResponseError>(getUserInfoConditionSubsetCacheKey(), () =>
    loadUserInfo(handleSessionExpiration)
  )
}

export function useCreateOverride(): (attributes: UserConditionSubset) => void {
  const queryClient = useQueryClient()
  return (attributes: UserConditionSubset) => {
    queryClient.setQueryDefaults(getUserInfoConditionSubsetCacheKey(), { staleTime: Infinity, cacheTime: Infinity })
    queryClient.setQueryData(getUserInfoConditionSubsetCacheKey(), attributes)
  }
}
