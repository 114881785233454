import CarrotClient from "../../../lib/carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { UseMutationResult } from "@tanstack/react-query"
import { useToastsWithMutation } from "../../../utils/hooks/useToastsWithMutation"
import { useIntl } from "react-intl"
import useGetBankDetailsPageData from "./useGetBankDetailsPage"
import { useCurrentUser } from "components/context/user/UserContext"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)
const queryKey = "UPDATE_BANK_DETAILS"

export default function useUpdateBankDetails(): UseMutationResult {
  const intl = useIntl()
  const { refetchData } = useGetBankDetailsPageData()
  const { refreshUserInfo } = useCurrentUser()

  const getSuccessMessage = (): string => {
    return intl.formatMessage({ defaultMessage: "Bank details have been saved." })
  }
  // @ts-expect-error TS7006
  const request = (args) => client.updateBankDetails(args)

  const onSuccess = () => {
    refetchData()
    refreshUserInfo()
  }

  return useToastsWithMutation({
    request,
    queryKey,
    getSuccessMessage,
    onSuccess
  })
}
