import { Box, Container, Typography } from "@carrotfertility/carotene-core"
import { UnauthPreferredLocale } from "components/views/UnauthPreferredLocale"
import React, { ReactNode } from "react"

interface RegistrationFlowLayoutProps {
  header?: ReactNode | ReactNode[]
  description?: ReactNode | ReactNode[]
  interactiveElements?: ReactNode | ReactNode[]
  imageName?: string
  showLocaleSelect?: boolean
}

export function RegistrationFlowLayout({ ...props }: RegistrationFlowLayoutProps): JSX.Element {
  const showLocaleSelect = props.showLocaleSelect

  return (
    <Box
      bgcolor="#eeddcd"
      height="100%"
      width="100%"
      minHeight="100vh"
      paddingBottom={(theme) => ({ xs: 0, md: theme.spacing(theme.tokens.spacing.xxl) })}
    >
      <Box
        display={{ xs: "none", md: "block" }}
        padding={(theme) => theme.spacing(theme.tokens.spacing.lg, theme.tokens.spacing.xxxl)}
      >
        <Box
          component={"img"}
          src={`/images/CarrotGrey.svg`}
          alt=""
          height={(theme) => theme.spacing(theme.tokens.spacing.md)}
        />
      </Box>
      <Container
        component="main"
        maxWidth={false}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          border: `${theme.tokens.borderWidth.sm} solid white`,
          borderRadius: theme.tokens.borderRadius.md,
          minHeight: "100vh",
          maxWidth: 624,
          [theme.breakpoints.down("md")]: {
            padding: theme.spacing(theme.tokens.spacing.md)
          },
          [theme.breakpoints.up("md")]: {
            padding: theme.spacing(theme.tokens.spacing.xl),
            minHeight: "unset"
          }
        })}
      >
        {showLocaleSelect && <UnauthPreferredLocale showModal={true} />}
        <RegistrationStepLayout {...props} />
      </Container>
    </Box>
  )
}

function RegistrationStepLayout({ header, description, interactiveElements, imageName }: RegistrationFlowLayoutProps) {
  return (
    <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="center">
          {imageName ? (
            <Box
              component="img"
              src={`/images/${imageName}.png`}
              srcSet={`/images/${imageName}-2x.png`}
              alt=""
              marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
              marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
              height={228}
            />
          ) : (
            <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)} />
          )}
        </Box>
        <Typography
          color={(theme) => theme.palette.text.primary}
          variant="h3"
          component="h1"
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          {header}
        </Typography>
        {description}
      </Box>
      {interactiveElements}
    </Box>
  )
}

RegistrationFlowLayout.defaultProps = {
  showLocaleSelect: true
}
