import { PageWrapperProps, TileOrPhoneSupportActions } from "components/dynamic-content/util/dynamicContent"
import {
  ICalendlyLink,
  ITalkToCarrotLanguage,
  ITalkToCarrotLanguagePage,
  ITalkToCarrotSendAMessage,
  ITile,
  ITilePage
} from "contentful-types"
import React, { ReactElement } from "react"
import { GuidanceElement } from "./GuidanceElement"
import {
  fieldIsValid,
  getNextActionType,
  getNextLanguageAction,
  getNextTileAction,
  getPathForLanguage,
  getPathForTile,
  TileNextActionType
} from "../utils/mappingUtils"
import { TTCTilePageElement } from "./TTCTilePageElement"
import TwoColumnRightPrimary from "components/views/atoms/layouts/TwoColumnRightPrimary"
import { SendMessage } from "../../../components/talk-to-carrot/send-message/SendMessage"
import { talkToCarrotSource } from "../../../components/talk-to-carrot/send-message/SendMessageUtil"
import { TTCLanguagePageElement } from "./TTCLanguagePageElement"
import { Breadcrumb } from "@carrotfertility/carotene-core"
import { TalkToCarrotScheduleSpecialistChat } from "../../../components/talk-to-carrot/TalkToCarrotScheduleSpecialistChat"

type BaseContentProps = {
  tile?: ITile
  language?: ITalkToCarrotLanguage
  actions: TileOrPhoneSupportActions
  baseUrl: string
}

const BaseContent = ({ tile, language, actions, baseUrl }: BaseContentProps): ReactElement => {
  const nextAction = tile ? getNextTileAction(tile) : getNextLanguageAction(language)
  const destinationBaseUrl = tile ? getPathForTile({ tile, baseUrl }) : getPathForLanguage({ language, baseUrl })

  if (fieldIsValid(nextAction)) {
    switch (getNextActionType(nextAction)) {
      case TileNextActionType.TILE_PAGE:
        return <TTCTilePageElement page={nextAction as ITilePage} baseUrl={destinationBaseUrl} />
      case TileNextActionType.LANGUAGE_PAGE:
        return (
          <TTCLanguagePageElement
            page={nextAction as ITalkToCarrotLanguagePage}
            actions={actions}
            baseUrl={destinationBaseUrl}
          />
        )
      case TileNextActionType.CALENDLY_LINK:
        return <TalkToCarrotScheduleSpecialistChat calendlyLink={nextAction as ICalendlyLink} />
      case TileNextActionType.PHONE_SUPPORT_NUMBER:
        return null
      case TileNextActionType.TALK_TO_CARROT_SEND_A_MESSAGE:
        return (
          <TwoColumnRightPrimary
            secondaryContent={
              <>
                {fieldIsValid((nextAction as ITalkToCarrotSendAMessage)?.fields?.guidance) ? (
                  <GuidanceElement guidance={(nextAction as ITalkToCarrotSendAMessage)?.fields?.guidance} />
                ) : null}
              </>
            }
            primaryContent={<SendMessage source={talkToCarrotSource.SEND_A_MESSAGE} />}
          />
        )
    }
  } else {
    return null
  }
}

type TileContentProps = {
  tile: ITile
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
}

const TileContent = ({ tile, actions, baseUrl, breadcrumbs, Wrapper }: TileContentProps): ReactElement => {
  const routeContent = <BaseContent tile={tile} actions={actions} baseUrl={baseUrl} />
  return Wrapper ? <Wrapper breadcrumbs={breadcrumbs}>{routeContent}</Wrapper> : routeContent
}

type LanguageContentProps = {
  language: ITalkToCarrotLanguage
  actions: TileOrPhoneSupportActions
  baseUrl: string
  breadcrumbs: Array<ReactElement<typeof Breadcrumb>>
  Wrapper?: ({ children, breadcrumbs }: PageWrapperProps) => ReactElement
}

const LanguageContent = ({ language, actions, baseUrl, breadcrumbs, Wrapper }: LanguageContentProps): ReactElement => {
  const routeContent = <BaseContent language={language} actions={actions} baseUrl={baseUrl} />
  return Wrapper ? <Wrapper breadcrumbs={breadcrumbs}>{routeContent}</Wrapper> : routeContent
}
export { TileContent, LanguageContent }
