import React, { useLayoutEffect, useRef } from "react"
import { Provider } from "./types/Providers"
import { ResultsSidebar } from "./components/sidebar/ResultsSidebar"
import { ResultDisplay } from "./components/results/ResultDisplay"
import { Redirect, useLocation } from "react-router-dom"
import useGetProviders from "./hooks/useGetProviders"
import { useUserContext } from "./context/UserContext"
import {
  ProviderFinderParam,
  providerFinderRootPath,
  RedirectToProviderFinderURL
} from "./utils/providerFinderSearchUrl"
import { useMapViewContext } from "./context/MapViewContext"
import { Loading } from "./components/Loading"
import { Benefit } from "./types/Benefit"
import { Helmet } from "react-helmet"
import { useIntl } from "react-intl"
import { Box } from "@carrotfertility/carotene-core"

export function ResultsPage({ benefit }: { benefit: Benefit }): JSX.Element {
  const intl = useIntl()
  const { search: geocodeQueryString } = useLocation()
  const { companyCountryCode } = useUserContext()
  const params = new URLSearchParams(geocodeQueryString)
  const locationCountryCode = params.get(ProviderFinderParam.Country)
  const searchLocation = params.get(ProviderFinderParam.Search)
  const keyword = params.get(ProviderFinderParam.Keyword)
  params.set("companyCountryCode", companyCountryCode)
  const { data, isLoading, isError } = useGetProviders(params)
  const { showMapView } = useMapViewContext()
  const pageTitle = showMapView ? "Map view of providers" : "List of providers"

  const currentPage = Number(params.get(ProviderFinderParam.Page))
  const priorPage = useRef(null)
  useLayoutEffect(() => {
    if (currentPage !== priorPage.current) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      priorPage.current = currentPage
    }
  }, [currentPage, priorPage])

  if (isError) {
    return <Redirect to={`${providerFinderRootPath}/error`} />
  }

  // redirect Donor Assistance & Gestational Surrogacy searches
  const providerTypes = params.get(ProviderFinderParam.ProviderType)
  const providerTypeFromParams = providerTypes?.split(",") ?? []
  if (providerTypeFromParams.includes("4")) {
    params.set(
      ProviderFinderParam.ProviderType,
      [...providerTypeFromParams.filter((providerType) => providerType !== "4"), "35", "36"].join(",")
    )
    const redirectURL = new URL(window.location.toString().replace(window.location.search, ""))
    redirectURL.search = params.toString()
    return <RedirectToProviderFinderURL url={redirectURL} />
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            {
              defaultMessage: "{pageTitle} | Find a provider",
              description:
                "The title of the current page as shown in the browser's tab or window. {pageTitle} is the name of current page."
            },
            { pageTitle }
          )}
        </title>
      </Helmet>
      <Box sx={{ position: "relative" }}>
        <Box
          display="flex"
          justifyContent="flex-start"
          gap={[null, null, null, "48px"]}
          padding="8px 0 0 0"
          width="100%"
          id="provider-finder-results-container"
          data-provider-finder-results-view={showMapView ? "map" : "list"}
          height={showMapView ? "calc(100vh - 216px)" : "100%"}
        >
          <Box role="region" width="100%" maxWidth={["100%", "100%", "100%", 600]}>
            {isLoading ? (
              <Loading />
            ) : (
              <ResultDisplay
                locationCountryCode={locationCountryCode}
                providers={data as Provider[]}
                benefit={benefit}
              />
            )}
          </Box>
          <ResultsSidebar searchLocation={searchLocation} benefit={benefit} keyword={keyword} />
        </Box>
      </Box>
    </>
  )
}
