import React, { ReactNode } from "react"
import { Typography, Link, Alert, useTheme, Button, Box, Stack } from "@carrotfertility/carotene-core"
import { AppAccessPageLayout } from "components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "components/access-page/AppAccessPageCard"
import { Link as RouterLink } from "react-router-dom"
import { CenteredLoadingIndicator } from "components/views/molecules/Molecules"
import { FormattedMessage, useIntl } from "react-intl"
import { EmployeeSupportUrl } from "../utils/EmployeeSupportLink"

function RequestResetPasswordContent({ errorMessage }: { errorMessage?: ReactNode }): JSX.Element {
  const theme = useTheme()
  return (
    <Box textAlign="center">
      <Stack display="block" spacing={theme.spacing(theme.tokens.spacing.lg)}>
        {errorMessage ? (
          <Alert sx={{ textAlign: "left" }} severity="error">
            {errorMessage}
          </Alert>
        ) : (
          <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
            <Typography variant="h1">
              <FormattedMessage defaultMessage="Email sent" />
            </Typography>
            <Typography>
              <FormattedMessage
                defaultMessage="We've sent password reset instructions to the email addresses associated with your account. If you experience any issues, please <link>contact us</link>."
                values={{
                  link: (linkContent) => (
                    <Link href={EmployeeSupportUrl} target={"_blank"}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
          </Stack>
        )}
        <Button component={RouterLink} to={"/login"}>
          <FormattedMessage defaultMessage="Back to sign in" />
        </Button>
      </Stack>
    </Box>
  )
}

export default function RequestResetPassword({
  errorMessage,
  loading
}: {
  errorMessage: string
  loading: boolean
}): JSX.Element {
  const intl = useIntl()
  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Reset password" })}>
      <AppAccessPageCard>
        {loading ? (
          <Box padding={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
            <CenteredLoadingIndicator />
          </Box>
        ) : (
          <RequestResetPasswordContent {...{ errorMessage }} />
        )}
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
