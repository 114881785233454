import React, { FC } from "react"

import { CarrotCardModuleBase, CarrotCardModuleProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { SupportCenterLinks } from "utils/SupportCenterLinks"
import { ZendeskArrowLink } from "../../../shared/components/ZendeskArrowLink"

const CarrotCardDeductibleNotMetLockedModule: FC<CarrotCardModuleProps> = () => (
  <CarrotCardModuleBase
    backgroundColor={(theme) => ({ backgroundColor: theme.palette.error.light })}
    status="locked"
    body={<FormattedMessage defaultMessage="Your card will be unlocked when you meet your deductible for this year." />}
    links={[
      <>
        <ZendeskArrowLink supportCenterLink={SupportCenterLinks.USING_CARROT_WITH_INSURANCE}>
          <FormattedMessage defaultMessage="Learn more about your deductible" />
        </ZendeskArrowLink>
      </>
    ]}
  />
)

export { CarrotCardDeductibleNotMetLockedModule }
