import React, { FC } from "react"
import { CarrotCardModuleBase, CarrotCardModuleProps } from "components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage } from "react-intl"
import { Button, InternalIcon, Link } from "@carrotfertility/carotene-core"
import { ArrowLink } from "@carrotfertility/carotene"
import { DbgPaths } from "utils/Paths"

const CarrotCardLockedModule: FC<CarrotCardModuleProps> = ({ isVirtualCard }) => {
  const links = [
    <>
      <ArrowLink href={DbgPaths.CARROT_CARD}>
        <FormattedMessage defaultMessage="How to use your Carrot Card" />
      </ArrowLink>
    </>
  ]

  if (!isVirtualCard) {
    links.push(
      <Link endIcon={<InternalIcon fontSize="small" color="primary" />} href="/talk-to-carrot/sendmessage/stolen">
        <FormattedMessage defaultMessage="Report lost or stolen card" />
      </Link>
    )
  }

  links.push(
    <>
      <Button
        href="/talk-to-carrot/sendmessage/lockedcardsupport"
        variant="outlined"
        color="secondary"
        sx={(theme) => ({ marginTop: theme.tokens.spacing.md })}
      >
        <FormattedMessage defaultMessage="Contact Care Team" />
      </Button>
    </>
  )

  return (
    <CarrotCardModuleBase
      backgroundColor={(theme) => ({ backgroundColor: theme.palette.error.light })}
      status="locked"
      body={
        isVirtualCard ? (
          <FormattedMessage defaultMessage="Your virtual card has been locked. Check your email for details or contact us for further assistance." />
        ) : (
          <FormattedMessage defaultMessage="Your card has been locked. Check your email for details or contact us for further assistance." />
        )
      }
      links={links}
    />
  )
}

export { CarrotCardLockedModule }
