import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "utils/CarrotApiRetryPolicy"
import ResponseError from "types/responseError"
import { reportErrorMessage } from "utils/ErrorReporting"
import { ITileDisplay } from "contentful-types"
import { getScheduleAVideoChatOrCallDisplay } from "lib/contentful/contentfulClient"

const scheduleAVideoCallOrChatDisplay = "getScheduleAVideoChatOrCall"

export function useGetScheduleAVideoChatOrCallDisplay(locale: string): UseQueryResult<ITileDisplay, ResponseError> {
  return useQuery<ITileDisplay, ResponseError>(
    [scheduleAVideoCallOrChatDisplay],
    () => getScheduleAVideoChatOrCallDisplay(locale),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onError: (error) => reportErrorMessage(`Failed to fetch video chat or call tile with error: ${error}`)
    }
  )
}
