import React, { ReactElement, useState } from "react"
import { Typography, Link, useTheme, Stack, Divider, Dialog } from "@carrotfertility/carotene-core"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCurrentUser } from "../../context/user/UserContext"
import { useGetRoutingInfo, useStartJourneyChangeRoutingInfo } from "../shared/useQueryRoutingInfo"
import { useGetRegistrationJourney } from "../hooks/useGetRegistrationJourney"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { getShortJourneyLabel, useEnabledJourneyOptions, isJourneySupported } from "../hooks/useJourneyOptions"
import useGetMemberBenefit from "../hooks/useGetMemberBenefit"
import useCarrotPlan from "../hooks/useCarrotPlan"
import useCarrotPlanEmployeeSettings from "../hooks/useCarrotPlanEmployeeSettings"
import { useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { UpdateRoutingFlowModal } from "../shared/UpdateRoutingFlowModal"
import { useStartRouting } from "../shared/useQueryStartRouting"
import { useGetMostRecentlyCompletedRouting } from "../hooks/useGetMostRecentlyCompletedRouting"
import { Paths } from "../../../utils/Paths"

const journeyChangeUrl = "/carrot-plans/journey-change/routing"
const journeysWithExpectedUpdates = ["ASSISTED_REPRODUCTION", "GESTATIONAL", "PREGNANT"]

export function JourneyChange(): ReactElement {
  const { data: routingInfo, isLoading: isRoutingInfoLoading } = useGetRoutingInfo()
  const { data: registrationJourneyResult, isLoading: isRegistrationJourneyLoading } = useGetRegistrationJourney()
  const { data: mostRecentCompleteRouting, isLoading: isMostRecentCompleteRoutingLoading } =
    useGetMostRecentlyCompletedRouting()
  const { data: memberBenefit, isLoading: isMemberBenefitLoading } = useGetMemberBenefit()
  const { journeyOptions } = useEnabledJourneyOptions(memberBenefit)
  const { enrolledJourneySelectionHomePageDisplay, engagedJourneySelectionHomePageDisplay } = useFlags()
  const { data: carrotPlan, isLoading: isCarrotPlanLoading } = useCarrotPlan()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    isDoneForNow,
    appIsUnlockedAndPendingCarrotPlan
  } = useCarrotPlanEmployeeSettings()
  const { isInAppLockdown } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()
  const { startRouting } = useStartRouting(useStartJourneyChangeRoutingInfo(), journeyChangeUrl)
  const history = useHistory()
  const memberHasJourneyType = mostRecentCompleteRouting?.journey || routingInfo?.journey || registrationJourneyResult
  const isJourneyComplete = carrotPlan?.journeyCompleteTimestampUtc != null
  const isCarrotPlanOnTheWay =
    !isErrorEmployeeSettings &&
    !isJourneyComplete &&
    (carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested || appIsUnlockedAndPendingCarrotPlan)
  const shouldShowEnrolledJourneyDisplay =
    enrolledJourneySelectionHomePageDisplay && (isInAppLockdown || isJourneyComplete) && !isCarrotPlanOnTheWay
  const shouldShowEngagedJourneyDisplay =
    engagedJourneySelectionHomePageDisplay && !isInAppLockdown && !isJourneyComplete && memberHasJourneyType

  const allDataIsLoaded =
    !isMemberBenefitLoading &&
    !isRoutingInfoLoading &&
    !isRegistrationJourneyLoading &&
    !isCarrotPlanLoading &&
    !isLoadingEmployeeSettings &&
    !isMostRecentCompleteRoutingLoading

  async function sendToRouting(): Promise<Promise<void> | void> {
    if (isDoneForNow && !isPartnerAccessingAccount) {
      await startRouting()
    } else {
      history.push("/carrot-plans/about-you")
    }
  }

  if (shouldShowEnrolledJourneyDisplay && allDataIsLoaded) {
    let journeySelection: string = routingInfo?.journey
    journeySelection ??= registrationJourneyResult?.journey
    journeySelection = isJourneySupported({ journey: journeySelection, options: journeyOptions })
      ? journeySelection
      : null

    return (
      <PreCarrotPlanJourneyChange
        journeySelection={journeySelection}
        sendToRouting={sendToRouting}
        isJourneyComplete={isJourneyComplete}
      />
    )
  }

  if (shouldShowEngagedJourneyDisplay && allDataIsLoaded) {
    let journeySelection: string = mostRecentCompleteRouting?.journey
    journeySelection ??= routingInfo?.journey
    journeySelection ??= registrationJourneyResult?.journey

    return (
      <PostCarrotPlanJourneyChange
        journeySelection={journeySelection}
        isPendingCarrotPlan={appIsUnlockedAndPendingCarrotPlan}
      />
    )
  }

  return null
}

type PreCarrotPlanJourneyChangeProps = {
  journeySelection: string
  sendToRouting: () => void
  isJourneyComplete: boolean
}

function PreCarrotPlanJourneyChange({
  journeySelection,
  sendToRouting,
  isJourneyComplete
}: PreCarrotPlanJourneyChangeProps): ReactElement {
  const theme = useTheme()

  return (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      {journeySelection ? <JourneySelection journeySelection={journeySelection} /> : <ReadyToUnlockYourBenefit />}
      <Link component="button" onClick={sendToRouting}>
        {journeySelection && !isJourneyComplete && (
          <FormattedMessage defaultMessage="Tell us about your journey to unlock your benefit" />
        )}
        {journeySelection && isJourneyComplete && <FormattedMessage defaultMessage="Update us on your journey" />}
        {!journeySelection && <FormattedMessage defaultMessage="Tell us about your journey" />}
      </Link>
    </Stack>
  )
}

type JourneySelectionProps = {
  journeySelection: string
  showDivider?: boolean
}

function JourneySelection({ journeySelection, showDivider = true }: JourneySelectionProps): ReactElement {
  const theme = useTheme()
  const intl = useIntl()
  const journeyLabel = getShortJourneyLabel(journeySelection)
  return journeyLabel ? (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Typography>
        <FormattedMessage defaultMessage="Your journey:" /> {intl.formatMessage(journeyLabel)}
      </Typography>
      {showDivider && <Divider orientation="vertical" />}
    </Stack>
  ) : null
}

function ReadyToUnlockYourBenefit(): ReactElement {
  return (
    <Typography>
      <FormattedMessage defaultMessage="Ready to unlock your benefit?" />
    </Typography>
  )
}

type PostCarrotPlanJourneyChangeProps = {
  journeySelection: string
  isPendingCarrotPlan: boolean
}

function UpdateUsButton() {
  const theme = useTheme()
  const history = useHistory()

  function handleUpdateUsClick() {
    history.push(Paths.REFRESH_CARROT_PLAN)
  }

  return (
    <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
      <Divider orientation="vertical" />
      <Link component="button" onClick={handleUpdateUsClick}>
        <FormattedMessage defaultMessage="Update us" />
      </Link>
    </Stack>
  )
}

function PostCarrotPlanJourneyChange({
  journeySelection,
  isPendingCarrotPlan
}: PostCarrotPlanJourneyChangeProps): ReactElement {
  const theme = useTheme()
  const [openJourneyChangeModal, setOpenJourneyChangeModal] = useState(false)
  const { startRouting } = useStartRouting(useStartJourneyChangeRoutingInfo(), journeyChangeUrl)
  const intl = useIntl()
  const header = intl.formatMessage({ defaultMessage: "We've got a few questions..." })
  const isJourneyUpdateExpected = journeysWithExpectedUpdates.includes(journeySelection)

  async function openModal(event: { preventDefault: () => void }) {
    event.preventDefault()
    setOpenJourneyChangeModal(true)
  }

  return (
    <>
      <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <JourneySelection journeySelection={journeySelection} showDivider={!isPendingCarrotPlan} />
        {!isPendingCarrotPlan && (
          <Link component="button" onClick={openModal}>
            <FormattedMessage defaultMessage="Change journeys" />
          </Link>
        )}
        {isJourneyUpdateExpected ? <UpdateUsButton /> : null}
      </Stack>
      <Dialog aria-labelledby="journey-change-modal" scroll="body" open={openJourneyChangeModal}>
        <UpdateRoutingFlowModal
          header={header}
          clickGoBack={() => setOpenJourneyChangeModal(false)}
          clickUpdateMyAnswers={startRouting}
        />
      </Dialog>
    </>
  )
}
