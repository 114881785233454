import * as cmdWorkflows from "./flows/cmd"
import * as acaWorkflows from "./flows/aca"
import * as emdWorkflows from "./flows/emd"

export enum Workflows {
  CMD = "CMD",
  ACA_ONLY = "ACAOnly",
  EMD = "EMD"
}

export const benefitEnrollmentWorkflows = [
  { name: Workflows.CMD, workflow: cmdWorkflows.benefitEnrollmentFlow },
  { name: Workflows.ACA_ONLY, workflow: acaWorkflows.benefitEnrollmentFlow },
  { name: Workflows.EMD, workflow: emdWorkflows.benefitEnrollmentFlow }
]

export const connectInsuranceWorkflows = [
  { name: Workflows.CMD, workflow: cmdWorkflows.connectInsuranceFlow },
  { name: Workflows.EMD, workflow: emdWorkflows.connectInsuranceFlow }
]
