import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { useTrackView } from "utils/heap"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { continueToRouting } from "./RegistrationComplete"

interface AnswersProps {
  onBack: () => void
  onComplete: () => void
}

export function Answers({ onComplete, onBack }: AnswersProps): JSX.Element {
  useTrackView("AnswersInformationalRegFlow", "Answers screen")
  const theme = useTheme()
  const intl = useIntl()
  const header = intl.formatMessage({
    defaultMessage: "Expert help is just a tap away"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-experts"
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
            <Typography>
              <FormattedMessage defaultMessage="Need help with your benefit? Have a medical question? Don’t know where to start?" />
            </Typography>
            <Typography>
              <FormattedMessage defaultMessage="Schedule a chat with a Carrot Expert, or browse our library of articles and videos." />
            </Typography>
          </Stack>
        }
        interactiveElements={
          <RegistrationButtonFooter
            onBack={onBack}
            getCarrotPlanButton={
              <Button onClick={continueToRouting} variant="text" color="secondary">
                <FormattedMessage defaultMessage="Get your Carrot Plan" />
              </Button>
            }
            continueButton={
              <ContinueButton onClick={onComplete}>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
          />
        }
      />
    </>
  )
}
