import CarrotClient from "../../../lib/carrot-api"
import Settings from "../../../utils/CarrotConfig"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { CarrotPlanStepRequest } from "../../../types/carrotPlanTypes"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import ResponseError from "../../../types/responseError"

// @ts-expect-error TS7009
const client = new CarrotClient(Settings.CARROT_BACKEND_URL)

const submitData = async (updateStepData: CarrotPlanStepRequest): Promise<void> => {
  return await client.updateCarrotPlanStep(updateStepData)
}

export default function useUpdatePlanStep(): UseMutationResult<void, ResponseError> {
  return useMutation((updateStepData: CarrotPlanStepRequest) => submitData(updateStepData), {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    }
  })
}
