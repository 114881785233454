import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import styled from "styled-components"
import { Spacer } from "../atoms/Atoms"
import { RetinaImage } from "../atoms/images/RetinaImage"
import { H1 } from "@carrotfertility/carotene"

const StyledRetinaImage = styled(RetinaImage)`
  margin-top: ${(props) => props.top}px;
`

export const TitleWithIcon = ({ title, icon, top }) => (
  <div className="flex">
    <StyledRetinaImage name={icon} height={36} width={36} top={top} />
    <Spacer width={0.5} />
    <H1 variant="primary">{title}</H1>
  </div>
)

export const CenteredLoadingIndicator = ({ id = "centered-loading-indicator", ...otherProps }) => (
  <div {...otherProps} className="flex items-center justify-center flex-auto">
    <div className="lds-ellipsis" id={id}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

const StyledResponsiveWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -${(props) => props.padding}rem;
  & > * {
    min-width: 232px;
    margin: ${(props) => props.padding}rem;
    flex-grow: 1;
    width: calc(50% - ${(props) => props.padding * 2}rem);
    height: auto;
  }
`

export const ResponsiveWrapper = ({ children, padding }) => (
  <StyledResponsiveWrapper padding={padding}>{children}</StyledResponsiveWrapper>
)

export const StylelessButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`

export const FadeTransition = ({ children }) => (
  <TransitionGroup>
    <CSSTransition timeout={{ exit: 200, enter: 600, appear: 600 }} classNames="fade" appear>
      {children}
    </CSSTransition>
  </TransitionGroup>
)
